import { FC } from 'react';

import { useFormat, ValuationHooks } from '../../../../hooks';
import { eventFormType, EventTypeBackend } from '../../../../types/events.types';
import { toNumber } from '../../../../utils/toNumber';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const Payout: FC<CardProps> = ({ companyId, event }) => {
  const { format } = useFormat();
  const { valuation } = ValuationHooks.useCurrent({ companyId });
  if (event.type !== EventTypeBackend.PAYOUT) return <></>;

  const {
    type,
    exercise: {
      exercised,
      vestingTask: {
        grantItem: { stakeholder },
      },
    },
    createdAt,
  } = event;

  return (
    <>
      <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm">
        <TransactionTitleItem
          createdAt={format(createdAt, 'dd/MM/yyyy')}
          name={eventFormType[type]}
        />
        <TransactionOverviewItem title={'Transaction Type'} value={eventFormType[type]} />
        <TransactionOverviewItem title={'Stakeholder'} value={stakeholder.fullName} />
        <TransactionOverviewItem title={'Shares  Paid Out'} value={exercised} />
        <TransactionOverviewItem
          title={'Total Payout'}
          value={`${(exercised * toNumber(valuation?.sharePrice)).toLocaleString('en-US')}`}
        />
      </div>
    </>
  );
};
