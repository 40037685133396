import { Column, DetailsSortVariant, ViewBy } from '../types/snapshot.types';
import { localStorageUtil } from '../utils/local-storage';

export const capTableStore = localStorageUtil<
  {
    customCapTableDate: Date | null;
  } & Record<
    DetailsSortVariant,
    {
      viewBy: Record<ViewBy, boolean>;
      columns: Column[];
    } | null
  >
>('capTableStore');
