import React, { FC, ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Calendar, CalendarProps } from '../../../components/Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../../../components/Popover';

type DatePickerProps = {
  onSelect?: (date: Date) => void;
  children: ReactNode;
  value?: Date | null;
  isDefaultOpenCalendar?: boolean;
  wrapperClassName?: string;
  calendar?: Except<CalendarProps, 'selected' | 'mode' | 'initialFocus'>;
  disabled?: boolean;
  defaultMonth?: Date;
};

export const DatePicker: FC<DatePickerProps> = ({
  children,
  onSelect,
  value,
  isDefaultOpenCalendar,
  wrapperClassName,
  calendar,
  disabled = false,
  defaultMonth,
}) => {
  const [isOpenCalendar, setOpenCalendar] = useState(isDefaultOpenCalendar ?? false);

  return (
    <Popover onOpenChange={setOpenCalendar} open={isOpenCalendar}>
      <PopoverTrigger className={twMerge('relative w-full cursor-pointer', wrapperClassName)}>
        <div
          className="absolute z-[100] h-full w-full"
          onClick={() => setOpenCalendar((prev) => !prev)}
        />
        {children}
      </PopoverTrigger>
      <PopoverContent className="z-[100] w-auto p-0">
        <Calendar
          {...calendar}
          defaultMonth={
            new Date(defaultMonth || 0)?.getTime() === new Date(0).getTime()
              ? undefined
              : defaultMonth
          }
          disabled={disabled}
          initialFocus
          mode="single"
          onSelect={(date) => {
            if (!date) return;
            setOpenCalendar(false);
            onSelect?.(date);
          }}
          selected={value || undefined}
        />
      </PopoverContent>
    </Popover>
  );
};
