import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Pool } from '../../types/pool-plans.types';
import { useAppQuery } from '../useAppQuery';

type TerminatedPools = {
  companyId: string;
} & (
  | {
      take: number;
      currentPage: number;
    }
  | { take?: never; currentPage?: never }
);

export const useTerminatedPools = ({ companyId, currentPage, take }: TerminatedPools) => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useAppQuery<{ pools: Pool[]; totalPages: number }>({
    queryKey: [QueryKey.GET_TERMINATED_POOLS, companyId, { currentPage, take }],
    queryFn: () =>
      ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'terminated-pools'] },
        {
          queryParams: {
            ...(take && currentPage
              ? {
                  take,
                  skip: (currentPage >= 1 ? currentPage - 1 : 0) * take,
                }
              : {}),
          },
        },
      ),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_TERMINATED_POOLS] });
  }, [queryClient]);

  return {
    ...data,
    pools: data?.pools || [],
    invalidateQuery,
    isLoading,
  };
};
