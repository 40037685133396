import { FlagCode } from '../../components/Flag';
import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { ApiService } from '../../services';
import { useAppQuery } from '../useAppQuery';
import { useInvalidateQueries } from '../useInvalidateQueries';

type PlanProps = {
  onError?: (error: HttpException | unknown) => void;
  enabled?: boolean;
};

export const useCountryCode = ({ enabled, onError }: PlanProps = {}) => {
  const { invalidateQuery } = useInvalidateQueries(QueryKey.GET_IP_COUNTRY_CODE);

  const { data: countryCode, isLoading } = useAppQuery<Lowercase<FlagCode>>({
    queryKey: [QueryKey.GET_IP_COUNTRY_CODE],
    queryFn: () => ApiService.get({ endpoint: BackendRoute.IP_INFO, routePath: ['county-code'] }),
    onError,
    enabled,
  });

  return { countryCode, invalidateQuery, isLoading };
};
