import React, { FC, memo, useRef, useState } from 'react';
import { Control } from 'react-hook-form';

import { ChevronDownIcon, SearchIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../../components/Combobox';
import { QueryKey } from '../../../../constants';
import { useAppQuery } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ApiCountryInfoService, RequestType, ResponseDataType } from '../../../../services';
import { useFilteredData } from './CompanySetupStepTwo';
import { FormSchema } from './Validation';

export type CityOfStateItemsListProps = {
  data: ResponseDataType<RequestType.GET_CITIES_BY_COUNTRY_AND_STATE>;
  control: Control<FormSchema>;
  selectedCountry: Partial<FormSchema['country']> | undefined;
  selectedState: FormSchema['state'] | undefined;
  isLoading: boolean;
  isDataEmpty: boolean;
};

const CityOfStateItemsList: FC<CityOfStateItemsListProps> = memo(
  ({ data, control, selectedCountry, selectedState, isDataEmpty, isLoading }) => {
    if (!data || !selectedState || !selectedCountry || isLoading) return <span>Loading ...</span>;

    if (isDataEmpty)
      return (
        <FormComboboxOption
          control={control}
          key={`${selectedState.name || selectedCountry.name}_${selectedState.iso2Code || selectedCountry.iso2Code}`}
          name="city"
          value={selectedState.name ?? selectedCountry.name ?? ''}
        >
          <span className="text-sm text-gray-700">
            {selectedState.name || selectedCountry.name}
          </span>
        </FormComboboxOption>
      );

    return (
      <>
        {data.map(({ name, id }) => (
          <FormComboboxOption control={control} key={`${id}_${name}`} name="city" value={name}>
            <span className="text-sm text-gray-700">{name}</span>
          </FormComboboxOption>
        ))}
      </>
    );
  },
);

CityOfStateItemsList.displayName = 'CityOfStateItemsList';

export type CityOfStateComboboxProps = {
  control: Control<FormSchema>;
  selectedState: FormSchema['state'] | undefined;
  selectedCountry: Partial<FormSchema['country']> | undefined;
};

const CityOfStateCombobox: FC<CityOfStateComboboxProps> = memo(
  ({ control, selectedCountry, selectedState }) => {
    const [inputValue, setInputValue] = useState('');

    const { data: cityData, isLoading } = useAppQuery({
      queryKey: [QueryKey.GET_CITY, selectedState?.name, { inputValue }],
      queryFn: async () => {
        return ApiCountryInfoService({
          countryCode: selectedCountry?.iso2Code || '',
          stateCode: selectedState?.iso2Code || '',
          requestType: RequestType.GET_CITIES_BY_COUNTRY_AND_STATE,
        });
      },
      enabled: !!selectedState?.iso2Code && !!selectedCountry?.iso2Code,
    });

    const regExp = new RegExp(inputValue, 'gi');
    const cityFilteredData = useFilteredData({
      data: cityData,
      inputValue,
      filterFunc: ({ name }) => regExp.test(name),
    }).sort((a, b) => a.name.localeCompare(b.name));

    const ref = useRef<HTMLInputElement>(null);

    return (
      <FormCombobox
        className="relative w-full"
        control={control}
        disabled={!selectedCountry?.iso2Code || !selectedState?.iso2Code}
        name={'city'}
        onClose={() => setInputValue('')}
        onSelect={() => setInputValue('')}
      >
        <div className="relative">
          <FormComboboxInput
            control={control}
            icon={<ChevronDownIcon className="mt-3" />}
            name={'city'}
            placeholder={<AppFormattedMessage id={StringKey.CITY} />}
            readOnly
          />
          <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
        </div>
        <ComboboxOptions className="w-full">
          <div className="relative flex items-center" onClick={() => ref.current?.focus()}>
            <SearchIcon className="absolute ml-[6px]" />
            <input
              className="w-full rounded border-[2px] border-transparent bg-gray-50 p-[6px] pl-8 text-gray-700 outline-none placeholder:text-gray-400 focus:border-brand-700 focus:bg-white"
              onChange={({ target: { value } }) => setInputValue(value)}
              ref={ref}
              value={inputValue}
            />
          </div>
          <CityOfStateItemsList
            control={control}
            data={cityFilteredData}
            isDataEmpty={cityData?.length === 0}
            isLoading={isLoading}
            selectedCountry={selectedCountry}
            selectedState={selectedState}
          />
        </ComboboxOptions>
      </FormCombobox>
    );
  },
);

CityOfStateCombobox.displayName = 'CityOfStateCombobox';

export default CityOfStateCombobox;
