import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { StringKey } from '../../../../lang';
import { SharePlanType } from '../../../../types/pool-plans.types';
import { Rsa, StockOption, WarrantExercise } from './StepComponents';
import { ChildrenFormProps } from './type';
import { FormSchema, stepOne as stepOneFormSchema } from './validation';

export type StepOneProps = ChildrenFormProps;

export const StepOne: FC<StepOneProps> = ({
  handleCloseModal,
  nextFormStep,
  control,
  setFormData,
  companyId,
  filedState,
  type,
  valuation,
  resetField,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { success } = stepOneFormSchema.safeParse(stepOne);

  const isValid = success && !filedState('stepOne').invalid;

  stepOne;

  if (!type || !valuation) return;

  return (
    <>
      <div className="flex max-h-[460px] w-full flex-col gap-2 overflow-y-auto">
        {type === SharePlanType.STOCK_OPTIONS && (
          <StockOption
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
        {type === SharePlanType.RSA && (
          <Rsa
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
        {type === SharePlanType.WARRANTS && (
          <WarrantExercise
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
      </div>

      <div className="flex h-[44px] w-full justify-between gap-3">
        <Button
          className="w-fit bg-transparent p-3 text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-[260px] p-3 text-sm font-[550] text-gray-25"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            <AppFormattedMessage id={StringKey.NEXT} />
          </Button>
        </div>
      </div>
    </>
  );
};
