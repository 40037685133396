import { FC, ReactNode, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { twMerge } from 'tailwind-merge';

import {
  AddDocumentIcon,
  ChevronDownIcon,
  EditIcon,
  EyeIconSmall,
  PinIcon,
  ThreeDotsIcon,
  TrashBinIcon,
} from '../../../../assets/icons';
import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import Button from '../../../../components/Button';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../components/HoverCard';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '../../../../components/Popover';
import { QueryKey } from '../../../../constants';
import { EventHooks, useFormat, useInvalidateQueries, useModalState } from '../../../../hooks';
import {
  eventFormType,
  EventTypeBackend,
  EventUnion,
  SortByDate,
} from '../../../../types/events.types';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { EditPlanModal } from '../../../PoolPlans/PlanForm/Edit';
import { EditPoolModal } from '../../../PoolPlans/PoolForm';
import { DeleteEventModal, FilesModal } from '../../Modals';
import { Tag, TagVariant } from '../../Tag';
import { Approval } from './EventTableTypes/Approval';
import { Buyback } from './EventTableTypes/Buyback';
import { Conversion } from './EventTableTypes/Conversion';
import { Exercised } from './EventTableTypes/Exercise';
import { Expire } from './EventTableTypes/Expire';
import { FundraisingRound } from './EventTableTypes/FundraisingRound';
import { GrantBody } from './EventTableTypes/Grant';
import { NewPlan } from './EventTableTypes/NewPlan';
import { NewPool } from './EventTableTypes/NewPool';
import { Payout } from './EventTableTypes/Payout';
import { Secondaries } from './EventTableTypes/Secondaries';
import { ShareIssuance } from './EventTableTypes/ShareIssuance';
import { TableBodyProps } from './EventTableTypes/types';
import { Valuation } from './EventTableTypes/Valuation';

const tableBodyConstructor: Record<EventTypeBackend, (props: TableBodyProps) => ReactNode> = {
  [EventTypeBackend.GRANT]: (props) => <GrantBody {...props} />,
  [EventTypeBackend.FUNDRAISING_ROUND]: (props) => <FundraisingRound {...props} />,
  [EventTypeBackend.SHARE_ISSUANCE]: (props) => <ShareIssuance {...props} />,
  [EventTypeBackend.SECONDARIES]: (props) => <Secondaries {...props} />,
  [EventTypeBackend.BUYBACK]: (props) => <Buyback {...props} />,
  [EventTypeBackend.VALUATION]: (props) => <Valuation {...props} />,
  [EventTypeBackend.NEW_POOL]: (props) => <NewPool {...props} />,
  [EventTypeBackend.CONVERSION]: (props) => <Conversion {...props} />,
  [EventTypeBackend.NEW_PLAN]: (props) => <NewPlan {...props} />,
  [EventTypeBackend.EXERCISE]: (props) => <Exercised {...props} />,
  [EventTypeBackend.APPROVAL]: (props) => <Approval {...props} />,
  [EventTypeBackend.PAYOUT]: (props) => <Payout {...props} />,
  [EventTypeBackend.EXPIRE]: (props) => <Expire {...props} />,
};

export type EventItemProps = {
  onView: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onTransactionView: (id: string, transactionId: string) => void;
  onTransactionDelete: (event: EventUnion, transactionId: string) => void;
  onTransactionEdit: (id: string, transactionId: string) => void;
  event: EventUnion;
  companyId: string;
  selectedDateFilter: SortByDate;
  selectedCustomDateFilter: DateRange;
};

export const EventItem: FC<EventItemProps> = ({
  event,
  onDelete,
  onEdit,
  onView,
  onTransactionDelete,
  onTransactionView,
  onTransactionEdit,
  companyId,
  selectedDateFilter,
  selectedCustomDateFilter,
}) => {
  const [isPinned, setPinned] = useState(event.pinned);
  const { type, createdAt, filesLinks } = event;

  const mergedEventUnion = event as MergeUnion<EventUnion>;
  const [isExpanded, setExpanded] = useState(false);
  const { format } = useFormat();

  const { isOpen, handleCloseModal, handleSuccessModal, handleOpenModal, toggler } = useModalState({
    onSuccess: () => {
      onDelete();
    },
  });
  const {
    isOpen: isOpenFilesModal,
    handleCloseModal: handleCloseFilesModal,
    handleSuccessModal: handleSuccessFilesModal,
    handleOpenModal: handleOpenFilesModal,
    toggler: filesModalToggler,
  } = useModalState({ onSuccess: () => handleDownloadAllDocuments(filesLinks) });

  const {
    handleCloseModal: handleCloseEditPoolModal,
    handleOpenModal: handleOpenEditPoolModal,
    isOpen: isOpenEditPoolModal,
  } = useModalState({});

  const {
    handleCloseModal: handleCloseEditPlanModal,
    handleOpenModal: handleOpenEditPlanModal,
    isOpen: isOpenEditPlanModal,
  } = useModalState({});

  const transactions = mergedEventUnion.items || [];
  const { pinEvent } = EventHooks.usePin({ companyId });
  const { invalidateQuery } = useInvalidateQueries(QueryKey.GET_EVENTS, QueryKey.GET_EVENT);
  const originalName = mergedEventUnion.valuation?.name || mergedEventUnion.name;

  const isPoolOrPlan = type === EventTypeBackend.NEW_PLAN || type === EventTypeBackend.NEW_POOL;

  const name = originalName || eventFormType[type];

  const haveOneTransaction = type === EventTypeBackend.VALUATION || isPoolOrPlan;

  const shouldRenderPrePostValuation =
    type === EventTypeBackend.FUNDRAISING_ROUND || type === EventTypeBackend.SHARE_ISSUANCE;

  const postMoneyValuation: number =
    (mergedEventUnion.valuation?.sharePrice || 0) * (mergedEventUnion.valuation?.issuedShares || 0);

  const preValuation: number =
    (mergedEventUnion.valuation?.sharePrice || 0) *
    (mergedEventUnion.valuation?.issuedSharesOnStart || 0);

  const handleDocumentClick = (link: string) => {
    window.open(link, '_blank');
  };

  const handleDownloadAllDocuments = async (links: string[]) => {
    for (let i = 0; i < links.length; i++) {
      await new Promise<void>((resolve) => {
        const link = links[i];
        const a = document.createElement('a');
        a.href = link;
        a.download = '';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        setTimeout(() => {
          resolve();
        }, 1000);
      });
    }
  };

  return (
    <div className="h-full w-full">
      <EditPoolModal
        companyId={companyId}
        handleClose={handleCloseEditPoolModal}
        handleTerminate={() => ''}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditPoolModal}
        onEditClick={() => ''}
        poolId={event.type === EventTypeBackend.NEW_POOL ? event.pool.id : ''}
        previewMode={true}
        step={3}
      />
      <EditPlanModal
        companyId={companyId}
        handleClose={handleCloseEditPlanModal}
        handleTerminate={() => ''}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditPlanModal}
        onEditClick={() => ''}
        planId={event.type === EventTypeBackend.NEW_PLAN ? event.sharePlan.id : ''}
        previewMode={true}
        step={5}
      />
      <AlertDialogWrapper control={{ onOpenChange: toggler, open: isOpen }}>
        <DeleteEventModal
          eventName="Event"
          onClose={handleCloseModal}
          onSuccess={handleSuccessModal}
        />
      </AlertDialogWrapper>
      <AlertDialogWrapper control={{ onOpenChange: filesModalToggler, open: isOpenFilesModal }}>
        <FilesModal
          createdAt={format(createdAt, 'dd/MM/yyyy')}
          filesLinks={filesLinks}
          name={name}
          onClose={handleCloseFilesModal}
          onDownloadFile={handleDocumentClick}
          onSuccess={handleSuccessFilesModal}
        />
      </AlertDialogWrapper>
      <div
        className={twMerge(
          'flex w-full flex-col rounded-md border border-gray-100 transition-all',
          isExpanded && 'h-fit bg-gray-50',
        )}
      >
        <div
          className={twMerge('flex w-full items-center justify-between border-gray-100 px-4 py-3')}
        >
          <div className="flex w-full max-w-[calc(100%-500px)] flex-col">
            <div className="flex items-center gap-3">
              <span className="truncate text-base font-semibold text-gray-700">{name}</span>
              {event.type === EventTypeBackend.FUNDRAISING_ROUND && event.isOpen && (
                <Tag title="Open Round" variant={TagVariant.GREEN} />
              )}
              {event.type === EventTypeBackend.FUNDRAISING_ROUND && !event.isOpen && (
                <Tag title="Closed Round" variant={TagVariant.RED} />
              )}
            </div>
            <div className="flex items-center text-label-sm font-medium text-gray-500">
              {type === EventTypeBackend.NEW_PLAN && `${event.sharePlan.name} - `}
              {type === EventTypeBackend.NEW_POOL && `${event.pool.name} - `}
              {format(createdAt, 'dd/MM/yyyy')} - {haveOneTransaction ? 1 : transactions.length}{' '}
              {transactions.length > 1 ? 'Transactions' : 'Transaction'}
              {isPinned && (
                <HoverCard>
                  <HoverCardTrigger
                    className="ml-4"
                    onClick={() => {
                      pinEvent({ eventId: event.eventId, pinState: false });
                      setPinned((prev) => !prev);
                      invalidateQuery();
                    }}
                  >
                    <PinIcon className="rotate-45" iconColor="#2565C8" />
                  </HoverCardTrigger>
                  <HoverCardContent className="max-w-[360px] rounded-lg bg-[#101828] px-3 py-2">
                    <span className="block w-full truncate p-0 text-label-md font-[550] text-white">
                      Unpin
                    </span>
                  </HoverCardContent>
                </HoverCard>
              )}
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              {shouldRenderPrePostValuation && (
                <Tag
                  borderColor="border-brand-200"
                  title={`Pre-Money Valuation: ${preValuation.toLocaleString('en-US')}`}
                  variant={TagVariant.BLUE}
                />
              )}
              {shouldRenderPrePostValuation && (
                <Tag
                  borderColor="border-forest-200"
                  title={`Post-Money Valuation: ${postMoneyValuation.toLocaleString('en-US')}`}
                  variant={TagVariant.GREEN}
                />
              )}
              {type === EventTypeBackend.VALUATION && (
                <Tag
                  borderColor="border-brand-200"
                  title={`Pre-Money Valuation: ${((mergedEventUnion.issuedSharesOnStart || 0) * (mergedEventUnion.sharePrice || 0)).toLocaleString('en-US')}`}
                  variant={TagVariant.BLUE}
                />
              )}
            </div>
            <HoverCard>
              <HoverCardTrigger>
                <AddDocumentIcon
                  className="size-6 cursor-pointer p-[2px]"
                  onClick={() =>
                    filesLinks.length > 0 &&
                    (filesLinks.length === 1
                      ? handleDocumentClick(filesLinks[0])
                      : handleOpenFilesModal())
                  }
                />
              </HoverCardTrigger>
              <HoverCardContent className="max-w-[360px] rounded-lg bg-[#101828] px-3 py-2">
                <span className="block w-full truncate p-0 text-label-md font-[550] text-white">
                  {filesLinks.length === 1
                    ? getS3FileOriginalName(filesLinks[0])
                    : `${filesLinks.length} files attached to event`}
                </span>
              </HoverCardContent>
            </HoverCard>
            <Popover>
              <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
                <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
                  <ThreeDotsIcon />
                </div>
              </PopoverTrigger>
              <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
                <PopoverClose>
                  <div
                    className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={
                      !isPoolOrPlan
                        ? onView
                        : type === EventTypeBackend.NEW_POOL
                          ? handleOpenEditPoolModal
                          : handleOpenEditPlanModal
                    }
                  >
                    <EyeIconSmall />
                    <span className="text-sm font-normal text-gray-700">View Details</span>
                  </div>
                  {!isPoolOrPlan && (
                    <>
                      <div
                        className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                        onClick={onEdit}
                      >
                        <EditIcon />
                        <span className="text-sm font-normal text-gray-700">Edit Details</span>
                      </div>
                      <div
                        className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                        onClick={() => {
                          pinEvent({ eventId: event.eventId, pinState: !event.pinned });
                          setPinned((prev) => !prev);
                        }}
                      >
                        <PinIcon />
                        <span className="text-sm font-normal text-gray-700">
                          {isPinned ? 'Unpin' : 'Pin'} Event
                        </span>
                      </div>
                      <div
                        className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                        onClick={handleOpenModal}
                      >
                        <TrashBinIcon iconColor="#F04438" />
                        <span className="text-sm font-normal text-fireside-500">Delete Event</span>
                      </div>
                    </>
                  )}
                </PopoverClose>
              </PopoverContent>
            </Popover>
            <Button
              className={twMerge('flex w-fit items-center justify-center p-1 hover:bg-gray-50')}
              onClick={() => setExpanded((prev) => !prev)}
              styleType="NONE"
            >
              <ChevronDownIcon className={twMerge('transition-all', isExpanded && 'rotate-180')} />
            </Button>
          </div>
        </div>
        {isExpanded && (
          <div className="flex flex-col overflow-x-auto rounded-md bg-white px-2 pt-3 shadow-sm">
            {tableBodyConstructor[type]({
              ...event,
              selectedCustomDateFilter,
              selectedDateFilter,
              onView: onTransactionView,
              onDelete: onTransactionDelete,
              onEdit: onTransactionEdit,
              companyId,
            })}
          </div>
        )}
      </div>
    </div>
  );
};
