import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { SharePlanUnionType } from '../../types/pool-plans.types';
import { useAppQuery } from '../useAppQuery';

export const useSharePlan = ({ companyId, planId }: { companyId: string; planId: string }) => {
  const queryClient = useQueryClient();

  const { data: sharePlan, isLoading } = useAppQuery<SharePlanUnionType>({
    queryKey: [QueryKey.GET_SHARE_PLAN, companyId, planId],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'share-plans', planId],
      }),
    enabled: !!companyId && !!planId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SHARE_PLAN] });
  }, [queryClient]);

  return {
    invalidateQuery,
    sharePlan,
    isLoading,
  };
};
