import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { ArrowIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { QueryKey } from '../../constants';
import { AnalyticHooks, Pool, SharePlan, useModalState, useSelectedCompany } from '../../hooks';
import { StringKey } from '../../lang';
import { PossibleAction } from '../../types/google-analytics-types';
import { PageRoute } from '../../types/pageTypes';
import { PoolPlansTab, sharePlanTypeTitle } from '../../types/pool-plans.types';
import { EditPlanModal } from '../PoolPlans/PlanForm/Edit';
import { SelectedPlan } from '../PoolPlans/PoolPlans';
import { PoolMobileDatePicker } from '../PoolPlans/PoolPlansMobile';
import { StakeholderCard } from '../PoolPlans/PoolPlansMobile/Cards/StakeholderCard';
import { PoolPlansPopover } from '../PoolPlans/PoolPlansTable';

const PlanDetails: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { poolId, planId } = useParams();
  const [date, setDate] = useState<Date | undefined>(new Date());
  const { selectedCompany } = useSelectedCompany();
  const [selectedTab, setTab] = useState<PoolPlansTab>(PoolPlansTab.ACTIVE);
  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan | null>({
    id: '',
    step: 1,
    mode: 'EDIT',
  });
  const {
    handleCloseModal: handleCloseEditPlanModal,
    handleOpenModal: handleOpenEditPlanModal,
    isOpen: isOpenEditPlanModal,
  } = useModalState({});

  const companyId = selectedCompany?.id || '';

  const { stakeholders } = SharePlan.useStakeholders({
    companyId,
    planId: planId || '',
  });
  const currentPath = location.pathname;
  const queryClient = useQueryClient();

  const { pool } = Pool.usePool({ companyId, poolId: poolId || '' });

  const { sharePlan } = SharePlan.useSharePlan({ companyId, planId: planId || '' });
  const { terminate } = SharePlan.useTerminate();
  const deleteSharePlanTracker = AnalyticHooks.useClick(PossibleAction.DELETE_PLAN);
  const { deletePlan } = SharePlan.useDelete();

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_POOLS] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_POOL] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SHARE_PLANS] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SHARE_PLAN] });
  };

  return (
    <>
      <EditPlanModal
        companyId={companyId}
        handleClose={handleCloseEditPlanModal}
        handleTerminate={({ date, id }) =>
          terminate({ companyId, planId: id, date }, { onSuccess: invalidateQuery })
        }
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditPlanModal}
        onEditClick={({ id, step }) => setSelectedPlan({ id, mode: 'EDIT', step })}
        planId={selectedPlan?.id || ''}
        previewMode={selectedPlan?.mode === 'VIEW' || undefined}
        step={selectedPlan?.step || 1}
      />
      <div className="flex h-fit min-h-full w-full flex-col gap-4 rounded-md lg:p-4 lg:shadow-sm">
        <div className="flex h-[36px] w-full gap-4 border-b-[1px] border-gray-200">
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent text-sm font-[450] text-gray-400',
              selectedTab === PoolPlansTab.ACTIVE && 'border-brand-700 font-[550] text-brand-700',
            )}
            onClick={() => {
              setTab(PoolPlansTab.ACTIVE);
            }}
            styleType="NONE"
          >
            <AppFormattedMessage id={StringKey.ACTIVE} />
          </Button>
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent text-sm font-[450] text-gray-400',
              selectedTab === PoolPlansTab.TERMINATED &&
                'border-brand-700 font-[550] text-brand-700',
            )}
            onClick={() => {
              setTab(PoolPlansTab.TERMINATED);
            }}
            styleType="NONE"
          >
            <AppFormattedMessage id={StringKey.TERMINATED} />
          </Button>
        </div>
        <div className="flex w-full items-center justify-center xlg:hidden">
          <div className="flex min-w-[300px] max-w-[440px] flex-col border-gray-200">
            {pool && sharePlan && (
              <>
                <PoolMobileDatePicker className="mb-0" date={date} setDate={setDate} />
                <div className="align-center flex gap-2 py-4 pr-4">
                  <NavLink
                    className="shrink-0 text-xs font-[450] text-brand-700"
                    to={PageRoute.POOL_PLANS}
                  >
                    <AppFormattedMessage id={StringKey.POOLS_PLANS} />
                  </NavLink>
                  <span className="text-xs font-[450] text-gray-400">/</span>
                  <NavLink
                    className="truncate text-xs font-[450] text-brand-700"
                    to={`${PageRoute.POOL_PLANS}/${poolId}`}
                  >
                    {pool.name}
                  </NavLink>
                  <span className="text-xs font-[450] text-gray-400">/</span>
                  <NavLink className="truncate text-xs font-[450] text-gray-400" to={currentPath}>
                    {sharePlan?.name}
                  </NavLink>
                </div>
                <div className="relative flex w-full items-center justify-between border-y border-[#F2F2F2] pr-6">
                  <div className="flex w-full items-center gap-3">
                    <Button
                      className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full shadow-buttonShadow"
                      onClick={() => navigate(`${PageRoute.POOL_PLANS}/${poolId}`)}
                      styleType="NONE"
                    >
                      <ArrowIcon />
                    </Button>
                    <div className="flex w-full flex-col overflow-hidden py-3">
                      <span className="truncate text-base font-[550] text-gray-700">
                        {sharePlan?.name}
                      </span>
                      <span className="text-xs font-[450] text-gray-400">
                        {sharePlan?.type && sharePlanTypeTitle[sharePlan.type]}
                      </span>
                    </div>
                  </div>
                  <PoolPlansPopover
                    id={sharePlan?.id || ''}
                    onDeleteClick={(id) =>
                      deletePlan(
                        { companyId, planId: id },
                        {
                          onSuccess: () => {
                            deleteSharePlanTracker();
                            toast.success('Plan successfully deleted');
                            invalidateQuery();
                          },
                        },
                      )
                    }
                    onEditClick={(id) => {
                      handleOpenEditPlanModal();
                      setSelectedPlan({
                        id,
                        mode: 'EDIT',
                        step: 5,
                      });
                    }}
                    onViewClick={(id) => {
                      handleOpenEditPlanModal();

                      setSelectedPlan({
                        id,
                        mode: 'VIEW',
                        step: 5,
                      });
                    }}
                    text="plan"
                  />
                </div>

                <div className="flex min-h-full flex-col items-center">
                  {sharePlan && stakeholders && (
                    <>
                      {stakeholders.map((stakeholder) => (
                        <StakeholderCard key={stakeholder.id} stakeholder={stakeholder} />
                      ))}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanDetails;
