import React, { FC, memo, useRef, useState } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';

import { ChevronDownIcon, SearchIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../../components/Combobox';
import { Flag, FlagCode } from '../../../../components/Flag';
import { BackendRoute } from '../../../../config';
import { QueryKey } from '../../../../constants';
import { IpInfoHooks, useAppQuery, useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ApiService } from '../../../../services';
import { Country } from '../../../../types/countryTypes';
import { FormSchema } from './Validation';

export type CountryOfCompanyItemsListProps = {
  data: Country[];
  control: Control<FormSchema>;
  isLoading: boolean;
  isFetching: boolean;
};

const CountryOfCompanyItemsList: FC<CountryOfCompanyItemsListProps> = ({
  data,
  control,
  isFetching,
  isLoading,
}) => {
  const { countryCode } = IpInfoHooks.useCountryCode();

  const recommendedCurrency = data?.filter(({ iso2 }) => iso2 === countryCode);

  if (!data || isFetching || isLoading) return <span>Loading ...</span>;

  const sortedData = data
    .filter(({ iso2 }) => iso2 !== countryCode)
    .sort((a, b) => a.name.localeCompare(b.name));

  const sortedRecommendedCurrency = recommendedCurrency.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return (
    <div className="flex w-full flex-col gap-2">
      {sortedRecommendedCurrency.map(({ name, iso2 }) => (
        <FormComboboxOption
          control={control}
          key={`${name}_${iso2}`}
          name="country"
          value={{ iso2Code: iso2, name }}
        >
          <Flag countryCode={iso2.toUpperCase() as FlagCode} />
          <span className="text-sm text-gray-700">{name}</span>
        </FormComboboxOption>
      ))}

      {sortedRecommendedCurrency.length > 0 && sortedData.length > 0 && (
        <div className="h-[1px] w-full bg-gray-100" />
      )}
      {sortedData.map(({ iso2, name }) => (
        <FormComboboxOption
          control={control}
          key={iso2}
          name="country"
          value={{ iso2Code: iso2, name }}
        >
          <Flag countryCode={iso2.toUpperCase() as FlagCode} />
          <span className="text-sm text-gray-700">{name}</span>
        </FormComboboxOption>
      ))}
    </div>
  );
};

export type CountryOfCompanyComboboxProps = {
  control: Control<FormSchema>;
  selectedCountry: Partial<FormSchema['country']> | undefined;
  setValue: UseFormSetValue<FormSchema>;
};

const CountryOfCompanyCombobox: FC<CountryOfCompanyComboboxProps> = memo(
  ({ control, selectedCountry, setValue }) => {
    const [inputValue, setInputValue] = useState('');
    const { locale } = useLocale();
    const {
      data: countryData,
      isLoading,
      isFetching,
    } = useAppQuery<Country[]>({
      queryKey: [QueryKey.GET_COUNTRY, { inputValue }],
      queryFn: () =>
        ApiService.get(
          { endpoint: BackendRoute.COUNTRIES },
          { queryParams: { search: inputValue }, locale },
        ),
    });
    const ref = useRef<HTMLInputElement>(null);
    return (
      <FormCombobox
        className="relative"
        control={control}
        name={'country'}
        onClose={() => setInputValue('')}
        onSelect={(country) => {
          setInputValue('');
          setValue('country', country);
          setValue('city', '');
          setValue(
            'state',
            {
              iso2Code: '',
              name: '',
            },
            { shouldValidate: true },
          );
        }}
      >
        <div className="relative">
          <FormComboboxInput
            className={'bg-gray-900'}
            control={control}
            customValue={(value) => value?.name}
            icon={<ChevronDownIcon className="mt-3" />}
            iconBeforeInput={
              <Flag
                className="mt-5 w-4 shrink-0"
                countryCode={selectedCountry?.iso2Code?.toUpperCase() as FlagCode}
              />
            }
            name={'country'}
            placeholder={<AppFormattedMessage id={StringKey.COUNTRY_OF_COMPANY} />}
            readOnly
          />
          <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
        </div>
        <ComboboxOptions className="w-full">
          <div className="relative flex items-center" onClick={() => ref.current?.focus()}>
            <SearchIcon className="absolute ml-[6px]" />
            <input
              className="w-full rounded border-[2px] border-transparent bg-gray-50 p-[6px] pl-8 text-gray-700 outline-none placeholder:text-gray-400 focus:border-brand-700 focus:bg-white"
              onChange={({ target: { value } }) => setInputValue(value)}
              ref={ref}
              value={inputValue}
            />
          </div>

          <CountryOfCompanyItemsList
            control={control}
            data={countryData || []}
            isFetching={isFetching}
            isLoading={isLoading}
          />
        </ComboboxOptions>
      </FormCombobox>
    );
  },
);

CountryOfCompanyCombobox.displayName = 'CountryOfCompanyCombobox';

export default CountryOfCompanyCombobox;
