import { Field, Label } from '@headlessui/react';
import { FC } from 'react';

import { useFormat } from '../../../hooks';
import { SharePlanVestingType } from '../../../types/pool-plans.types';
import {
  ApproveMilestoneDto,
  TargetBasedVestingMilestoneItem,
  VestingTask,
} from '../../../types/vestingTasks.types';
import { toNumber } from '../../../utils/toNumber';
import { Checkbox } from '../../Checkbox';
import { DatePicker } from '../../DatePicker';
import { TableRow } from '../../Table';
import { ApproveTargetBasedColumn, approveTargetBasedColumnMap } from './types';

export type TargetBasedTableRowProps = {
  isSelected: boolean;
  onSelect: (data: Except<ApproveMilestoneDto, 'milestoneIndex'>) => void;
  onRemoveSelect: (stakeholderId: string) => void;
  milestone: TargetBasedVestingMilestoneItem | undefined;
} & VestingTask;

export const TargetBasedTableRow: FC<TargetBasedTableRowProps> = ({
  isSelected,
  onRemoveSelect,
  onSelect,
  id,
  targetBasedVestingMilestones,
  milestone,
  grantItem: {
    numbersOfGrants,
    plan: {
      name,
      vesting,
      pool: { shareClass },
    },
  },
}) => {
  const { format } = useFormat();
  if (vesting?.type !== SharePlanVestingType.TARGET_BASED) return <></>;

  const finishedMilestonesPercentage =
    targetBasedVestingMilestones?.reduce(
      (totalPercentage, { weight, isCompleted }) =>
        totalPercentage + toNumber(isCompleted && weight),
      0,
    ) ||
    milestone?.weight ||
    1;

  return (
    <TableRow
      columns={approveTargetBasedColumnMap}
      fields={{
        id,
        [ApproveTargetBasedColumn.SELECT]: (
          <Field className="flex h-full w-full items-center">
            <Checkbox
              checked={isSelected}
              id={id}
              onChange={(checked) => {
                checked
                  ? onSelect({
                      vestingTaskId: id,
                      value: numbersOfGrants,
                    })
                  : onRemoveSelect(id);
              }}
            />
            <Label className="absolute left-0 top-0 h-full w-full cursor-pointer" htmlFor={id} />
          </Field>
        ),
        [ApproveTargetBasedColumn.STAKEHOLDER]: 'Stakeholder',
        [ApproveTargetBasedColumn.GRANT]: id.split('-')[0].toUpperCase(),
        [ApproveTargetBasedColumn.APPROVAL_DATE]: (
          <DatePicker className="border-b border-gray-700 bg-gray-50 px-3 py-2" />
        ),
        [ApproveTargetBasedColumn.SHARE_CLASS]: shareClass.name,
        [ApproveTargetBasedColumn.TARGET_DATE]: format(milestone?.targetDate, 'dd/MM/yyyy'),
        [ApproveTargetBasedColumn.TARGET_NAME]: milestone?.name,
        [ApproveTargetBasedColumn.PLAN_TYPE]: name,
        [ApproveTargetBasedColumn.ISSUED_SHARES]: numbersOfGrants * finishedMilestonesPercentage,
      }}
      tdClassName="pl-4"
      trClassName={isSelected && 'bg-gray-50'}
    />
  );
};
