import { PaymentMethod } from '@stripe/stripe-js';

import { FlagCode } from '../components/Flag';
import { StringKey } from '../lang';
import { Country } from './countryTypes';
import { Currency } from './currencyTypes';
import { Plan, SubscriptionType } from './planTypes';
import { Stakeholder, StakeholderRole } from './stakeholderTypes';
import { State } from './stateTypes';

export enum CompanyModule {
  STAKEHOLDER = 'stakeholder',
  SHARE_CLASSES = 'share-classes',
  POOLS_AND_PLANS = 'pools-and-plans',
  EVENTS = 'events',
  CAP_TABLE = 'cap-table',
  MY_HOLDINGS = 'my-holdings',
  NOTIFICATIONS = 'notifications',
}

export enum CompanyIndustry {
  AGRICULTURE_FARMING = 'agriculture-farming',
  AEROSPACE = 'aerospace',
  ART = 'art',
  ARTIFICIAL_INTELLIGENCE = 'artificial-intelligence',
  AUTOMOTIVE = 'automotive',
  BANKING_FINANCIAL_SERVICES = 'banking-financial-services',
  BIOTECHNOLOGY = 'biotechnology',
  BUILDING_MATERIALS_SUPPLIES = 'building-materials-supplies',
  BUSINESS_SERVICES = 'business-services',
  CHEMICAL_INDUSTRY = 'chemical-industry',
  CLEANING_PRODUCTS_SERVICES = 'cleaning-products-services',
  CLOUD_COMPUTING = 'cloud-computing',
  COMPUTER_HARDWARE = 'computer-hardware',
  CONSTRUCTION = 'construction',
  CONSUMER_ELECTRONICS = 'consumer-electronics',
  CONSUMER_GOODS = 'consumer-goods',
  CONSUMER_HEALTHCARE = 'consumer-healthcare',
  CONSULTING = 'consulting',
  CULTURE = 'culture',
  CYBERSECURITY = 'cybersecurity',
  DATA_STORAGE_MANAGEMENT = 'data-storage-management',
  DEFENSE = 'defense',
  E_COMMERCE = 'e-commerce',
  EDUCATION_TRAINING = 'education-training',
  ELECTRIC_VEHICLES = 'electric-vehicles',
  ENERGY = 'energy',
  ENTERTAINMENT = 'entertainment',
  ENVIRONMENTAL_SERVICES = 'environmental-services',
  EVENTS = 'events',
  FABRICS_TEXTILES = 'fabrics-textiles',
  FASHION = 'fashion',
  FINTECH = 'fintech',
  FOOD_BEVERAGES = 'food-beverages',
  GOVERNMENT_PUBLIC_INSTITUTIONS = 'government-public-institutions',
  HEALTHCARE = 'healthcare',
  HOSPITALITY = 'hospitality',
  INFORMATION_TECHNOLOGIES = 'information-technologies',
  INTERNET_OF_THINGS = 'internet-of-things',
  INSURANCE = 'insurance',
  LEGAL = 'legal',
  LEISURE_RECREATION = 'leisure-recreation',
  LOGISTICS_SUPPLY_CHAIN = 'logistics-supply-chain',
  LUXURY_GOODS = 'luxury-goods',
  MACHINERY_HEAVY_EQUIPMENT = 'machinery-heavy-equipment',
  MANUFACTURING = 'manufacturing',
  MARKETING_ADVERTISING = 'marketing-advertising',
  MEDIA = 'media',
  MEDICAL_DEVICES_SUPPLIES = 'medical-devices-supplies',
  MINING = 'mining',
  MUSIC = 'music',
  MOBILITY_TRANSPORTATION = 'mobility-transportation',
  PERSONAL_SERVICES = 'personal-services',
  PET_CARE_SUPPLIES = 'pet-care-supplies',
  PHARMACEUTICALS = 'pharmaceuticals',
  PROFESSIONAL_SERVICES = 'professional-services',
  PUBLISHING = 'publishing',
  REAL_ESTATE_PROPTECH = 'real-estate-proptech',
  RESTAURANTS_FOOD_SERVICES = 'restaurants-food-services',
  RETAIL = 'retail',
  ROBOTICS = 'robotics',
  SMART_CITY = 'smart-city',
  SOCIAL_MEDIA = 'social-media',
  SOFTWARE_DEVELOPMENT = 'software-development',
  SPORTS_FITNESS = 'sports-fitness',
  TELECOM_CONNECTIVITY = 'telecom-connectivity',
  TRAVEL_TOURISM = 'travel-tourism',
  VIDEO_GAMES = 'video-games',
  WASTE_MANAGEMENT = 'waste-management',
  NON_PROFIT = 'non-profit',
  OTHER = 'other',
}
export enum CompanyType {
  PRIVATE_EQUITY = 'private-equity',
  VENTURE_CAPITAL = 'venture-capital',
  SPV = 'spv',
  FAMILY_OFFICE = 'family-office',
  COMPANY = 'company',
  OTHER = 'other',
}
export enum CompanyAnnualRevenue {
  NO_REV = 'no-rev',
  LESS_1_MIL = 'less-1-mil',
  BETWEEN_5_20_MIL = 'between-5-20-mil',
  BETWEEN_1_5_MIL = 'between-1-5-mil',
  MORE_20_MIL = 'more-20-mil',
}

export enum CompanyNumberOfEmployees {
  BETWEEN_1_10 = '1-10',
  BETWEEN_11_25 = '11-25',
  BETWEEN_26_50 = '26-50',
  BETWEEN_51_250 = '51-250',
  MORE_250 = '>250',
}

export enum IncorporatedIn {
  DIFC = 'DIFC',
  DMCC = 'DMCC',
  DIC = 'DIC',
  DMC = 'DMC',
  DHCC = 'DHCC',
  DKP = 'DKP',
  DSO = 'DSO',
  JAFZA = 'JAFZA',
  ADGM = 'ADGM',
  ADGM_SQUARE = 'ADGM_SQUARE',
  KIZAD = 'KIZAD',
  ABU_DHABI_AIRPORT_FREE_ZONE = 'ABU_DHABI_AIRPORT_FREE_ZONE',
  TWOFOUR54 = 'TWOFOUR54',
  SAIF_ZONE = 'SAIF_ZONE',
  SHAMS = 'SHAMS',
  RAKEZ = 'RAKEZ',
  RAK_MARITIME_CITY_FREE_ZONE = 'RAK_MARITIME_CITY_FREE_ZONE',
  RAK_FREE_TRADE_ZONE = 'RAK_FREE_TRADE_ZONE',
  AJMAN_FREE_ZONE = 'AJMAN_FREE_ZONE',
  FUJAIRAH_FREE_ZONE = 'FUJAIRAH_FREE_ZONE',
  UMM_AL_QUWAIN_FREE_TRADE_ZONE = 'UMM_AL_QUWAIN_FREE_TRADE_ZONE',
  OTHER = 'OTHER',
}

export const incorporatedInTitle: Record<IncorporatedIn, string> = {
  [IncorporatedIn.DIFC]: 'Dubai International Financial Centre (DIFC)',
  [IncorporatedIn.DMCC]: 'Dubai Multi Commodities Centre (DMCC)',
  [IncorporatedIn.DIC]: 'Dubai Internet City (DIC)',
  [IncorporatedIn.DMC]: 'Dubai Media City (DMC)',
  [IncorporatedIn.DHCC]: 'Dubai Healthcare City (DHCC)',
  [IncorporatedIn.DKP]: 'Dubai Knowledge Park (DKP)',
  [IncorporatedIn.DSO]: 'Dubai Silicon Oasis (DSO)',
  [IncorporatedIn.JAFZA]: 'Jebel Ali Free Zone (JAFZA)',
  [IncorporatedIn.ADGM]: 'Abu Dhabi Global Market (ADGM)',
  [IncorporatedIn.ADGM_SQUARE]: 'Abu Dhabi Global Market Square (ADGM SQUARE)',
  [IncorporatedIn.KIZAD]: 'KIZAD',
  [IncorporatedIn.ABU_DHABI_AIRPORT_FREE_ZONE]: 'Abu Dhabi Airport Free Zone',
  [IncorporatedIn.TWOFOUR54]: 'TwoFour54',
  [IncorporatedIn.SAIF_ZONE]: 'Sharjah Airport International Free Zone (SAIF Zone)',
  [IncorporatedIn.SHAMS]: 'Sharjah Media City (SHAMS)',
  [IncorporatedIn.RAKEZ]: 'RAK Economic Zone (RAKEZ)',
  [IncorporatedIn.RAK_MARITIME_CITY_FREE_ZONE]: 'RAK Maritime City Free Zone',
  [IncorporatedIn.RAK_FREE_TRADE_ZONE]: 'RAK Free Trade Zone',
  [IncorporatedIn.AJMAN_FREE_ZONE]: 'Ajman Free Zone',
  [IncorporatedIn.FUJAIRAH_FREE_ZONE]: 'Fujairah Free Zone',
  [IncorporatedIn.UMM_AL_QUWAIN_FREE_TRADE_ZONE]: 'Umm Al Quwain Free Trade Zone',
  [IncorporatedIn.OTHER]: 'Other',
};

export const incorporatedInTitleMap = Object.entries(incorporatedInTitle);

export const unitedArabEmiratesIso2: Lowercase<FlagCode> = 'ae';

export type PatchCompanyDto = {
  name?: string;
  url?: string;
  coupon?: string;
  industry?: CompanyIndustry;
  type?: CompanyType;
  annualRevenue?: CompanyAnnualRevenue;
  numberOfEmployees?: CompanyNumberOfEmployees;
  countryIso2?: string;
  currencyIso3?: string;
  currencyCountryIso2Code?: string;
  stateIso2?: string;
  stateCountryIso2?: string;
  city?: string;
  zipCode?: string;
  address?: string;
  companyId?: string;
  incorporatedIn?: IncorporatedIn | null;
  planId?: string;
  vatGst?: null | string;
};

export type Company = {
  id: string;
  incorporatedIn?: IncorporatedIn;

  // company details
  name?: string;
  url?: string;
  industry?: CompanyIndustry;
  type?: CompanyType;
  annualRevenue?: CompanyAnnualRevenue;
  numberOfEmployees?: CompanyNumberOfEmployees;

  // location & logo
  country?: Country;
  currency?: Currency;
  currencyCountryIso2Code?: string;
  state?: State;
  city?: string;
  zipCode?: string;
  address?: string;
  companyId?: string;
  logoImgSrc?: string;

  isActive: boolean;
  isCompleted: boolean;
  isFirstSubscribed: boolean;
  createdAt: string;
  updatedAt: string;
  toCancelAt?: string;
  inactivateAt?: string;
  deleteAt?: string;

  stakeholder?: Stakeholder;
  roleInCompany: StakeholderRole;
  stripeSubscriptionId?: string;
  isSelected: boolean;
  planId?: string;
  plan?: Plan;
  vatGst?: string;
  stakeholdersLimit: number;
  isDemo: boolean;
  isEnterpriseRequested?: boolean;
  latestSubscriptionData: {
    id: string;
    plan?: {
      id: string;
      active: boolean;
      amount: number;
      created: number;
      currency: string;
      interval: string;
    };
    items?: { data: { price: { product: { metadata: { type: SubscriptionType } } } }[] };
  };
};

export enum CompanyFilter {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
export type FilterVariant = 'all' | CompanyFilter;

export const filterVariant: Record<FilterVariant, StringKey> = {
  all: StringKey.FILTER_ALL,
  [CompanyFilter.ACTIVE]: StringKey.FILTER_ACTIVE,
  [CompanyFilter.INACTIVE]: StringKey.FILTER_INACTIVE,
};

export const filterVariantMap = Object.entries(filterVariant);

export type UpgradeCompanyDto = {
  planId: string;
};

export type GetStartedProgress = {
  isCompleteStakeholders: boolean;
  isCompleteShareClass: boolean;
  isCompletePool: boolean;
  isCompletePlan: boolean;
  isCompleteEvent: boolean;
};
export interface PaymentData {
  defaultPaymentMethod: PaymentMethod;
  periodEnd: number;
}
