import React, { FC } from 'react';

import { Pool, PoolPlansTab } from '../../../../../types/pool-plans.types';
import { StickyPoolItem } from './StickyPoolItem';

type StickyPoolTableProps = {
  pools: Pool[];
  selectedPool: string[];
  selectedPlan: string[];
  selectedTab: PoolPlansTab;
  setSelectedPool: (state: string) => void;
  setSelectedPlan: (state: string) => void;
  deselectPlan: (id: string) => void;
  deselectPool: (id: string) => void;
};

export const StickyPoolTable: FC<StickyPoolTableProps> = ({
  pools,
  selectedPlan,
  selectedPool,
  selectedTab,
  setSelectedPlan,
  setSelectedPool,
  ...callbacks
}) => {
  return (
    <table className="h-fit transition-all">
      <thead>
        <tr className="flex border-y-[1px] border-[#F2F2F2] bg-gray-50">
          <td className="relative">
            <div className="flex h-full w-[48px] items-center justify-center"> </div>
          </td>
          <td className="px-4 py-3">
            <div className="flex items-center">
              <span className="min-w-[210px] max-w-[320px] text-label-md font-[450] text-[#172335]">
                Pool
              </span>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        {pools && (
          <>
            {pools.map((pool) => (
              <StickyPoolItem
                {...callbacks}
                isSelected={selectedPool.includes(pool.id)}
                key={pool.id}
                pool={pool}
                selectedPlans={selectedPlan}
                selectedTab={selectedTab}
                setSelectedPlan={setSelectedPlan}
                setSelectedPool={setSelectedPool}
              />
            ))}
          </>
        )}
      </tbody>
    </table>
  );
};
