import { FC } from 'react';

import { useFormat } from '../../../../../hooks';
import { eventFormType, EventTypeBackend } from '../../../../../types/events.types';
import { BasicTable } from './BasicTable';
import { Td } from './TableTd';
import { TableBodyProps } from './types';

export const Expire: FC<TableBodyProps> = (props) => {
  const { format } = useFormat();

  if (props.type !== EventTypeBackend.EXPIRE) return <></>;

  const {
    type,
    exercise: {
      exercised,
      vestingTask: {
        grantItem: {
          numbersOfGrants,
          stakeholder,
          plan: {
            pool: { shareClass },
          },
        },
      },
    },
    createdAt,
  } = props;
  return (
    <>
      <BasicTable eventType={EventTypeBackend.EXPIRE}>
        <tr>
          <Td value={format(createdAt, 'dd/MM/yyyy')} />
          <Td value={eventFormType[type]} />
          <Td value={stakeholder.fullName} />
          <Td value={exercised} />
          <Td value={shareClass.name} />
          <Td value={numbersOfGrants.toLocaleString('en-US')} />
        </tr>
      </BasicTable>
    </>
  );
};
