import { z } from 'zod';

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const ACCEPTED_FILE_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/png',
  'image/jpeg',
];

export const fileSchemaMessage = 'File size is too large';

const commonStock = z.object({
  stockItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          grant: z.object({
            id: z.string().trim().min(1, { message: 'Required' }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
            grantItem: z.object({
              plan: z.object({
                strikePrice: z.coerce.number(),
                conversionRatio: z.coerce.number(),
                pool: z.object({
                  shareClass: z.object({ name: z.string().trim().min(1, { message: 'Required' }) }),
                }),
              }),
            }),
          }),
          date: z.coerce.date(),
          exercised: z.coerce.number().min(1, { message: 'Required' }),
        })
        .refine(
          ({ exercised, grant }) => {
            return exercised <= grant.vestedSharesCount - grant.exercisedCount;
          },
          {
            message: 'Options must be less than or equal to balance',
            path: ['exercised'],
          },
        ),
    )
    .min(1, { message: 'Required' }),
});

const sar = z.object({
  sarItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          grant: z.object({
            id: z.string().trim().min(1, { message: 'Required' }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
            grantItem: z.object({
              plan: z.object({
                sarBasePrice: z.coerce.number(),
                pool: z.object({
                  shareClass: z.object({ name: z.string().trim().min(1, { message: 'Required' }) }),
                }),
              }),
            }),
          }),
          date: z.coerce.date(),
          sarCount: z.coerce.number().min(1, { message: 'Required' }),
        })
        .refine(
          ({ sarCount, grant }) => {
            return sarCount <= grant.vestedSharesCount - grant.exercisedCount;
          },
          {
            message: 'No of SARs must be less than or equal to balance',
            path: ['sarCount'],
          },
        ),
    )
    .min(1, { message: 'Required' }),
});

const phantom = z.object({
  phantomItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          grant: z.object({
            id: z.string().trim().min(1, { message: 'Required' }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
          }),
          date: z.coerce.date(),
          sharePrice: z.coerce.number().min(1, { message: 'Required' }),
          phantomShares: z.coerce.number().min(1, { message: 'Required' }),
        })
        .refine(
          ({ phantomShares, grant }) => {
            return phantomShares <= grant.vestedSharesCount - grant.exercisedCount;
          },
          {
            message: 'Shares must be less than or equal to balance',
            path: ['phantomShares'],
          },
        ),
    )
    .min(1, { message: 'Required' }),
});

const hurdle = z.object({
  hurdleItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          grant: z.object({
            id: z.string().trim().min(1, { message: 'Required' }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
            grantItem: z.object({
              plan: z.object({
                hardleValue: z.coerce.number(),
                pool: z.object({
                  shareClass: z.object({ name: z.string().trim().min(1, { message: 'Required' }) }),
                }),
              }),
            }),
          }),
          date: z.coerce.date(),
          issuedShares: z.coerce.number().min(1, { message: 'Required' }),
        })
        .refine(
          ({ issuedShares, grant }) => {
            return issuedShares <= grant.vestedSharesCount - grant.exercisedCount;
          },
          {
            message: 'Shares must be less than or equal to balance',
            path: ['issuedShares'],
          },
        ),
    )
    .min(1, { message: 'Required' }),
});

const bspce = z.object({
  bspceItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          grant: z.object({
            id: z.string().trim().min(1, { message: 'Required' }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
            grantItem: z.object({
              plan: z.object({
                strikePrice: z.coerce.number(),
                conversionRatio: z.coerce.number(),
                pool: z.object({
                  shareClass: z.object({ name: z.string().trim().min(1, { message: 'Required' }) }),
                }),
              }),
            }),
          }),
          date: z.coerce.date(),
          exercised: z.coerce.number().min(1, { message: 'Required' }),
        })
        .refine(
          ({ exercised, grant }) => {
            return exercised <= grant.vestedSharesCount - grant.exercisedCount;
          },
          {
            message: 'Options must be less than or equal to balance',
            path: ['exercised'],
          },
        ),
    )
    .min(1, { message: 'Required' }),
});

const rsa = z.object({
  rsaItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          grant: z.object({
            id: z.string().trim().min(1, { message: 'Required' }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
            grantItem: z.object({
              plan: z.object({
                purchasePrice: z.coerce.number(),
                conversionRatio: z.coerce.number(),
                pool: z.object({
                  shareClass: z.object({ name: z.string().trim().min(1, { message: 'Required' }) }),
                }),
              }),
            }),
          }),
          date: z.coerce.date(),
          exercised: z.coerce.number().min(1, { message: 'Required' }),
        })
        .refine(
          ({ exercised, grant }) => {
            return exercised <= grant.vestedSharesCount - grant.exercisedCount;
          },
          {
            message: 'Options must be less than or equal to balance',
            path: ['exercised'],
          },
        ),
    )
    .min(1, { message: 'Required' }),
});

const warrantExercise = z.object({
  warrantExerciseItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          grant: z.object({
            id: z.string().trim().min(1, { message: 'Required' }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
            grantItem: z.object({
              plan: z.object({
                warrantPrice: z.coerce.number(),
                conversionRatio: z.coerce.number(),
                pool: z.object({
                  shareClass: z.object({ name: z.string().trim().min(1, { message: 'Required' }) }),
                }),
              }),
            }),
          }),
          date: z.coerce.date(),
          exercised: z.coerce.number().min(1, { message: 'Required' }),
        })
        .refine(
          ({ exercised, grant }) => {
            return exercised <= grant.vestedSharesCount - grant.exercisedCount;
          },
          {
            message: 'Options must be less than or equal to balance',
            path: ['exercised'],
          },
        ),
    )
    .min(1, { message: 'Required' }),
});

const warrantExpire = z.object({
  warrantExpireItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          grant: z.object({
            id: z.string().trim().min(1, { message: 'Required' }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
          }),
          date: z.coerce.date(),
          expired: z.coerce.number().min(1, { message: 'Required' }),
        })
        .refine(
          ({ expired, grant }) => {
            return expired <= grant.vestedSharesCount;
          },
          {
            message: 'Warrants must be less than or equal to balance',
            path: ['expired'],
          },
        ),
    )
    .min(1, { message: 'Required' }),
});

export const stepOne = z.object({
  commonStock: commonStock.optional(),
  sar: sar.optional(),
  phantom: phantom.optional(),
  hurdle: hurdle.optional(),
  bspce: bspce.optional(),
  rsa: rsa.optional(),
  warrantExercise: warrantExercise.optional(),
  warrantExpire: warrantExpire.optional(),
});

export const stepTwo = z
  .object({
    additionalNotes: z.string().max(3000, 'Maximum 3000 characters').optional(),
    files: z
      .object({
        docLink: z.string(),
        loadProgress: z.number().max(100),
        abort: z.function(),
        id: z.string(),
        doc: z
          .custom<File>()
          .or(
            z.object({
              size: z.number(),
              type: z.string(),
              name: z.string(),
            }),
          )
          .refine(
            ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
            'File format is not supported',
          )
          .refine(({ size }) => size <= MAX_FILE_SIZE, fileSchemaMessage),
      })
      .array()

      .optional(),
  })
  .optional();

export const formSchema = z.object({
  stepOne,
  stepTwo,
});

export type FormSchema = z.infer<typeof formSchema>;
