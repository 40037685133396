import React, { FC, useCallback, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, EditIcon, SearchIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import { FormInput } from '../../../components/Input';
import { useReactForm } from '../../../hooks';
import { StringKey } from '../../../lang';
import {
  incorporatedInTitle,
  incorporatedInTitleMap,
  unitedArabEmiratesIso2,
} from '../../../types/companyTypes';
import { DeepPartial } from '../../../utils/deepPartial';
import { filterByAllFields } from '../../../utils/filterByAllFields';
import { CompanySettingsCardComponentProps } from '../CompanySettings';
import CityOfStateCombobox from './CityOfStateCombobox';
import CountryOfCompanyCombobox from './CountryOfCompanyCombobox';
import StateOfCountryCombobox from './StateOfCountryCombobox';
import { FormSchema, formSchema } from './Validation';

const AddressFormCard: FC<CompanySettingsCardComponentProps> = ({
  data,
  setEditing,
  isEditing,
  invalidateQuery,
  updateData,
}) => {
  const [inputValue, setInputValue] = useState('');
  const ref = useRef<HTMLInputElement>(null);

  const addressData: DeepPartial<FormSchema> = {
    address: data.address,
    city: data.city || '',
    zipCode: data.zipCode,
    incorporatedIn: data?.incorporatedIn,
    country: {
      iso2Code: data.country?.iso2 || '',
      name: data.country?.name || '',
    },
    state: {
      iso2Code: data.state?.iso2 || '',
      name: data.state?.name || '',
    },
  };

  const {
    control,
    handleSubmit,
    resetField,
    setValue,
    watch,
    reset,
    formState: { isValid, disabled },
  } = useReactForm({
    mode: 'all',
    schema: formSchema,
    disabled: !isEditing,
    defaultValues: addressData,
    values: addressData as FormSchema,
  });
  const submitHandler = useCallback(
    async (data: FormSchema) => {
      updateData(
        {
          address: data.address,
          stateIso2: data.state.iso2Code,
          city: data.city,
          zipCode: data.zipCode,
          stateCountryIso2: data.country.iso2Code,
          countryIso2: data.country.iso2Code,
          incorporatedIn: data.incorporatedIn,
        },
        {
          onSuccess: () => {
            setEditing(false);
            invalidateQuery();
          },
        },
      );
    },
    [invalidateQuery, setEditing, updateData],
  );
  const selectedCountry = watch('country');

  const isSelectedAE = selectedCountry.iso2Code?.toLowerCase() === unitedArabEmiratesIso2;

  const selectedState = watch('state');

  return (
    <div className="flex w-fit flex-col gap-4 rounded-md p-4 shadow-sm max-lg:p-0 max-lg:shadow-none">
      <div className="flex max-w-[400px] items-center justify-between">
        <span className="text-nowrap text-base font-[550] text-gray-700">
          <AppFormattedMessage id={StringKey.LOCATION} />
        </span>
        <Button
          className="size-8 rounded transition-colors hover:bg-gray-100"
          onClick={() => setEditing(true)}
          styleType="NONE"
        >
          <EditIcon height={'24'} iconColor="#2565C8" width="24" />
        </Button>
      </div>

      <form className="flex max-w-[400px] flex-wrap gap-6" onSubmit={handleSubmit(submitHandler)}>
        <div className="flex w-full flex-wrap gap-6">
          <CountryOfCompanyCombobox
            control={control}
            selectedCountry={selectedCountry}
            setValue={setValue}
          />

          <StateOfCountryCombobox
            control={control}
            resetField={resetField}
            selectedCountry={selectedCountry}
            setValue={setValue}
          />

          <div className="flex w-full justify-between gap-4">
            <CityOfStateCombobox
              control={control}
              selectedCountry={selectedCountry}
              selectedState={selectedState}
            />

            <FormInput
              control={control}
              name="zipCode"
              placeholder={<AppFormattedMessage id={StringKey.ZIP_CODE} />}
              wrapperClassName="w-[100px] shrink-0"
            />
          </div>

          {isSelectedAE && (
            <FormCombobox className="relative w-full" control={control} name={'incorporatedIn'}>
              <div className="relative">
                <FormComboboxInput
                  className={'absolute z-0 bg-gray-900'}
                  control={control}
                  customValue={(value) => (value ? incorporatedInTitle[value] : '')}
                  icon={<ChevronDownIcon className={twMerge('mt-3', disabled && 'hidden')} />}
                  name={'incorporatedIn'}
                  placeholder="Incorporated In"
                  readOnly
                  wrapperClassName={disabled && 'border-none'}
                />
                <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
              </div>
              <ComboboxOptions className="w-full">
                <div className="relative flex items-center" onClick={() => ref.current?.focus()}>
                  <SearchIcon className="absolute ml-[6px]" />
                  <input
                    className="w-full rounded border-[2px] border-transparent bg-gray-50 p-[6px] pl-8 text-gray-700 outline-none placeholder:text-gray-400 focus:border-brand-700 focus:bg-white"
                    onChange={({ target: { value } }) => setInputValue(value)}
                    ref={ref}
                    value={inputValue}
                  />
                </div>
                {filterByAllFields(incorporatedInTitleMap, inputValue).map(
                  ([incorporatedInKey, incorporatedIn]) => (
                    <FormComboboxOption
                      control={control}
                      key={incorporatedInKey}
                      name="incorporatedIn"
                      value={incorporatedInKey}
                    >
                      <span className="text-sm font-normal text-gray-700">{incorporatedIn}</span>
                    </FormComboboxOption>
                  ),
                )}
              </ComboboxOptions>
            </FormCombobox>
          )}

          <FormInput
            control={control}
            name="address"
            placeholder={<AppFormattedMessage id={StringKey.COMPANY_ADDRESS} />}
            wrapperClassName="w-full"
          />
        </div>

        {isEditing && (
          <div className="flex w-full justify-end gap-4 border-t-[1px] border-gray-200 pt-6">
            <Button
              className="border-[1px] border-gray-100 px-3 py-[6px] text-sm text-gray-700 transition-colors hover:bg-gray-100"
              onClick={() => {
                setEditing(false);
                reset();
              }}
              styleType="DEFAULT_ROUNDED"
            >
              <AppFormattedMessage id={StringKey.CANCEL} />
            </Button>
            <Button
              className="w-full px-4 py-[10px] text-sm font-[550] text-gray-25 transition-colors hover:bg-brand-600"
              disabled={!isValid}
              type="submit"
            >
              <AppFormattedMessage id={StringKey.UPDATE} />
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

AddressFormCard.displayName = 'DetailsFormCard';

export default AddressFormCard;
