import { Pool, SharePlan } from './pool-plans.types';
import { ShareClass } from './share-classes.types';
import { Stakeholder } from './stakeholderTypes';
import { VestingTask } from './vestingTasks.types';

export enum SortByType {
  GRANT = 'grant',
  FUNDRAISING_ROUND = 'fundraising-round',
  SHARE_ISSUANCE = 'share-issuance',
  SECONDARIES = 'secondaries',
  BUYBACK = 'buyback',
  VALUATION = 'valuation',
  CONVERSION = 'conversion',
  NEW_POOL = 'new-pool',
  NEW_PLAN = 'new-plan',
  EXERCISE = 'exercise',
  APPROVAL = 'approval',
  PAYOUT = 'payout',
  EXPIRE = 'expire',
}

export const sortByTypeTitle: Record<SortByType, string> = {
  [SortByType.GRANT]: 'Grant',
  [SortByType.FUNDRAISING_ROUND]: 'Fundraising',
  [SortByType.SHARE_ISSUANCE]: 'Shares Issuance',
  [SortByType.SECONDARIES]: 'Secondaries',
  [SortByType.BUYBACK]: 'Buyback',
  [SortByType.VALUATION]: 'Valuation',
  [SortByType.CONVERSION]: 'Conversion',
  [SortByType.NEW_POOL]: 'Pools',
  [SortByType.NEW_PLAN]: 'Plans',
  [SortByType.EXERCISE]: 'Exercise',
  [SortByType.APPROVAL]: 'Approval (Hurdle)',
  [SortByType.PAYOUT]: 'Payout (Phantom)',
  [SortByType.EXPIRE]: 'Expire (Warrants)',
};

export const sortByTypeMap = Object.entries(sortByTypeTitle);

export enum SortByDate {
  LAST_30_DAYS = 'last-30-days',
  LAST_60_DAYS = 'last-60-days',
  LAST_QUARTER = 'last-quatre',
  LAST_YEAR = 'last-year',
  ALL_TIME = 'all-time',
  CUSTOM_DATE = 'custom-date',
}

export const sortByDateTitle: Record<SortByDate, string> = {
  [SortByDate.LAST_30_DAYS]: 'Last 30 days',
  [SortByDate.LAST_60_DAYS]: 'Last 60 days',
  [SortByDate.LAST_QUARTER]: 'Last quater',
  [SortByDate.LAST_YEAR]: 'Last year',
  [SortByDate.ALL_TIME]: 'All time',
  [SortByDate.CUSTOM_DATE]: 'Custom date',
};

export const sortByDateMap = Object.entries(sortByDateTitle);

export enum EventExercisedTypeBackend {
  STOCK_OPTIONS = 'stock-options',
  WARRANTS = 'warrants',
  SAR = 'sar',
}

export enum EventTypeBackend {
  GRANT = 'grant',
  FUNDRAISING_ROUND = 'fundraising-round',
  SHARE_ISSUANCE = 'share-issuance',
  BUYBACK = 'buyback',
  SECONDARIES = 'secondaries',
  CONVERSION = 'conversion',
  VALUATION = 'valuation',
  NEW_POOL = 'new-pool',
  NEW_PLAN = 'new-plan',
  EXERCISE = 'exercise',
  APPROVAL = 'approval',
  PAYOUT = 'payout',
  EXPIRE = 'expire',
}

export enum EventFormType {
  GRANT = 'grant',
  BULK_IMPORT_GRANT = 'bulk-import-grant',
  FUNDRAISING_ROUND = 'fundraising-round',
  SHARE_ISSUANCE = 'share-issuance',
  SECONDARIES = 'secondaries',
  BUYBACK = 'buyback',
  CONVERSION = 'conversion',
  VALUATION = 'valuation',
}

export const eventFormType: Record<EventTypeBackend, string> = {
  [EventTypeBackend.GRANT]: 'Grant',
  [EventTypeBackend.BUYBACK]: 'Buyback',
  [EventTypeBackend.CONVERSION]: 'Class conversion',
  [EventTypeBackend.FUNDRAISING_ROUND]: 'Fundraising Round',
  [EventTypeBackend.SECONDARIES]: 'Secondaries',
  [EventTypeBackend.SHARE_ISSUANCE]: 'Share Issuance',
  [EventTypeBackend.VALUATION]: 'Valuation Event',
  [EventTypeBackend.NEW_PLAN]: 'New Plan',
  [EventTypeBackend.NEW_POOL]: 'New Pool',
  [EventTypeBackend.EXERCISE]: 'Exercised',
  [EventTypeBackend.APPROVAL]: 'Approval',
  [EventTypeBackend.PAYOUT]: 'Payout',
  [EventTypeBackend.EXPIRE]: 'Expire',
};

export const eventTypeMapping: Record<EventFormType, EventTypeBackend> = {
  [EventFormType.BULK_IMPORT_GRANT]: EventTypeBackend.GRANT,
  [EventFormType.GRANT]: EventTypeBackend.GRANT,
  [EventFormType.FUNDRAISING_ROUND]: EventTypeBackend.FUNDRAISING_ROUND,
  [EventFormType.SHARE_ISSUANCE]: EventTypeBackend.SHARE_ISSUANCE,
  [EventFormType.SECONDARIES]: EventTypeBackend.SECONDARIES,
  [EventFormType.BUYBACK]: EventTypeBackend.BUYBACK,
  [EventFormType.CONVERSION]: EventTypeBackend.CONVERSION,
  [EventFormType.VALUATION]: EventTypeBackend.VALUATION,
};

export const backendToFormTypeMapping: Record<EventTypeBackend, EventFormType> = Object.fromEntries(
  Object.entries(eventTypeMapping).map(([formType, backendType]) => [backendType, formType]),
) as Record<EventTypeBackend, EventFormType>;

export const EventTypeTitle: Record<EventFormType, string> = {
  [EventFormType.GRANT]: 'Add Grant',
  [EventFormType.BULK_IMPORT_GRANT]: 'Bulk Import Grant',
  [EventFormType.FUNDRAISING_ROUND]: 'Add Fundraising Round',
  [EventFormType.SHARE_ISSUANCE]: 'Add Share Issuance',
  [EventFormType.SECONDARIES]: 'Add Secondaries (Share Transfer)',
  [EventFormType.BUYBACK]: 'Add Buyback (Decrease Shares)',
  [EventFormType.CONVERSION]: 'Add Class Conversion',
  [EventFormType.VALUATION]: 'Add Valuation',
};

export const EventTypeButtonTitle: Record<EventFormType, string> = {
  [EventFormType.GRANT]: 'Grant',
  [EventFormType.BULK_IMPORT_GRANT]: 'Bulk Import Grant',
  [EventFormType.FUNDRAISING_ROUND]: 'Fundraising Round',
  [EventFormType.SHARE_ISSUANCE]: 'Share Issuance',
  [EventFormType.SECONDARIES]: 'Secondaries',
  [EventFormType.BUYBACK]: 'Buyback',
  [EventFormType.CONVERSION]: 'Class Conversion',
  [EventFormType.VALUATION]: 'Valuation',
};

export type CreateGrantItemDto = {
  date: string;
  planId: string;
  stakeholderId: string;
  numbersOfGrants: number;
};

export type CreateInvestmentItemDto = {
  stakeholderId: string;
  shareClassId: string;
  investedValue: number;
  date: string;
};

export type CreateBuybackItemDto = {
  date: string;
  stakeholderId: string;
  shareClassId: string;
  sharesCount: number;
};

export type CreateIssuanceEventItemDto = {
  stakeholderId: string;
  shareClassId: string;
  sharesCount: number;
};

export type CreateSecondariesEventItemDto = {
  date: string;
  fromStakeholderId: string;
  toStakeholderId: string;
  shareClassId: string;
  sharesCount: number;
  sharesValue: number;
};

export type CreateConversionItemDto = {
  date: string;
  stakeholderId: string;
  fromShareClassId: string;
  toShareClassId: string;
  sharesCount: number;
};

export type CreateValuationEventDto = {
  id?: string;
  name: string;
  date: string;
  sharePrice: number;
};

export type CreateEventDto = {
  type: EventTypeBackend;
  grant?: {
    id?: string;
    items: CreateGrantItemDto[];
  };
  fundraisingRound?: {
    id?: string;
    items: CreateInvestmentItemDto[];
    valuationId: string;
    sharePrice: number;
    isOpen?: boolean;
  };
  issuance?: {
    id?: string;
    valuationId: string;
    date: string;
    sharePrice: number;
    items: CreateIssuanceEventItemDto[];
  };
  buyback?: {
    id?: string;
    items: CreateBuybackItemDto[];
  };
  secondaries?: {
    id?: string;
    items: CreateSecondariesEventItemDto[];
  };
  conversion?: {
    id?: string;
    items: CreateConversionItemDto[];
  };
  valuation?: CreateValuationEventDto;
  filesLinks: string[];
  additionalNotes: string;
};

export type Valuation = {
  id: string;
  event: EventUnion;
  name: string;
  date: Date;
  sharePrice: number;
  issuedSharesOnStart?: number;
  issuedShares?: number;
};
export interface CommonEvent {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  filesLinks: string[];
  additionalNotes: string;
  eventId: string;
  pinned: boolean;
}

export type ExerciseEvent = {
  id: string;
  vestingTask: VestingTask;
  exercised: number;
};

export interface NewExerciseEvent extends CommonEvent {
  type:
    | EventTypeBackend.EXERCISE
    | EventTypeBackend.APPROVAL
    | EventTypeBackend.PAYOUT
    | EventTypeBackend.EXPIRE;
  exercise: ExerciseEvent;
}

export interface GrantEventItem {
  id: string;
  date: Date;
  plan: SharePlan;
  stakeholder: Stakeholder;
  numbersOfGrants: number;
  processed: boolean;
}

export interface FundraisingEventItem {
  id: string;
  date: Date;
  stakeholder: Stakeholder;
  shareClass: ShareClass;
  investedValue: number;
}

export interface GrantEvent extends CommonEvent {
  type: EventTypeBackend.GRANT;
  items: GrantEventItem[];
  filesLinks: string[];
}

export interface FundraisingEvent extends CommonEvent {
  type: EventTypeBackend.FUNDRAISING_ROUND;
  valuation: Valuation;
  sharePrice: number;
  items: FundraisingEventItem[];
  isOpen: boolean;
}

export interface ShareIssuanceEventItem {
  id: string;
  stakeholder: Stakeholder;
  shareClass: ShareClass;
  sharesCount: number;
}

export interface ShareIssuanceEvent extends CommonEvent {
  type: EventTypeBackend.SHARE_ISSUANCE;
  valuation: Valuation;
  date: Date;
  sharePrice: number;
  items: ShareIssuanceEventItem[];
}

export interface BuybackEventItem {
  id: string;
  date: Date;
  stakeholder: Stakeholder;
  shareClass: ShareClass;
  sharesCount: number;
}

export interface BuybackEvent extends CommonEvent {
  type: EventTypeBackend.BUYBACK;
  items: BuybackEventItem[];
}

export interface SecondariesEventItem {
  id: string;
  date: Date;
  fromStakeholder: Stakeholder;
  toStakeholder: Stakeholder;
  shareClass: ShareClass;
  sharesCount: number;
  sharesValue: number;
}

export interface SecondariesEvent extends CommonEvent {
  type: EventTypeBackend.SECONDARIES;
  items: SecondariesEventItem[];
}

export interface ConversionEventItem {
  id: string;
  date: Date;
  stakeholder: Stakeholder;
  fromShareClass: ShareClass;
  toShareClass: ShareClass;
  sharesCount: number;
}

export interface ConversionEvent extends CommonEvent {
  type: EventTypeBackend.CONVERSION;
  items: ConversionEventItem[];
}

export interface ValuationEvent extends CommonEvent {
  type: EventTypeBackend.VALUATION;
  name: string;
  date: Date;
  sharePrice: number;
  issuedSharesOnStart: number;
  issuedShares: number;
}

export interface NewPoolEvent extends CommonEvent {
  type: EventTypeBackend.NEW_POOL;
  pool: Pool;
}

export interface NewPlanEvent extends CommonEvent {
  type: EventTypeBackend.NEW_PLAN;
  pool: Pool;
  sharePlan: SharePlan;
}

export type EventUnion =
  | GrantEvent
  | FundraisingEvent
  | ShareIssuanceEvent
  | BuybackEvent
  | SecondariesEvent
  | ConversionEvent
  | ValuationEvent
  | NewPoolEvent
  | NewPlanEvent
  | NewExerciseEvent;
