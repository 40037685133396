import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useExercise = () => {
  const { mutate: create, isPending } = useAppMutation([QueryKey.EXERCISE], {
    mutationFn: ({
      companyId,
      data,
    }: {
      companyId: string;
      data: {
        vestingTaskId: string;
        value: number;
        payout?: number;
        filesLinks?: string[];
      }[];
    }) =>
      ApiService.post(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'exercise'] },
        { body: data },
      ),
  });

  return { create, isPending };
};
