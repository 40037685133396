import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import React, { FC, useMemo, useRef, useState } from 'react';
import { Control } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { ChevronDownIcon, SearchIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../../components/Combobox';
import Loader from '../../../../components/Loader';
import { useStakeholdersWithShares } from '../../../../hooks/stakeholder';
import { useInfiniteStakeholders } from '../../../../hooks/stakeholder/useInfiniteStakeholders';
import { StringKey } from '../../../../lang';
import { stakeholderTypeTranslation } from '../../../../translations/stakeholderTranslation';
import {
  Stakeholder,
  stakeholderSingularTypeTitle,
  StakeholderType,
} from '../../../../types/stakeholderTypes';
import { FormSchema } from '../validation';

export type StakeholderValue = {
  fullName: string;
  id: string;
};

export type StakeholdersItemsListProps = {
  data: Stakeholder[];
  control: Control<FormSchema>;
  name: string;
  hasNextPage: boolean;
  isLoading: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<
    InfiniteQueryObserverResult<
      InfiniteData<
        {
          stakeholders: Stakeholder[];
          totalPages: number;
        },
        unknown
      >,
      Error
    >
  >;
};

const StakeholdersItemsList: FC<StakeholdersItemsListProps> = ({
  data,
  control,
  name,
  hasNextPage,
  fetchNextPage,
  isLoading,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex w-full flex-col gap-2">
      <InfiniteScroll
        className="flex w-full flex-col gap-2"
        dataLength={data.length || 0}
        hasMore={hasNextPage}
        loader={<Loader />}
        next={fetchNextPage}
        scrollableTarget="scrollbar-target"
      >
        {data.length > 0 ? (
          <>
            {data
              .sort((a, b) => a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase()))
              .map(({ fullName, id, type }, i) => (
                <FormComboboxOption
                  className="w-full"
                  control={control}
                  key={`${fullName}_${i}`}
                  name={name as keyof FormSchema}
                  value={{ id, fullName } as unknown as FormSchema[keyof FormSchema]}
                >
                  <div className="flex w-full flex-col gap-[1px]">
                    <span className="truncate text-sm text-gray-700">{fullName}</span>
                    <span className="truncate text-label-md italic text-gray-500">
                      {stakeholderSingularTypeTitle[type]}
                    </span>
                  </div>
                </FormComboboxOption>
              ))}
          </>
        ) : isLoading ? (
          <span className="py-[6px] text-sm font-normal text-gray-700">Loading...</span>
        ) : (
          <span className="py-[6px] text-sm font-normal text-gray-700">No Shareholder found</span>
        )}
      </InfiniteScroll>

      <Button
        className="w-full justify-start text-nowrap rounded bg-brand-25 px-2 py-[6px] text-start text-sm font-[450] text-brand-700"
        onClick={() => navigate('/stakeholders?openAddModal=true')}
        styleType="NONE"
      >
        + Add a new Shareholder
      </Button>
    </div>
  );
};

export type StakeholderComboboxProps = {
  control: Control<FormSchema>;
  companyId: string;
  name: string;
  stakeholderFromId?: string;
  onlyWithShares?: boolean;
};

export const StakeholderCombobox: FC<StakeholderComboboxProps> = ({
  control,
  companyId,
  name,
  stakeholderFromId,
  onlyWithShares,
}) => {
  const [search, setSearch] = useState<string>('');
  const findStakeholderTypeKeys = (search: string) =>
    Object.entries(stakeholderTypeTranslation)
      .filter(([_, value]) => (search ? value.toLowerCase().includes(search.toLowerCase()) : false))
      .map(([key]) => key as StakeholderType);
  const searchByType = useMemo(() => findStakeholderTypeKeys(search || ''), [search]);

  const { stakeholders, fetchNextPage, hasNextPage, isLoading } = useInfiniteStakeholders({
    companyId,
    filter: 'all',
    orderBy: null,
    sortBy: null,
    search: search || '',
    searchType: searchByType,
  });

  const {
    stakeholders: stakeholdersWithShares,
    fetchNextPage: fetchNextWithShares,
    hasNextPage: hasNextWithShares,
    isLoading: isLoadingWithShares,
  } = useStakeholdersWithShares({
    companyId,
    search: search || '',
  });

  const filteredStakeholders = stakeholderFromId
    ? stakeholders.filter(({ id }) => id !== stakeholderFromId)
    : onlyWithShares
      ? stakeholdersWithShares
      : stakeholders;

  const ref = useRef<HTMLInputElement>(null);
  return (
    <>
      <FormCombobox
        className="relative w-[224px]"
        control={control}
        name={name as keyof FormSchema}
        onFocus={() => setSearch('')}
        onSelect={() => {
          setSearch('');
        }}
      >
        <div className="relative">
          <FormComboboxInput
            className={'bg-gray-900'}
            control={control}
            customValue={(value) => (value as StakeholderValue)?.fullName}
            icon={<ChevronDownIcon className="mt-3" />}
            name={name as keyof FormSchema}
            placeholder={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
            readOnly
          />
          <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
        </div>
        <ComboboxOptions className="w-full overflow-x-hidden">
          <div className="relative flex w-full items-center" onClick={() => ref.current?.focus()}>
            <SearchIcon className="absolute ml-[6px]" />
            <input
              className="w-full rounded border-[2px] border-transparent bg-gray-50 p-[6px] pl-8 text-gray-700 outline-none placeholder:text-gray-400 focus:border-brand-700 focus:bg-white"
              onChange={({ target: { value } }) => setSearch(value)}
              ref={ref}
              value={search}
            />
          </div>
          <StakeholdersItemsList
            control={control}
            data={filteredStakeholders || []}
            fetchNextPage={onlyWithShares ? fetchNextWithShares : fetchNextPage}
            hasNextPage={onlyWithShares ? hasNextWithShares : hasNextPage}
            isLoading={onlyWithShares ? isLoadingWithShares : isLoading}
            name={name}
          />
        </ComboboxOptions>
      </FormCombobox>
    </>
  );
};
