import { Field, Label } from '@headlessui/react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Control,
  useFieldArray,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import MinusIcon from '../../../../../../assets/icons/MinusIcon';
import { Checkbox } from '../../../../../../components/Checkbox';
import { Table } from '../../../../../../components/Table';
import { SharePlan } from '../../../../../../hooks';
import { Valuation } from '../../../../../../types/events.types';
import { VestingTask } from '../../../../../../types/vestingTasks.types';
import { toNumber } from '../../../../../../utils/toNumber';
import { FormSchema } from '../../validation';
import { HurdleTableRow } from './HurdleTableRow';
import { ApproveHurdleColumn, approveHurdleColumnMap, approveHurdleColumnTitle } from './types';

export type HurdleProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
  valuation: Valuation;
};

export const Hurdle: FC<HurdleProps> = ({ control, companyId, valuation }) => {
  const { stepOne } = useWatch<FormSchema>({ control });

  const { approveHurdleGrants: approveHurdleGrantsData } = SharePlan.useSharePlanGrantItems({
    companyId,
    sharePlanId: stepOne?.hurdle?.planId || '',
  });

  const approveHurdleGrants = approveHurdleGrantsData.filter(
    ({ exercisedCount, totalSharesCount }) => exercisedCount !== totalSharesCount,
  );

  const [selectedGrantItemIds, setSelectedGrantItemIds] = useState<string[]>([]);

  const {
    fields: hurdleItemsFields,
    update: updateHurdleItem,
    replace: replaceHurdleItems,
  } = useFieldArray({
    control,
    name: 'stepOne.hurdle.hurdleItems',
  });

  const onDeselectAll = () => {
    setSelectedGrantItemIds([]);
    replaceHurdleItems(
      approveHurdleGrants.map((grant) => ({ ...handlePrepareHurdleItem(grant), selected: false })),
    );
  };

  const onSelectAll = (grantIds: string[]) => {
    setSelectedGrantItemIds(grantIds);
    replaceHurdleItems(approveHurdleGrants.map(handlePrepareHurdleItem));
  };

  const handleSelectStakeholder = (grantId: string, hurdleItemsIndex: number) => {
    setSelectedGrantItemIds((prev) => [...prev, grantId]);
    updateHurdleItem(hurdleItemsIndex, { ...hurdleItemsFields[hurdleItemsIndex], selected: true });
  };
  const handleDeselectStakeholder = (grantId: string, hurdleItemsIndex: number) => {
    setSelectedGrantItemIds((prev) => prev.filter((id) => id !== grantId));
    updateHurdleItem(hurdleItemsIndex, { ...hurdleItemsFields[hurdleItemsIndex], selected: false });
  };

  const handlePrepareHurdleItem = useCallback(
    ({
      grantItem: {
        stakeholder,
        numbersOfGrants,
        plan: {
          hardleValue,
          pool: { shareClass },
        },
      },
      id: grantId,
    }: VestingTask) => ({
      stakeholder,
      grantId,
      selected: true,
      hurdleValue: toNumber(hardleValue),
      shareClassName: shareClass.name,
      issuedShares: numbersOfGrants,
      date: new Date(),
    }),
    [],
  );

  useEffect(() => {
    if (approveHurdleGrants.length === hurdleItemsFields.length) return;
    replaceHurdleItems(
      approveHurdleGrants.map((grantItem) => {
        setSelectedGrantItemIds((prev) => [...prev, grantItem.id]);
        return handlePrepareHurdleItem(grantItem);
      }),
    );
  }, [approveHurdleGrants, handlePrepareHurdleItem, hurdleItemsFields.length, replaceHurdleItems]);

  return (
    <>
      <div className="max-h-[400px] w-[1309px] overflow-y-auto rounded-lg border-[1px] border-gray-100">
        <Table
          columns={approveHurdleColumnMap}
          columnsTitle={{
            ...approveHurdleColumnTitle,
            [ApproveHurdleColumn.SELECT]: (
              <Field className="flex cursor-pointer items-center">
                <Checkbox
                  checked={selectedGrantItemIds.length > 0}
                  id="select-all-hurdle-plans"
                  onChange={(checked) =>
                    checked
                      ? onSelectAll(hurdleItemsFields?.map(({ grantId }) => grantId) || [])
                      : onDeselectAll()
                  }
                  selectIcon={<MinusIcon />}
                />
                <Label
                  className="absolute left-0 top-0 h-full w-full cursor-pointer"
                  htmlFor="select-all-hurdle-plans"
                />
              </Field>
            ),
          }}
          tHeadClassNames={{
            thClassName: 'pl-4',
            spanClassName: 'font-500 whitespace-nowrap text-#172335 text-label-md',
          }}
        >
          {hurdleItemsFields.map((approveItem, i) => {
            const {
              hurdleValue,
              grantId,
              issuedShares,
              shareClassName,
              stakeholder: { fullName },
            } = approveItem;

            return (
              <HurdleTableRow
                currentValuation={toNumber(valuation.issuedShares) * valuation.sharePrice}
                grantId={grantId}
                hurdleValue={hurdleValue}
                id={grantId}
                isSelected={selectedGrantItemIds.includes(grantId)}
                issuedShares={issuedShares}
                key={grantId}
                onDateChange={(date) => updateHurdleItem(i, { ...approveItem, date })}
                onDeselect={() => handleDeselectStakeholder(grantId, i)}
                onSelect={() => handleSelectStakeholder(grantId, i)}
                shareClassName={shareClassName}
                stakeholderName={fullName}
              />
            );
          })}
        </Table>
      </div>
    </>
  );
};
