import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import { FC, ReactNode } from 'react';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LocaleProvider } from './context/locale.context';

interface AppProps {
  children: ReactNode;
}

const queryClient = new QueryClient();

const googleAnalyticId = process.env.REACT_APP_GA_MEASUREMENT_ID || '';
ReactGA.initialize(googleAnalyticId, {
  testMode: process.env.REACT_APP_ENV === 'development',
});

const App: FC<AppProps> = ({ children }) => {
  polyfillCountryFlagEmojis();

  // useRedirectToHttps();

  return (
    <LocaleProvider>
      <QueryClientProvider client={queryClient}>
        <div className="App [&>*]:font-inter [&>*]:font-normal">{children}</div>

        {/* {process.env.REACT_APP_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )} */}
        <ToastContainer
          bodyClassName={'h-full p-[3px]'}
          className="flex flex-col items-center gap-2 pb-1"
          closeButton={false}
          closeOnClick
          limit={3}
          position="bottom-center"
          toastClassName={() =>
            'rounded-[40px] h-fit shadow-2xl overflow-hidden relative bg-white text-gray-700 text-sm font-[450] p-2 lg:text-nowrap w-fit px-3 py-2'
          }
        />
      </QueryClientProvider>
    </LocaleProvider>
  );
};

export default App;
