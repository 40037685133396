import { Field, Label } from '@headlessui/react';
import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { HelpIcon } from '../../../assets/icons';
import { HelpIconVariant } from '../../../assets/icons/HelpIcon';
import MinusIcon from '../../../assets/icons/MinusIcon';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Checkbox } from '../../../components/Checkbox';
import { EmptySearchList } from '../../../components/EmptySearchList';
import { StringKey } from '../../../lang';
import { StakeholderTableProps } from '../StakeholderTable/StakeholderTable';
import { StakeholderTerminatedTableItem } from './StakeholderTerminatedTableItem';

export const StakeholderTerminatedTable: FC<StakeholderTableProps> = ({
  data,
  className,
  handleEdit,
  handleView,
  handleDelete,
  handleCancelInvitation,
  handleGrantAccess,
  handleMakeContributor,
  handleRemoveContributorRole,
  handleResendInvitation,
  handleRevokeAccess,
  handleTerminate,
  handleEditTermination,
  handleSendInvitation,
  isSelected,
  onRemoveSelect,
  onSelect,
  searchValue,
  onRemoveSelectAll,
  onSelectAll,
  isSelectStarted,
  roleInCompany,
  accountId,
  isDemo,
}) => {
  return (
    <>
      <div className="w-full shrink-0 overflow-x-auto *:text-nowrap">
        <table
          className={twMerge(
            'h-fit w-full divide-y-[1px] divide-[#F2F2F2] border-b-[1px] border-[#F2F2F2] bg-brand-700',
            className,
          )}
        >
          <colgroup>
            <col className="w-12 shrink-0" span={1} />
          </colgroup>
          <thead>
            <tr className="h-11 bg-gray-50">
              <td className="relative">
                <div className="flex h-full w-full items-center justify-center px-4">
                  <Field className="flex cursor-pointer items-center">
                    <Checkbox
                      checked={isSelectStarted}
                      id="select-all"
                      onChange={(checked) =>
                        checked ? onSelectAll(data?.map(({ id }) => id) || []) : onRemoveSelectAll()
                      }
                      selectIcon={<MinusIcon />}
                    />
                    <Label
                      className="absolute left-0 top-0 h-full w-full cursor-pointer"
                      htmlFor="select-all"
                    />
                  </Field>
                </div>
              </td>

              <td className="pl-4">
                <span className="text-label-md font-[450] text-[#172335]">
                  <AppFormattedMessage id={StringKey.STAKEHOLDER} />
                </span>
              </td>
              <td className="pl-4">
                <span className="text-label-md font-[450] text-[#172335]">Current type</span>
              </td>
              <td className="pl-4">
                <div className="flex items-center gap-2">
                  <span className="text-label-md font-[450] text-[#172335]">Termination type</span>
                  <HelpIcon variant={HelpIconVariant.EXERCISE} />
                </div>
              </td>
              <td className="pl-4">
                <div className="flex items-center gap-2">
                  <span className="text-label-md font-[450] text-[#172335]">Termination date</span>
                  <HelpIcon variant={HelpIconVariant.EXERCISE} />
                </div>
              </td>
              <td className="pl-4">
                <div className="flex items-center gap-2">
                  <span className="text-label-md font-[450] text-[#172335]">Last vesting date</span>
                  <HelpIcon variant={HelpIconVariant.EXERCISE} />
                </div>
              </td>
              <td className="pl-4">
                <span className="text-label-md font-[450] text-[#172335]">Contact info</span>
              </td>
              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.STATUS} />
                  </span>
                </div>
              </td>
            </tr>
          </thead>
          <tbody className="divide-y-[1px] divide-[#F2F2F2] bg-gray-600">
            {data?.map((stakeholder, i) => (
              <StakeholderTerminatedTableItem
                accountId={accountId}
                isDemo={isDemo}
                key={stakeholder.id}
                {...stakeholder}
                handleCancelInvitation={handleCancelInvitation}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                handleEditTermination={handleEditTermination}
                handleGrantAccess={handleGrantAccess}
                handleMakeContributor={handleMakeContributor}
                handleRemoveContributorRole={handleRemoveContributorRole}
                handleResendInvitation={handleResendInvitation}
                handleRevokeAccess={handleRevokeAccess}
                handleSendInvitation={handleSendInvitation}
                handleTerminate={handleTerminate}
                handleView={handleView}
                index={i}
                isSelected={isSelected}
                onRemoveSelect={onRemoveSelect}
                onSelect={onSelect}
                roleInCompany={roleInCompany}
              />
            ))}
          </tbody>
        </table>
        {searchValue && data?.length === 0 && (
          <div className="flex w-full items-center justify-center max-lg:hidden">
            <EmptySearchList className="mt-20" />
          </div>
        )}
      </div>
    </>
  );
};
