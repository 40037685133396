import React, { FC } from 'react';

import { Pool, PoolPlansTab, PoolsPlansTableColumn } from '../../../../../types/pool-plans.types';
import { toUpperCaseWords } from '../../../../../utils/toUppercaseUtil';
import { ScrollablePoolItem } from './ScrollablePoolItem';

export type ScrollablePoolTableProps = {
  pools: Pool[] | undefined;
  selectedPool: string[];
  selectedPlan: string[];
  selectedTab: PoolPlansTab;
  onEditClick: (id: string) => void;
  onViewClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onEditPlanClick: (id: string) => void;
  onViewPlanClick: (id: string) => void;
  onDeletePlanClick: (id: string) => void;
  onEditTerminationDateClick: (id: string) => void;
  onEditPlanTerminationDateClick: (id: string) => void;
};

const tableColumns = Object.values(PoolsPlansTableColumn);

export const ScrollablePoolTable: FC<ScrollablePoolTableProps> = ({
  pools,
  selectedPlan,
  selectedPool,
  selectedTab,
  onEditClick,
  onViewClick,
  onDeleteClick,
  onEditPlanClick,
  onViewPlanClick,
  onDeletePlanClick,
  onEditTerminationDateClick,
  onEditPlanTerminationDateClick,
}) => {
  return (
    <table className="w-full transition-all">
      <thead>
        <tr className="border-y-[1px] border-[#F2F2F2] bg-gray-50">
          {tableColumns.map((column) => (
            <td className="px-4 py-3" key={column}>
              <div className="flex items-center">
                <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
                  {toUpperCaseWords(column)}
                </span>
              </div>
            </td>
          ))}
          <td className="relative">
            <div className="flex h-full w-full items-center justify-center px-6"></div>
          </td>
        </tr>
      </thead>
      <tbody>
        {pools && (
          <>
            {pools.map((pool) => (
              <ScrollablePoolItem
                isSelected={selectedPool.includes(pool.id)}
                key={pool.id}
                onDeleteClick={onDeleteClick}
                onDeletePlanClick={onDeletePlanClick}
                onEditClick={onEditClick}
                onEditPlanClick={onEditPlanClick}
                onEditPlanTerminationDateClick={onEditPlanTerminationDateClick}
                onEditTerminationDateClick={onEditTerminationDateClick}
                onViewClick={onViewClick}
                onViewPlanClick={onViewPlanClick}
                pool={pool}
                selectedPlan={selectedPlan}
                selectedTab={selectedTab}
              />
            ))}
          </>
        )}
      </tbody>
    </table>
  );
};
