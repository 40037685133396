import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { Company, SharePlan } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { sharePlanTypeTitle, SharePlanUnionType } from '../../../../types/pool-plans.types';
import { PoolPlansPopover } from '../../PoolPlansTable';

type PlanCardProps = {
  plan: SharePlanUnionType;
  navigationLink: string;
  onEditPlanClick: (id: string) => void;
  onViewPlanClick: (id: string) => void;
  onDeletePlanClick: (id: string) => void;
};

export const PlanCard: FC<PlanCardProps> = ({
  plan,
  navigationLink,
  onEditPlanClick,
  onViewPlanClick,
  onDeletePlanClick,
}) => {
  const { selectedCompanyId } = Company.useSelected();
  const { stakeholders } = SharePlan.useStakeholders({
    companyId: selectedCompanyId,
    planId: plan.id,
  });

  const navigate = useNavigate();

  const granted = plan.granted?.toLocaleString('en-US');
  const totalGrantVested = plan.totalGrantVested?.toLocaleString('en-US');
  const sharesVested = plan.sharesVested?.toLocaleString('en-US');
  const exercised = plan.exercised?.toLocaleString('en-US');
  const exercisable = plan.exercisable?.toLocaleString('en-US');
  const isTerminated = plan?.isTerminated ?? false;

  return (
    <div className="mb-4 flex h-fit w-full min-w-[300px] max-w-[440px] flex-col divide-y-[1px] divide-gray-100 rounded-md shadow-sm">
      <div className="flex flex-col p-3">
        <div className="flex items-center justify-between">
          <div className="flex max-w-[calc(100%-90px)] flex-col overflow-hidden">
            <span className="truncate text-base font-[550] text-gray-700">{plan?.name}</span>
            <span className="text-xs font-[450] text-gray-400">
              {plan?.type && sharePlanTypeTitle[plan.type]}
            </span>
          </div>
          {plan.isTerminated && (
            <span className="rounded-2xl bg-fireside-50 px-2 py-[2px] text-label-sm font-[450] text-fireside-600">
              <AppFormattedMessage id={StringKey.TERMINATED} />
            </span>
          )}
          {onEditPlanClick && onDeletePlanClick && onViewPlanClick && !isTerminated && (
            <PoolPlansPopover
              id={plan?.id || ''}
              isTerminated={isTerminated}
              onDeleteClick={onDeletePlanClick}
              onEditClick={onEditPlanClick}
              onViewClick={onViewPlanClick}
              text="plan"
            />
          )}
        </div>
      </div>
      <div className="flex flex-wrap divide-x-[1px] divide-y-[1px] divide-[#F2F4F7]">
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Diluted shares
          </span>
          <span className="whitespace-nowrap text-sm text-[#172335]"></span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Available pool
          </span>
          <span className="whitespace-nowrap text-sm text-[#172335]"></span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Pool granted
          </span>
          <span className="whitespace-nowrap text-sm text-[#172335]">{granted}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Total grant vested
          </span>
          <span className="text-sm text-[#172335]">{totalGrantVested}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Shares vested
          </span>
          <span className="text-sm text-[#172335]">{sharesVested}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Exercisable
          </span>
          <span className="text-sm text-[#172335]">{exercisable}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Exercised
          </span>
          <span className="text-sm text-[#172335]">{exercised}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Cash settled
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Returned to pool
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">Expired</span>
          <span className="text-sm text-gray-300">-</span>
        </div>
      </div>
      {stakeholders && (
        <Button
          className="flex w-full items-center justify-between bg-brand-25 p-3 text-brand-700"
          onClick={() => navigationLink && navigate(navigationLink)}
          styleType="DEFAULT_ROUNDED"
        >
          <span className="text-sm">View {stakeholders.length} associated stakeholder(s)</span>
          <ArrowIcon className="rotate-180 transform" iconColor="#2565C8" />
        </Button>
      )}
    </div>
  );
};
