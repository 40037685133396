import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../../../assets/icons';
import Button from '../../../../../components/Button';
import Loader from '../../../../../components/Loader';
import { Pool, useSelectedCompany } from '../../../../../hooks';
import { Stakeholder } from '../../../../../types/stakeholderTypes';
import { StickyPoolPlanItem } from './StickyPoolPlanItem';

type StickyStakeholderItemProps = {
  stakeholder: Stakeholder;
  isSelected: boolean;
  setSelectedPool: (state: string) => void;
  selectedPlan: string[];
  setSelectedPlan: (state: string) => void;
};

export const StickyStakeholderItem: FC<StickyStakeholderItemProps> = ({
  stakeholder,
  isSelected,
  setSelectedPool,
  selectedPlan,
  setSelectedPlan,
}) => {
  const { selectedCompany } = useSelectedCompany();
  const companyId = selectedCompany?.id || '';

  const handlePoolSelected = () => {
    setSelectedPool(stakeholder.id);
    setSelectedPlan('');
  };

  const handlePoolUnselected = () => {
    setSelectedPool('');
    setSelectedPlan('');
  };

  const { pools, fetchNextPage, hasNextPage, isEmpty, totalPages } = Pool.usePoolsByStakeholder({
    companyId,
    stakeholderId: stakeholder?.id || '',
  });

  return (
    <>
      <tr
        className={twMerge(
          'flex h-[72px] w-full border-l-[2px] border-l-white bg-white transition-colors',
          isSelected && 'border-l-brand-700 bg-brand-25',
        )}
      >
        <td className="relative h-[72px] border-b-[1px] border-b-gray-100">
          <div className="flex h-full w-full items-center justify-center">
            {stakeholder && pools && (
              <Button
                className="h-[72px] p-4 transition-colors"
                onClick={isSelected ? handlePoolUnselected : handlePoolSelected}
                styleType="NONE"
              >
                <ChevronDownIcon
                  className={twMerge('transition-transform', isSelected ? 'rotate-180' : '')}
                  iconColor={isSelected ? '#2565C8' : '#344054'}
                />
              </Button>
            )}
          </div>
        </td>
        <td className="flex h-[72px] w-full items-center justify-between gap-4 overflow-hidden text-nowrap border-b-[1px] border-b-gray-100 px-6 py-4">
          <div className="flex max-w-[300px] items-center gap-3">
            <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
              {stakeholder.fullName[0]}
            </div>
            <span className="truncate text-sm font-[450] text-gray-700">
              {stakeholder.fullName}
            </span>
          </div>
        </td>
      </tr>
      {!isEmpty && pools && isSelected && (
        <InfiniteScroll
          dataLength={totalPages}
          hasMore={hasNextPage}
          loader={<Loader />}
          next={fetchNextPage}
          scrollableTarget="scrollbar-target"
        >
          {pools.map((pool) => (
            <StickyPoolPlanItem
              companyId={companyId}
              isSelected={selectedPlan.includes(pool.id)}
              key={pool.id}
              pool={pool}
              setSelectedPlan={setSelectedPlan}
              stakeholderId={stakeholder.id}
            />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};
