import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export enum HelpIconVariant {
  DEFAULT,
  EXERCISE,
}

export type HelpIconProps = { variant?: HelpIconVariant } & IconProps;

const helpIconVariant: Record<
  HelpIconVariant,
  (props: Except<HelpIconProps, 'variant'>) => ReactNode
> = {
  [HelpIconVariant.DEFAULT]: ({ iconColor = '#2565C8', className, ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="20"
      viewBox="0 0 22 22"
      width="20"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.09 8C8.3251 7.33167 8.78915 6.76811 9.39995 6.40913C10.0108 6.05016 10.7289 5.91894 11.4272 6.03871C12.1255 6.15849 12.7588 6.52152 13.2151 7.06353C13.6713 7.60553 13.9211 8.29152 13.92 9C13.92 11 10.92 12 10.92 12M11 16H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
  [HelpIconVariant.EXERCISE]: ({ iconColor = '#98A2B3', className, ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.56 6.00065C6.71673 5.5551 7.0261 5.17939 7.4333 4.94007C7.8405 4.70076 8.31926 4.61328 8.78478 4.69313C9.2503 4.77297 9.67254 5.015 9.97671 5.37634C10.2809 5.73767 10.4474 6.195 10.4467 6.66732C10.4467 8.00065 8.44666 8.66732 8.44666 8.66732M8.5 11.334H8.50666M15.1667 8.00065C15.1667 11.6826 12.1819 14.6673 8.5 14.6673C4.8181 14.6673 1.83333 11.6826 1.83333 8.00065C1.83333 4.31875 4.8181 1.33398 8.5 1.33398C12.1819 1.33398 15.1667 4.31875 15.1667 8.00065Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
    </svg>
  ),
};

const HelpIcon: FC<HelpIconProps> = ({ variant = HelpIconVariant.DEFAULT, ...props }) =>
  helpIconVariant[variant](props);

export default HelpIcon;
