import React, { FC } from 'react';

import { InfoCircle } from '../../assets/icons';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../components/HoverCard';
import { useFormat } from '../../hooks';
import { toRound } from '../../utils/getRoundedNumber';

export type CurrentSharePriceProps = {
  currency?: string;
  price?: number;
  valuationEventDate?: Date;
};

export const CurrentSharePrice: FC<CurrentSharePriceProps> = ({
  currency = 'usd',
  price = 0,
  valuationEventDate = new Date(),
}) => {
  const { format } = useFormat();
  return (
    <div className="flex w-full flex-col px-4 py-2">
      <span className="text-label-sm font-[450] text-gray-500">Current share price</span>
      <div className="flex items-center gap-2">
        <span className="text-xl font-bold uppercase text-gray-700">
          {currency} {toRound(price)}
        </span>

        <HoverCard>
          <HoverCardTrigger className="max-lg:hidden">
            <InfoCircle className="size-4" iconColor="#344054" />
          </HoverCardTrigger>
          <HoverCardContent
            className="z-20 h-[34px] w-max rounded-lg bg-[#101828] px-3 py-1"
            isShownTriangle
            side="top"
            triangleClassName="bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 ml-0"
          >
            <span className="text-label-md font-[550] text-white">
              Value at Latest Valuation Event {format(valuationEventDate, '(dd/MM/yyyy)')}
            </span>
          </HoverCardContent>
        </HoverCard>
      </div>
      <span className="text-label-sm font-[450] text-gray-500 lg:hidden">
        Value at Latest Valuation Event {format(valuationEventDate, '(dd/MM/yyyy)')}
      </span>
    </div>
  );
};
