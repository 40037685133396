import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type DiscountIconProps = IconProps;

const DiscountIcon: FC<DiscountIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7.33334L8.93726 2.2706C8.59135 1.9247 8.4184 1.75175 8.21657 1.62806C8.03762 1.51841 7.84254 1.4376 7.63846 1.3886C7.40829 1.33334 7.16369 1.33334 6.67452 1.33334L4 1.33334M2 5.80001L2 7.11635C2 7.44248 2 7.60554 2.03684 7.75899C2.0695 7.89503 2.12337 8.02509 2.19648 8.14439C2.27894 8.27895 2.39424 8.39425 2.62484 8.62485L7.82484 13.8248C8.35286 14.3529 8.61687 14.6169 8.92131 14.7158C9.1891 14.8028 9.47757 14.8028 9.74536 14.7158C10.0498 14.6169 10.3138 14.3529 10.8418 13.8249L12.4915 12.1752C13.0195 11.6471 13.2835 11.3831 13.3825 11.0787C13.4695 10.8109 13.4695 10.5224 13.3825 10.2547C13.2835 9.95022 13.0195 9.68621 12.4915 9.15818L7.62484 4.29152C7.39424 4.06091 7.27894 3.94561 7.14438 3.86316C7.02508 3.79005 6.89502 3.73618 6.75898 3.70352C6.60553 3.66668 6.44247 3.66668 6.11634 3.66668H4.13333C3.3866 3.66668 3.01323 3.66668 2.72801 3.812C2.47713 3.93983 2.27316 4.14381 2.14532 4.39469C2 4.67991 2 5.05327 2 5.80001Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
  </svg>
);

export default DiscountIcon;
