import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { ApproveMilestoneDto } from '../../types/vestingTasks.types';
import { useAppMutation } from '../useAppMutation';

export const useApproveTargetBasedVestingTasks = ({ companyId }: { companyId: string }) => {
  const { mutate: approve } = useAppMutation([QueryKey.APPROVE_TARGET_BASED_MILESTONE, companyId], {
    mutationFn: (body: ApproveMilestoneDto[]) =>
      ApiService.post(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'share-plans', 'approve-target-based'],
        },
        { body },
      ),
  });

  return {
    approve,
  };
};
