import { format, FormatOptions, LocalizeFn } from 'date-fns';

import { useLocale } from './useLocale';

export const useFormat = (ordinalNumber?: LocalizeFn<number>) => {
  const { dateFnsLocale } = useLocale();
  return {
    format: <DateType extends Date>(
      date: DateType | number | string | undefined,
      formatStr: string,
      options?: Except<FormatOptions, 'locale'>,
    ) => {
      if (!date) return '';
      return format(date, formatStr, {
        ...options,
        locale: {
          ...dateFnsLocale,
          localize: {
            ...dateFnsLocale.localize,
            ordinalNumber: ordinalNumber ?? dateFnsLocale.localize.ordinalNumber,
          },
        },
      });
    },
  };
};
