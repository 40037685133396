import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

type SwitchIconProps = IconProps;

export const SwitchIcon: FC<SwitchIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 9.91667H2.33334M2.33334 9.91667L4.66667 7.58333M2.33334 9.91667L4.66667 12.25M2.33334 4.08333H11.6667M11.6667 4.08333L9.33334 1.75M11.6667 4.08333L9.33334 6.41667"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.1375"
    />
  </svg>
);
