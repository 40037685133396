import { FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { EditIcon } from '../../../../assets/icons';
import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { SummaryItem } from '../../../../components/SummaryItem';
import { Switch } from '../../../../components/Switch';
import { useFormat, useModalState } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  SharePlanType,
  sharePlanTypeTitle,
  SharePlanVestingType,
  terminationIntervalTitle,
  VestingOnTitle,
} from '../../../../types/pool-plans.types';
import { TerminationModal } from '../../TerminationModal';
import { ChildrenFormProps, EditPlanStepVariant } from '../type';
import { formatSingular } from './FinalStep';

export type EditPlanPreviewProps = {
  onEditClick: (step: EditPlanStepVariant) => void;
  previewMode?: true;
  handleTerminate: (date: Date) => void;
} & ChildrenFormProps;

export const EditPlanPreview: FC<EditPlanPreviewProps> = ({
  handleCloseModal,
  formData,
  onEditClick,
  handleTerminate,
  previewMode,
  isTerminated,
  terminationDate,
}) => {
  const { format } = useFormat();
  const [terminatingData, setTerminatingData] = useState<{
    isTerminating: boolean;
    date: Date | null;
  } | null>(null);
  const { stepOne, stepTwo, stepThree, stepFour } = formData();

  const handleDocumentClick = (link: string) => {
    window.open(link, '_blank');
  };

  const {
    toggler: terminationModalToggler,
    isOpen: isOpenTerminationModal,
    handleOpenModal: handleOpenTerminationModal,
    handleCloseModal: handleCloseTerminationModal,
    handleSuccessModal: handleSuccessTerminationModalModal,
  } = useModalState({
    onSuccess: () => {
      if (!terminatingData?.date) return;
      handleTerminate(terminatingData.date);
      handleCloseModal();
    },
  });

  return (
    <>
      <AlertDialogWrapper
        control={{ onOpenChange: terminationModalToggler, open: isOpenTerminationModal }}
      >
        <TerminationModal
          onClose={handleCloseTerminationModal}
          onSuccess={handleSuccessTerminationModalModal}
          text="plan"
        />
      </AlertDialogWrapper>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <div className="flex w-full justify-between px-4">
            <span className="text-sm font-[550] text-gray-700">General</span>
            {!previewMode && (
              <Button className="w-fit" onClick={() => onEditClick(1)} styleType="NONE">
                <EditIcon iconColor="#2565C8" />
              </Button>
            )}
          </div>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-0">
            <SummaryItem className="pt-0" title="Plan name" value={stepOne?.name} />
            <SummaryItem title="Pool" value={stepOne?.pool?.name} />
            <SummaryItem
              title="Date"
              value={stepOne?.date ? format(stepOne?.date, 'dd/MM/yyyy') : ''}
            />
            <SummaryItem title="Equity plan type" value={sharePlanTypeTitle[stepOne?.type]} />
            {stepOne?.type === SharePlanType.GROWTH_HURDLE && (
              <SummaryItem
                title="Hurdle value"
                value={
                  stepOne?.financialDetails['growth-hurdle']?.value &&
                  Number(stepOne?.financialDetails['growth-hurdle']?.value).toLocaleString('en-US')
                }
              />
            )}
            {stepOne?.type === SharePlanType.STOCK_OPTIONS && (
              <>
                <SummaryItem
                  title="Strike Price value"
                  value={stepOne?.financialDetails['stock-options']?.value}
                />
                <SummaryItem
                  title="Conversion ratio (x)"
                  value={stepOne?.financialDetails['stock-options']?.conversionRatio || '-'}
                />
              </>
            )}
            {stepOne?.type === SharePlanType.BSPCE && (
              <>
                <SummaryItem
                  title="Strike Price value"
                  value={stepOne?.financialDetails['bspce']?.value}
                />
                <SummaryItem
                  title="Conversion ratio (x)"
                  value={stepOne?.financialDetails['bspce']?.conversionRatio || '-'}
                />
              </>
            )}
            {stepOne?.type === SharePlanType.WARRANTS && (
              <>
                <SummaryItem
                  title="Warrant Price value"
                  value={stepOne?.financialDetails['warrants']?.value}
                />
                <SummaryItem
                  title="Conversion ratio (x)"
                  value={stepOne?.financialDetails['warrants']?.conversionRatio || '-'}
                />
              </>
            )}
            {stepOne?.type === SharePlanType.SAR && (
              <SummaryItem
                title="SAR Base Price value"
                value={stepOne?.financialDetails['sar']?.value}
              />
            )}
            {stepOne?.type === SharePlanType.RSU && (
              <SummaryItem
                title="Conversion ratio (x)"
                value={stepOne?.financialDetails['rsu']?.conversionRatio || '-'}
              />
            )}
            {stepOne?.type === SharePlanType.PHANTOM && (
              <SummaryItem
                title="Conversion ratio (x)"
                value={stepOne?.financialDetails['phantom']?.conversionRatio || '-'}
              />
            )}
            {stepOne?.type === SharePlanType.RSA && (
              <>
                <SummaryItem
                  title="RSA Purchase Price value"
                  value={stepOne?.financialDetails['rsa']?.value || '-'}
                />
                <SummaryItem
                  title="Conversion ratio (x)"
                  value={stepOne?.financialDetails['rsa']?.conversionRatio || '-'}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <div className="flex w-full justify-between px-4">
            <span className="text-sm font-[550] text-gray-700">Vesting</span>
            {!previewMode && (
              <Button className="w-fit" onClick={() => onEditClick(2)} styleType="NONE">
                <EditIcon iconColor="#2565C8" />
              </Button>
            )}
          </div>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            {!stepTwo?.vesting && (
              <span
                className={twMerge(
                  'text-xs font-[450] text-gray-500',
                  stepOne?.type === SharePlanType.WARRANTS && !stepTwo?.expiryDate?.date && 'pb-3',
                )}
              >
                No Vesting Structure Selected
              </span>
            )}
            {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_STANDARD && (
              <>
                <div>
                  <SummaryItem
                    className="pt-0"
                    title={<AppFormattedMessage id={StringKey.UPDATE} />}
                    value="Time Based (Standard)"
                  />
                  <SummaryItem
                    className="pt-0"
                    title="Vesting on"
                    value={
                      stepTwo.vesting.timeBasedStandard?.vestingOn &&
                      VestingOnTitle[stepTwo.vesting.timeBasedStandard?.vestingOn]
                    }
                  />
                </div>
                <div>
                  <SummaryItem
                    className="pb-0"
                    title="Duration"
                    value={formatSingular(
                      stepTwo?.vesting?.timeBasedStandard?.vestingDuration,
                      'month',
                    )}
                  />
                  <SummaryItem
                    className="pb-0"
                    title="Vesting freq"
                    value={formatSingular(
                      stepTwo?.vesting?.timeBasedStandard?.vestingFrequency,
                      'month',
                    )}
                  />
                  <SummaryItem
                    className="pb-0"
                    title="Cliff"
                    value={formatSingular(
                      stepTwo?.vesting?.timeBasedStandard?.cliffPeriod,
                      'month',
                    )}
                  />
                  <SummaryItem
                    className={stepTwo?.expiryDate?.enabled ? 'pb-3' : 'pb-0'}
                    title="Acceleration date"
                    value={
                      stepTwo?.vesting?.timeBasedStandard?.accelerationDate
                        ? format(
                            stepTwo?.vesting?.timeBasedStandard?.accelerationDate,
                            'dd/MM/yyyy',
                          )
                        : '-'
                    }
                  />
                </div>
              </>
            )}
            {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_DYNAMIC && (
              <>
                <div>
                  <SummaryItem
                    className="pt-0"
                    title={<AppFormattedMessage id={StringKey.TYPE} />}
                    value="Time Based (Dynamic)"
                  />
                  <SummaryItem
                    className="pt-0"
                    title="Vesting on"
                    value={
                      stepTwo.vesting.timeBasedDynamic?.vestingOn &&
                      VestingOnTitle[stepTwo.vesting.timeBasedDynamic?.vestingOn]
                    }
                  />
                </div>

                <div className="flex flex-col divide-y divide-gray-100">
                  {stepTwo?.vesting?.timeBasedDynamic?.dynamicVestings.map((period, index) => (
                    <div className="pt-3" key={index}>
                      <span className="text-xs font-[550] text-gray-700">Period {index + 1}</span>
                      <SummaryItem
                        className="pb-0"
                        title="Duration"
                        value={formatSingular(period.vestingDuration, 'month')}
                      />
                      <SummaryItem
                        className="pb-0"
                        title="Vesting freq"
                        value={formatSingular(period.vestingFrequency, 'month')}
                      />
                      <SummaryItem title="Plan allocation" value={`${period.planAllocation} %`} />
                    </div>
                  ))}
                </div>
                <SummaryItem
                  className={stepTwo?.expiryDate?.enabled && 'pb-3'}
                  title="Acceleration date"
                  value={
                    stepTwo?.vesting?.timeBasedDynamic?.accelerationDate
                      ? format(stepTwo?.vesting?.timeBasedDynamic?.accelerationDate, 'dd/MM/yyyy')
                      : '-'
                  }
                />
              </>
            )}
            {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TARGET_BASED && (
              <>
                <SummaryItem
                  className="pt-0"
                  title={<AppFormattedMessage id={StringKey.TYPE} />}
                  value="Target Based"
                />
                {stepTwo?.vesting?.targetBasedStandard?.targetVestings.map((period, index) => (
                  <div className="pt-3" key={index}>
                    <span className="text-xs font-[550] text-gray-700">Milestone {index + 1}</span>
                    <SummaryItem className="pb-0" title="Milestone name" value={period.name} />
                    <SummaryItem className="pb-0" title="Weight" value={`${period.weight} %`} />
                    <SummaryItem
                      title="Target date"
                      value={period.targetDate ? format(period.targetDate, 'dd/MM/yyyy') : '-'}
                    />
                  </div>
                ))}
              </>
            )}
            {stepOne?.type === SharePlanType.WARRANTS && (
              <SummaryItem
                className="pb-0"
                title="Expiry date"
                value={
                  stepTwo?.expiryDate?.date ? format(stepTwo?.expiryDate?.date, 'dd/MM/yyyy') : '-'
                }
              />
            )}
          </div>
        </div>

        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <div className="flex w-full justify-between px-4">
            <span className="text-sm font-[550] text-gray-700">Termination Logic</span>

            {!previewMode && (
              <Button className="w-fit" onClick={() => onEditClick(3)} styleType="NONE">
                <EditIcon iconColor="#2565C8" />
              </Button>
            )}
          </div>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            {!stepThree?.terminationLogic && (
              <span className="text-xs font-[450] text-gray-500">
                No Termination logic Structure Selected
              </span>
            )}
            {stepThree?.terminationLogic?.enabled && (
              <>
                {stepThree?.terminationLogic?.resignation?.enabled ? (
                  <div>
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.RESIGNATION} />}
                      value="Exercise Expiration Period"
                    />
                    <SummaryItem
                      className="pb-0"
                      title="Value"
                      value={stepThree.terminationLogic.resignation.value}
                    />
                    <SummaryItem
                      title="Interval"
                      value={`${terminationIntervalTitle[stepThree.terminationLogic.resignation.interval]}`}
                    />
                  </div>
                ) : (
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.RESIGNATION} />}
                    value="No Exercise Expiration Period"
                  />
                )}
                {stepThree?.terminationLogic?.termination?.enabled ? (
                  <div>
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.TERMINATION} />}
                      value="Exercise Expiration Period"
                    />
                    <SummaryItem
                      className="pb-0"
                      title="Value"
                      value={stepThree.terminationLogic.termination.value}
                    />
                    <SummaryItem
                      title="Interval"
                      value={`${terminationIntervalTitle[stepThree.terminationLogic.termination.interval]}`}
                    />
                  </div>
                ) : (
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.TERMINATION} />}
                    value="No Exercise Expiration Period"
                  />
                )}
                {stepThree?.terminationLogic?.terminationWithCause?.enabled ? (
                  <div>
                    <SummaryItem
                      className="pb-0"
                      title="Termination with cause"
                      value="Exercise Expiration Period"
                    />
                    <SummaryItem
                      className="pb-0"
                      title="Value"
                      value={stepThree.terminationLogic.terminationWithCause.value}
                    />
                    <SummaryItem
                      title="Interval"
                      value={`${terminationIntervalTitle[stepThree.terminationLogic.terminationWithCause.interval]}`}
                    />
                  </div>
                ) : (
                  <SummaryItem
                    title="Termination with cause"
                    value="No Exercise Expiration Period"
                  />
                )}
                {stepThree?.terminationLogic?.death?.enabled ? (
                  <div>
                    <SummaryItem
                      className="pb-0"
                      title="Death"
                      value="Exercise Expiration Period"
                    />
                    <SummaryItem
                      className="pb-0"
                      title="Value"
                      value={stepThree.terminationLogic.death.value}
                    />
                    <SummaryItem
                      title="Interval"
                      value={`${terminationIntervalTitle[stepThree.terminationLogic.death.interval]}`}
                    />
                  </div>
                ) : (
                  <SummaryItem title="Death" value="No Exercise Expiration Period" />
                )}
                {stepThree?.terminationLogic?.retirement?.enabled ? (
                  <div>
                    <SummaryItem
                      className="pb-0"
                      title="Retirement"
                      value="Exercise Expiration Period"
                    />
                    <SummaryItem
                      className="pb-0"
                      title="Value"
                      value={stepThree.terminationLogic.retirement.value}
                    />
                    <SummaryItem
                      className="pb-0"
                      title="Interval"
                      value={`${terminationIntervalTitle[stepThree.terminationLogic.retirement.interval]}`}
                    />
                  </div>
                ) : (
                  <SummaryItem
                    className="pb-0"
                    title="Retirement"
                    value="No Exercise Expiration Period"
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex w-full items-center justify-between rounded-lg border border-gray-100 bg-white px-4">
          <div className={'flex gap-3 text-nowrap py-2'}>
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              Documents:
            </span>
            <div className="flex max-w-[220px] flex-col gap-2">
              {(stepFour?.files?.length || 0) > 0 ? (
                stepFour?.files?.map(({ id, doc, docLink }) => (
                  <div
                    className={twMerge(
                      'truncate text-xs font-[450] text-gray-700',
                      previewMode && 'cursor-pointer text-brand-700 underline',
                    )}
                    key={id}
                    onClick={() => previewMode && handleDocumentClick(docLink)}
                  >
                    {doc?.name}
                  </div>
                ))
              ) : (
                <span className="truncate text-xs font-[450] text-gray-700">-</span>
              )}
            </div>
          </div>
          {!previewMode && (
            <Button className="w-fit" onClick={() => onEditClick(4)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          )}
        </div>
        <div className="flex w-full items-center justify-between rounded-lg border border-gray-100 bg-white px-4">
          <SummaryItem
            className="py-2"
            title="Additional notes"
            value={stepFour?.additionalNotes || '-'}
          />
          {!previewMode && (
            <Button className="w-fit" onClick={() => onEditClick(4)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          )}
        </div>

        {!previewMode && !isTerminated && (
          <div className="flex w-full flex-col justify-center rounded-lg bg-fireside-25 px-1 py-1">
            <div className="flex gap-2 px-3 py-3">
              <Switch
                onChange={(state) =>
                  setTerminatingData(state ? { isTerminating: state, date: null } : null)
                }
              />
              <span className="text-sm font-[550] text-fireside-600">Terminate Plan</span>
            </div>
            {terminatingData && (
              <div className="flex w-full flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
                <FormDatePicker
                  calendar={{ fromDate: new Date() }}
                  defaultMonth={terminatingData?.date || undefined}
                  inputValue={
                    terminatingData?.date ? format(terminatingData?.date, 'dd/MM/yy') : ''
                  }
                  inputWrapperClassName="cursor-pointer"
                  onSelect={(date) => setTerminatingData({ isTerminating: true, date })}
                  placeholder="Date of Termination"
                  value={terminatingData?.date}
                />
                <DropDown
                  answer="Plan termination refers to the process of ending an employee stock option plan, stock purchase plan, or any other equity compensation plan."
                  className="rounded-lg bg-gray-50"
                  question="How does plan termination works?"
                />
              </div>
            )}
          </div>
        )}

        {!previewMode && isTerminated && terminationDate && (
          <div className="flex flex-col gap-3 p-3 pt-5">
            <div className="flex flex-col gap-1">
              <span className="text-sm font-[550] text-fireside-600">Terminate Plan</span>
              <div className="flex items-center gap-2">
                <span className="text-xs font-[450] text-gray-700">Current Termination Date:</span>
                <span className="text-xs font-[450] text-fireside-600">
                  {terminationDate ? format(terminationDate, 'dd/MM/yy') : ''}
                </span>
              </div>
            </div>

            <div className="flex w-full flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
              <FormDatePicker
                calendar={{ fromDate: new Date() }}
                defaultMonth={terminatingData?.date || undefined}
                inputValue={terminatingData?.date ? format(terminatingData.date, 'dd/MM/yy') : ''}
                inputWrapperClassName="cursor-pointer"
                onSelect={(date) => setTerminatingData({ isTerminating: true, date })}
                placeholder="New termination date"
                value={terminatingData?.date}
              />
              <DropDown
                answer="Plan termination refers to the process of ending an employee stock option plan, stock purchase plan, or any other equity compensation plan."
                className="rounded-lg bg-gray-50"
                question="How does plan termination works?"
              />
            </div>
          </div>
        )}
      </div>

      <div
        className={twMerge(
          'flex h-9 w-full justify-start gap-3 px-6',
          (previewMode || terminatingData) && 'justify-end',
        )}
      >
        <Button
          className={twMerge(
            'w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700',
            terminatingData && 'bg-fireside-600 text-white',
            previewMode && 'bg-brand-700 text-white',
          )}
          disabled={(terminatingData && !terminatingData.date) || false}
          onClick={() => {
            if (!terminatingData) return handleCloseModal();

            terminatingData.date && handleOpenTerminationModal();
          }}
          styleType="NONE"
          type="button"
        >
          {terminatingData ? (
            'Terminate Plan'
          ) : previewMode ? (
            <AppFormattedMessage id={StringKey.CLOSE} />
          ) : (
            <AppFormattedMessage id={StringKey.CANCEL} />
          )}
        </Button>
      </div>
    </>
  );
};
