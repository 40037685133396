import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useEnterpriseRequest = () => {
  const { mutate: enterpriseRequest, isPending } = useAppMutation([QueryKey.CREATE_COMPANY], {
    mutationFn: ({ companyId }: { companyId: string }) =>
      ApiService.post({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'request-enterprise'],
      }),
  });
  return { enterpriseRequest, isPending };
};
