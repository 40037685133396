import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Stakeholder } from '../../types/stakeholderTypes';
import { useAppQuery } from '../useAppQuery';
import { useLocale } from '../useLocale';

export const useStakeholder = ({
  companyId,
  stakeholderId,
}: {
  companyId: string;
  stakeholderId: string;
}) => {
  const { locale } = useLocale();
  const queryClient = useQueryClient();

  const { data: stakeholder, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_STAKEHOLDER, companyId, stakeholderId],
    queryFn: () =>
      ApiService.get<Stakeholder>(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'stakeholders', stakeholderId],
        },
        { locale },
      ),
    enabled: !!companyId && !!stakeholderId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_STAKEHOLDER] });
  }, [queryClient]);

  return {
    invalidateQuery,
    stakeholder,
    isLoading,
  };
};
