import { Field, Label } from '@headlessui/react';
import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  AddContributorIcon,
  ArrowIcon,
  CloseCircleIconSmall,
  CrossIcon,
  EditIcon,
  EyeIconSmall,
  GrantAccessIcon,
  RevokeAccessIcon,
  SendInviteIcon,
  SuccessCircleIcon,
  ThreeDotsIcon,
  TrashBinIcon,
} from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Checkbox } from '../../../components/Checkbox';
import { FormattedDateDistance } from '../../../components/FormattedDateDistance';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { StringKey } from '../../../lang';
import { stakeholderTypeTranslation } from '../../../translations/stakeholderTranslation';
import {
  Stakeholder,
  stakeholderExTypeTitle,
  StakeholderRole,
  StakeholderType,
} from '../../../types/stakeholderTypes';
import { toRound } from '../../../utils/getRoundedNumber';
import { toNumber } from '../../../utils/toNumber';
import { toUpperCaseWords } from '../../../utils/toUppercaseUtil';
import { getStakeholderCommonActions } from '../StakeholderPermissions';
import StakeholderStatus, { StakeholderActionStatus } from '../StakeholderStatus';

export type StakeholderTableItemProps = {
  handleEdit: (id: string) => void;
  handleView: (id: string) => void;
  handleDelete: (id: string) => void;
  handleMakeContributor: (id: string) => void;
  handleRemoveContributorRole: (id: string) => void;
  handleResendInvitation: (id: string) => void;
  handleCancelInvitation: (id: string) => void;
  handleRevokeAccess: (id: string) => void;
  handleTerminate: (id: string) => void;
  handleEditTermination: (id: string) => void;
  handleGrantAccess: (id: string) => void;
  handleSendInvitation: (id: string) => void;
  onSelect: (stakeholderId: string) => void;
  onRemoveSelect: (stakeholderId: string) => void;
  isSelected: (stakeholderId: string) => boolean;
  roleInCompany?: StakeholderRole;
  index: number;
  accountId: string;
  isDemo: boolean;
} & Pick<
  Stakeholder,
  | 'email'
  | 'fullName'
  | 'id'
  | 'isAccessRevoked'
  | 'isActive'
  | 'isInvited'
  | 'lastActive'
  | 'role'
  | 'type'
  | 'votingRightsPercentage'
  | 'isTerminated'
  | 'terminationDate'
  | 'lastVestingDate'
  | 'terminationCause'
  | 'hasShares'
>;

const StakeholderTableItem: FC<StakeholderTableItemProps> = ({
  role,
  type,
  isInvited,
  isActive,
  isAccessRevoked,
  id,
  fullName,
  lastActive,
  email,
  votingRightsPercentage,
  isTerminated,
  hasShares,
  handleDelete,
  handleEdit,
  handleView,
  handleCancelInvitation,
  handleGrantAccess,
  handleMakeContributor,
  handleRemoveContributorRole,
  handleResendInvitation,
  handleRevokeAccess,
  handleTerminate,
  handleEditTermination,
  handleSendInvitation,
  isSelected,
  onRemoveSelect,
  onSelect,
  roleInCompany,
  accountId,
  isDemo,
}) => {
  const {
    canDelete,
    canTerminate,
    canEditTerminate,
    cancelInvitation,
    grantAccess,
    makeContributor,
    resendInvitation,
    revokeAccess,
    revokeContributor,
  } = getStakeholderCommonActions({
    role,
    isInvited,
    isActive,
    isAccessRevoked,
    isTerminated,
    isDemo,
  });

  return (
    <tr className={twMerge('w-full bg-white', isSelected(id) && 'bg-gray-50')} key={id}>
      <td className="relative">
        <div className="flex h-full w-full cursor-pointer items-center justify-center">
          <Field className="flex items-center">
            <Checkbox
              checked={isSelected(id)}
              id={id}
              onChange={(checked) => {
                checked ? onSelect(id) : onRemoveSelect(id);
              }}
            />
            <Label className="absolute left-0 top-0 h-full w-full cursor-pointer" htmlFor={id} />
          </Field>
        </div>
      </td>
      <td className="flex w-fit items-center gap-4 overflow-hidden text-nowrap p-4">
        <div className="relative flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
          {fullName[0]}
          {isTerminated && (
            <div className="absolute bottom-0 right-0 flex size-[14px] shrink-0 items-center justify-center rounded-full border-[1px] border-white bg-fireside-600">
              <CrossIcon className="size-[10px]" iconColor="#ffffff" />
            </div>
          )}
        </div>
        {accountId === id ? (
          <div className="flex items-center gap-1">
            <span className="max-w-[100px] truncate text-sm font-[450] text-gray-700">
              {fullName}
            </span>
            <span className="text-sm font-[450] text-gray-700">(You)</span>
          </div>
        ) : (
          <span className="max-w-[100px] truncate text-sm font-[450] text-gray-700">
            {fullName}
          </span>
        )}
      </td>
      <td className="p-4 text-sm text-gray-700">
        {isTerminated ? (
          toUpperCaseWords(stakeholderExTypeTitle[type])
        ) : (
          <AppFormattedMessage id={stakeholderTypeTranslation[type]} />
        )}
      </td>
      <td className="overflow-hidden p-4">
        <span className="max-w-[100px] truncate text-sm text-gray-700">{email}</span>
      </td>
      <td className="p-4 text-sm text-gray-700">
        {isActive ? <FormattedDateDistance date={new Date(lastActive)} /> : '-'}
      </td>
      <td className="p-4 text-sm text-gray-700">
        {toRound(toNumber(votingRightsPercentage) * 100)}
      </td>
      <td className="p-4 text-sm text-gray-700">
        <div className="flex h-full w-full shrink-0 items-center justify-between gap-4 pr-4">
          <div className="flex gap-4">
            <StakeholderStatus
              type={isActive ? StakeholderActionStatus.ACTIVE : StakeholderActionStatus.INACTIVE}
            />
            {isActive && <StakeholderStatus type={role} />}

            {!isActive && (
              <>
                {isAccessRevoked && (
                  <StakeholderStatus type={StakeholderActionStatus.ACCESS_REVOKED} />
                )}
                {isInvited || isDemo ? (
                  !isAccessRevoked && (
                    <StakeholderStatus
                      className="flex-row-reverse"
                      icon={<SuccessCircleIcon />}
                      type={StakeholderActionStatus.INVITED}
                    />
                  )
                ) : (
                  <StakeholderStatus
                    className="cursor-pointer"
                    icon={<ArrowIcon className={'rotate-180'} iconColor="#344054" />}
                    onClick={() => handleSendInvitation(id)}
                    type={StakeholderActionStatus.INVITE_USER}
                  />
                )}
              </>
            )}
          </div>

          <Popover>
            <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
              <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
                <ThreeDotsIcon />
              </div>
            </PopoverTrigger>
            <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
              <PopoverClose>
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleView(id)}
                >
                  <EyeIconSmall />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.VIEW_DETAILS} />
                  </span>
                </div>
                {(role !== StakeholderRole.ADMIN || roleInCompany === StakeholderRole.ADMIN) &&
                  !isDemo &&
                  !isTerminated && (
                    <div
                      className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => handleEdit(id)}
                    >
                      <EditIcon />
                      <span className="text-sm font-normal text-gray-700">
                        <AppFormattedMessage id={StringKey.EDIT_DETAILS} />
                      </span>
                    </div>
                  )}

                {resendInvitation && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleResendInvitation(id)}
                  >
                    <SendInviteIcon />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage id={StringKey.RESEND_INVITATION} />
                    </span>
                  </div>
                )}
                {cancelInvitation && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleCancelInvitation(id)}
                  >
                    <CloseCircleIconSmall />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage id={StringKey.CANCEL_INVITATION} />
                    </span>
                  </div>
                )}
                {grantAccess && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleGrantAccess(id)}
                  >
                    <GrantAccessIcon />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage id={StringKey.GRANT_ACCESS} />
                    </span>
                  </div>
                )}

                {makeContributor && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleMakeContributor(id)}
                  >
                    <AddContributorIcon />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage id={StringKey.MAKE_CONTRIBUTOR} />
                    </span>
                  </div>
                )}

                {revokeAccess && accountId !== id && !isAccessRevoked && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleRevokeAccess(id)}
                  >
                    <RevokeAccessIcon />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage id={StringKey.REVOKE_ACCESS} />
                    </span>
                  </div>
                )}

                {revokeContributor && accountId !== id && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleRemoveContributorRole(id)}
                  >
                    <RevokeAccessIcon />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage id={StringKey.REVOKE_CONTRIBUTOR_ROLE} />
                    </span>
                  </div>
                )}
                {canTerminate &&
                  !isTerminated &&
                  hasShares &&
                  [
                    StakeholderType.EMPLOYEE,
                    StakeholderType.FOUNDER,
                    StakeholderType.ADVISOR,
                  ].includes(type) && (
                    <div
                      className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => handleTerminate(id)}
                    >
                      <CrossIcon iconColor="#F04438" />
                      <span className="text-sm font-normal text-fireside-500">
                        <AppFormattedMessage id={StringKey.TERMINATE} />
                      </span>
                    </div>
                  )}
                {canEditTerminate &&
                  isTerminated &&
                  [
                    StakeholderType.EMPLOYEE,
                    StakeholderType.FOUNDER,
                    StakeholderType.ADVISOR,
                  ].includes(type) && (
                    <div
                      className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => handleEditTermination(id)}
                    >
                      <EditIcon />
                      <span className="text-sm font-normal">Edit termination</span>
                    </div>
                  )}
                {!hasShares && canDelete && accountId !== id && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-fireside-500 hover:bg-gray-50"
                    onClick={() => handleDelete(id)}
                  >
                    <TrashBinIcon iconColor="#F04438" />
                    <span className="text-sm font-normal text-fireside-500">
                      <AppFormattedMessage id={StringKey.DELETE} />
                    </span>
                  </div>
                )}
              </PopoverClose>
            </PopoverContent>
          </Popover>
        </div>
      </td>
    </tr>
  );
};

export default StakeholderTableItem;
