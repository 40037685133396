import { StringKey } from '../lang';
import { SubscriptionDuration } from '../types/planTypes';

type SubscriptionDurationInfo = {
  description: StringKey;
  duration: StringKey;
};

export const subscriptionDurationTranslation: Record<
  SubscriptionDuration,
  SubscriptionDurationInfo
> = {
  [SubscriptionDuration.ANNUALY]: {
    description: StringKey.BILLED_ANNUALY,
    duration: StringKey.MONTH,
  },
  [SubscriptionDuration.MONTHLY]: {
    description: StringKey.BILLED_MONTHLY_PLAN,
    duration: StringKey.MONTH,
  },
};

export const startUpBenefits: string[] = [
  'Data Room',
  'Fundraising Tracker',
  'Pools and Plans Management',
  'Cap Table Management',
  'Basic Support',
];

export const growthBenefits: string[] = [
  'Convertible Management',
  'Venture Debt Management',
  'Fundraising Modeling',
  'Liquidity Modeling',
  'Priority Support',
];

export const enterpriseBenefits: string[] = [
  'Loan Modeling',
  'Event Management X',
  'Dashboard Customization X',
  'Assisted onboarding process',
  'Dedicated Account Manager',
];
