import { FC, ReactNode } from 'react';

import {
  Column,
  columnTitle,
  DetailsSortVariant,
  detailsViewByColumns,
  ViewBy,
} from '../../../../types/snapshot.types';

export type TableProps = {
  children: ReactNode;
  viewBy: Record<ViewBy, boolean>;
  sortBy: DetailsSortVariant;
  visibleColumns: Column[];
  additionalColumns?: PartialKeys<Column, string[]>;
};

export const BasicTable: FC<TableProps> = ({
  children,
  sortBy,
  viewBy,
  visibleColumns,
  additionalColumns,
}) => {
  const selectedViewBy: ViewBy | 'all' =
    viewBy.stakeholders && viewBy.type ? 'all' : viewBy.type ? ViewBy.TYPE : ViewBy.STAKEHOLDERS;

  return (
    <table className="w-full shrink-0 overflow-hidden text-nowrap">
      <thead className="h-11 border-y border-[#F2F2F2] bg-gray-50 px-4">
        <tr>
          {selectedViewBy === 'all' && (
            <th className="pl-4 text-left">
              <span className="text-label-md font-medium text-[#172335]" />
            </th>
          )}
          {detailsViewByColumns[selectedViewBy][sortBy].map(
            (fieldNameKey) =>
              visibleColumns.includes(fieldNameKey) &&
              (additionalColumns?.[fieldNameKey] ? (
                additionalColumns?.[fieldNameKey].map((fieldName) => (
                  <th className="px-4 pr-6 text-left" key={fieldName}>
                    <span className="text-label-md font-medium text-[#172335]">{fieldName}</span>
                  </th>
                ))
              ) : (
                <th className="px-4 pr-6 text-left" key={fieldNameKey}>
                  <span className="text-label-md font-medium text-[#172335]">
                    {columnTitle[fieldNameKey]}
                  </span>
                </th>
              )),
          )}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
