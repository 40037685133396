import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { PlanStakeholder } from '../../types/stakeholderTypes';
import { useAppQuery } from '../useAppQuery';

type StakeholdersWithGrants = {
  companyId: string;
  take?: number;
  currentPage?: number;
};

type StakeholdersWithGrantsResponse = {
  stakeholders: PlanStakeholder[];
  totalPages: number;
};

export const useStakeholdersWithGrants = ({
  companyId,
  currentPage = 0,
  take = 100,
}: StakeholdersWithGrants) => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useAppQuery<StakeholdersWithGrantsResponse>({
    queryKey: [QueryKey.GET_STAKEHOLDERS_WITH_GRANTS, companyId, { currentPage, take }],
    queryFn: () =>
      ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'stakeholders-with-grants'] },
        {
          queryParams: {
            ...(take && currentPage
              ? {
                  take,
                  skip: (currentPage >= 1 ? currentPage - 1 : 0) * take,
                }
              : {}),
          },
        },
      ),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_STAKEHOLDERS_WITH_GRANTS] });
  }, [queryClient]);

  return {
    stakeholders: data?.stakeholders || [],
    totalPages: data?.totalPages,
    invalidateQuery,
    isLoading,
  };
};
