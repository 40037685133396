import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const usePin = ({ companyId }: { companyId: string }) => {
  const { mutate: pinEvent } = useAppMutation([QueryKey.PIN_EVENT, companyId], {
    mutationFn: ({ eventId, pinState }: { eventId: string; pinState: boolean }) =>
      ApiService.post(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'events', eventId, 'pin'],
        },
        { queryParams: { pinState } },
      ),
  });

  return {
    pinEvent,
  };
};
