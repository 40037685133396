import React, { FC, useCallback, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { Checkbox, FormCheckbox } from '../../../../components/Checkbox';
import { FormInput } from '../../../../components/Input';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { FormTextarea } from '../../../../components/Textarea';
import { Company, useReactForm } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { PageRoute } from '../../../../types/pageTypes';
import PhoneNumberCombobox, {
  PhoneCountry,
} from '../../../Stakeholders/StakeholderActions/PhoneNumberCombobox';
import {
  arrayOfCapTableManagementTypes,
  arrayOfEnterpriseFeatures,
  CapTableManagementTypes,
} from './types';
import { FormSchema, formSchema } from './validation';

type ModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  setShouldUnblock: React.Dispatch<React.SetStateAction<boolean>>;
  companyId: string;
};

export const EnterpriseModal: FC<ModalProps> = ({
  isOpenModal,
  handleClose,
  setShouldUnblock,
  companyId,
}) => {
  const navigate = useNavigate();
  const { enterpriseRequest, isPending } = Company.useEnterpriseRequest();
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isValid },
  } = useReactForm({
    schema: formSchema,
    mode: 'onChange',
  });

  const { capTableManagement, additionalDetails, anotherSoftware, features } = useWatch<FormSchema>(
    {
      control,
    },
  );

  const isCapTableValid =
    capTableManagement === CapTableManagementTypes.ANOTHER
      ? anotherSoftware
        ? true
        : false
      : true;

  const isAllFeaturesChecked =
    features?.['data-room'] &&
    features.equity &&
    features.esop &&
    features['fundraising-tracker'] &&
    features['venture-debt'];

  const isSomeFeaturesChecked =
    features?.['data-room'] ||
    features?.equity ||
    features?.esop ||
    features?.['fundraising-tracker'] ||
    features?.['venture-debt'];

  const onSelectAll = () => {
    setValue('features.data-room', true);
    setValue('features.equity', true);
    setValue('features.esop', true);
    setValue('features.fundraising-tracker', true);
    setValue('features.venture-debt', true);
  };

  const onRemoveSelectAll = () => {
    setValue('features.data-room', false);
    setValue('features.equity', false);
    setValue('features.esop', false);
    setValue('features.fundraising-tracker', false);
    setValue('features.venture-debt', false);
  };

  const handleCloseModal = useCallback(() => {
    handleClose();
    reset();
  }, [handleClose, reset]);

  const submitHandler = useCallback(() => {
    enterpriseRequest(
      { companyId },
      {
        onSuccess: () => {
          navigate(PageRoute.ALL_DONE);
        },
      },
    );
  }, [companyId, enterpriseRequest, navigate]);

  useEffect(() => {
    if (isOpenModal) {
      setShouldUnblock(true);
    } else {
      setShouldUnblock(false);
    }
  }, [isOpenModal, setShouldUnblock]);

  useEffect(() => {
    if (capTableManagement === CapTableManagementTypes.ANOTHER && !anotherSoftware) {
      setError('anotherSoftware', { type: 'manual', message: 'Please fill the field' });
    } else {
      clearErrors('anotherSoftware');
    }
  }, [anotherSoftware, capTableManagement, clearErrors, setError]);

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[500px] border-transparent bg-transparent p-2 shadow-3xl"
        onInteractOutside={handleCloseModal}
        side="CENTER"
      >
        <div className="flex h-full w-full flex-col gap-4 overflow-hidden rounded-[4px] bg-white p-4">
          <div className="flex max-h-[80vh] w-full flex-col gap-4 overflow-y-auto">
            <div className="flex flex-col gap-2">
              <span className="text-xl font-semibold text-gray-700">Help us understand!</span>
              <span className="text-xs font-[450] text-gray-800">
                Our sales team will be in touch in the next 12 - 24 hours
              </span>
            </div>

            <div className="flex flex-col gap-3 rounded-[4px] bg-gray-50 p-3">
              <span className="text-xs font-semibold text-gray-700">
                How does your company currently manage their cap table? <sup>*</sup>
              </span>
              <div className="flex flex-col gap-1">
                {arrayOfCapTableManagementTypes.map(([key, title]) => (
                  <div className="flex w-full items-center gap-3" key={key}>
                    <Checkbox
                      checked={key === capTableManagement}
                      className="rounded-full"
                      onChange={() => setValue('capTableManagement', key)}
                    />
                    <span className="px-1 py-[6px] text-xs font-[450] text-gray-700">{title}</span>
                  </div>
                ))}
              </div>
              {capTableManagement === CapTableManagementTypes.ANOTHER && (
                <FormInput
                  className="text-xs font-[450]"
                  control={control}
                  errorMessage={!isCapTableValid && 'Please fill the field'}
                  inputClassName=" border-[1px] rounded-[4px] bg-white h-10"
                  name="anotherSoftware"
                />
              )}
            </div>

            <div className="flex flex-col gap-3 rounded-[4px] bg-gray-50 p-3">
              <span className="text-xs font-semibold text-gray-700">
                What specific features of CapQuest are you most interested in? <sup>*</sup>
              </span>
              <div className="flex flex-col gap-1">
                {arrayOfEnterpriseFeatures.map(([key, title]) => (
                  <div className="flex w-full items-center gap-1" key={key}>
                    <FormCheckbox control={control} name={`features.${key}`} />
                    <span className="px-1 py-[6px] text-xs font-[450] text-gray-700">{title}</span>
                  </div>
                ))}
                <div className="flex w-full items-center gap-1">
                  <Checkbox
                    checked={isAllFeaturesChecked}
                    id="select-all"
                    onChange={(checked) => (checked ? onSelectAll() : onRemoveSelectAll())}
                  />
                  <span className="px-1 py-[6px] text-xs font-[450] text-gray-700">
                    All of the above
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 rounded-[4px] border-[1px] border-gray-100 p-3">
              <div className="flex flex-col gap-2">
                <span className="text-xs font-semibold text-gray-700">
                  Additional details (optional)
                </span>
                <span className="text-xs font-[450] text-gray-700">
                  In addition to those already provide, please feel free to provide other contact
                  details
                </span>
              </div>
              <div>
                <FormInput control={control} name="additionalDetails.email" placeholder="Email" />
                <PhoneNumberCombobox
                  onPhoneChange={(phoneNumber) =>
                    setValue('additionalDetails.phoneNumber', phoneNumber, {
                      shouldValidate: true,
                    })
                  }
                  onSelectCountry={(country) => setValue('additionalDetails.phoneCountry', country)}
                  selectedPhoneNumber={{
                    number: additionalDetails?.phoneNumber || '',
                    country: additionalDetails?.phoneCountry as PhoneCountry,
                  }}
                />
              </div>
            </div>

            <FormTextarea
              className="text-xs"
              control={control}
              isShownCharCount={false}
              name="additionalDetails.additionalNotes"
              placeholder="Any additional details would you link to share?"
              placeholderClassName="text-xs"
              styleVariant="LIGHT_W_PLACEHOLDER"
              textAreaClassName="border-none"
              wrapperClassName="border-[1px] border-gray-100 rounded-[4px] mr-2"
            />
          </div>
          <div className="mt-4 flex h-[44px] w-full justify-end gap-3">
            <Button
              className="h-full w-fit rounded border border-gray-300 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
              onClick={handleCloseModal}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.CANCEL} />
            </Button>
            <Button
              className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
              disabled={!isValid || !isSomeFeaturesChecked || !isCapTableValid}
              isLoading={isPending}
              onClick={() => handleSubmit(submitHandler)()}
              type="button"
            >
              <AppFormattedMessage id={StringKey.SUBMIT} />
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
