import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type ExerciseIconProps = IconProps;

export const ExerciseIcon: FC<ExerciseIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.074 3.92584L3.92584 12.074M5.40733 6.88881V3.92584M3.92584 5.40733H6.88881M9.11103 10.5925H12.074M4.88881 14.6666H11.111C12.3556 14.6666 12.9779 14.6666 13.4532 14.4244C13.8714 14.2113 14.2113 13.8714 14.4244 13.4532C14.6666 12.9779 14.6666 12.3556 14.6666 11.111V4.88881C14.6666 3.64425 14.6666 3.02197 14.4244 2.54661C14.2113 2.12847 13.8714 1.78851 13.4532 1.57546C12.9779 1.33325 12.3556 1.33325 11.111 1.33325H4.88881C3.64425 1.33325 3.02197 1.33325 2.54661 1.57546C2.12847 1.78851 1.78851 2.12847 1.57546 2.54661C1.33325 3.02197 1.33325 3.64425 1.33325 4.88881V11.111C1.33325 12.3556 1.33325 12.9779 1.57546 13.4532C1.78851 13.8714 2.12847 14.2113 2.54661 14.4244C3.02197 14.6666 3.64425 14.6666 4.88881 14.6666Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.15556"
    />
  </svg>
);
