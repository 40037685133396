import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { TerminateStakeholderDto } from '../../types/stakeholderTypes';
import { useAppMutation } from '../useAppMutation';

export const useTerminate = () => {
  const { mutate: terminate, isPending } = useAppMutation([QueryKey.TERMINATE_STAKEHOLDERS], {
    mutationFn: ({ companyId, data }: { companyId: string; data: TerminateStakeholderDto }) =>
      ApiService.post(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'stakeholders', 'terminate'] },
        { body: data },
      ),
  });
  return { terminate, isPending };
};
