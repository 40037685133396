import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Valuation } from '../../types/events.types';
import { useAppQuery } from '../useAppQuery';

export const useCurrent = ({ companyId, enabled }: { companyId: string; enabled?: boolean }) => {
  const queryClient = useQueryClient();

  const { data: valuation, isPending } = useAppQuery({
    queryKey: [QueryKey.GET_CURRENT_VALUATION, companyId],
    queryFn: () =>
      ApiService.get<Valuation>({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'current-valuation'],
      }),
    enabled: !!companyId && enabled,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_CURRENT_VALUATION] });
  }, [queryClient]);

  return {
    invalidateQuery,
    valuation,
    isPending,
  };
};
