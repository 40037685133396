export enum ActionCategory {
  ENGAGEMENT_TIME = 'engagement-time',
  SCROLLING = 'scrolling',
  NAVIGATION = 'navigation',
  USER_ACTIONS = 'user-actions',
}

export const actionCategoryTitle: Record<ActionCategory, string> = {
  [ActionCategory.ENGAGEMENT_TIME]: 'engagement_time_s',
  [ActionCategory.SCROLLING]: 'scrolling',
  [ActionCategory.NAVIGATION]: 'navigation',
  [ActionCategory.USER_ACTIONS]: 'user_actions',
};

export enum PossibleAction {
  ADD_POOL = 'add-pool',
  ADD_PLAN = 'add-plan',
  ADD_EVENT = 'add-event',
  ADD_COMPANY = 'add-company',
  ADD_SHARE_CLASS = 'add-share-class',
  ADD_STAKEHOLDER = 'add-stakeholder',
  EDIT_POOL = 'edit-pool',
  EDIT_PLAN = 'edit-plan',
  EDIT_EVENT = 'edit-event',
  EDIT_COMPANY = 'edit-company',
  COMPANY_PLAN_CHANGE = 'company-plan-change',
  EDIT_SHARE_CLASS = 'edit-share-class',
  EDIT_STAKEHOLDER = 'edit-stakeholder',
  DELETE_POOL = 'delete-pool',
  DELETE_PLAN = 'delete-plan',
  DELETE_EVENT = 'delete-event',
  DELETE_COMPANY = 'delete-company',
  DEACTIVATE_COMPANY = 'deactivate-company',
  REACTIVATE_COMPANY = 'reactivate-company',
  DELETE_SHARE_CLASS = 'delete-share-class',
  DELETE_STAKEHOLDER = 'delete-stakeholder',
  DOWNLOAD_FILE = 'download-file',
  UPLOAD_FILE = 'upload-file',
  SCROLLED_50 = 'scrolled-50',
  SEARCHING = 'searching',
  TIME_ON_PAGE = 'time-on-page',
}
export const possibleActionTitle: Record<PossibleAction, string> = {
  [PossibleAction.ADD_POOL]: 'added_pool',
  [PossibleAction.ADD_PLAN]: 'added_plan',
  [PossibleAction.ADD_EVENT]: 'added_event',
  [PossibleAction.ADD_COMPANY]: 'added_company',
  [PossibleAction.ADD_SHARE_CLASS]: 'added_share_class',
  [PossibleAction.ADD_STAKEHOLDER]: 'added_stakeholder',
  [PossibleAction.EDIT_POOL]: 'edited_pool',
  [PossibleAction.EDIT_PLAN]: 'edited_plan',
  [PossibleAction.EDIT_EVENT]: 'edited_event',
  [PossibleAction.EDIT_COMPANY]: 'edited_company',
  [PossibleAction.EDIT_SHARE_CLASS]: 'edited_share_class',
  [PossibleAction.EDIT_STAKEHOLDER]: 'edited_stakeholder',
  [PossibleAction.DELETE_POOL]: 'deleted_pool',
  [PossibleAction.DELETE_PLAN]: 'deleted_plan',
  [PossibleAction.DELETE_EVENT]: 'deleted_event',
  [PossibleAction.DEACTIVATE_COMPANY]: 'deactivated_company',
  [PossibleAction.DELETE_SHARE_CLASS]: 'deleted_share_class',
  [PossibleAction.DELETE_STAKEHOLDER]: 'deleted_stakeholder',
  [PossibleAction.DOWNLOAD_FILE]: 'downloaded_file',
  [PossibleAction.UPLOAD_FILE]: 'uploaded_file',
  [PossibleAction.SCROLLED_50]: 'scrolled_50_percent',
  [PossibleAction.SEARCHING]: 'searching',
  [PossibleAction.TIME_ON_PAGE]: 'time_on_page',
  [PossibleAction.REACTIVATE_COMPANY]: 'reactivated_company',
  [PossibleAction.DELETE_COMPANY]: 'deleted_company',
  [PossibleAction.COMPANY_PLAN_CHANGE]: 'company_plan_change',
};
