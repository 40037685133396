import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppQuery } from '../useAppQuery';

export const useIssuedShares = ({ companyId }: { companyId: string }) => {
  const queryClient = useQueryClient();

  const { data: issuedShares, isLoading } = useAppQuery<number>({
    queryKey: [QueryKey.GET_ISSUED_SHARES, companyId],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'issued-shares'],
      }),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_ISSUED_SHARES] });
  }, [queryClient]);

  return {
    invalidateQuery,
    issuedShares,
    isLoading,
  };
};
