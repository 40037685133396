import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type PinIconProps = IconProps;

export const PinIcon: FC<PinIconProps> = ({ iconColor = '#344054', className, ...props }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 12 16"
      width="12"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00012 9.99992L6.00012 14.6666M3.33346 4.87201V6.29242C3.33346 6.4311 3.33346 6.50044 3.31985 6.56676C3.30777 6.6256 3.2878 6.68254 3.26047 6.73603C3.22966 6.79633 3.18635 6.85047 3.09972 6.95876L2.0532 8.26691C1.60937 8.82169 1.38746 9.09908 1.38721 9.33253C1.38699 9.53556 1.4793 9.72762 1.63797 9.85428C1.82042 9.99992 2.17566 9.99992 2.88612 9.99992H9.11412C9.82459 9.99992 10.1798 9.99992 10.3623 9.85428C10.5209 9.72762 10.6133 9.53556 10.613 9.33253C10.6128 9.09908 10.3909 8.82169 9.94705 8.26691L8.90053 6.95876C8.8139 6.85047 8.77058 6.79633 8.73978 6.73603C8.71245 6.68254 8.69248 6.6256 8.6804 6.56676C8.66679 6.50044 8.66679 6.4311 8.66679 6.29242V4.87201C8.66679 4.79526 8.66679 4.75688 8.67113 4.71904C8.67498 4.68542 8.68139 4.65214 8.6903 4.6195C8.70033 4.58275 8.71458 4.54712 8.74308 4.47586L9.415 2.79607C9.61102 2.30601 9.70903 2.06098 9.66816 1.86428C9.63241 1.69227 9.53022 1.54132 9.38379 1.44425C9.21634 1.33325 8.95243 1.33325 8.42462 1.33325H3.57563C3.04782 1.33325 2.78391 1.33325 2.61646 1.44425C2.47003 1.54132 2.36783 1.69227 2.33209 1.86428C2.29122 2.06098 2.38923 2.30601 2.58525 2.79607L3.25717 4.47586C3.28567 4.54712 3.29992 4.58275 3.30995 4.6195C3.31886 4.65214 3.32526 4.68542 3.32912 4.71904C3.33346 4.75688 3.33346 4.79526 3.33346 4.87201Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
    </svg>
  );
};
