import React, { FC, useCallback, useEffect } from 'react';
import {
  Control,
  useFieldArray,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import { CalendarIcon, CloseIcon } from '../../../../../assets/icons';
import Button from '../../../../../components/Button';
import { FormInput } from '../../../../../components/Input';
import { useFormat } from '../../../../../hooks';
import { SharePlanType } from '../../../../../types/pool-plans.types';
import { DatePicker } from '../../../../DatePicker';
import { GrantCombobox, StakeholderCombobox, TableHeaderItem } from '../FormComponents';
import { FormSchema } from '../validation';

export type WarrantExerciseProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
};

export const WarrantExercise: FC<WarrantExerciseProps> = ({
  control,
  setFormData,
  companyId,
  resetField,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();

  const {
    fields: warrantItemsFields,
    append: warrantItemsAppend,
    remove: warrantItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.warrantExercise.warrantExerciseItems',
  });

  const handleRemoveWarrantItems = useCallback(
    (index: number) => {
      warrantItemsRemove(index);
    },
    [warrantItemsRemove],
  );

  const handleAppendWarrantItems = useCallback(() => {
    const defaultDate = new Date();
    warrantItemsAppend({
      stakeholder: { id: '', fullName: '' },
      grant: {
        id: '',
        grantItem: {
          plan: { warrantPrice: 0, conversionRatio: 1, pool: { shareClass: { name: '' } } },
        },
        exercisedCount: 0,
        vestedSharesCount: 0,
      },
      date: defaultDate,
      exercised: 0,
    });
  }, [warrantItemsAppend]);

  const handleStakeholderChange = (index: number) => {
    resetField(`stepOne.warrantExercise.warrantExerciseItems.${index}.grant`);
  };

  const calculateBalance = useCallback(
    (index: number) => {
      const vestedShares =
        stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.vestedSharesCount || 0;
      const exercisedShares =
        stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.exercisedCount || 0;
      const value = stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.exercised || 0;
      const balance = Math.max(vestedShares - exercisedShares - value, 0);

      return balance.toLocaleString('en-US');
    },
    [stepOne?.warrantExercise?.warrantExerciseItems],
  );

  useEffect(() => {
    if (warrantItemsFields.length === 0) {
      handleAppendWarrantItems();
    }
  }, [handleAppendWarrantItems, warrantItemsFields.length]);

  return (
    <>
      <div className="flex flex-col overflow-x-auto rounded-lg border-[1px] border-gray-100 bg-white">
        <div className="overflow-x-auto">
          <div className="grid grid-cols-[repeat(3,184px)_repeat(4,minmax(164px,1fr))_repeat(1,48px)]">
            <TableHeaderItem showTooltip text="Stakeholder" />
            <TableHeaderItem showTooltip text="Grant" />
            <TableHeaderItem showTooltip text="Exercise date" />
            <TableHeaderItem showTooltip text="Share class" />
            <TableHeaderItem text="Warrant Price" variant="dark" />
            <TableHeaderItem text="Exercised Warrants" variant="dark" />
            <TableHeaderItem text="Issued Shares" variant="dark" />
            <TableHeaderItem text="" />
          </div>
          {warrantItemsFields.map((field, index) => (
            <div
              className="grid grid-cols-[repeat(3,184px)_repeat(4,minmax(164px,1fr))_repeat(1,48px)] border-b-[1px] border-b-gray-100"
              key={field.id}
            >
              <div className="px-4 pb-10 pt-7">
                <StakeholderCombobox
                  companyId={companyId}
                  control={control}
                  handleStakeholderChange={() => handleStakeholderChange(index)}
                  name={`stepOne.warrantExercise.warrantExerciseItems.${index}.stakeholder`}
                />
              </div>
              <div className="px-4 pb-10 pt-7">
                <GrantCombobox
                  companyId={companyId}
                  control={control}
                  name={`stepOne.warrantExercise.warrantExerciseItems.${index}.grant`}
                  sharePlanType={SharePlanType.WARRANTS}
                  stakeholderId={
                    stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.stakeholder?.id || ''
                  }
                />
              </div>
              <div className="px-4 pb-10 pt-7">
                <DatePicker
                  className="w-full"
                  defaultMonth={
                    stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.date || undefined
                  }
                  onSelect={(date) =>
                    setFormData(`stepOne.warrantExercise.warrantExerciseItems.${index}.date`, date)
                  }
                >
                  <FormInput
                    autoComplete="off"
                    control={control}
                    customValue={(value) => {
                      return value && format(value, 'dd/MM/yyyy');
                    }}
                    disabledInput
                    icon={<CalendarIcon className="size-5" iconColor="#344054" />}
                    inputClassName="h-10 w-[150px]"
                    name={`stepOne.warrantExercise.warrantExerciseItems.${index}.date`}
                    wrapperClassName="w-full"
                  />
                </DatePicker>
              </div>
              <div className="flex items-center px-4 pb-10 pt-7">
                <span className="text-sm font-[450] text-gray-700">
                  {
                    stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.grantItem?.plan
                      ?.pool?.shareClass?.name
                  }
                </span>
              </div>
              <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
                <FormInput
                  control={control}
                  disabled={true}
                  inputClassName="h-10"
                  name={`stepOne.warrantExercise.warrantExerciseItems.${index}.grant.grantItem.plan.warrantPrice`}
                  shouldFormatNumber
                  wrapperClassName="w-full"
                />
                <span className="text-xs font-[450] text-gray-600">
                  Total cost: $
                  {(
                    (stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.grantItem?.plan
                      ?.warrantPrice || 0) *
                    (stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.exercised || 1)
                  ).toLocaleString('en-US')}
                </span>
              </div>
              <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
                <FormInput
                  control={control}
                  inputClassName="h-10"
                  name={`stepOne.warrantExercise.warrantExerciseItems.${index}.exercised`}
                  numberOnly
                  shouldFormatNumber
                  wrapperClassName="w-full"
                />
                <span className="text-xs font-[450] text-gray-600">
                  Available: {calculateBalance(index)}
                </span>
              </div>
              <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
                <div className="rounded-t-[4px] bg-gray-50 px-3 py-2">
                  <span className="text-sm font-[450] text-gray-700">
                    {' '}
                    {(
                      (stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.exercised || 0) *
                      (stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.grantItem
                        ?.plan?.conversionRatio || 1)
                    ).toLocaleString('en-US')}
                  </span>
                </div>
                <span className="text-xs font-[450] text-gray-600">
                  Conversion:{' '}
                  {stepOne?.warrantExercise?.warrantExerciseItems?.[index]?.grant?.grantItem?.plan
                    ?.conversionRatio || 1}
                  x
                </span>
              </div>
              <div>
                {warrantItemsFields.length > 1 && (
                  <Button
                    className="flex h-full w-full items-center justify-center"
                    onClick={() => handleRemoveWarrantItems(index)}
                    styleType="NONE"
                  >
                    <CloseIcon />
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
