import React, { FC, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { StringKey } from '../../../../lang';
import { ExerciseTab, ExerciseType } from '../../../../types/pool-plans.types';
import {
  ApproveHurdlePlanList,
  Bspce,
  Phantom,
  Rsa,
  Sar,
  StockOption,
  WarrantExercise,
  WarrantExpire,
} from './StepComponents';
import { ChildrenFormProps } from './type';
import { FormSchema, stepOne as stepOneFormSchema } from './validation';

export type StepOneProps = ChildrenFormProps;

export const StepOne: FC<StepOneProps> = ({
  handleCloseModal,
  nextFormStep,
  control,
  setFormData,
  companyId,
  filedState,
  type,
  resetField,
  reset,
}) => {
  const [selectedTab, setTab] = useState<ExerciseTab>(ExerciseTab.EXERCISE);
  const { stepOne } = useWatch<FormSchema>({ control });
  const { success } = stepOneFormSchema.safeParse(stepOne);
  const isValid = success && !filedState('stepOne').invalid;
  const handleTabChange = (tab: ExerciseTab) => {
    setTab(tab);
    reset();
  };

  if (!type) return;

  return (
    <>
      <div className="flex w-full flex-col gap-2">
        {type === ExerciseType.STOCK_OPTIONS && (
          <StockOption
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
        {type === ExerciseType.SAR && (
          <Sar
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
        {type === ExerciseType.PHANTOM && (
          <Phantom
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
        {type === ExerciseType.HURDLE && (
          <ApproveHurdlePlanList
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
        {type === ExerciseType.BSPCE && (
          <Bspce
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
        {type === ExerciseType.RSA && (
          <Rsa
            companyId={companyId}
            control={control}
            resetField={resetField}
            setFormData={setFormData}
          />
        )}
        {type === ExerciseType.WARRANTS && (
          <div className="flex w-full flex-col gap-6">
            <div className="flex h-[54px] w-full gap-4 border-b-[1px] border-gray-200 px-4">
              <Button
                className={twMerge(
                  'flex h-full w-fit rounded-none border-b-2 border-transparent text-sm font-[450] text-gray-400',
                  selectedTab === ExerciseTab.EXERCISE &&
                    'border-brand-700 font-[550] text-brand-700',
                )}
                onClick={() => handleTabChange(ExerciseTab.EXERCISE)}
                styleType="NONE"
              >
                Exercise
              </Button>
              <Button
                className={twMerge(
                  'flex h-full w-fit rounded-none border-b-2 border-transparent text-sm font-[450] text-gray-400',
                  selectedTab === ExerciseTab.EXPIRE &&
                    'border-brand-700 font-[550] text-brand-700',
                )}
                onClick={() => handleTabChange(ExerciseTab.EXPIRE)}
                styleType="NONE"
              >
                Expire
              </Button>
            </div>
            {selectedTab === ExerciseTab.EXERCISE ? (
              <WarrantExercise
                companyId={companyId}
                control={control}
                resetField={resetField}
                setFormData={setFormData}
              />
            ) : (
              <WarrantExpire
                companyId={companyId}
                control={control}
                resetField={resetField}
                setFormData={setFormData}
              />
            )}
          </div>
        )}
      </div>

      {(type !== ExerciseType.HURDLE || stepOne?.hurdle?.planId) && (
        <div className="sticky bottom-0 left-0 z-10 flex h-[76px] w-full justify-between gap-3 bg-white pt-8">
          <Button
            className="w-fit bg-transparent p-3 text-sm font-[450] text-gray-700"
            onClick={handleCloseModal}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.CANCEL} />
          </Button>
          <div className="flex gap-3">
            <Button
              className="h-full w-[260px] p-3 text-sm font-[550] text-gray-25"
              disabled={!isValid}
              onClick={nextFormStep}
              type="button"
            >
              <AppFormattedMessage id={StringKey.NEXT} />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
