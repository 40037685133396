// Vesting  Structure

import {
  automaticVestingSharePlanTypeMap,
  exercisableVestingSharePlanTypeMap,
  SharePlanType,
} from '../../../types/pool-plans.types';

export enum VestingStructureColumn {
  GRANTED = 'granted',
  TYPE = 'type',
  VESTED = 'vested',
  REMAINING = 'remaining',
  NEXT_VESTING = 'next-vesting',
  FULL_VESTING = 'full-vesting',
}
export enum VestingPlanType {
  AUTOMATIC = 'AUTOMATIC',
  EXERCISABLE = 'EXERCISABLE',
}

export const tableVestingStructureColumn: VestingStructureColumn[] =
  Object.values(VestingStructureColumn);

export const tableVestingStructureColumnTitle: Record<VestingStructureColumn, string> = {
  [VestingStructureColumn.GRANTED]: 'Granted',
  [VestingStructureColumn.TYPE]: 'Type',
  [VestingStructureColumn.VESTED]: 'Vested',
  [VestingStructureColumn.REMAINING]: 'Remaining',
  [VestingStructureColumn.NEXT_VESTING]: 'Next Vesting',
  [VestingStructureColumn.FULL_VESTING]: 'Full Vesting',
};

// Vesting Exercise Structure

export enum VestingExerciseStructureColumn {
  GRANTED = 'granted',
  TYPE = 'type',
  EXERCISABLE = 'exercisable',
  EXERCISED = 'exercised',
  TO_BE_VESTED = 'to-be-vested',
  FULL_VESTING = 'full-vesting',
  EXERCISE_BUTTON = 'exercise-button',
}

export const tableVestingExerciseStructureColumn: VestingExerciseStructureColumn[] = Object.values(
  VestingExerciseStructureColumn,
);

export const tableVestingExerciseStructureColumnTitle: Record<
  VestingExerciseStructureColumn,
  string
> = {
  [VestingExerciseStructureColumn.GRANTED]: 'Granted',
  [VestingExerciseStructureColumn.TYPE]: 'Type',
  [VestingExerciseStructureColumn.EXERCISABLE]: 'Exercisable',
  [VestingExerciseStructureColumn.EXERCISED]: 'Exercised',
  [VestingExerciseStructureColumn.TO_BE_VESTED]: 'To be vested',
  [VestingExerciseStructureColumn.FULL_VESTING]: 'Full Vesting',
  [VestingExerciseStructureColumn.EXERCISE_BUTTON]: '',
};

// Common Stock, RSU, RSA (without RSA purchase price), Phantom

export enum PlanColumn {
  PLAN = 'plan',
  GRANT_ID = 'grant-id',
  PLAN_TYPE = 'plan-type',
  SHARE_CLASS = 'share-class',
}

export const tablePlanColumn: PlanColumn[] = Object.values(PlanColumn);

export const tablePlanColumnTitle: Record<PlanColumn, string> = {
  [PlanColumn.PLAN]: 'Plan',
  [PlanColumn.GRANT_ID]: 'Grant ID',
  [PlanColumn.PLAN_TYPE]: 'Plan Type',
  [PlanColumn.SHARE_CLASS]: 'Share Class',
};

//Hurdle

export enum HurdlePlanColumn {
  PLAN = 'plan',
  GRANT_ID = 'grant-id',
  PLAN_TYPE = 'plan-type',
  SHARE_CLASS = 'share-class',
  CURRENT_VALUATION = 'current-valuation',
  HURDLE = 'hurdle',
}

export const tableHurdlePlanColumn: HurdlePlanColumn[] = Object.values(HurdlePlanColumn);

export const tableHurdlePlanColumnTitle: Record<HurdlePlanColumn, string> = {
  [HurdlePlanColumn.PLAN]: 'Plan',
  [HurdlePlanColumn.GRANT_ID]: 'Grant ID',
  [HurdlePlanColumn.PLAN_TYPE]: 'Plan Type',
  [HurdlePlanColumn.SHARE_CLASS]: 'Share Class',
  [HurdlePlanColumn.CURRENT_VALUATION]: 'Current Valuation',
  [HurdlePlanColumn.HURDLE]: 'Hurdle',
};

//SAR

export enum SARPlanColumn {
  PLAN = 'plan',
  GRANT_ID = 'grant-id',
  PLAN_TYPE = 'plan-type',
  SHARE_CLASS = 'share-class',
  BASE_PRICE = 'base-price',
}

export const tableSARPlanColumn: SARPlanColumn[] = Object.values(SARPlanColumn);

export const tableSARPlanColumnTitle: Record<SARPlanColumn, string> = {
  [SARPlanColumn.PLAN]: 'Plan',
  [SARPlanColumn.GRANT_ID]: 'Grant ID',
  [SARPlanColumn.PLAN_TYPE]: 'Plan Type',
  [SARPlanColumn.SHARE_CLASS]: 'Share Class',
  [SARPlanColumn.BASE_PRICE]: 'Base Price',
};

//Stock Option

export enum StockOptionPlanColumn {
  PLAN = 'plan',
  GRANT_ID = 'grant-id',
  PLAN_TYPE = 'plan-type',
  SHARE_CLASS = 'share-class',
  CURRENT_PRICE = 'current-price',
  STRIKE_PRICE = 'strike-price',
}

export const tableStockOptionPlanColumn: StockOptionPlanColumn[] =
  Object.values(StockOptionPlanColumn);

export const tableStockOptionPlanColumnTitle: Record<StockOptionPlanColumn, string> = {
  [StockOptionPlanColumn.PLAN]: 'Plan',
  [StockOptionPlanColumn.GRANT_ID]: 'Grant ID',
  [StockOptionPlanColumn.PLAN_TYPE]: 'Plan Type',
  [StockOptionPlanColumn.SHARE_CLASS]: 'Share Class',
  [StockOptionPlanColumn.CURRENT_PRICE]: 'Current Price ',
  [StockOptionPlanColumn.STRIKE_PRICE]: 'Strike Price',
};

//Warrant

export enum WarrantPlanColumn {
  PLAN = 'plan',
  GRANT_ID = 'grant-id',
  PLAN_TYPE = 'plan-type',
  SHARE_CLASS = 'share-class',
  CURRENT_PRICE = 'current-price',
  WARRANT_PRICE = 'warrant-price',
}

export const tableWarrantPlanColumn: WarrantPlanColumn[] = Object.values(WarrantPlanColumn);

export const tableWarrantPlanColumnTitle: Record<WarrantPlanColumn, string> = {
  [WarrantPlanColumn.PLAN]: 'Plan',
  [WarrantPlanColumn.GRANT_ID]: 'Grant ID',
  [WarrantPlanColumn.PLAN_TYPE]: 'Plan Type',
  [WarrantPlanColumn.SHARE_CLASS]: 'Share Class',
  [WarrantPlanColumn.CURRENT_PRICE]: 'Current Price ',
  [WarrantPlanColumn.WARRANT_PRICE]: 'Warrant Price',
};

//RSA (With RSA purchase price)

export enum RSAWithPricePlanColumn {
  PLAN = 'plan',
  GRANT_ID = 'grant-id',
  PLAN_TYPE = 'plan-type',
  SHARE_CLASS = 'share-class',
  CURRENT_PRICE = 'current-price',
  RSA_PRICE = 'rsa-price',
}

export const tableRSAWithPricePlanColumn: RSAWithPricePlanColumn[] =
  Object.values(RSAWithPricePlanColumn);

export const tableRSAWithPricePlanColumnTitle: Record<RSAWithPricePlanColumn, string> = {
  [RSAWithPricePlanColumn.PLAN]: 'Plan',
  [RSAWithPricePlanColumn.GRANT_ID]: 'Grant ID',
  [RSAWithPricePlanColumn.PLAN_TYPE]: 'Plan Type',
  [RSAWithPricePlanColumn.SHARE_CLASS]: 'Share Class',
  [RSAWithPricePlanColumn.CURRENT_PRICE]: 'Current Price ',
  [RSAWithPricePlanColumn.RSA_PRICE]: 'RSA Price',
};

export type AllVestingColumnEnums = VestingStructureColumn | VestingExerciseStructureColumn;

export type AllVestingColumnRecords = Record<VestingStructureColumn, string> &
  Record<VestingExerciseStructureColumn, string>;

export type AllPlanColumnEnums =
  | PlanColumn
  | HurdlePlanColumn
  | SARPlanColumn
  | StockOptionPlanColumn
  | WarrantPlanColumn
  | RSAWithPricePlanColumn;

export type AllPlanColumnRecords = Record<PlanColumn, string> &
  Record<HurdlePlanColumn, string> &
  Record<SARPlanColumn, string> &
  Record<StockOptionPlanColumn, string> &
  Record<WarrantPlanColumn, string> &
  Record<RSAWithPricePlanColumn, string>;

export const vestingSharePlanType: Record<VestingPlanType, SharePlanType[]> = {
  [VestingPlanType.AUTOMATIC]: automaticVestingSharePlanTypeMap,
  [VestingPlanType.EXERCISABLE]: exercisableVestingSharePlanTypeMap,
};

export const table: Record<
  SharePlanType,
  {
    planColumnsTitle: Partial<AllPlanColumnRecords>;
    planColumns: AllPlanColumnEnums[];
    vestingColumnsTitle: Partial<AllVestingColumnRecords>;
    vestingColumns: AllVestingColumnEnums[];
  }
> = {
  [SharePlanType.COMMON_STOCK]: {
    vestingColumns: tableVestingStructureColumn,
    planColumns: tablePlanColumn,
    planColumnsTitle: tablePlanColumnTitle,
    vestingColumnsTitle: tableVestingStructureColumnTitle,
  },
  [SharePlanType.RSU]: {
    vestingColumns: tableVestingStructureColumn,
    planColumns: tablePlanColumn,
    planColumnsTitle: tablePlanColumnTitle,
    vestingColumnsTitle: tableVestingStructureColumnTitle,
  },
  [SharePlanType.RSA]: {
    vestingColumns: tableVestingStructureColumn,
    planColumns: tablePlanColumn,
    planColumnsTitle: tablePlanColumnTitle,
    vestingColumnsTitle: tableVestingStructureColumnTitle,
  },
  [SharePlanType.PHANTOM]: {
    vestingColumns: tableVestingStructureColumn,
    planColumns: tablePlanColumn,
    planColumnsTitle: tablePlanColumnTitle,
    vestingColumnsTitle: tableVestingStructureColumnTitle,
  },

  [SharePlanType.GROWTH_HURDLE]: {
    vestingColumns: tableVestingStructureColumn,
    planColumns: tableHurdlePlanColumn,
    planColumnsTitle: tableHurdlePlanColumnTitle,
    vestingColumnsTitle: tableVestingStructureColumnTitle,
  },
  [SharePlanType.SAR]: {
    vestingColumns: tableVestingStructureColumn,
    planColumns: tableSARPlanColumn,
    planColumnsTitle: tableSARPlanColumnTitle,
    vestingColumnsTitle: tableVestingStructureColumnTitle,
  },
  [SharePlanType.STOCK_OPTIONS]: {
    vestingColumns: tableVestingExerciseStructureColumn,
    planColumns: tableStockOptionPlanColumn,
    planColumnsTitle: tableStockOptionPlanColumnTitle,
    vestingColumnsTitle: tableVestingExerciseStructureColumnTitle,
  },
  [SharePlanType.WARRANTS]: {
    vestingColumns: tableVestingExerciseStructureColumn,
    planColumns: tableWarrantPlanColumn,
    planColumnsTitle: tableWarrantPlanColumnTitle,
    vestingColumnsTitle: tableVestingExerciseStructureColumnTitle,
  },
  [SharePlanType.BSPCE]: {
    vestingColumns: tableVestingExerciseStructureColumn,
    planColumns: tableRSAWithPricePlanColumn,
    planColumnsTitle: tableRSAWithPricePlanColumnTitle,
    vestingColumnsTitle: tableVestingExerciseStructureColumnTitle,
  },
};
