import { z } from 'zod';

import {
  SharePlanType,
  SharePlanVestingType,
  TerminationInterval,
  VestingOnOption,
} from '../../../types/pool-plans.types';
import { ACCEPTED_FILE_MIME_TYPES, MAX_FILE_SIZE } from '../PoolForm/Validation';

const hurdle = z.object({
  enabled: z.boolean(),
  value: z.coerce.number().min(1, { message: 'Required' }),
});

const stockOptions = z.object({
  enabled: z.boolean(),
  value: z.coerce.number().min(0.01, { message: 'Required' }),
  conversionRatio: z.coerce.number().optional(),
});
const warrants = z.object({
  enabled: z.boolean(),
  value: z.coerce.number().min(1, { message: 'Required' }),
  conversionRatio: z.coerce.number().optional(),
});

const sar = z.object({
  enabled: z.boolean(),
  value: z.coerce.number().min(1, { message: 'Required' }),
});

const rsu = z.object({
  enabled: z.boolean(),
  conversionRatio: z.coerce.number().optional(),
});

const phantom = z.object({
  enabled: z.boolean(),
  conversionRatio: z.coerce.number().optional(),
});

const rsa = z.object({
  enabled: z.boolean(),
  value: z.coerce.number().optional(),
  conversionRatio: z.coerce.number().optional(),
});

const commonStock = z.object({
  enabled: z.boolean().optional(),
});

const bspce = z.object({
  enabled: z.boolean(),
  value: z.coerce.number().min(0.01, { message: 'Required' }),
  conversionRatio: z.coerce.number().optional(),
});

export const stepOne = z.object({
  name: z.string().trim().min(1, { message: 'Required' }),
  pool: z.object({
    name: z.string(),
    id: z.string(),
  }),
  date: z.coerce.date(),
  type: z.nativeEnum(SharePlanType),
  financialDetails: z.object({
    [SharePlanType.GROWTH_HURDLE]: hurdle.optional(),
    [SharePlanType.STOCK_OPTIONS]: stockOptions.optional(),
    [SharePlanType.WARRANTS]: warrants.optional(),
    [SharePlanType.SAR]: sar.optional(),
    [SharePlanType.RSA]: rsa.optional(),
    [SharePlanType.RSU]: rsu.optional(),
    [SharePlanType.PHANTOM]: phantom.optional(),
    [SharePlanType.COMMON_STOCK]: commonStock.optional(),
    [SharePlanType.BSPCE]: bspce.optional(),
  }),
});

export const formSchemaTimeBasedStandard = z
  .object({
    vestingOn: z.nativeEnum(VestingOnOption),
    vestingDuration: z.coerce.number().min(1, { message: 'Required' }),
    vestingFrequency: z.coerce.number().min(1, { message: 'Required' }),
    cliffPeriod: z.coerce.number().optional(),
    accelerationDate: z.coerce.date().optional(),
  })
  .refine(({ vestingDuration, vestingFrequency }) => vestingDuration % vestingFrequency === 0, {
    message: 'The duration should be a multiple of your frequency',
    path: ['vestingDuration'],
  });

export const formSchemaTimeBasedDynamic = z
  .object({
    vestingOn: z.nativeEnum(VestingOnOption, {
      required_error: 'You must select a vesting option',
    }),

    enabled: z.boolean(),
    dynamicVestings: z
      .array(
        z
          .object({
            vestingDuration: z.coerce.number().min(1, { message: 'Required' }),
            vestingFrequency: z.coerce.number().min(1, { message: 'Required' }),
            planAllocation: z.coerce.number().min(1, { message: 'Required' }),
          })
          .refine(
            ({ vestingDuration, vestingFrequency }) => vestingDuration % vestingFrequency === 0,
            {
              message: 'The duration should be a multiple of your frequency',
              path: ['dynamicVestings'],
            },
          ),
      )
      .max(10, { message: 'You cannot add more than 10 items' }),
    accelerationDate: z.coerce.date().optional(),
  })
  .refine(
    ({ dynamicVestings, enabled }) =>
      enabled
        ? dynamicVestings.reduce((sum, vesting) => sum + (vesting.planAllocation ?? 0), 0) === 100
        : true,
    {
      message: 'Plan allocation percentage does not reach 100%',
      path: ['dynamicVestings'],
    },
  );

export const formSchemaTargetBased = z
  .object({
    enabled: z.boolean(),
    targetVestings: z
      .array(
        z
          .object({
            name: z.string().trim().min(1, { message: 'Required' }),
            weight: z.coerce.number(),
            targetDate: z.coerce.date(),
          })
          .refine(
            ({ name, weight, targetDate }) => {
              if (!name || weight === undefined) return true;
              return targetDate.getTime() !== new Date(+0).getTime();
            },
            {
              message: 'Invalid target date',
              path: ['targetDate'],
            },
          ),
      )
      .max(10, { message: 'You cannot add more than 10 items' }),
  })
  .refine(
    ({ targetVestings, enabled }) =>
      enabled
        ? targetVestings.reduce((sum, vesting) => sum + (vesting.weight ?? 0), 0) === 100
        : true,
    {
      message: 'Weight allocation does not reach 100%',
      path: ['targetVestings'],
    },
  );

const vesting = z
  .object({
    enabled: z.boolean(),
    vestingMechanism: z.nativeEnum(SharePlanVestingType),
    timeBasedStandard: formSchemaTimeBasedStandard.optional(),
    timeBasedDynamic: formSchemaTimeBasedDynamic.optional(),
    targetBasedStandard: formSchemaTargetBased.optional(),
  })
  .refine(
    ({ targetBasedStandard, timeBasedDynamic, timeBasedStandard }) =>
      targetBasedStandard || timeBasedDynamic || timeBasedStandard,
  );

export const stepTwo = z
  .object({
    vesting: vesting.optional(),
    expiryDate: z
      .object({
        enabled: z.boolean(),
        date: z.coerce.date(),
      })
      .optional(),
  })
  .optional();

const terminationCauseSchema = z.union([
  z.object({
    enabled: z.literal(true),
    value: z.coerce.number().min(1),
    interval: z.nativeEnum(TerminationInterval),
  }),
  z.object({
    enabled: z.literal(false),
  }),
]);

export const formSchemaTerminationLogic = z.object({
  enabled: z.boolean(),
  resignation: terminationCauseSchema,
  termination: terminationCauseSchema,
  terminationWithCause: terminationCauseSchema,
  death: terminationCauseSchema,
  retirement: terminationCauseSchema,
});

export const stepThree = z
  .object({
    terminationLogic: formSchemaTerminationLogic.optional(),
  })
  .optional();

export const stepFour = z
  .object({
    additionalNotes: z.string().max(3000, 'Maximum 3000 characters').optional(),
    files: z
      .object({
        docLink: z.string(),
        id: z.string(),
        loadProgress: z.number().max(100),
        abort: z.function(),
        doc: z
          .custom<File>()
          .or(
            z.object({
              size: z.number(),
              type: z.string(),
              name: z.string(),
            }),
          )
          .refine(
            ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
            'File format is not supported',
          )
          .refine(({ size }) => size <= MAX_FILE_SIZE, 'File size is too large'),
      })
      .array()
      .optional(),
  })
  .optional();

export const formSchema = z.object({
  stepOne,
  stepTwo,
  stepThree,
  stepFour,
});

export type FormSchema = z.infer<typeof formSchema>;
