import { StringKey } from '../lang';
import { Company } from './companyTypes';

export enum ShareClassSortBy {
  NAME = 'name',
  CREATION_DATE = 'creationDate',
  STAKEHOLDERS = 'stakeholders',
  VOTING_RIGHT = 'votingRight',
  CONVERSION_RATIO = 'conversionRatio',
}

export enum ShareClassOrder {
  ASC = 'asc',
  DESC = 'desc',
}
export enum DaysPerYearOption {
  ROUNDED = '360',
  FULL_YEAR = '365',
}

export enum AmountOfFetchedItems {
  FIVE = '5',
  TEN = '10',
  TWENTY = '20',
  FIFTY = '50',
  HUNDRED = '100',
}

export type ParticipatingSettings = {
  capValue: number;
} & (
  | { interest: number; daysPersYear: DaysPerYearOption }
  | { interest?: never; daysPersYear?: never }
);

export interface LiquidityPreferences {
  multiple: number;
  participating?: ParticipatingSettings;
  seniority: number;
}

export enum AntiDilutionBase {
  BROAD_BASED_WEIGHTED_AVERAGE = 'bbwa',
  NARROW_BASED_WEIGHTED_AVERAGE = 'nbwa',
  FULL_RATCHET = 'fr',
}

export const antiDilutionBaseTitle: Record<AntiDilutionBase, string> = {
  [AntiDilutionBase.BROAD_BASED_WEIGHTED_AVERAGE]: 'Broad-Based Weighted Average',
  [AntiDilutionBase.FULL_RATCHET]: 'Full Ratchet',
  [AntiDilutionBase.NARROW_BASED_WEIGHTED_AVERAGE]: 'Narrow Based Weighted Average ',
};

export const antiDilutionBaseTitleMap = Object.entries(antiDilutionBaseTitle) as [
  AntiDilutionBase,
  string,
][];

export interface AntiDilutionRightsSettings {
  base: AntiDilutionBase;
}

export type ShareClass = {
  id: string;
  company: Company;
  name: string;
  creationDate: string;
  votingRightEnabled: boolean;
  votingRight: number;
  conversionRatioEnabled: boolean;
  conversionRatio: number;
  dividendRightEnabled: boolean;
  liquidityPreferences?: LiquidityPreferences;
  antiDilutionRights?: AntiDilutionRightsSettings;
  docLink?: string;
  isDefault: boolean;
  createdAt: string;
  isUsed: boolean;
  stakeholders?: number;
  stakeholderIds?: string[];
};

export type ParticipatingSettingsDto = {
  capValue: number;
} & (
  | { interest: number; daysPersYear: DaysPerYearOption }
  | { interest?: never; daysPersYear?: never }
);

export type LiquidityPreferencesDto = {
  seniority: number;
  multiple: number;
  participating?: ParticipatingSettingsDto | null;
};

export type AntiDilutionRightsSettingsDto = {
  base: AntiDilutionBase;
};

export type CreateShareClassDto = {
  name: string;
  creationDate?: string;
  votingRightEnabled: boolean;
  votingRight: number;
  conversionRatioEnabled: boolean;
  conversionRatio: number;
  dividendRightEnabled: boolean;
  liquidityPreferences?: LiquidityPreferencesDto;
  antiDilutionRights?: AntiDilutionRightsSettingsDto;
  docLink?: string;
};

export class PatchShareClassDto {
  antiDilutionRights?: AntiDilutionRightsSettingsDto | null;
  conversionRatio?: number;
  conversionRatioEnabled?: boolean;
  creationDate?: string;
  dividendRightEnabled?: boolean;
  docLink?: null | string;
  liquidityPreferences?: LiquidityPreferencesDto | null;
  name?: string;
  votingRight?: number;
  votingRightEnabled?: boolean;
}

export enum ShareClassesFilters {
  IN_USE = 'in-use',
  NOT_USED = 'not-used',
}

export type FilterVariant = 'all' | ShareClassesFilters;

export const filterVariant: Record<FilterVariant, StringKey> = {
  all: StringKey.FILTER_ALL,
  [ShareClassesFilters.IN_USE]: StringKey.FILTER_IN_USE,
  [ShareClassesFilters.NOT_USED]: StringKey.FILTER_NOT_USED,
};

export const filterVariantMap = Object.entries(filterVariant);
