import { Transition } from '@headlessui/react';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { ComponentProps, FC, Fragment } from 'react';
import { DayPicker, useNavigation } from 'react-day-picker';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../assets/icons';
import Button from '../Button';
import { Select, SelectButton, SelectOption, SelectOptions } from '../Select';

export type CalendarProps = ComponentProps<typeof DayPicker> & {
  defaultMonth?: Date;
};

const CustomCaption = (): JSX.Element | null => {
  const { currentMonth, goToMonth } = useNavigation();
  const locale = enGB;
  const months = Array.from({ length: 12 }, (_, i) => format(new Date(0, i), 'LLLL', { locale }));

  const handlePreviousMonth = () => {
    const newDate = new Date(currentMonth);
    newDate.setMonth(currentMonth.getMonth() - 1);
    goToMonth(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(currentMonth);
    newDate.setMonth(currentMonth.getMonth() + 1);
    goToMonth(newDate);
  };

  const handleYearChange = (value: string) => {
    const newDate = new Date(currentMonth);
    newDate.setFullYear(parseInt(value, 10));
    goToMonth(newDate);
  };

  const currentMonthName = months[currentMonth.getMonth()];

  return (
    <div className="flex h-8 items-center justify-between gap-1">
      <div className="flex items-center">
        <span className="text-sm font-[550] text-gray-700">{currentMonthName}</span>

        <Select onChange={handleYearChange} value={String(currentMonth.getFullYear())}>
          {({ open, value }) => (
            <>
              <div className="relative rounded border-gray-200">
                <SelectButton className="border-none bg-transparent p-1">
                  <span className="text-sm font-[550] text-gray-700">{value}</span>
                  <ChevronDownIcon className="ml-2 h-4 w-4" />
                </SelectButton>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  show={open}
                >
                  <SelectOptions className="-left-[82px] flex w-[304px] flex-row flex-wrap justify-center gap-x-2 gap-y-1">
                    {Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - 15 + i).map(
                      (year) => (
                        <SelectOption
                          className={twMerge(
                            'flex w-[60px] justify-center rounded-full text-sm text-gray-700',
                            value === String(year)
                              ? '!bg-brand-700 text-white'
                              : 'hover:bg-gray-200',
                          )}
                          key={year}
                          value={String(year)}
                        >
                          {year}
                        </SelectOption>
                      ),
                    )}
                  </SelectOptions>
                </Transition>
              </div>
            </>
          )}
        </Select>
      </div>

      <div className="flex items-center justify-center">
        <Button
          className="flex h-full items-center justify-center p-1"
          onClick={handlePreviousMonth}
          styleType="NONE"
        >
          <ChevronDownIcon className="size-5 rotate-90" />
        </Button>
        <Button
          className="flex h-full items-center justify-center p-1"
          onClick={handleNextMonth}
          styleType="NONE"
        >
          <ChevronDownIcon className="size-5 -rotate-90" />
        </Button>
      </div>
    </div>
  );
};

export const Calendar: FC<CalendarProps> = ({
  className,
  classNames,
  showOutsideDays = true,
  defaultMonth = new Date(),
  ...props
}) => {
  return (
    <DayPicker
      captionLayout="dropdown"
      className={twMerge('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4 ',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-gray-700 font-[550] text-sm',
        nav: 'space-x-1 flex items-center',
        nav_button: twMerge(
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 disabled:hidden',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-muted-foreground rounded-md w-10 font-normal text-[0.8rem] text-gray-500',
        row: 'flex w-full mt-1',
        cell: twMerge(
          'h-8 w-10 text-center text-sm p-0 relative',
          '[&:has([aria-selected].day-range-end)]:rounded-r-md',
          '[&:has([aria-selected].day-outside)]:bg-accent/50',
          'first:[&:has([aria-selected])]:rounded-l-md',
          'last:[&:has([aria-selected])]:rounded-r-md',
          'focus-within:relative focus-within:z-20',
          'hover:bg-gray-200 rounded-full',
        ),
        day: twMerge('h-8 w-10 p-0 font-normal aria-selected:opacity-100'),
        day_range_end: 'day-range-end rounded-l-md',
        day_range_start: 'day-range-start rounded-r-md',
        day_selected:
          'rounded-full bg-brand-700 text-white hover:bg-brand-700 hover:text-white focus:bg-brand-700 focus:text-white',
        day_today: twMerge(
          '[&:not(.day-selected):not(.day-range-middle):not(.day-range-start):not(.day-range-end)]:border-[1px] border-gray-700 rounded-full',
        ),
        day_outside:
          'day-outside text-muted-foreground opacity-100 aria-selected:bg-accent/50 aria-selected:text-muted-foreground',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle:
          'day-range-middle aria-selected:bg-accent aria-selected:text-accent-foreground rounded-none !bg-brand-25 !text-gray-700',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        Caption: CustomCaption,
      }}
      defaultMonth={defaultMonth}
      locale={enGB}
      showOutsideDays={showOutsideDays}
      {...props}
    />
  );
};
