import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppQuery } from '../useAppQuery';
export const useCoupon = (id: string, coupon: string, enabled: boolean, onError?: () => void) => {
  const { data, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_COUPON, coupon, enabled],
    queryFn: () =>
      ApiService.get<{ amountOff: number; percentOff: number }>({
        endpoint: BackendRoute.COMPANIES,
        routePath: [id, 'coupon', coupon],
      }),
    enabled: !!coupon && !!id && enabled,
    onError,
  });
  return { isLoading, data };
};
