import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type PieIconProps = IconProps;

const PieIcon: FC<PieIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.66602C11.0944 1.66602 12.178 1.88156 13.189 2.30035C14.2001 2.71914 15.1188 3.33297 15.8926 4.10679C16.6664 4.88062 17.2802 5.79928 17.699 6.81032C18.1178 7.82137 18.3334 8.905 18.3334 9.99935M10 1.66602V9.99935M10 1.66602C5.39765 1.66602 1.66669 5.39698 1.66669 9.99935C1.66669 14.6017 5.39765 18.3327 10 18.3327C14.6024 18.3327 18.3334 14.6017 18.3334 9.99935M10 1.66602C14.6024 1.66602 18.3334 5.39698 18.3334 9.99935M18.3334 9.99935L10 9.99935M18.3334 9.99935C18.3334 11.3144 18.0221 12.6109 17.4251 13.7826C16.828 14.9544 15.9622 15.9682 14.8982 16.7412L10 9.99935"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);

export default PieIcon;
