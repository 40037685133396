import React, { FC, useMemo, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { twMerge } from 'tailwind-merge';

import { Snapshot } from '../../types/snapshot.types';
import { EmptyPositionGraph } from '../MyHoldings/Graphs/EmptyPositionGraph';
import { PoolGraphVariant } from './variables';

export const colors = {
  [PoolGraphVariant.EQUITY]: {
    strokeColors: ['#D2a9FA', '#A6F4C5'],
    fillColors: ['#E9D8FA', '#D1FADF'],
    activeColors: ['#6717b6', '#039855'],
  },
  [PoolGraphVariant.GRANTED]: {
    strokeColors: ['#FEDF89', '#B4D1FE'],
    fillColors: ['#FEF0C7', '#D1E3FF'],
    activeColors: ['#DC6803', '#2565C8'],
  },
};

type PoolGraphProps = {
  isDesktop: boolean;
  isTablet: boolean;
  variant: PoolGraphVariant;
  snapshot?: Snapshot;
};

export const PoolGraph: FC<PoolGraphProps> = ({ snapshot, isDesktop, isTablet, variant }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const equityGraphData = useMemo(() => {
    if (!snapshot) {
      return [];
    }
    const totalShares = snapshot.poolDilutedShares + snapshot.issuedShares;
    return [
      {
        name: 'Equity',
        percentage: parseFloat(((snapshot.issuedShares * 100) / (totalShares || 1)).toFixed(2)),
      },
      {
        name: 'Pools',
        percentage: parseFloat(
          ((snapshot.poolDilutedShares * 100) / (totalShares || 1)).toFixed(2),
        ),
      },
    ];
  }, [snapshot]);

  const grantedGraphData = useMemo(() => {
    if (!snapshot) {
      return [];
    }
    const ungrantedShares = snapshot.poolDilutedShares - snapshot.poolGrantedShares;

    return [
      {
        name: 'Ungranted Pool',
        percentage: parseFloat(
          ((ungrantedShares * 100) / (snapshot.poolDilutedShares || 1)).toFixed(2),
        ),
      },
      {
        name: 'Granted Pool',
        percentage: parseFloat(
          ((snapshot.poolGrantedShares * 100) / (snapshot.poolDilutedShares || 1)).toFixed(2),
        ),
      },
    ];
  }, [snapshot]);

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const data = variant === PoolGraphVariant.EQUITY ? equityGraphData : grantedGraphData;

  const isMobile = !isDesktop && !isTablet;

  return (
    <div className={twMerge('flex h-full', isDesktop || isTablet ? 'w-[50%]' : 'w-[100%]')}>
      <div
        className={twMerge('flex w-full items-center justify-center gap-9', isMobile && 'flex-col')}
      >
        <div
          className={twMerge(
            !snapshot?.valuation && 'animate-pulse',
            isTablet ? 'size-[130px]' : 'size-[214px]',
          )}
        >
          {data.every((item) => item.percentage === 0) ? (
            <EmptyPositionGraph />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <PieChart>
                <Pie
                  cx="50%"
                  cy="50%"
                  data={data}
                  dataKey="percentage"
                  innerRadius="50%"
                  isAnimationActive={false}
                  outerRadius="100%"
                >
                  {data.map((entry, index) => (
                    <Cell
                      fill={
                        activeIndex === index
                          ? colors[variant].activeColors[
                              index % colors[variant].activeColors.length
                            ]
                          : colors[variant].fillColors[index % colors[variant].fillColors.length]
                      }
                      fillOpacity={1}
                      key={entry.name}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      stroke={
                        colors[variant].strokeColors[index % colors[variant].strokeColors.length]
                      }
                      style={{
                        transition: 'all 0.5s ease, stroke 0.5s ease',
                      }}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
        <div
          className={twMerge(
            'flex max-w-[108px] flex-col gap-6',
            isMobile && 'max-w-full flex-row justify-center gap-4',
          )}
        >
          {data.map((item, index) => (
            <div
              className={twMerge('flex w-[108px] flex-col gap-2', isMobile && 'items-center')}
              key={`${item.name}_${index}`}
            >
              {snapshot?.valuation ? (
                <span
                  className="text-label-md font-[600] text-gray-600"
                  style={{
                    color: activeIndex !== null && activeIndex !== index ? '#98A2B3' : '#344054',
                    transition: 'color 0.5s ease',
                  }}
                >
                  {item.name}
                </span>
              ) : (
                <div className="h-2 w-full animate-pulse bg-gray-50" />
              )}

              {snapshot?.valuation ? (
                <>
                  {' '}
                  <div className="flex items-center gap-2">
                    <div
                      className="h-[5px] w-3 rounded"
                      style={{
                        backgroundColor:
                          activeIndex === index
                            ? colors[variant].activeColors[
                                index % colors[variant].activeColors.length
                              ]
                            : colors[variant].fillColors[index % colors[variant].fillColors.length],
                        border:
                          activeIndex === index
                            ? `1px solid ${colors[variant].activeColors[index % colors[variant].activeColors.length]}`
                            : `1px solid ${colors[variant].fillColors[index % colors[variant].fillColors.length]}`,
                        transition: 'background-color 0.5s ease, border 0.5s ease',
                      }}
                    />
                    <span
                      className="whitespace-nowrap text-sm font-[450] text-gray-700"
                      style={{
                        color:
                          activeIndex !== null
                            ? activeIndex === index
                              ? colors[variant].activeColors[
                                  index % colors[variant].activeColors.length
                                ]
                              : '#98A2B3'
                            : '#344054',
                        transition: 'color 0.5s ease',
                      }}
                    >
                      {item.percentage}%
                    </span>
                  </div>
                </>
              ) : (
                <div className="h-2 w-full animate-pulse bg-gray-50" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
