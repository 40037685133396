import { FC, useEffect, useMemo, useState } from 'react';

import { ShareClass, useFakePagination, useLocale } from '../../../../hooks';
import { stakeholderTypeTranslation } from '../../../../translations/stakeholderTranslation';
import { Column } from '../../../../types/snapshot.types';
import { filterByAllFields } from '../../../../utils/filterByAllFields';
import { toRound } from '../../../../utils/getRoundedNumber';
import { calcAvg, calcTotal } from '../../../../utils/reduceUtils';
import { toNumber } from '../../../../utils/toNumber';
import { AmountOfFetchedItems } from '../../variables';
import { PaginationItem } from '../TableVariants/Items/PaginationItem';
import { StakeholderTableItem } from '../TableVariants/Items/StakeholderTableItem';
import { getShareClasses, SnapshotViewByProps } from './type';

export const SnapshotViewByStakeholder: FC<SnapshotViewByProps> = ({
  snapshot: {
    stats: { stakeholders, shareClasses },
  },
  viewBy,
  visibleColumns,
  search,
  setEmptySearchResult,
}) => {
  const { messagesLocale } = useLocale();
  const [currentPage, setCurrentPage] = useState(1);

  const [itemsToFetch, setItemsToFetch] = useState(AmountOfFetchedItems.FIVE);

  const searchResult = useMemo(
    () => filterByAllFields(stakeholders, search),
    [stakeholders, search],
  );

  const { shareClass } = ShareClass.useDefaultShareClass();

  const defaultShareClassId = shareClass?.id || '';

  const { otherShareClasses, defaultShareClass } = getShareClasses(
    defaultShareClassId,
    shareClasses,
  );

  const stakeholdersWithDiluted = searchResult.filter((stakeholder) => stakeholder.diluted > 0);

  const { data, totalPages } = useFakePagination({
    currentPage,
    data: stakeholdersWithDiluted,
    take: Number(itemsToFetch),
  });

  useEffect(() => {
    if (stakeholdersWithDiluted.length === 0) {
      setEmptySearchResult(true);
    }
  }, [setEmptySearchResult, stakeholdersWithDiluted.length]);

  const issuedPercentageTotal = calcTotal(stakeholders, ({ issuedPercentage }) => issuedPercentage);

  const dilutedPercentageTotal = calcTotal(
    stakeholders,
    ({ dilutedPercentage }) => dilutedPercentage,
  );

  const votingRightPercentageTotal = calcTotal(
    stakeholders,
    ({ votingPercentage }) => votingPercentage,
  );

  const issuedTotal = calcTotal(stakeholders, ({ issued }) => issued);

  const dilutedTotal = calcTotal(stakeholders, ({ diluted }) => diluted);

  const votingRightTotal = calcTotal(stakeholders, ({ votingRight }) => votingRight);

  const commonStockTotal = toNumber(defaultShareClass?.issued);

  const shareClassesTotal = otherShareClasses.map(({ issued }) => issued);

  const investmentTotal = calcTotal(stakeholders, ({ invested }) => invested);

  const averageSharePrice = calcAvg(stakeholdersWithDiluted, ({ avgSharePrice }) => avgSharePrice);

  return (
    <>
      {data.map(
        ({
          diluted,
          dilutedPercentage,
          issued,
          issuedPercentage,
          type,
          votingPercentage,
          name,
          id,
          votingRight,
          shareClasses,
          invested,
          avgSharePrice,
        }) => {
          const { defaultShareClass, otherShareClasses } = getShareClasses(
            defaultShareClassId,
            shareClasses,
          );

          return (
            <StakeholderTableItem
              fields={{
                [Column.NAME]: null,
                [Column.STAKEHOLDER_NAME]: name,
                [Column.TYPE]: messagesLocale[stakeholderTypeTranslation[type]],
                [Column.ISSUED]: toRound(issuedPercentage * 100),
                [Column.DILUTED]: toRound(dilutedPercentage * 100),
                [Column.VOTING]: toRound(votingPercentage * 100),
                [Column.NO_ISSUED]: issued,
                [Column.DILUTED_SHARES]: diluted,
                [Column.COMMON_STOCK]: toNumber(defaultShareClass?.issued),
                [Column.OTHER_SHARE_CLASSES]: otherShareClasses.map(({ issued }) => issued),
                [Column.VOTING_RIGHTS]: votingRight.toLocaleString('en-US'),
                [Column.INVESTMENT]: invested.toLocaleString('en-US'),
                [Column.AVERAGE_SHARE_PRICE]: toRound(avgSharePrice),
              }}
              key={id}
              viewBy={viewBy}
              visibleColumns={visibleColumns}
            />
          );
        },
      )}

      <tr>
        <td colSpan={visibleColumns.length}>
          <PaginationItem
            currentPage={currentPage}
            handleChangeCurrentPage={setCurrentPage}
            handleFetchAmountChange={setItemsToFetch}
            itemsToFetch={itemsToFetch}
            totalPages={totalPages}
          />
        </td>
      </tr>

      <StakeholderTableItem
        className="font-[550] text-gray-800"
        fields={{
          [Column.NAME]: 'Total',
          [Column.STAKEHOLDER_NAME]: 'Total',
          [Column.TYPE]: '',
          [Column.ISSUED]: toRound(issuedPercentageTotal * 100),
          [Column.DILUTED]: toRound(dilutedPercentageTotal * 100),
          [Column.VOTING]: toRound(votingRightPercentageTotal * 100),
          [Column.NO_ISSUED]: issuedTotal.toLocaleString('en-US'),
          [Column.DILUTED_SHARES]: dilutedTotal.toLocaleString('en-US'),
          [Column.VOTING_RIGHTS]: votingRightTotal.toLocaleString('en-US'),
          [Column.COMMON_STOCK]: commonStockTotal.toLocaleString('en-US'),
          [Column.OTHER_SHARE_CLASSES]: shareClassesTotal,
          [Column.INVESTMENT]: investmentTotal.toLocaleString('en-US'),
          [Column.AVERAGE_SHARE_PRICE]: toRound(averageSharePrice),
        }}
        viewBy={viewBy}
        visibleColumns={visibleColumns}
      />
    </>
  );
};
