export const useFakePagination = <T>({
  currentPage,
  data,
  take,
}: {
  data: T[];
  take: number;
  currentPage: number;
}) => ({
  data: data.slice((currentPage - 1 || 0) * take, (currentPage - 1 || 0) * take + take),
  totalPages: Math.ceil(data.length / take),
});
