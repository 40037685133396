import React, { FC, ReactNode, useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { CloseModalButton } from '../../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { useReactForm, ValuationHooks, VestingTaskHooks } from '../../../../hooks';
import { SharePlanType, sharePlanTypeTitle } from '../../../../types/pool-plans.types';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { ChildrenFormProps } from './type';
import { FormSchema, formSchema } from './validation';

export type ExerciseFormProps = {
  isOpen: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  id: string;
  type: SharePlanType;
};

const formSteps: ((props: ChildrenFormProps) => ReactNode)[] = [
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
];

export const ExerciseForm: FC<ExerciseFormProps> = ({
  isOpen,
  companyId,
  handleClose,
  invalidateQuery,
  type,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    setError,
    clearErrors,
    resetField,
    formState: { errors },
  } = useReactForm({
    schema: formSchema,
  });

  const { valuation } = ValuationHooks.useCurrent({ companyId });

  const { create: exercise } = VestingTaskHooks.useExercise();

  const handleCloseModal = useCallback(() => {
    reset();
    handleClose();
    setCurrentStep(1);
  }, [reset, handleClose]);

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { stepOne, stepTwo } = data;

      const hurdle =
        type === SharePlanType.GROWTH_HURDLE &&
        stepOne.hurdle?.hurdleItems &&
        stepOne.hurdle?.hurdleItems.length > 0 &&
        stepOne.hurdle?.hurdleItems.map(({ issuedShares, grant }) => ({
          vestingTaskId: grant.id,
          value: issuedShares,
          filesLinks:
            stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
        }));

      const rsa =
        type === SharePlanType.RSA &&
        stepOne.rsa?.rsaItems &&
        stepOne.rsa?.rsaItems.length > 0 &&
        stepOne.rsa?.rsaItems.map(({ exercised, grant }) => ({
          vestingTaskId: grant.id,
          value: exercised,
          filesLinks:
            stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
        }));

      const stock =
        type === SharePlanType.STOCK_OPTIONS &&
        stepOne.commonStock?.stockItems &&
        stepOne.commonStock.stockItems.length > 0 &&
        stepOne.commonStock?.stockItems.map(({ exercised, grant }) => ({
          vestingTaskId: grant.id,
          value: exercised,
          filesLinks:
            stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
        }));

      const warrantExercise =
        type === SharePlanType.WARRANTS &&
        stepOne.warrantExercise?.warrantExerciseItems &&
        stepOne.warrantExercise.warrantExerciseItems.length > 0 &&
        stepOne.warrantExercise?.warrantExerciseItems.map(({ exercised, grant }) => ({
          vestingTaskId: grant.id,
          value: exercised,
          filesLinks:
            stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
        }));

      const exerciseData = hurdle || rsa || stock || warrantExercise;

      if (exerciseData) {
        exercise(
          {
            companyId,
            data: exerciseData,
          },
          {
            onSuccess: () => {
              toast.success('Exercised successfully');
              invalidateQuery();
              handleCloseModal();
            },
          },
        );
      }
    },
    [companyId, exercise, handleCloseModal, invalidateQuery, type],
  );

  const handleNextStep = useCallback(() => {
    const nextStep = currentStep + 1;
    if (nextStep === Object.keys(formSteps).length + 1) {
      handleSubmit(submitHandler)();
      return;
    }
    if (nextStep > Object.keys(formSteps).length) return;

    setCurrentStep(nextStep);
  }, [currentStep, handleSubmit, submitHandler]);

  const handlePrevStep = useCallback(() => {
    const prevStep = currentStep - 1;
    if (prevStep < 1) return;
    setCurrentStep(prevStep);
  }, [currentStep]);

  return (
    <Sheet open={isOpen}>
      <SheetContent
        className="h-fit max-h-[720px] w-[90%] max-w-[1422px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="CENTER"
      >
        <div className="flex h-full w-full flex-col gap-8 overflow-hidden rounded-2xl bg-gray-25 p-8">
          <div className="flex h-fit w-full items-center justify-between">
            <span className="text-xl font-semibold text-gray-700">
              Exercise {type && sharePlanTypeTitle[type]}
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <div className="flex h-full flex-col gap-8 overflow-hidden">
            {formSteps[currentStep - 1]({
              companyId,
              formData: getValues,
              nextFormStep: handleNextStep,
              prevFormStep: handlePrevStep,
              setFormData: setValue,
              control,
              clearErrors,
              handleCloseModal,
              setError,
              filedState: getFieldState,
              errors,
              type,
              resetField,
              reset,
              valuation,
            })}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
