import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type SarIconProps = IconProps;

const SarIcon: FC<SarIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9167 2.91602V1.66602M16.1995 3.7999L17.0834 2.91602M17.0919 7.08268H18.3419M18.2922 10.8327C17.8741 15.0438 14.3211 18.3327 10 18.3327C5.39765 18.3327 1.66669 14.6017 1.66669 9.99935C1.66669 5.67823 4.95558 2.12527 9.16669 1.70716M10 6.66602H13.3334V9.99935M13.0164 6.66602C11.0545 9.43901 7.82199 11.2493 4.16669 11.2493C3.33093 11.2493 2.51729 11.1547 1.73587 10.9756"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);

export default SarIcon;
