import { StringKey } from '../string-key.enum';

export const frMessages: Record<StringKey, string> = {
  /// For this keys Translation is missing
  [StringKey.EMPLOYEE]: 'Employé',
  [StringKey.FOUNDER]: 'Fondateur',
  [StringKey.BOARD_MEMBERS]: 'Membres du CA',
  [StringKey.VCS]: 'Venture Capital',
  [StringKey.INVESTMENT_BANKS]: "Banque d'investissement",
  [StringKey.PES]: 'Capital-investissement',
  [StringKey.FAMILY_OFFICE]: 'Family Office',
  [StringKey.OTHER_INST]: 'Autre Investisseurs Inst.',
  [StringKey.STAKEHOLDER]: 'Actionnaire',
  [StringKey.NO_GO_BACK]: 'Non, reviens sur la page',
  [StringKey.NO_KEEP_1]: 'Non',
  [StringKey.NO_KEEP_2]: 'Non',
  [StringKey.NO_KEEP_3]: 'Non, garde le',
  [StringKey.NO_KEEP_4]: 'Non, garde les',
  [StringKey.NO_KEEP_5]: "Non, garde l'accés",
  [StringKey.CAN_REACTIVATE]: 'Vous pouvez réactiver votre abonnement dans les 6 prochains mois',
  [StringKey.SAVE_APPROX]: '(économisez environ 17%)',
  [StringKey.BETWEEN_SYMBOL]: 'à',
  [StringKey.COUNT_STAKEHOLDERS]: '{count} actionnaires',
  [StringKey.ACTIVE]: 'Actif',
  [StringKey.ACTIVE_UNTIL]: "Actif jusqu'au {date}",
  [StringKey.ACTIVITY]: 'Activité',
  [StringKey.ADD_NEW_COMPANY]: 'Ajouter une nouvelle entreprise',
  [StringKey.ADD_STAKEHOLDER]: 'Ajouter un actionnaire',
  [StringKey.ADDRESS]: 'Adresse',
  [StringKey.ADDRESS_OPTIONAL]: 'Adresse (facultatif)',
  [StringKey.INACTIVE]: 'Inactif',
  [StringKey.ROWS_SELECTED]: '{count, plural, =1 {# ligne} other {# lignes}} sélectionnées',
  [StringKey.ACCESS_AND_PERMISSIONS]: 'Accès et autorisations',
  [StringKey.ACCESS_REVOKED]: 'Accès révoqué',
  [StringKey.ACCOUNT]: 'Compte',
  [StringKey.ADMIN]: 'Administrateur',
  [StringKey.ADVISOR]: 'Conseiller',
  [StringKey.ALL]: 'Tous',
  [StringKey.PLAN_SOON_EXPIRE]:
    "Nous sommes navrés de vous voir partir. Vous pouvez continuer à utiliser CapQuest jusqu'à ce que votre abonnement actuel expire le {date}.",
  [StringKey.ALL_ASSOCIATED_STAKEHOLDER_DATA_WILL_BE_LOST]:
    "Toutes les données associées à l'actionnaire seront perdues.",
  [StringKey.ALL_STAKEHOLDERS]: 'Tous les actionnaires',
  [StringKey.ALREADY_HAVE_ACCOUNT]: 'Vous avez déjà un compte?',
  [StringKey.AMOUNT_TO_PAY_TODAY]: "Montant à payer aujourd'hui",
  [StringKey.ANGEL]: 'Angel',
  [StringKey.ANGELS]: 'Angels',
  [StringKey.ANNUAL]: 'Annuel',
  [StringKey.ANNUAL_REVENUE]: 'Revenu annuel ($)',
  [StringKey.ANOTHER_BENEFIT]: 'Autre avantage n°{num}',
  [StringKey.ANY_ADDITIONAL_FEEDBACK]: 'Des commentaires supplémentaires ?',
  [StringKey.ARE_YOU_SURE_YOU_WANT_CANCEL]: "Êtes-vous sûr de vouloir annuler l'abonnement ?",
  [StringKey.ARE_YOU_SURE_YOU_WANT_DEACTIVATE]:
    "Etes-vous sûr de vouloir désactiver l'entreprise ?",
  [StringKey.LOSE_ACCESS_CAUTION]:
    " Vous et tous les actionnaires n’y aurez plus accès. Vous avez {time} pour réactiver l'entreprise après quoi elle sera définitivement supprimée.",
  [StringKey.ARE_YOU_SURE_YOU_WANT_DELETE_ACCOUNT]:
    'Êtes-vous sûr de vouloir supprimer votre compte ?',
  [StringKey.ARE_YOU_SURE_YOU_WANT_GRANT_ACCESS]: " Êtes-vous sûr de vouloir accorder l'accès ?",
  [StringKey.ARE_YOU_SURE_YOU_WANT_MAKE_CONTRIBUTOR]:
    'Êtes-vous sûr de vouloir rendre cet utilisateur contributeur ?',
  [StringKey.AT_LEAST_ONE_LOWER_CASE_LETTER]: 'Au moins 1 lettre minuscule (a-z)',
  [StringKey.AT_LEAST_ONE_SPECIAL_CHAR]: 'Au moins 1 caractère spécial (par exemple !@#$%^&*)',
  [StringKey.AT_LEAST_ONE_UPPER_CASE_LETTER]: 'Au moins 1 lettre majuscule (A-Z)',
  [StringKey.AT_LEAST_EIGHT_CHARS]: 'Au moins 8 caractères',
  [StringKey.AUTO_RENEW_ON]: 'Renouvellement automatique activé',
  [StringKey.BACK]: 'Retour',
  [StringKey.BACK_TO_SIGN_IN]: 'Retour à la page de connexion',
  [StringKey.BEFORE_YOU_START_YOUR_JOURNEY_CONFIRMATION]:
    'Avant de commencer votre parcours, nous voulions simplement vous montrer comment vos informations ont été enregistrées. Si vous avez des commentaires, veuillez contacter votre administrateur',
  [StringKey.BILLED_ANNUALY]: 'Facturé annuellement',
  [StringKey.ANNUALY]: 'Annualy',
  [StringKey.BILLED_MONTHLY]: 'Facturé mensuellement tous les {date}',
  [StringKey.BILLED_MONTHLY_PLAN]: 'Facturé mensuellement',
  [StringKey.BIRTHDAY]: 'Date de naissance',
  [StringKey.BIRTHDAY_OPTIONAL]: 'Date de naissance (facultatif)',
  [StringKey.BULK_MODIFY]: 'Modification groupée',
  [StringKey.BULK_MODIFY_STAKEHOLDERS]: 'Modifier en bloc les actionnaires',
  [StringKey.BY_PROCEEDING_YOU_AGREE_TO]: 'En poursuivant, vous acceptez',
  [StringKey.TERMS_OF_SERVICE]: "les conditions d'utilisation",
  [StringKey.AND]: 'et',
  [StringKey.PRIVACY_POLICY]: 'la politique de confidentialité',
  [StringKey.CANCEL]: 'Annuler',
  [StringKey.CANCEL_INVITATION]: "Annuler l'invitation",
  [StringKey.CANCEL_SUBSCRIPTION]: "Annuler l'abonnement",
  [StringKey.CANNOT_DOWNGRADE]: "Impossible de changer d'abonnement",
  [StringKey.CAP_TABLE_MANAGEMENT]: 'Gestion de la Cap Table',
  [StringKey.MISSING_FEATURES]: 'CapQuest a des fonctionnalités manquantes',
  [StringKey.TOO_EXPENSIVE]: 'CapQuest est trop cher',
  [StringKey.CARD_NUMBER]: 'Numéro de carte',
  [StringKey.CARDHOLDER_NAME]: 'Nom du titulaire',
  [StringKey.CHANGE_PASSWORD]: 'Changer le mot de passe',
  [StringKey.CHANGE_PLAN]: "Changer d'abonnement",
  [StringKey.CHANGE_YOUR_PASSWORD]: 'Changez votre mot de passe',
  [StringKey.CHECK_THE_URL]: "Vérifiez que l'URL ne contient pas de fautes de frappe et réessayez.",
  [StringKey.CHECK_YOUR_EMAIL]: 'Vérifiez votre e-mail',
  [StringKey.CHECK_YOUR_INBOX]: 'Vérifiez votre boîte de réception',
  [StringKey.CITY]: 'Ville',
  [StringKey.CLICK_TO_UPLOAD]: 'Cliquez pour télécharger',
  [StringKey.OR_DRAG_AND_DROP]: 'ou glisser-déposer',
  [StringKey.CLOSE]: 'Fermer',
  [StringKey.COLLAPSE]: 'Réduire',
  [StringKey.COMPANY_ADDRESS]: 'Adresse de la société',
  [StringKey.COMPANY_ID_NUMBER_OPTIONAL]: "Numéro d'identification de l'entreprise (facultatif)",
  [StringKey.COMPANY_IDENTIFIER]: "Identifiant de l'entreprise",
  [StringKey.COMPANY_IDENTIFIER_OPTIONAL]: "Identifiant de l'entreprise (facultatif)",
  [StringKey.COMPANY_LOGO_OPTIONAL]: "Logo de l'entreprise (facultatif)",
  [StringKey.COMPANY_NAME]: "Nom de l'entreprise",
  [StringKey.COMPANY_REGISTRATION_DETAILS]: "Détails d'enregistrement de l'entreprise",
  [StringKey.COMPANY_SETTINGS]: "Paramètres de l'Entreprise",
  [StringKey.COMPANY_SETUP]: "Configuration de l'entreprise",
  [StringKey.COMPANY_TYPE]: "Type d'entreprise",
  [StringKey.COMPANY_URL]: "URL de l'entreprise",
  [StringKey.COMPANY_URL_OPTIONAL]: "URL de l'entreprise (facultatif)",
  [StringKey.CONFIRM]: 'Confirmer',
  [StringKey.CONFIRM_PASSWORD]: 'Confirmez le mot de passe',
  [StringKey.MAXIMUM_FILE_SIZE]: 'Taille maximale du fichier {size} Mo',
  [StringKey.MAXIMUM_PHOTO_SIZE]: 'Taille maximale: {rules}',
  [StringKey.CARD_ENDING_NUMBERS]: '{brand} se terminant par {number}',
  [StringKey.LETS_TALK]: 'Parlons-en',
  [StringKey.LAST_UPDATE]: 'Dernière mise à jour : {date}',
  [StringKey.INDUSTRY]: 'Industrie',

  [StringKey.INVITE_USER]: 'Inviter un utilisateur',
  [StringKey.INVITED]: 'Invité',
  /// For this keys Translation is missing
  [StringKey.ACTIVE_STAKEHOLDERS]: 'Actif Stakeholders',
  [StringKey.INACTIVE_STAKEHOLDERS]: 'Inactif Stakeholders',
  [StringKey.ALL_TERMINATED]: 'Actionnaires avec rupture de contrat',
  [StringKey.ACTIVE_TERMINATED]: 'Active Terminated',
  [StringKey.INACTIVE_TERMINATED]: 'Inactive Terminated',
  [StringKey.STAKEHOLDERS_TAB]: 'Actionnaires',
  [StringKey.TERMINATED_TAB]: 'Rupture de contrat',
  [StringKey.FILTER_ALL]: 'Tous',
  [StringKey.FILTER_ACTIVE]: 'Actif',
  [StringKey.FILTER_INACTIVE]: 'Inactif',
  [StringKey.FILTER_IN_USE]: 'In Use',
  [StringKey.FILTER_NOT_USED]: 'Not Used',
  [StringKey.KILOBYTE]: 'Ko',
  [StringKey.MINUTES_AGO]: 'Il y a {date}',
  [StringKey.SINGULAR_MIN]: 'minute',
  [StringKey.PLURAL_MIN]: 'minutes',

  [StringKey.HOURS_AGO]: 'Il y a {date}',
  [StringKey.SINGULAR_HOUR]: 'heure',
  [StringKey.PLURAL_HOUR]: 'heures',

  [StringKey.DAYS_AGO]: 'Il y a {date}',
  [StringKey.SINGULAR_DAY]: 'jours',
  [StringKey.PLURAL_DAY]: 'jour',

  [StringKey.MONTHS_AGO]: 'Il y a {date}',
  [StringKey.SINGULAR_MONTH]: 'mois',
  [StringKey.PLURAL_MONTH]: 'mois',
  [StringKey.LESS_THAN_MIN_AGO]: "il y a moins d'une minute",
  [StringKey.ZIP_CODE]: 'Code postal',
  [StringKey.SWITCH_TO_ANNUAL_PLAN_MESSAGE]: 'Votre abonnement sera renouvelé le {date}',
  [StringKey.CONGRATS_ON_YOUR_GROWTH]:
    "Félicitations pour votre croissance! Vous avez atteint votre limite d'actionnaires. Veuillez mettre à niveau votre abonnement pour continuer. ",
  [StringKey.CONTINUE]: 'Continuer',
  [StringKey.CONTINUE_TO_PLANS]: 'Continuer vers les abonnements',
  [StringKey.CONTINUE_TO_USE_CAPQUEST_UNTIL]:
    "Continuez à utiliser CapQuest jusqu'à l'expiration de votre abonnement actuel, le {date}.",
  [StringKey.CONTINUE_WITH_GOOGLE]: 'Continuer avec Google',
  [StringKey.CONTRIBUTOR]: 'Contributeur',
  [StringKey.COUNTRY_OF_COMPANY]: "Pays de l'entreprise",
  [StringKey.CREATE_MY_ACCOUNT]: 'Créer mon compte',
  [StringKey.CREATING_YOUR_ACCOUNT]: 'Création de votre compte ...',
  [StringKey.CURRENT_PLAN]: 'Abonnement actuel',
  [StringKey.CURRENTLY_SELECTED]: 'Actuellement sélectionné',
  [StringKey.DASHBOARD]: 'Tableau de bord',
  [StringKey.DATE_CREATED]: 'Date de création',
  [StringKey.DATE_OF_INCORPORATION]: 'Date de constitution',
  [StringKey.DATE_OF_INCORPORATION_OPTIONAL]: 'Date de constitution (facultatif)',
  [StringKey.DAY]: 'Jour',
  [StringKey.DEACTIVATE]: 'Désactiver',
  [StringKey.DEACTIVATE_COMPANY]: "Désactiver l'entreprise",
  [StringKey.DELETE]: 'Supprimer',
  [StringKey.DELETE_ACCOUNT]: 'Supprimer le compte',
  [StringKey.DELETE_STAKEHOLDER]:
    "Supprimer {count, plural, =1 {l'actionnaire} other {les actionnaires}} ",
  [StringKey.DELETE_YOUR_CAPQUEST_ACCOUNT]: 'Supprimer votre compte CapQuest',
  [StringKey.DEMO]: 'Démo',
  [StringKey.DETAILS]: 'Détails',
  [StringKey.DONT_HAVE_AN_ACCOUNT_YET]: "Vous n'avez pas encore de compte",
  [StringKey.DONT_WORRY_YOUR_DATA_IS_SAVED]:
    "Ne vous inquiétez pas, vos données sont sauvegardées chez nous. Vous pouvez réactiver l'abonnement pendant {timeDistance}.",
  [StringKey.DOWNGRADE]: "Changer l'abonnement",
  [StringKey.SWITCH_TO_ANNUAL_PLAN_CHARGE_MESSAGE]:
    'Votre méthode de facturation principale sera facturée {price} {currency}.',
  [StringKey.SWITCH_TO_ANNUAL_PLAN_ACTIVE_MESSAGE]: 'Votre abonnement sera activé immédiatement',
  [StringKey.PAY_MONTHLY]: 'Payez mensuellement',
  [StringKey.COMPANY_SETUP_EXIT]: 'Votre progression sera perdue si vous quittez',
  [StringKey.PRIVATE_EQUITY]: 'Private Equity',
  [StringKey.VENTURE_CAPITAL]: 'Capital-risque',
  [StringKey.SPV]: 'SPV',
  [StringKey.COMPANY]: 'Company',
  [StringKey.OTHER]: 'Other financial institutions',
  [StringKey.STAKEHOLDER_COMPANY_GROUP]: 'Legal entities',
  [StringKey.STAKEHOLDER_HUMAN_GROUP]: 'Personnes physiques',
  [StringKey.MONTH]: 'Mois',
  [StringKey.MONTHLY]: 'Mensuel',
  [StringKey.STAKEHOLDERS_LIMIT]:
    "Vous ne pouvez pas rétrograder votre abonnement car vous comptez actuellement plus de 25 actionnaires. Contactez l'assistance client.",
  [StringKey.YOURE_SAVE_APPROX_WITH_ANNUAL]: 'Vous économisez env. 17%',
  [StringKey.UPGRADE]: 'Mise à niveau',
  [StringKey.SWITCH_ANNUAL]: "Passer à l'annuel",
  [StringKey.SELECT]: 'Sélectionner',
  [StringKey.SPACIAL_PLAN_TEXT]: 'Contactez un agent',
  [StringKey.CANNOT_DOWNGRADE_STAKEHOLDERS]:
    'Vous ne pouvez pas déclasser votre plan parce que vous avez actuellement un plan annuel.',
  [StringKey.CANCEL_SUBSCRIPTION_PROBLEM_1]:
    'Vous et tous les actionnaires n’aurez plus accès à cette entreprise par la suite.',
  [StringKey.EXIT]: 'Oui, quitter',
  [StringKey.CONFIRM_DELETE]: 'Oui, supprimer',
  [StringKey.CONFIRM_DEACTIVATE]: 'Oui, désactiver',
  [StringKey.CONFIRM_CANCEL]: 'Oui, annuler',
  [StringKey.YEAR]: 'Année',
  [StringKey.WHERERE_LOCATED]: 'Où êtes-vous situé?',
  [StringKey.WELCOME]: 'Bienvenue',
  [StringKey.WELCOME_TO_CAPQUEST]: 'Bienvenue sur CapQuest',
  [StringKey.WELCOME_BACK]: 'Content de vous revoir',
  [StringKey.WELCOME_ABOARD]: 'Bienvenue à bord',
  [StringKey.FEW_DETAILS]: 'Nous avons juste besoin de quelques détails pour commencer',
  [StringKey.SENDED_RECEIPT_TO_EMAIL]:
    'Nous vous avons envoyé le reçu sur votre adresse email affiliée',
  [StringKey.SENDED_LOGIN_VERIFY_LINK]:
    'Nous vous avons envoyé un lien de connexion sécurisé. Veuillez cliquer sur le lien pour authentifier votre compte',
  [StringKey.WANT_EXIT]: 'Vous souhaitez partir ?',
  [StringKey.VOTING_RIGHTS]: '% Droit de vote',
  [StringKey.VIEW_MY_COMPANIES]: 'Voir mes entreprises',
  [StringKey.VIEW_MODIFICATION_GUID]: 'Voir le guide de modification',
  [StringKey.VIEW_IMPORT_GUID]: "Afficher le guide d'importation",
  [StringKey.VIEW_DETAILS]: 'Voir les détails',
  [StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART1]: 'Télécharger',
  [StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART2]: 'les informations',
  [StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART3]: "sur l'actionnaire",
  [StringKey.DOWNLOAD_TEMPLATE]: 'Télécharger le modèle',
  [StringKey.EDIT_DETAILS]: 'Modifier les détails',
  [StringKey.EDIT_STAKEHOLDER]: "Modifier l'actionnaire",
  [StringKey.EFFICIENTLY_HANDLE_MANAGEMENT]:
    'Gérez efficacement vos stock-options grâce à nos abonnement annuels avantageux.',
  [StringKey.EMAIL]: 'E-mail',
  [StringKey.EMAIL_ADDRESS]: 'Adresse e-mail',
  [StringKey.ENTER_A_NEW_PASSWORD_BELOW]:
    'Entrez un nouveau mot de passe ci-dessous pour modifier votre mot de passe actuel et pursuivez votre accès à CapQuest',
  [StringKey.ENTER_YOUR_EMAIL_ADDRESS_AND_WE_WILL]:
    'Entrez votre adresse email et nous vous enverrons un e-mail avec les instructions de réinitialisation du mot de passe',
  [StringKey.ENTERPRISE_AND_FI]: 'Entreprises et Institutions Financières',
  [StringKey.EQUITY_AND_CAPITAL_CHANGE]: "Historique de l'Equity et des flux de capitaux",
  [StringKey.EVENTS]: 'Événements',
  [StringKey.EVERYTHING_FROM_STARTER_PLAN]: 'Tous les bénéfices du plan Startup +',
  [StringKey.EXPIRY]: "Date d'expiration",
  [StringKey.EXPORT]: 'Exporter',
  [StringKey.FILE_FORMAT_NOT_SUPPORTED]: "Le format de fichier n'est pas pris en charge",
  [StringKey.FILE_PROCESSED_SUCCESSFULLY]: 'Fichier traité avec succès',
  [StringKey.FOR_CUSTOM_PRICING]: 'pour un prix personnalisé',
  [StringKey.FORGOT_PASSWORD]: 'Mot de passe oublié',
  [StringKey.FULL_NAME]: 'Nom et prénom',
  [StringKey.GENERATING_INVITATION_AGAIN_WILL_VOID]:
    'Générer une nouvelle invitation annulera l’invitation précédente.',
  [StringKey.GRANT_ACCESS]: "Accorder l'accès",
  [StringKey.HELP_ME_SETUP]: 'Aidez-moi à configurer',
  [StringKey.HELP_US_MAKE_CAPQUEST_BETTTER]:
    'Aidez-nous à améliorer CapQuest - dites-nous pourquoi vous avez annulé cet abonnement',
  [StringKey.HERE_ARE_A_FEW_THING_TO_TRY]: 'Voici quelques astuces que vous pouvez essayer',
  [StringKey.I_FOUND_BETTER_ALTERNATIVE]: "J'ai trouvé une meilleure alternative",
  [StringKey.I_HAVE_SEEN_MY_INFO]: "J'ai vu mes informations",
  [StringKey.I_NO_LONGER_NEED_IT]: "je n'en ai plus besoin",
  [StringKey.IF_YOU_CANCEL]: 'Si vous annulez',
  [StringKey.IF_YOU_RECEIVED_LINK_IN_EMAIL]:
    "Si vous avez reçu le lien dans un e-mail, essayez de contacter l'expéditeur pour en obtenir un nouveau.",
  [StringKey.IMPORT]: 'Importer',
  [StringKey.IMPORT_STAKEHOLDERS]: 'Importer les actionnaires',
  [StringKey.IN_THE_MEANTIME_FEEL_FREE_TO]: "En attendant, n'hésitez pas à parcourir notre",
  [StringKey.INACTIVE_COMPANY]: 'Entreprise désactivée',
  [StringKey.INCOMPLETE_SETUP]: 'Configuration incomplète',
  [StringKey.LAST_ACTIVE]: 'Dernière activité',
  [StringKey.LET_US_HELP_TO_SETUP]: 'Laissez-nous vous aider à configurer votre CapQuest',
  [StringKey.LET_US_KNOW_ABOUT_YOUR_COMPANY_DETAILS]:
    'Faites-nous part des données de votre entreprise',
  [StringKey.LOCATION]: 'Adresse',
  [StringKey.LOG_IN]: 'Se connecter',
  [StringKey.LOG_OUT]: 'Se déconnecter',
  [StringKey.LOSE_SPECIAL_OFFER_PRICE]: 'Perdez le prix de votre offre spéciale, le cas échéant.',
  [StringKey.MAKE_CONTRIBUTOR]: 'Rendre contributeur',
  [StringKey.MY_BUSINESS_IS_NO_LONGER_ACTIVE]: "Mon entreprise n'est plus active",
  [StringKey.MY_COMPANIES]: 'Mes entreprises',
  [StringKey.MY_COMPANY]: 'Mon Entreprise',
  [StringKey.MY_HOLDING_EQUITY]: 'Mes avoirs / mes capitaux propres',
  [StringKey.MY_HOLDINGS]: 'Mes avoirs',
  [StringKey.MY_PROFILE]: 'Mon profil',
  [StringKey.NAME]: 'Nom',
  [StringKey.NATIONALITY]: 'Nationalité',
  [StringKey.NATIONALITY_OPTIONAL]: 'Nationalité (facultatif)',
  [StringKey.NEW_PASSWORD]: 'Nouveau mot de passe',
  [StringKey.NEXT]: 'Suivant',
  [StringKey.VAT_GST]: 'Numéro de TVA/TPS',
  [StringKey.USER_ROLE]: "Rôle de l'utilisateur",
  [StringKey.MISC_ADVISE_3]:
    'Utilisez la barre de recherche du site pour trouver les informations que vous recherchez.',
  [StringKey.UPLOAD_AGAIN]: 'Uploader à nouveau',
  [StringKey.UPGRADE_PLAN_NOW]: 'Upgrader votre abonnement maintenant',
  [StringKey.UPDATE]: 'Mise à jour',
  [StringKey.UNABLE_DELETE]: 'Impossible de supprimer',
  [StringKey.TYPE]: 'Type',
  [StringKey.TERMINATION]: 'Rupture',
  [StringKey.TERMINATED]: 'Rupture de contrat',
  [StringKey.TERMINATE]: 'Rupture de contrat',
  [StringKey.SWITCH_TO_ANNUAL_BILLING]: 'Passer à la facturation annuelle',
  [StringKey.SWITCH_COMPANY]: "Changer d'entreprise",
  [StringKey.SUPPORTED_FORMATS]: 'Formats pris en charge : {formats}',
  [StringKey.SUBSCRIPTION_CYCLE]: "Cycle d'abonnement",
  [StringKey.SUBSCRIPTION_CANCELED]: 'Abonnement annulé',
  [StringKey.SUBMIT]: 'Soumettre',
  [StringKey.STATUS]: 'Statut',
  [StringKey.STATE]: 'État',
  [StringKey.STAKEHOLDERS]: 'Actionnaires',
  [StringKey.STAKEHOLDER_UPDATE_TOAST]: 'Actionnaire mis à jour avec succès',
  [StringKey.STAKEHOLDER_TYPE]: "Type d'actionnaire",
  [StringKey.STAKEHOLDER_DELETED_TOAST]: 'Supprimé avec succès',
  [StringKey.STAKEHOLDER_CREATED_TOAST]: 'Actionnaire créé avec succès',
  [StringKey.SOMETHING_ELSE]: 'Autre raison',
  [StringKey.SKYROCKETING]: 'Bravo pour votre croissance fulgurante !',
  [StringKey.SKIP]: 'Ignorer',
  [StringKey.SIGN_UP]: "S'inscrire",
  [StringKey.SIGN_UP_TO_CAPQUEST]: 'Connectez-vous à votre compte CapQuest',
  [StringKey.SIGN_IN_TO_CAPQEUST]: 'Connectez-vous à votre compte CapQuest',
  [StringKey.SIGN_IN]: 'Se connecter',
  [StringKey.SHARE_CLASSES]: "Classes d'actions",
  [StringKey.POOL_PLANS_POOL]: 'Détails de la piscine',
  [StringKey.POOL_PLANS_PLAN]: 'Détails du plan',
  [StringKey.POOL_PLANS]: 'Pool et Plans',
  [StringKey.CAP_TABLE]: 'Cap Table',
  [StringKey.SETTINGS]: 'Paramètres',
  [StringKey.ACCEPT_INVITATION]: "Accepter l'invitation",
  [StringKey.LOGIN]: 'Login',
  [StringKey.REGISTER]: 'Registre',
  [StringKey.REGISTER_VERIFY]: 'Register Verify',
  [StringKey.PASSWORD_RECOVERY]: 'Récupération du mot de passe',
  [StringKey.BILLING]: 'Facturation',
  [StringKey.ERROR]: 'Erreur',
  [StringKey.SEND_EMAIL]: 'Envoyer un e-mail',
  [StringKey.SEARCH]: 'Recherche',
  [StringKey.SAVED_APPROX_V2]: 'Économisé env. 17%',
  [StringKey.SAVE_APPROX_V2]: 'Économisé env. 17%',
  [StringKey.SAVE_APPROX_W_ANNUAL_BILLING]: 'Économisez env. 17% avec une facturation annuelle',
  [StringKey.SAVE]: 'Sauvegarder',
  [StringKey.REVOKE_CONTRIBUTOR_ROLE]: 'Révoquer le rôle de contributeur',
  [StringKey.REVOKE_ACCESS]: 'Accès révoqué',
  [StringKey.RESUME_COMPANY_SETUP]: 'Reprendre la création de votre entreprise',
  [StringKey.RESUME]: 'CV',
  [StringKey.RESIGNATION]: 'Démission',
  [StringKey.RESEND_VERIFICATION_EMAIL]: "Renvoyer l'e-mail de vérification",
  [StringKey.RESEND_INVITATION]: "Renvoyer l'invitation",
  [StringKey.RESEND_EMAIL]: "Ré-envoyer l'email",
  [StringKey.NO]: 'Non',
  [StringKey.NO_OF_EMPLOYEES]: "Nombre d'employés",
  [StringKey.NO_RESULTS_MATCHS]: 'Aucun résultat ne correspond à votre recherche',
  [StringKey.NO_REV]: 'Pas de rév.',
  [StringKey.NO_THANKS]: 'Non merci',
  [StringKey.NOTE_THAT_YOUR_INFORMATION_STILL_SAVED]:
    "Veuillez noter qu'en tant qu'actionnaire vos informations seront encore enregistrées et visibles par les administrateurs et les contributeurs.",
  [StringKey.OOPS]: 'Oups!',
  [StringKey.OR]: 'OU',
  [StringKey.PASSPORT_EXPIRY_DATE]: "Date d'expiration du passeport",
  [StringKey.PASSPORT_EXPIRY_DATE_OPTIONAL]: "Date d'expiration du passeport (facultatif)",
  [StringKey.PASSPORT_NO]: 'No de passeport',
  [StringKey.PASSPORT_NO_OPTIONAL]: 'No de passeport (facultatif)',
  [StringKey.PASSWORD]: 'Mot de passe',
  [StringKey.PASSWORD_CHANGED]: 'Mot de passe modifié',
  [StringKey.PAY_NOW]: 'Payez maintenant',
  [StringKey.PAY_YEARLY]: 'Payer annuellement',
  [StringKey.PAYMENT_METHOD]: 'Mode de paiement',
  [StringKey.PER_MONTH]: 'Par mois',
  [StringKey.PERSONAL_INFO]: 'Informations personnelles',
  [StringKey.PHONE_NUMBER]: 'Numéro de téléphone',
  [StringKey.PHONE_NUMBER_OPTIONAL]: 'Numéro de téléphone (facultatif)',
  [StringKey.PLAN_BILLING]: 'Abonnements et facturations',
  [StringKey.PLAN_FOR_ALL_SIZES]: 'Abonnements adaptés à la taille de votre Entreprise',
  [StringKey.PLAN_SELECTED]: 'Abonnement sélectionné',
  [StringKey.PLEASE_CHECK_THE_EMAIL]:
    "Veuillez vérifier l'e-mail envoyé à l'adresse e-mail {email} pour obtenir les instructions pour réinitialiser votre mot de passe.",
  [StringKey.PLEASE_TRY_AGAIN]: 'Veuillez réessayer',
  [StringKey.POOLS_PLANS]: 'Pool et Plans',
  [StringKey.PREVIOUS]: 'Précédent',
  [StringKey.REACTIVATE]: 'Réactiver',
  [StringKey.REACTIVATE_SUBSCRIPTION]: "Réactiver l'abonnement",
  [StringKey.REENTER_NEW_PASSWORD]: 'ré-entrez le nouveau mot de passe',
  [StringKey.REGISTRATION_NUMBER_OPTIONAL]: "Numéro d'enregistrement (facultatif)",
  [StringKey.REPORTING_CURRENCY]: 'Devise de la société',
  [StringKey.YES]: 'Oui',
  [StringKey.YES_CANCEL]: 'Oui, annuler',
  [StringKey.YES_DEACTIVATE]: 'Oui, désactiver',
  [StringKey.YES_DELETE]: 'Oui, supprimer',
  [StringKey.YES_EXIT]: 'Oui, quitter',
  [StringKey.YES_GRANT]: 'Oui, accorder',
  [StringKey.YES_RESEND]: 'Oui, renvoyer',
  [StringKey.YES_REVOKE]: 'Oui, révoquer',
  [StringKey.YES_UPDATE]: 'Oui, mettre à jour',
  [StringKey.YES_TERMINATE]: 'Oui, Terminer',
  [StringKey.UPDATE_STAKEHOLDER]: 'Mise à jour de la rupture de contrat',
  [StringKey.UPDATE_TERMINATION]: 'Mise à jour de la rupture de contrat',
  [StringKey.SIGN_UP_MESSAGE]:
    "Merci d'être un pionnier de CapQuest. Pour commencer à profiter des avantages, configurons votre compte.",
  [StringKey.COMPANY_ID_EXPLANATION]:
    "Le numéro d'identification de l'entreprise peut être tout identifiant unique généralement utilisé pour une entreprise dans les contrats, les subventions et la facturation (par exemple pour les entreprises américaines, il peut s'agir d'un numéro d'identification de l'employeur (EIN), et pour les entreprises française il peut s'agir du numéro de SIRET.)",
  [StringKey.UPDATE_INFORMATION_MESSAGE]:
    'Les informations que vous avez modifiées seront mises à jour et enregistrées.',
  [StringKey.INCORRECT_LINK_PT_1]:
    'Le lien sur lequel vous avez cliqué est peut-être obsolète ou incorrect.',
  [StringKey.INCORRECT_LINK_PT_2]: 'Ne vous inquiétez pas, ce sont des choses qui arrivent !',
  [StringKey.NOT_ABLE_TO_USE_STAKEHOLDER]:
    "L'actionnaire sélectionné ne pourra pas utiliser le CapQuest.",
  [StringKey.STAKEHOLDER_CANCEL_INVITE_DESCRIPTION]:
    "L'actionnaire ne pourra peut-être pas rejoindre votre équipe sur CapQuest.",
  [StringKey.GREETING_COMPANY_SETUP]:
    'Pour commencer, ajoutez vos actionnaires et invitez des personnes de votre entreprise à rejoindre votre équipe',
  [StringKey.PASSWORD_RECOMMENDATION]: 'Votre mot de passe doit contenir :',
  [StringKey.PASSWORD_SUCCESSFULLY_CHANGED]: 'Votre mot de passe a été changé avec succès',
  [StringKey.TERMINATE_STAKEHOLDER]: 'Rupture de contrat des actionnaire',
  [StringKey.STAKEHOLDER_SUCCESSFULLY_CREATED]: 'Actionnaire supprimé avec succès',
  [StringKey.FRENCH]: 'Français',
  [StringKey.ENGLISH]: 'English',
  [StringKey.NO_KEEP]: 'Non, garde-le',
  [StringKey.CONTACT_SUPPORT]: "Contactez l'assistance client",
  [StringKey.WEBSITE]: 'Site web',
  [StringKey.ALL_DONE]: 'All done',
  [StringKey.HANG_TIGHT]: 'Hang tight',
  [StringKey.ONBOARDING]: 'Onboarding',
};
