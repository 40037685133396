import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { CustomViewExportSnapshot } from '../../types/snapshot.types';
import { useAppMutation } from '../useAppMutation';

export const useExport = ({
  companyId,
  snapshotId = '',
}: {
  companyId: string;
  snapshotId?: string;
}) => {
  const exportLatest = [companyId, 'snapshots', 'export-current'];
  const exportCurrent = [companyId, 'snapshots', snapshotId, 'export'];

  const { mutate: mutateExport } = useAppMutation([QueryKey.EXPORT_SNAPSHOT, companyId], {
    mutationFn: async (body: CustomViewExportSnapshot) => {
      await ApiService.downloadFile(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: snapshotId ? exportCurrent : exportLatest,
        },
        { body },
      );
    },
  });

  return { mutateExport };
};
