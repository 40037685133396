export enum CapTableManagementTypes {
  EXCEL = 'excel',
  NOT_YET = 'not-yet',
  ANOTHER = 'another',
}

export const CapTableManagementTypesTitle: Record<CapTableManagementTypes, string> = {
  [CapTableManagementTypes.EXCEL]: 'Excel',
  [CapTableManagementTypes.NOT_YET]: `We don't manage our cap table yet`,
  [CapTableManagementTypes.ANOTHER]: 'Another Cap Table Management Software',
};

export const arrayOfCapTableManagementTypes = Object.entries(CapTableManagementTypesTitle);

export enum EnterpriseFeatures {
  EQUITY = 'equity',
  ESOP = 'esop',
  VENTURE_DEBT = 'venture-debt',
  FUNDRAISING_TRACKER = 'fundraising-tracker',
  DATA_ROOM = 'data-room',
}

export const EnterpriseFeaturesTitle: Record<EnterpriseFeatures, string> = {
  [EnterpriseFeatures.EQUITY]: 'Equity Management',
  [EnterpriseFeatures.ESOP]: 'ESOP management',
  [EnterpriseFeatures.VENTURE_DEBT]: 'Venture Debt management',
  [EnterpriseFeatures.FUNDRAISING_TRACKER]: 'Fundraising tracker',
  [EnterpriseFeatures.DATA_ROOM]: 'Data Room',
};

export const arrayOfEnterpriseFeatures = Object.entries(EnterpriseFeaturesTitle);
