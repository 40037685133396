import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { ApiService } from '../../services';
import { TerminateStakeholderItemDto } from '../../types/stakeholderTypes';
import { useAppMutation } from '../useAppMutation';

export const useTerminationFinalExerciseDate = () => {
  const {
    mutate: calcTerminationFinalExerciseDate,
    isPending,
    data,
  } = useAppMutation<
    Date,
    HttpException,
    { companyId: string; vestingTaskId: string; data: TerminateStakeholderItemDto }
  >([QueryKey.CALC_FINAL_EXERCISE_DATE], {
    mutationFn: ({
      companyId,
      vestingTaskId,
      data,
    }: {
      companyId: string;
      vestingTaskId: string;
      data: TerminateStakeholderItemDto;
    }) =>
      ApiService.post(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'stakeholders', 'terminate', vestingTaskId],
        },
        { body: data },
      ),
  });

  return { calcTerminationFinalExerciseDate, isPending, data };
};
