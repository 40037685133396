import React, { FC, useCallback, useMemo } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormInput } from '../../../../components/Input';
import { FormRadio, FormRadioGroup } from '../../../../components/RadioGroup';
import { Company, useReactForm } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  listOfCompanyAnnualRevenueTranslation,
  listOfCompanyNumberOfEmployeesTranslation,
} from '../../../../translations/companyTranslation';
import { ChildrenFormProps } from '../CompanySetupForm';
import CompanyIndustryCombobox from './CompanyIndustryCombobox';
import CompanyTypeCombobox from './CompanyTypeCombobox';
import { FormSchema, formSchema } from './Validation';

const CompanySetupStepOne: FC<ChildrenFormProps> = ({
  formData,
  setFormData,
  nextFormStep,
  companyId,
}) => {
  const { stepOne } = useMemo(() => formData(), [formData]);
  const { patch, isPending } = Company.useUpdate();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useReactForm({
    schema: formSchema,
    defaultValues: stepOne,
  });

  const submitHandler = useCallback(
    async (data: FormSchema) => {
      const { annualRevenue, type, url, industry, name, numberOfEmployees } = data;
      patch(
        {
          companyId,
          data: {
            name,
            url: url || undefined,
            industry,
            type: type,
            annualRevenue,
            numberOfEmployees,
          },
        },
        {
          onSuccess: () => {
            setFormData('stepOne', data);

            nextFormStep();
          },
        },
      );
    },
    [companyId, nextFormStep, patch, setFormData],
  );

  return (
    <form
      className="flex w-full max-w-[472px] flex-col gap-8 lg:pb-4"
      onSubmit={handleSubmit(submitHandler)}
    >
      <div className="flex flex-col gap-2 text-center">
        <span
          className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
          style={{
            background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
          }}
        >
          <AppFormattedMessage id={StringKey.ADD_NEW_COMPANY} />
        </span>
        <span className="text-sm font-normal text-gray-500">
          <AppFormattedMessage id={StringKey.LET_US_KNOW_ABOUT_YOUR_COMPANY_DETAILS} />
        </span>
      </div>
      <div className="flex flex-col gap-4">
        <FormInput
          control={control}
          name="name"
          placeholder={<AppFormattedMessage id={StringKey.COMPANY_NAME} />}
        />
        <FormInput
          control={control}
          name="url"
          placeholder={<AppFormattedMessage id={StringKey.COMPANY_URL_OPTIONAL} />}
        />

        <CompanyIndustryCombobox control={control} />

        <CompanyTypeCombobox control={control} />

        <FormRadioGroup
          className={'shrink-0 overflow-x-auto'}
          control={control}
          label={<AppFormattedMessage id={StringKey.ANNUAL_REVENUE} />}
          name="annualRevenue"
        >
          {listOfCompanyAnnualRevenueTranslation.map(([annualRevenueKey, annualRevenue]) => (
            <FormRadio
              control={control}
              key={annualRevenue}
              name="annualRevenue"
              value={annualRevenueKey}
            >
              <span className="text-nowrap text-label-md">{annualRevenue}</span>
            </FormRadio>
          ))}
        </FormRadioGroup>

        <FormRadioGroup
          className={'shrink-0 overflow-x-auto'}
          control={control}
          label={<AppFormattedMessage id={StringKey.NO_OF_EMPLOYEES} />}
          name="numberOfEmployees"
        >
          {listOfCompanyNumberOfEmployeesTranslation.map(
            ([numberOfEmployeesKey, numberOfEmployees]) => (
              <FormRadio
                control={control}
                key={numberOfEmployees}
                name="numberOfEmployees"
                value={numberOfEmployeesKey}
              >
                <span className="text-nowrap text-label-md">{numberOfEmployees}</span>
              </FormRadio>
            ),
          )}
        </FormRadioGroup>
      </div>
      <Button disabled={!isValid} isLoading={isPending} type="submit">
        <AppFormattedMessage id={StringKey.CONTINUE} />
      </Button>
    </form>
  );
};

export default CompanySetupStepOne;
