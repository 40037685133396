import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type ListIconProps = IconProps;

export const ListIcon: FC<ListIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="18"
    viewBox="0 0 16 18"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66665 8.16602H4.66665M6.33331 11.4993H4.66665M11.3333 4.83268H4.66665M14.6666 4.66602V13.3327C14.6666 14.7328 14.6666 15.4329 14.3942 15.9677C14.1545 16.4381 13.772 16.8205 13.3016 17.0602C12.7668 17.3327 12.0668 17.3327 10.6666 17.3327H5.33331C3.93318 17.3327 3.23312 17.3327 2.69834 17.0602C2.22793 16.8205 1.84548 16.4381 1.6058 15.9677C1.33331 15.4329 1.33331 14.7328 1.33331 13.3327V4.66602C1.33331 3.26588 1.33331 2.56582 1.6058 2.03104C1.84548 1.56063 2.22793 1.17818 2.69834 0.938499C3.23312 0.666016 3.93318 0.666016 5.33331 0.666016H10.6666C12.0668 0.666016 12.7668 0.666016 13.3016 0.938499C13.772 1.17818 14.1545 1.56063 14.3942 2.03104C14.6666 2.56582 14.6666 3.26588 14.6666 4.66602Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);
