import { useEffect } from 'react';

import { ActionCategory, PossibleAction } from '../../types/google-analytics-types';
import { createGAEvent } from '../../utils/createGoogleAnalyticEvent';

export const useTrackTimeOnPage = () => {
  useEffect(() => {
    const startTime = Date.now();
    return () => {
      const timeSpent = Math.floor((Date.now() - startTime) / 1000);
      createGAEvent({
        category: ActionCategory.ENGAGEMENT_TIME,
        action: PossibleAction.TIME_ON_PAGE,
        label: window.location.pathname,
        value: timeSpent,
      });
    };
  }, []);
};
