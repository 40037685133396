import { FC, useState } from 'react';

import { EditIcon, EyeIconSmall, ThreeDotsIcon, TrashBinIcon } from '../../../../../assets/icons';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '../../../../../components/Popover';
import { EventHooks, useFormat } from '../../../../../hooks';
import { eventFormType, EventTypeBackend, GrantEventItem } from '../../../../../types/events.types';
import { BasicTable } from './BasicTable';
import { PaginationItem } from './PaginationItem';
import { Td } from './TableTd';
import { AmountOfFetchedItems, TableBodyProps } from './types';

export const GrantBody: FC<TableBodyProps> = (props) => {
  const { format } = useFormat();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsToFetch, setItemsToFetch] = useState(AmountOfFetchedItems.THREE);
  const { items, totalPages } = EventHooks.useTransactions<GrantEventItem>({
    eventId: props.id,
    eventType: EventTypeBackend.GRANT,
    companyId: props.companyId,
    currentPage,
    take: Number(itemsToFetch),
    dateFilter: props.selectedDateFilter,
    customDateFilter: props.selectedCustomDateFilter,
  });

  if (props.type !== EventTypeBackend.GRANT) return <></>;

  const { type, eventId, onDelete, onView, onEdit } = props;

  return (
    <>
      <BasicTable eventType={EventTypeBackend.GRANT}>
        {items.map(({ stakeholder, date, numbersOfGrants, plan, id: transactionId }, index) => (
          <tr key={`${index}_${stakeholder}`}>
            <Td value={format(date, 'dd/MM/yyyy')} />
            <Td value={eventFormType[type]} />
            <Td value={stakeholder.fullName} />
            <Td className="uppercase" value={transactionId.split('-')[0]} />
            <Td value={numbersOfGrants?.toLocaleString('en-US')} />
            <Td value={plan.name} />
            <Td
              className="w-12"
              value={
                <Popover>
                  <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
                    <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
                      <ThreeDotsIcon />
                    </div>
                  </PopoverTrigger>
                  <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
                    <PopoverClose>
                      <div
                        className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                        onClick={() => onView(eventId, transactionId)}
                      >
                        <EyeIconSmall />
                        <span className="text-sm font-normal text-gray-700">View Details</span>
                      </div>
                      <div
                        className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                        onClick={() => onEdit(eventId, transactionId)}
                      >
                        <EditIcon />
                        <span className="text-sm font-normal text-gray-700">Edit Details</span>
                      </div>
                      {(totalPages !== 1 || items.length > 1) && (
                        <div
                          className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                          onClick={() => onDelete(props, transactionId)}
                        >
                          <TrashBinIcon iconColor="#F04438" />
                          <span className="text-sm font-normal text-fireside-500">
                            Delete Transaction
                          </span>
                        </div>
                      )}
                    </PopoverClose>
                  </PopoverContent>
                </Popover>
              }
            />
          </tr>
        ))}
      </BasicTable>
      <PaginationItem
        currentPage={currentPage}
        handleChangeCurrentPage={setCurrentPage}
        handleFetchAmountChange={setItemsToFetch}
        itemsCount={items.length}
        itemsToFetch={itemsToFetch}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
};
