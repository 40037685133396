import React, { FC, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { CloseModalButton } from '../../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { EventHooks, useModalState, useReactForm } from '../../../../hooks';
import {
  backendToFormTypeMapping,
  EventFormType,
  EventTypeBackend,
  EventTypeButtonTitle,
  eventTypeMapping,
} from '../../../../types/events.types';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { toBackendDateFormat } from '../../../../utils/toBackendDateFormat';
import { ConfirmUpdateEventModal } from '../../Modals';
import { EditPreview, OptionalStep, StepOne, StepTwo } from '../Steps';
import { ChildrenFormProps, EditEventStepVariant } from '../type';
import { FormSchema, formSchema } from '../validation';

type ModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  companyId: string;
  issuedShares: number;
  invalidateQuery: () => void;
  onEditClick: (props: { id: string; step: EditEventStepVariant }) => void;
  step: EditEventStepVariant;
  eventId: string;
  previewMode?: true;
  transactionId?: string;
};

const formSteps: ((
  props: {
    onEditClick: (step: EditEventStepVariant) => void;
  } & ChildrenFormProps,
) => JSX.Element)[] = [
  (props) => <StepOne {...props} />,
  (props) => <OptionalStep {...props} />,
  (props) => <StepTwo {...props} />,
  (props) => <EditPreview {...props} />,
];

export const EditEventModal: FC<ModalProps> = ({
  companyId,
  issuedShares,
  isOpenModal,
  handleClose,
  invalidateQuery,
  onEditClick,
  eventId,
  step,
  previewMode,
  transactionId,
}) => {
  const { event } = EventHooks.useEvent({ companyId, eventId });
  const { update } = EventHooks.useUpdate();

  const {
    control,
    reset,
    setValue,
    getValues,
    getFieldState,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useReactForm({
    schema: formSchema,
    mode: 'onChange',
  });
  const { initialStep } = getValues();

  useEffect(() => {
    if (!event) return;

    const files: {
      docLink: string;
      loadProgress: 100;
      abort: () => void;
      id: string;
      doc: {
        size: number;
        type: string;
        name: string;
      };
    }[] = event?.filesLinks?.map((link) => {
      const originalFileName = getS3FileOriginalName(link);

      return {
        abort: () => '',
        loadProgress: 100,
        doc: {
          name: originalFileName || '',
          size: 10,
          type: 'application/pdf',
        },
        docLink: link,
        id: uuidv4(),
      };
    });

    reset({
      initialStep: { type: backendToFormTypeMapping[event?.type], id: event.id },
      stepOne: {
        eventDetails: {
          [EventFormType.FUNDRAISING_ROUND]:
            event?.type === EventTypeBackend.FUNDRAISING_ROUND
              ? {
                  enabled: true,
                  date: event.valuation.date,
                  isOpen: event.isOpen,
                  event: {
                    id: event.valuation.id,
                    name: event.valuation.name,
                    sharePrice: event.valuation.sharePrice,
                    issuedSharesOnStart: event.valuation.issuedSharesOnStart,
                  },
                }
              : undefined,
          [EventFormType.BUYBACK]:
            event?.type === EventTypeBackend.BUYBACK
              ? {
                  enabled: true,
                  buyBackItems: event.items.map(
                    ({ date, shareClass, sharesCount, stakeholder, id }) => ({
                      date,
                      shareClass,
                      shares: sharesCount,
                      stakeholder,
                      id,
                      balance: sharesCount,
                      initialShares: sharesCount,
                      initialStakeholderId: stakeholder.id,
                      initialShareClassId: shareClass.id,
                    }),
                  ),
                }
              : undefined,
          [EventFormType.CONVERSION]:
            event?.type === EventTypeBackend.CONVERSION
              ? {
                  enabled: true,
                  classConversionItems: event.items.map(
                    ({ date, fromShareClass, toShareClass, sharesCount, stakeholder, id }) => ({
                      date,
                      shareClassFrom: fromShareClass,
                      shareClassTo: toShareClass,
                      shares: sharesCount,
                      stakeholder,
                      id,
                      balance: sharesCount,
                      initialShares: sharesCount,
                      initialStakeholderId: stakeholder.id,
                      initialShareClassFromId: fromShareClass.id,
                      initialShareClassToId: toShareClass.id,
                    }),
                  ),
                }
              : undefined,
          [EventFormType.GRANT]:
            event?.type === EventTypeBackend.GRANT
              ? {
                  enabled: true,
                  grantItems: event.items.map(
                    ({ date, numbersOfGrants, plan, stakeholder, id }) => ({
                      stakeholder,
                      grantDate: date,
                      sharePlan: plan,
                      shares: numbersOfGrants,
                      initialShares: numbersOfGrants,
                      initialSharePlanId: plan.id,
                      id,
                    }),
                  ),
                }
              : undefined,
          [EventFormType.SECONDARIES]:
            event?.type === EventTypeBackend.SECONDARIES
              ? {
                  enabled: true,
                  secondariesItems: event.items.map(
                    ({
                      date,
                      fromStakeholder,
                      shareClass,
                      sharesCount,
                      sharesValue,
                      toStakeholder,
                      id,
                    }) => ({
                      date,
                      shareClass,
                      shareValue: sharesValue,
                      stakeholderFrom: fromStakeholder,
                      stakeholderTo: toStakeholder,
                      shares: sharesCount,
                      id,
                      balance: sharesCount,
                      initialShares: sharesCount,
                      initialStakeholderFromId: fromStakeholder.id,
                      initialStakeholderToId: toStakeholder.id,
                      initialShareClassId: shareClass.id,
                    }),
                  ),
                }
              : undefined,
          [EventFormType.SHARE_ISSUANCE]:
            event?.type === EventTypeBackend.SHARE_ISSUANCE
              ? {
                  enabled: true,
                  date: event.date,
                  event: {
                    id: event.valuation.id,
                    name: event.valuation.name,
                    sharePrice: event.valuation.sharePrice,
                    issuedSharesOnStart: event.valuation.issuedSharesOnStart,
                  },
                }
              : undefined,
          [EventFormType.VALUATION]:
            event?.type === EventTypeBackend.VALUATION
              ? {
                  enabled: true,
                  date: event.date,
                  name: event.name,
                  sharePrice: event.sharePrice,
                  issuedSharesOnStart: event.issuedSharesOnStart,
                }
              : undefined,
        },
      },
      optionalStep: {
        [EventFormType.FUNDRAISING_ROUND]:
          event?.type === EventTypeBackend.FUNDRAISING_ROUND
            ? {
                investmentItems: event.items.map(
                  ({ date, investedValue, shareClass, stakeholder, id }) => ({
                    investmentDate: date,
                    stakeholder,
                    investment: investedValue,
                    shareClass,
                    id,
                  }),
                ),
              }
            : undefined,
        [EventFormType.SHARE_ISSUANCE]:
          event?.type === EventTypeBackend.SHARE_ISSUANCE
            ? {
                issuanceItems: event.items.map(({ sharesCount, shareClass, stakeholder, id }) => ({
                  shareClass,
                  stakeholder,
                  shares: sharesCount,
                  id,
                })),
              }
            : undefined,
      },
      stepTwo: {
        additionalNotes: event?.additionalNotes || '',
        files: files,
      },
    });
  }, [event, reset]);

  const handleCloseModal = useCallback(() => {
    handleClose();
    invalidateQuery();
    reset();
  }, [handleClose, invalidateQuery, reset]);

  const handleUpdateData = useCallback(
    (data: FormSchema) => {
      const { initialStep, stepOne, optionalStep, stepTwo } = data;

      function mapFormTypeToBackendType(formType: EventFormType): EventTypeBackend {
        return eventTypeMapping[formType];
      }

      const type = mapFormTypeToBackendType(initialStep.type);

      const grant = initialStep.type === EventFormType.GRANT &&
        stepOne?.eventDetails?.grant?.grantItems && {
          id: initialStep.id,
          items: stepOne.eventDetails.grant.grantItems.map(
            ({ grantDate, sharePlan, stakeholder, shares }) => ({
              date: toBackendDateFormat(grantDate),
              planId: sharePlan.id,
              stakeholderId: stakeholder.id,
              numbersOfGrants: shares,
            }),
          ),
        };

      const fundraisingRound = initialStep.type === EventFormType.FUNDRAISING_ROUND &&
        stepOne.eventDetails?.['fundraising-round']?.enabled &&
        optionalStep?.['fundraising-round']?.investmentItems && {
          id: initialStep.id,
          valuationId: stepOne.eventDetails['fundraising-round'].event.id,
          sharePrice: stepOne.eventDetails['fundraising-round'].event.sharePrice,
          isOpen: stepOne.eventDetails['fundraising-round'].isOpen,
          items: optionalStep?.['fundraising-round'].investmentItems.map(
            ({ investment, investmentDate, shareClass, stakeholder }) => ({
              stakeholderId: stakeholder.id,
              shareClassId: shareClass.id,
              investedValue: investment,
              date: toBackendDateFormat(investmentDate),
            }),
          ),
        };

      const issuance = initialStep.type === EventFormType.SHARE_ISSUANCE &&
        stepOne.eventDetails?.['share-issuance']?.enabled &&
        optionalStep?.['share-issuance']?.issuanceItems && {
          id: initialStep.id,
          valuationId: stepOne.eventDetails['share-issuance'].event.id,
          date: toBackendDateFormat(stepOne.eventDetails['share-issuance'].date),
          sharePrice: stepOne.eventDetails['share-issuance'].event.sharePrice,
          items: optionalStep?.['share-issuance']?.issuanceItems.map(
            ({ shares, shareClass, stakeholder }) => ({
              stakeholderId: stakeholder.id,
              shareClassId: shareClass.id,
              sharesCount: shares,
            }),
          ),
        };

      const buyback = initialStep.type === EventFormType.BUYBACK &&
        stepOne?.eventDetails?.buyback?.buyBackItems && {
          id: initialStep.id,
          items: stepOne?.eventDetails.buyback?.buyBackItems.map(
            ({ date, shareClass, stakeholder, shares }) => ({
              date: toBackendDateFormat(date),
              stakeholderId: stakeholder.id,
              shareClassId: shareClass.id,
              sharesCount: shares,
            }),
          ),
        };

      const secondaries = initialStep.type === EventFormType.SECONDARIES &&
        stepOne?.eventDetails?.secondaries?.secondariesItems && {
          id: initialStep.id,
          items: stepOne?.eventDetails?.secondaries?.secondariesItems.map(
            ({ date, shareClass, shares, shareValue, stakeholderFrom, stakeholderTo }) => ({
              date: toBackendDateFormat(date),
              fromStakeholderId: stakeholderFrom.id,
              toStakeholderId: stakeholderTo.id,
              shareClassId: shareClass.id,
              sharesCount: shares,
              sharesValue: shareValue,
            }),
          ),
        };

      const conversion = initialStep.type === EventFormType.CONVERSION &&
        stepOne?.eventDetails?.conversion?.classConversionItems && {
          id: initialStep.id,
          items: stepOne?.eventDetails?.conversion?.classConversionItems.map(
            ({ date, shares, shareClassFrom, shareClassTo, stakeholder }) => ({
              date: toBackendDateFormat(date),
              stakeholderId: stakeholder.id,
              fromShareClassId: shareClassFrom.id,
              toShareClassId: shareClassTo.id,
              sharesCount: shares,
            }),
          ),
        };

      const valuation = initialStep.type === EventFormType.VALUATION &&
        stepOne.eventDetails.valuation?.enabled && {
          id: initialStep.id,
          name: stepOne?.eventDetails.valuation?.name,
          date: toBackendDateFormat(stepOne?.eventDetails?.valuation?.date),
          sharePrice: stepOne?.eventDetails.valuation?.sharePrice,
        };

      update(
        {
          companyId,
          eventId,
          data: {
            type: type,
            grant: grant || undefined,
            fundraisingRound: fundraisingRound || undefined,
            issuance: issuance || undefined,
            buyback: buyback || undefined,
            secondaries: secondaries || undefined,
            conversion: conversion || undefined,
            valuation: valuation || undefined,
            filesLinks:
              stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
            additionalNotes: stepTwo?.additionalNotes || '',
          },
        },
        {
          onSuccess: () => {
            toast.success('Event successfully updated');
            invalidateQuery();
            handleCloseModal();
          },
        },
      );
    },
    [companyId, eventId, handleCloseModal, invalidateQuery, update],
  );

  const {
    toggler: confirmationModalToggler,
    isOpen: isOpenConfirmationModal,
    handleOpenModal: handleOpenConfirmationModal,
    handleCloseModal: handleCloseConfirmationModal,
    handleSuccessModal: handleSuccessConfirmationModal,
  } = useModalState({
    onSuccess: () => {
      handleSubmit(handleUpdateData)();
      handleCloseModal();
    },
  });

  if (!event) return;

  const eventName =
    event.type === EventTypeBackend.VALUATION
      ? event.name
      : event.type === EventTypeBackend.FUNDRAISING_ROUND ||
          event.type === EventTypeBackend.SHARE_ISSUANCE
        ? event.valuation.name
        : EventTypeButtonTitle[initialStep?.type];

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <AlertDialogWrapper
          control={{ onOpenChange: confirmationModalToggler, open: isOpenConfirmationModal }}
        >
          <ConfirmUpdateEventModal
            eventName={eventName}
            onClose={handleCloseConfirmationModal}
            onSuccess={handleSuccessConfirmationModal}
          />
        </AlertDialogWrapper>
        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="truncate text-xl font-[550] text-gray-700">
              {previewMode
                ? `View ${EventTypeButtonTitle[initialStep?.type]}`
                : `Edit ${EventTypeButtonTitle[initialStep?.type]}`}
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <div className="flex h-full flex-col gap-4 overflow-hidden">
            {formSteps[step - 1]({
              companyId,
              issuedShares,
              setError,
              formData: getValues,
              nextFormStep: handleOpenConfirmationModal,
              prevFormStep: () => {},
              setFormData: setValue,
              control,
              handleCloseModal,
              filedState: getFieldState,
              errors,
              clearErrors,
              lockMode: true,
              previewMode,
              onEditClick: (step) => onEditClick({ id: eventId, step }),
              transactionId,
            })}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
