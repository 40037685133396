import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { TableRow } from './Row';

export type TableRowData<
  T extends string,
  AdditionalCallbackFields extends object = object,
> = Record<
  T,
  | string
  | number
  | ((id: string, additionalFields: AdditionalCallbackFields) => ReactNode)
  | ReactNode
  | null
> & {
  id: string;
} & AdditionalCallbackFields;

export type TableHeaderRowData<T extends string> = Record<T, string | number | ReactNode>;

export type TableProps<T extends string, AdditionalCallbackFields extends object> = {
  columnsTitle: TableHeaderRowData<T> | Partial<TableHeaderRowData<T>>;
  className?: string;
  tHeadClassNames?: {
    className?: string;
    trClassName?: string;
    thClassName?: string;
    spanClassName?: string;
  };

  columns: T[];

  additionalCellContent?: RequireAtLeastOne<Record<T, (id: string) => ReactNode>>;
} & Optional<
  {
    rows: TableRowData<T, AdditionalCallbackFields>[];
    tBodyClassNames?: {
      className?: string;
      tdClassName?: string;
      trClassName?: string;
      spanClassName?: string;
    };
  },
  { children: ReactNode }
>;

export const Table = <TColumn extends string, AdditionalCallbackFields extends object = object>({
  columns,
  columnsTitle,
  rows = [],
  className,
  tBodyClassNames,
  tHeadClassNames,
  additionalCellContent,
  children,
}: TableProps<TColumn, AdditionalCallbackFields>) => {
  return (
    <table
      className={twMerge('h-fit w-full shrink-0 overflow-hidden text-nowrap rounded-md', className)}
    >
      <thead
        className={twMerge(
          'h-11 rounded-t-md border-b border-[#F2F2F2] bg-[#F9FAFB]',
          tHeadClassNames?.className,
        )}
      >
        <tr className={tHeadClassNames?.trClassName}>
          {columns.map((column) => (
            <th
              className={twMerge('relative pl-2 pr-8 text-left', tHeadClassNames?.thClassName)}
              key={column}
            >
              <span
                className={twMerge(
                  'text-label-md font-medium text-[#172335]',
                  tHeadClassNames?.spanClassName,
                )}
              >
                {columnsTitle[column]}
              </span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody
        className={twMerge(
          'divide-y divide-gray-100 border-b border-[#F2F2F2]',
          tBodyClassNames?.className,
        )}
      >
        {children
          ? children
          : rows.map((row, i) => (
              <TableRow
                additionalCellContent={additionalCellContent}
                columns={columns}
                fields={row}
                key={`${row.id}_${i}`}
                spanClassName={tBodyClassNames?.spanClassName}
                tdClassName={tBodyClassNames?.tdClassName}
                trClassName={tBodyClassNames?.trClassName}
              />
            ))}
      </tbody>
    </table>
  );
};
