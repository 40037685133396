import { useCallback } from 'react';

import { ActionCategory, PossibleAction } from '../../types/google-analytics-types';
import { createGAEvent } from '../../utils/createGoogleAnalyticEvent';

export const useClick = (action: PossibleAction) => {
  return useCallback(
    (label?: string) =>
      createGAEvent({
        category: ActionCategory.USER_ACTIONS,
        action,
        label,
      }),
    [action],
  );
};
