import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { ChevronDownIcon, HelpIcon, SearchIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../../components/Combobox';
import { FormInput } from '../../../../components/Input';
import { Company, useReactForm } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  incorporatedInTitle,
  incorporatedInTitleMap,
  unitedArabEmiratesIso2,
} from '../../../../types/companyTypes';
import { filterByAllFields } from '../../../../utils/filterByAllFields';
import { ChildrenFormProps } from '../CompanySetupForm';
import { FormSchema, formSchema } from './Validation';

const CompanySetupStepThree: FC<ChildrenFormProps> = ({
  formData,
  setFormData,
  prevFormStep,
  nextFormStep,
  companyId,
}) => {
  const [inputValue, setInputValue] = useState('');
  const ref = useRef<HTMLInputElement>(null);
  const { stepThree, stepTwo } = useMemo(() => formData(), [formData]);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useReactForm({
    schema: formSchema,
    defaultValues: stepThree,
  });
  const { patch, isPending } = Company.useUpdate();
  const { incorporatedIn } = useWatch<FormSchema>({ control });

  const isSelectedAE = stepTwo.country.iso2Code.toLowerCase() === unitedArabEmiratesIso2;
  const isIncorporatedValid = isSelectedAE ? !!incorporatedIn : true;

  const submitHandler = useCallback(
    async (data: FormSchema) => {
      patch(
        {
          companyId,
          data,
        },

        {
          onSuccess: () => {
            setFormData('stepThree', data);
            nextFormStep();
          },
        },
      );
    },
    [companyId, nextFormStep, patch, setFormData],
  );

  return (
    <form
      className="flex w-full min-w-[330px] max-w-[400px] flex-col gap-8 lg:pb-4"
      onSubmit={handleSubmit(submitHandler)}
    >
      <div className="flex flex-col gap-2 text-center">
        <span
          className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
          style={{
            background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
          }}
        >
          <AppFormattedMessage id={StringKey.ADD_NEW_COMPANY} />
        </span>
        <span className="text-sm font-normal text-gray-500">
          <AppFormattedMessage id={StringKey.COMPANY_REGISTRATION_DETAILS} />
        </span>
      </div>

      {isSelectedAE && (
        <FormCombobox className="relative" control={control} name={'incorporatedIn'}>
          <div className="relative">
            <FormComboboxInput
              className={'absolute z-0 bg-gray-900'}
              control={control}
              customValue={(value) => (value ? incorporatedInTitle[value] : '')}
              icon={<ChevronDownIcon className="mt-3" />}
              name={'incorporatedIn'}
              placeholder="Incorporated In"
              readOnly
            />
            <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
          </div>
          <ComboboxOptions className="w-full">
            <div className="relative flex items-center" onClick={() => ref.current?.focus()}>
              <SearchIcon className="absolute ml-[6px]" />
              <input
                className="w-full rounded border-[2px] border-transparent bg-gray-50 p-[6px] pl-8 text-gray-700 outline-none placeholder:text-gray-400 focus:border-brand-700 focus:bg-white"
                onChange={({ target: { value } }) => setInputValue(value)}
                ref={ref}
                value={inputValue}
              />
            </div>
            {filterByAllFields(incorporatedInTitleMap, inputValue).map(
              ([incorporatedInKey, incorporatedIn]) => (
                <FormComboboxOption
                  control={control}
                  key={incorporatedInKey}
                  name="incorporatedIn"
                  value={incorporatedInKey}
                >
                  <span className="text-sm font-normal text-gray-700">{incorporatedIn}</span>
                </FormComboboxOption>
              ),
            )}
          </ComboboxOptions>
        </FormCombobox>
      )}

      <FormInput
        control={control}
        name="companyId"
        placeholder={<AppFormattedMessage id={StringKey.COMPANY_ID_NUMBER_OPTIONAL} />}
      />
      <div className="flex flex-col gap-3 rounded bg-brand-25 p-4 text-xs font-normal text-gray-700">
        <HelpIcon />
        <span>
          The Company Identifier Number is a unique number assigned to your company by your
          government or tax authority. This number is used for official purposes such as invoicing,
          tax reporting, and other legal matters. <br />
          <br />
          Examples include: <br />
          - United States: Employer Identification Number (EIN)
          <br />- France: Système d'Identification du Répertoire des Entreprises (SIREN)
          <br /> - United Arab Emirates: Tax Registration Number (TRN)
          <br />
          <br />
          Please enter your company's identifier number as registered with the appropriate
          authorities. If you're unsure of what this number is, it may be found on your company’s
          registration or tax documents.
        </span>
      </div>
      <div className="flex gap-4">
        <Button
          className="border-[1px] border-gray-300 text-sm text-gray-700 hover:bg-gray-50 max-lg:hidden"
          onClick={prevFormStep}
          styleType="DEFAULT_ROUNDED"
        >
          <AppFormattedMessage id={StringKey.BACK} />
        </Button>
        <Button disabled={!isValid || !isIncorporatedValid} isLoading={isPending} type="submit">
          <AppFormattedMessage id={StringKey.CONTINUE_TO_PLANS} />
        </Button>
      </div>
    </form>
  );
};

export default CompanySetupStepThree;
