import React, { FC } from 'react';

import { Stakeholder } from '../../../../../types/stakeholderTypes';
import { StickyStakeholderItem } from './StickyStakeholderItem';

type StickyStakeholderTableProps = {
  poolsByStakeholders: Stakeholder[] | undefined;
  selectedPool: string[];
  selectedPlan: string[];
  setSelectedPool: (state: string) => void;
  setSelectedPlan: (state: string) => void;
};

export const StickyStakeholderTable: FC<StickyStakeholderTableProps> = ({
  poolsByStakeholders,
  selectedPlan,
  selectedPool,
  setSelectedPlan,
  setSelectedPool,
}) => {
  return (
    <table className="h-fit transition-all">
      <thead>
        <tr className="flex border-y-[1px] border-[#F2F2F2] bg-gray-50">
          <td className="relative">
            <div className="flex h-full w-[48px] items-center justify-center"> </div>
          </td>
          <td className="px-4 py-3">
            <div className="flex items-center">
              <span className="min-w-[210px] max-w-[320px] text-label-md font-[450] text-[#172335]">
                Stakeholder
              </span>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        {poolsByStakeholders && (
          <>
            {poolsByStakeholders.map((stakeholder) => (
              <StickyStakeholderItem
                isSelected={selectedPool.includes(stakeholder.id)}
                key={stakeholder.id}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                setSelectedPool={setSelectedPool}
                stakeholder={stakeholder}
              />
            ))}
          </>
        )}
      </tbody>
    </table>
  );
};
