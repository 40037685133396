import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';

import { ArrowIcon, CloseIcon } from '../../../assets/icons';
import Button from '../../../components/Button';
import { DeprecatedModalWrapper } from '../../../components/Modal';
import { QueryKey } from '../../../constants';
import { useHeaderContext } from '../../../context/headerContext';
import { useModalState, useReactForm } from '../../../hooks';
import { Company } from '../../../types/companyTypes';
import { getS3FileOriginalName } from '../../../utils/getS3FileOriginalName';
import BillingStep from './BillingStep';
import ExitModal from './ExitModal';
import FinalStep from './FinalStep';
import PlanCatalog from './PlanCatalog';
import CompanySetupStepOne from './StepOne';
import CompanySetupStepThree from './StepThree';
import CompanySetupStepTwo from './StepTwo';
import {
  CompanySetupFormSchema,
  companySetupFormSchema,
  CompanySetupFormStepFourSchema,
  CompanySetupFormStepOneSchema,
  companySetupFormStepOneSchema,
  CompanySetupFormStepThreeSchema,
  companySetupFormStepThreeSchema,
  CompanySetupFormStepTwoSchema,
  companySetupFormStepTwoSchema,
} from './Validations';

export type ChildrenFormProps = {
  formData: UseFormGetValues<CompanySetupFormSchema>;
  setFormData: UseFormSetValue<CompanySetupFormSchema>;
  setShouldUnblock: React.Dispatch<React.SetStateAction<boolean>>;
  nextFormStep: () => void;
  prevFormStep: () => void;
  companyId: string;
};

const formSteps: ((props: ChildrenFormProps) => JSX.Element)[] = [
  (props) => <CompanySetupStepOne {...props} />,
  (props) => <CompanySetupStepTwo {...props} />,
  (props) => <CompanySetupStepThree {...props} />,
  (props) => <PlanCatalog {...props} />,
  (props) => <BillingStep {...props} />,
];

type CompanySetupFormProps = {
  initialData: Company;
};

export const CompanySetupForm: FC<CompanySetupFormProps> = ({ initialData }) => {
  const [formStep, setFormStep] = useState(1);
  const [isShownFinalPage, setShownFinalPage] = useState(false);
  const [shouldUnblock, setShouldUnblock] = useState(false);
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !isShownFinalPage && !shouldUnblock && currentLocation.pathname !== nextLocation.pathname,
  );
  const { hideCompanySelect, showCompanySelect } = useHeaderContext();

  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { handleSuccessModal, handleDismissModal } = useModalState({
    onDismiss: () => blocker?.reset?.(),
    onSuccess: () => {
      showCompanySelect();
      if (blocker.state !== 'blocked') return;

      queryClient.invalidateQueries({
        queryKey: [QueryKey.GET_COMPANY, id],
      });
      blocker?.proceed?.();
    },
  });

  const stepOne: Partial<CompanySetupFormStepOneSchema> = useMemo(
    () => ({
      name: initialData.name,
      annualRevenue: initialData.annualRevenue,
      type: initialData.type,
      industry: initialData.industry,
      numberOfEmployees: initialData.numberOfEmployees,
      url: initialData.url || '',
    }),
    [
      initialData.annualRevenue,
      initialData.industry,
      initialData.name,
      initialData.numberOfEmployees,
      initialData.type,
      initialData.url,
    ],
  );

  const stepTwo: Partial<CompanySetupFormStepTwoSchema> = useMemo(
    () => ({
      address: initialData.address,
      city: initialData.city,
      country: {
        iso2Code: initialData.country?.iso2 || '',
        name: initialData.country?.name || '',
      },
      currency: {
        iso3Code: initialData.currency?.iso3 || '',
        countryIso2Code: initialData.currencyCountryIso2Code || '',
        name: initialData.currency?.name || '',
      },
      state: {
        iso2Code: initialData.state?.iso2 || '',
        name: initialData.state?.name || '',
      },
      zipCode: initialData.zipCode,
      logoImgSrc: {
        size: 10,
        type: 'image/png',
        name: getS3FileOriginalName(initialData.logoImgSrc) || '',
        link: initialData.logoImgSrc || '',
      },
    }),
    [
      initialData.address,
      initialData.city,
      initialData.country?.iso2,
      initialData.country?.name,
      initialData.currency?.iso3,
      initialData.currency?.name,
      initialData.currencyCountryIso2Code,
      initialData.logoImgSrc,
      initialData.state?.iso2,
      initialData.state?.name,
      initialData.zipCode,
    ],
  );

  const stepThree: Partial<CompanySetupFormStepThreeSchema> = useMemo(
    () => ({
      companyId: initialData.companyId || '',
      incorporatedIn: initialData.incorporatedIn,
    }),
    [initialData.companyId, initialData.incorporatedIn],
  );

  const stepFour: Partial<CompanySetupFormStepFourSchema> = useMemo(
    () => ({
      duration: initialData.plan?.duration,
      id: initialData.plan?.id,
      localCurrency: initialData.plan?.localCurrency || '',
      localPrice: initialData.plan?.localPrice || 0,
      priceInCents: initialData.plan?.priceInCents,
      stakeholdersCount: initialData.plan?.stakeholdersCount,
      stripePriceId: initialData.plan?.stripePriceId,
      title: initialData.plan?.title,
      type: initialData.plan?.type,
    }),
    [
      initialData.plan?.duration,
      initialData.plan?.id,
      initialData.plan?.localCurrency,
      initialData.plan?.localPrice,
      initialData.plan?.priceInCents,
      initialData.plan?.stakeholdersCount,
      initialData.plan?.stripePriceId,
      initialData.plan?.title,
      initialData.plan?.type,
    ],
  );

  const { setValue, getValues, handleSubmit, reset } = useReactForm({
    schema: companySetupFormSchema,
    defaultValues: {
      stepOne,
      stepTwo,
      stepThree,
      stepFour,
    },
  });

  useEffect(() => {
    hideCompanySelect();

    const { success: successStepOne } = companySetupFormStepOneSchema.safeParse(stepOne);
    const { success: successStepTwo } = companySetupFormStepTwoSchema.safeParse(stepTwo);

    const { success: successStepThree } = companySetupFormStepThreeSchema.safeParse(stepThree);

    if (successStepOne) {
      setValue('stepOne', stepOne as Required<CompanySetupFormStepOneSchema>);
      setFormStep(2);
    }

    if (successStepOne && successStepTwo) {
      setValue('stepTwo', stepTwo as Required<CompanySetupFormStepTwoSchema>);
      setFormStep(3);
    }

    if (successStepOne && successStepTwo && successStepThree) {
      setValue('stepThree', stepThree as Required<CompanySetupFormStepThreeSchema>);
      setFormStep(4);
    }

    if (successStepOne && successStepTwo && successStepThree && initialData.stripeSubscriptionId) {
      setValue('stepFour', stepFour as Required<CompanySetupFormStepFourSchema>);
      setFormStep(5);
    }
  }, [hideCompanySelect, initialData, reset, setValue, stepFour, stepOne, stepThree, stepTwo]);

  const submitHandler = useCallback(async () => {
    setShownFinalPage(true);
  }, []);

  const nextStep = useCallback(() => {
    const nextStep = formStep + 1;
    if (nextStep === Object.keys(formSteps).length + 1) {
      handleSubmit(submitHandler)();
      return;
    }
    if (nextStep > Object.keys(formSteps).length) return;

    setFormStep(nextStep);
  }, [formStep, handleSubmit, submitHandler]);

  const prevStep = useCallback(() => {
    const prevStep = formStep - 1;
    if (prevStep < 0) return;
    setFormStep(prevStep);
  }, [formStep]);

  const progress = (formStep / formSteps.length) * 100;
  return (
    <>
      <DeprecatedModalWrapper isOpen={blocker.state === 'blocked'}>
        <ExitModal onClose={handleDismissModal} onSuccess={handleSuccessModal} />
      </DeprecatedModalWrapper>
      {isShownFinalPage ? (
        <FinalStep companyId={id || ''} />
      ) : (
        <div className="relative flex h-full w-full items-center justify-center">
          <div className="flex h-full w-full flex-col items-center gap-8 max-lg:gap-6">
            <div className="-mb-2 flex w-full max-w-[400px] items-center justify-between gap-6">
              <Button
                className="size-9 shrink-0 rounded-full shadow-md transition-colors hover:bg-gray-50 lg:hidden"
                onClick={prevStep}
                style={{ display: formStep === 1 ? 'none' : '' }}
                styleType="NONE"
              >
                <ArrowIcon />
              </Button>
              <div className="flex w-full items-center gap-4">
                <div className="relative flex h-1 w-full overflow-hidden rounded bg-gray-100">
                  <div
                    className="absolute h-full bg-gradient-to-r from-forest-500 to-forest-300 transition-all duration-700"
                    style={{ width: `${progress}%` }}
                  />
                </div>
                <span className="text-label-md text-forest-500">
                  {formStep}/{formSteps.length}
                </span>
              </div>
              <Button
                className="size-9 shrink-0 rounded-full shadow-md transition-colors hover:bg-gray-50 lg:absolute lg:right-6 lg:top-6"
                onClick={() => navigate('/my-companies')}
                styleType="NONE"
              >
                <CloseIcon />
              </Button>
            </div>

            {formSteps[formStep - 1]({
              formData: getValues,
              setFormData: setValue,
              nextFormStep: nextStep,
              prevFormStep: prevStep,
              setShouldUnblock,
              companyId: id ?? '',
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default CompanySetupForm;
