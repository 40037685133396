import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { StringKey } from '../../../../lang';
import { Pool } from '../../../../types/pool-plans.types';

type PoolCardProps = {
  pool: Pool;
  navigationLink: string;
};

export const PoolCard: FC<PoolCardProps> = ({ pool, navigationLink }) => {
  const navigate = useNavigate();
  const poolDilutedSharesCount =
    pool?.dillutedSharesCount && Number(pool.dillutedSharesCount)?.toLocaleString('en-US');
  const available = (pool.dillutedSharesCount - pool.granted)?.toLocaleString('en-US');
  const granted = pool.granted?.toLocaleString('en-US');
  const totalGrantVested = pool.totalGrantVested?.toLocaleString('en-US');
  const sharesVested = pool.sharesVested?.toLocaleString('en-US');
  const exercised = pool.exercised?.toLocaleString('en-US');
  const exercisable = pool.exercisable?.toLocaleString('en-US');

  return (
    <div className="mb-4 flex h-fit w-full min-w-[300px] max-w-[440px] flex-col divide-y-[1px] divide-gray-100 rounded-md shadow-sm">
      <div className="flex items-center justify-between p-3">
        <div className="flex max-w-[calc(100%-90px)] flex-col">
          <span className="truncate text-base font-[550] text-gray-700">{pool?.name}</span>
          <span className="truncate text-xs font-[450] text-gray-400">
            {pool?.shareClass?.name}
          </span>
        </div>
        {pool.isTerminated && (
          <span className="rounded-2xl bg-fireside-50 px-2 py-[2px] text-label-sm font-[450] text-fireside-600">
            <AppFormattedMessage id={StringKey.TERMINATED} />
          </span>
        )}
      </div>
      <div className="flex flex-wrap divide-x-[1px] divide-y-[1px] divide-[#F2F4F7]">
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Diluted shares
          </span>
          <span className="whitespace-nowrap text-sm font-[450] text-[#172335]">
            {poolDilutedSharesCount}
          </span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Available pool
          </span>
          <span className="whitespace-nowrap text-sm text-[#172335]">{available}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Pool granted
          </span>
          <span className="whitespace-nowrap text-sm text-[#172335]">{granted}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Total grant vested
          </span>
          <span className="text-sm text-[#172335]">{totalGrantVested}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Shares vested
          </span>
          <span className="text-sm text-[#172335]">{sharesVested}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Exercisable
          </span>
          <span className="text-sm text-[#172335]">{exercisable}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Exercised
          </span>
          <span className="text-sm text-[#172335]">{exercised}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Cash settled
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Returned to pool
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">Expired</span>
          <span className="text-sm text-gray-300">-</span>
        </div>
      </div>
      <Button
        className="flex w-full items-center justify-between bg-brand-25 p-3 text-brand-700"
        onClick={() => navigationLink && navigate(navigationLink)}
        styleType="DEFAULT_ROUNDED"
      >
        <span className="text-sm">View {pool.sharePlansCount} associated plan(s)</span>
        <ArrowIcon className="rotate-180 transform" iconColor="#2565C8" />
      </Button>
    </div>
  );
};
