import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { BillingPlanCard } from '../../../../components/BillingPlanCard';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import { ReactPortal } from '../../../../components/Modal/ReactPortal';
import { QueryKey } from '../../../../constants';
import {
  AnalyticHooks,
  Company,
  Plan as PlanHook,
  useLocale,
  useModalState,
} from '../../../../hooks';
import { Locale, StringKey } from '../../../../lang';
import {
  enterpriseBenefits,
  growthBenefits,
  startUpBenefits,
} from '../../../../translations/planTranslation';
import { PossibleAction } from '../../../../types/google-analytics-types';
import { Plan, SubscriptionDuration, SubscriptionType } from '../../../../types/planTypes';
import { ChildrenFormProps } from '../CompanySetupForm';
import { EnterpriseModal } from '../EnterpriseModal';

export type PlanCatalogProps = ChildrenFormProps;

const PlanCatalog: FC<PlanCatalogProps> = ({
  prevFormStep,
  nextFormStep,
  companyId,
  setFormData,
  setShouldUnblock,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(SubscriptionDuration.MONTHLY);
  const { messagesLocale, locale } = useLocale();
  const queryClient = useQueryClient();

  const companyPlanChangeTracker = AnalyticHooks.useClick(PossibleAction.COMPANY_PLAN_CHANGE);
  const { patch, isPending } = Company.useUpdate();

  const { plans, isLoading } = PlanHook.usePlans();

  const {
    isOpen: isOpenEnterpriseModal,
    handleOpenModal: handleOpenEnterpriseModal,
    handleCloseModal: handleCloseEnterpriseModal,
  } = useModalState();

  const handleSelectPlan = useCallback(
    (plan: Plan) => {
      setFormData('stepFour', plan);
      patch(
        { companyId, data: { planId: plan.id } },
        {
          onSuccess: () => {
            companyPlanChangeTracker(plan.title);
            nextFormStep();
            queryClient.invalidateQueries({ queryKey: [QueryKey.GET_COMPANY] });
          },
        },
      );
    },
    [companyId, companyPlanChangeTracker, nextFormStep, patch, queryClient, setFormData],
  );

  const sortedData = useMemo(
    () =>
      plans?.reduce<Record<SubscriptionDuration, Plan[]>>(
        (acc, plan) => {
          const planDuration = plan.duration;
          if (!acc[planDuration]) {
            acc[planDuration] = [];
          }
          acc[planDuration].push(plan);
          return acc;
        },
        {} as Record<SubscriptionDuration, Plan[]>,
      ),
    [plans],
  );

  if (!sortedData || isLoading || isPending)
    return (
      <ReactPortal initialPortalId="modal">
        <Loader className="left-0 top-0 h-screen w-screen pl-10 pt-10 max-lg:pl-16 max-lg:pt-36 lg:absolute lg:z-[999999999]" />
      </ReactPortal>
    );
  return (
    <>
      <EnterpriseModal
        companyId={companyId}
        handleClose={handleCloseEnterpriseModal}
        isOpenModal={isOpenEnterpriseModal}
        setShouldUnblock={setShouldUnblock}
      />
      <div className="flex h-fit w-full max-w-[1000px] flex-col items-center justify-center lg:pb-4">
        <Button
          className="absolute left-6 top-6 border-[1px] border-gray-300 px-6 py-[10px] text-sm font-[450] text-gray-700 max-lg:hidden"
          onClick={prevFormStep}
          styleType="DEFAULT_ROUNDED"
        >
          <AppFormattedMessage id={StringKey.BACK} />
        </Button>
        <div className="flex flex-col gap-2 text-center">
          <span
            className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
            style={{
              background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
            }}
          >
            <AppFormattedMessage id={StringKey.PLAN_FOR_ALL_SIZES} />
          </span>
          <span className="text-sm font-[450] text-gray-500">
            <AppFormattedMessage id={StringKey.EFFICIENTLY_HANDLE_MANAGEMENT} />
          </span>
        </div>
        <div className="mt-10 flex h-full w-full flex-col gap-10">
          <div className="m-auto flex h-11 w-fit overflow-hidden truncate rounded-full border-2 border-brand-700 p-[2px] lg:w-fit">
            <Button
              className={twMerge(
                'w-fit gap-1 text-nowrap rounded-full bg-white p-2 text-sm font-[550] text-brand-900',
                selectedPlan === 'monthly' && 'bg-brand-700 text-white',
              )}
              onClick={() => setSelectedPlan(SubscriptionDuration.MONTHLY)}
              styleType="NONE"
            >
              <AppFormattedMessage id={StringKey.PAY_MONTHLY} />
            </Button>
            <Button
              className={twMerge(
                'w-fit shrink-0 gap-1 overflow-hidden truncate rounded-full bg-white p-2 text-sm font-[550] text-brand-900',
                selectedPlan === 'annualy' && 'bg-brand-700 text-white',
              )}
              onClick={() => setSelectedPlan(SubscriptionDuration.ANNUALY)}
              styleType="NONE"
            >
              <AppFormattedMessage id={StringKey.PAY_YEARLY} />
              <div
                className={twMerge(
                  'text-sm font-[550] text-forest-600',
                  selectedPlan === 'annualy' && 'text-white',
                  locale === Locale.FR && 'max-lg:hidden',
                )}
              >
                <AppFormattedMessage id={StringKey.SAVE_APPROX} />
              </div>
            </Button>
          </div>
          <div className="flex h-fit w-full flex-wrap justify-center gap-4">
            {sortedData[selectedPlan]
              .sort((a, b) => a.stakeholdersCount - b.stakeholdersCount)
              .map((plan, i) => (
                <BillingPlanCard
                  key={plan.id}
                  {...plan}
                  includes={i === 0 ? '' : 'Everything in Startup plan +'}
                  listOfBenefits={i === 0 ? startUpBenefits : growthBenefits}
                  onSelectClick={() => handleSelectPlan(plan)}
                />
              ))}
            <BillingPlanCard
              cardMode="SPECIAL"
              cardStyleType="SPECIAL"
              description={<AppFormattedMessage id={StringKey.FOR_CUSTOM_PRICING} />}
              includes="Everything in Growth plan +"
              listOfBenefits={enterpriseBenefits}
              onSpecialSelectClick={handleOpenEnterpriseModal}
              selectButtonStyleType="SPECIAL"
              selectButtonText={messagesLocale[StringKey.SPACIAL_PLAN_TEXT]}
              specialMessage={messagesLocale[StringKey.LETS_TALK]}
              title={<AppFormattedMessage id={StringKey.ENTERPRISE_AND_FI} />}
              type={SubscriptionType.ENTERPRISE}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanCatalog;
