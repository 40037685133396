import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { PlusIcon } from '../../../assets/icons';
import Button from '../../../components/Button';
import { AnalyticHooks, Company } from '../../../hooks';
import { PossibleAction } from '../../../types/google-analytics-types';
import noCompaniesImg from './../../../assets/images/no-companies.png';

const EmptyCompanyCard: FC = () => {
  const navigate = useNavigate();
  const companyCreationTracker = AnalyticHooks.useClick(PossibleAction.ADD_COMPANY);
  const { create } = Company.useCreate();

  return (
    <div className="flex w-full items-center justify-center">
      <div className="radial-gradient-border relative flex flex-col gap-6 rounded-2xl px-6 py-8 lg:min-w-[393px]">
        <div className="flex items-center gap-3">
          <div className="size-[96px] object-cover">
            <img alt="" src={noCompaniesImg} />
          </div>
          <div className="flex flex-col gap-[2px]">
            <span className="text-xl font-semibold text-gray-700">Add Company</span>
            <span className="text-xs font-[450px] text-gray-600">
              Manage your cap table effortlessly
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-3">
          <Button
            className="w-fit gap-1 px-4 py-2"
            onClick={() =>
              create(undefined, {
                onSuccess: ({ id }) => {
                  companyCreationTracker();
                  navigate(`/company-setup/${id}`);
                },
              })
            }
          >
            <div>
              <PlusIcon />
            </div>
            <span>Add a new company</span>
          </Button>
          <span className="rounded-2xl bg-brand-50 px-2 py-[2px] text-label-md font-medium text-brand-700">
            From $ 100/month
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmptyCompanyCard;
