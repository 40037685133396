import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';

import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { CloseModalButton } from '../../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { QueryKey } from '../../../../constants';
import {
  AnalyticHooks,
  ShareClass as ShareClassHook,
  useModalState,
  useReactForm,
} from '../../../../hooks';
import { PossibleAction } from '../../../../types/google-analytics-types';
import { DaysPerYearOption, ShareClass } from '../../../../types/share-classes.types';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { EditPreview, StepFour, StepOne, StepThree, StepTwo } from '../Steps';
import { ChildrenFormProps, EditShareClassStepVariant } from '../type';
import { UpdateShareClassModal } from '../UpdateShareClassModal';
import { FormSchema, formSchema } from '../Validation';

type ModalProps = {
  data: ShareClass;
  isOpenModal: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  onEditClick: (props: { id: string; step: EditShareClassStepVariant }) => void;
  step: EditShareClassStepVariant;
  shareClassId: string;
};

const formSteps: ((
  props: { onEditClick: (step: EditShareClassStepVariant) => void } & ChildrenFormProps,
) => JSX.Element)[] = [
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
  (props) => <StepThree {...props} />,
  (props) => <StepFour {...props} />,
  (props) => <EditPreview {...props} />,
];

export const EditShareClassForm: FC<ModalProps> = ({
  companyId,
  isOpenModal,
  handleClose,
  invalidateQuery,
  onEditClick,
  shareClassId,
  step,
  data,
}) => {
  const queryClient = useQueryClient();

  const values: FormSchema = useMemo(() => {
    const participating = data?.liquidityPreferences?.participating
      ? {
          enabled: true,
          capValue: data?.liquidityPreferences?.participating?.capValue,
          interest: data?.liquidityPreferences?.participating?.interest || 0,
          daysPersYear: data?.liquidityPreferences?.participating?.daysPersYear?.toString() || '',
        }
      : undefined;

    const liquidityPreferences = data?.liquidityPreferences
      ? {
          enabled: true,
          multiple: data?.liquidityPreferences?.multiple,
          seniority: data?.liquidityPreferences?.seniority,
          participating,
        }
      : undefined;

    const antiDilutionRights = data?.antiDilutionRights
      ? {
          enabled: true,
          base: data?.antiDilutionRights?.base,
        }
      : undefined;

    const conversionRatio = data.conversionRatioEnabled
      ? {
          enabled: data.conversionRatioEnabled,
          value: data.conversionRatio,
        }
      : undefined;

    const votingRight = data.votingRightEnabled
      ? {
          enabled: data.votingRightEnabled,
          value: data.votingRight,
        }
      : undefined;

    const originalFileName = getS3FileOriginalName(data.docLink);

    const docData = originalFileName
      ? {
          progress: 100,
          doc: {
            name: originalFileName,
            size: 1000,
            type: 'application/pdf',
          },

          docLink: data.docLink || '',
        }
      : undefined;

    return {
      stepOne: {
        creationDate: new Date(data.creationDate),
        name: data.name,
        dividendRightEnabled: data.dividendRightEnabled,
        conversionRatio,
        votingRight,
      },
      stepTwo: {
        liquidityPreferences,
      },
      stepThree: {
        antiDilutionRights,
      },

      stepFour: docData,
    };
  }, [data]);

  const {
    control,
    reset,
    setValue,
    getValues,
    getFieldState,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useReactForm({
    schema: formSchema,
    values,
    mode: 'onChange', // Validate on change
    reValidateMode: 'onChange', // Re-validate on change
  });
  const { update } = ShareClassHook.useUpdate();

  const companyEditShareClassTracker = AnalyticHooks.useClick(PossibleAction.EDIT_SHARE_CLASS);

  const handleCloseModal = useCallback(() => {
    handleClose();
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SHARE_CLASS] });
    reset();
  }, [reset, handleClose, queryClient]);

  const handleUpdateData = useCallback(
    (data: FormSchema) => {
      const { stepOne, stepFour, stepThree, stepTwo } = data;

      const participating = stepTwo?.liquidityPreferences?.participating
        ? stepTwo?.liquidityPreferences?.participating?.daysPersYear
          ? {
              capValue: stepTwo?.liquidityPreferences?.participating?.capValue,
              interest: stepTwo?.liquidityPreferences?.participating?.interest,
              daysPersYear: Number(
                stepTwo?.liquidityPreferences?.participating?.daysPersYear,
              ) as unknown as DaysPerYearOption,
            }
          : {
              capValue: stepTwo?.liquidityPreferences?.participating?.capValue,
            }
        : null;

      const liquidityPreferences = stepTwo?.liquidityPreferences
        ? {
            multiple: stepTwo?.liquidityPreferences?.multiple,
            seniority: stepTwo?.liquidityPreferences?.seniority,
            participating,
          }
        : null;

      const antiDilutionRights = stepThree?.antiDilutionRights
        ? {
            base: stepThree?.antiDilutionRights?.base,
          }
        : null;

      update(
        {
          companyId,
          data: {
            name: stepOne?.name,
            creationDate: new Date(stepOne.creationDate).toISOString(),
            votingRightEnabled: stepOne?.votingRight?.enabled || false,
            votingRight: stepOne?.votingRight?.value || 0,
            conversionRatioEnabled: stepOne?.conversionRatio?.enabled || false,
            conversionRatio: stepOne?.conversionRatio?.value || 0,
            dividendRightEnabled: stepOne?.dividendRightEnabled,
            liquidityPreferences,
            antiDilutionRights,
            docLink: stepFour?.docLink || null,
          },
          shareClassId,
        },
        {
          onSuccess: () => {
            companyEditShareClassTracker();
            toast.success('Share class successfully updated');
            handleCloseModal();
            invalidateQuery();
          },
        },
      );
    },
    [
      companyEditShareClassTracker,
      companyId,
      handleCloseModal,
      invalidateQuery,
      shareClassId,
      update,
    ],
  );

  const {
    toggler: updateModalToggler,
    isOpen: isOpenUpdateModal,
    handleOpenModal: handleOpenUpdateModal,
    handleCloseModal: handleCloseUpdateModal,
    handleSuccessModal: handleSuccessUpdateModal,
  } = useModalState({
    onSuccess: () => {
      handleSubmit(handleUpdateData)();
      handleCloseModal();
    },
  });

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <AlertDialogWrapper control={{ onOpenChange: updateModalToggler, open: isOpenUpdateModal }}>
          <UpdateShareClassModal
            onClose={handleCloseUpdateModal}
            onSuccess={handleSuccessUpdateModal}
          />
        </AlertDialogWrapper>
        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="text-xl font-[550] text-gray-700">Edit Share Class</span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>

          {formSteps[step - 1]({
            companyId,
            setError,
            formData: getValues,
            nextFormStep: handleOpenUpdateModal,
            prevFormStep: () => {},
            setFormData: setValue,
            control,
            handleCloseModal,
            filedState: getFieldState,
            errors,
            lockMode: true,
            clearErrors,
            onEditClick: (step) => onEditClick({ id: shareClassId, step }),
          })}
        </div>
      </SheetContent>
    </Sheet>
  );
};
