import { FC } from 'react';

import { ActivityOverviewIcon, ExportIcon, PlusIcon } from '../../assets/icons';
import { PlusIconVariant } from '../../assets/icons/PlusIcon';
import Button from '../../components/Button';
import { AnalyticHooks, EventHooks, useSelectedCompany } from '../../hooks';
import { PossibleAction } from '../../types/google-analytics-types';

type ActivityOverviewProps = {
  onAddEvent?: () => void;
  companyId: string;
};

export const ActivityOverview: FC<ActivityOverviewProps> = ({ onAddEvent, companyId }) => {
  const downloadEventsFileTracker = AnalyticHooks.useClick(PossibleAction.DOWNLOAD_FILE);
  const { mutateExport } = EventHooks.useExport({ companyId });
  const { selectedCompany } = useSelectedCompany();
  return (
    <div className="flex w-full items-center justify-between pb-4 max-lg:border-b max-lg:border-gray-100 lg:rounded-md lg:p-4 lg:shadow-sm">
      <div className="flex items-center gap-2">
        <ActivityOverviewIcon />
        <span className="text-base font-semibold text-gray-700">Activity Overview</span>
      </div>
      <div className="flex h-9 gap-3 max-lg:hidden">
        <Button
          className="flex h-full w-fit gap-1 rounded border border-gray-100 bg-gray-25 px-3 py-[6px]"
          onClick={() =>
            mutateExport(undefined, {
              onSuccess: () => {
                downloadEventsFileTracker('Events');
              },
            })
          }
          styleType="NONE"
        >
          <ExportIcon className="rotate-180" />
          <span className="text-sm font-[450] text-gray-700">Export</span>
        </Button>
        <Button
          className="flex h-full w-fit gap-1 rounded bg-brand-700 px-4 py-[6px]"
          disabled={selectedCompany?.isDemo}
          onClick={onAddEvent}
        >
          <PlusIcon
            iconColor={selectedCompany?.isDemo ? '#D0D5DD' : '#ffffff'}
            variant={PlusIconVariant.EVENTS}
          />
          Add Event
        </Button>
      </div>
    </div>
  );
};
