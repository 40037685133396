import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { SharePlanType } from '../../types/pool-plans.types';

export const SummaryItem = ({
  title,
  value,
  className,
  titleAdditionalNote,
  id,
  planType,
}: {
  title: string | undefined;
  titleAdditionalNote?: string;
  value:
    | string
    | number
    | ReactNode
    | ((id: string, fields: { planType: SharePlanType }) => ReactNode);
  className?: string;
} & Optional<{ id: string; planType: SharePlanType }>) => (
  <div
    className={twMerge(
      'flex items-center justify-between gap-2 text-nowrap px-4 py-[10px] text-sm font-[450] text-gray-700',
      className,
    )}
  >
    <div className="flex flex-col justify-center">
      <span>{title}</span>
      <span className="text-label-sm font-medium text-gray-500">{titleAdditionalNote}</span>
    </div>
    <span>{typeof value === 'function' ? planType && value(id || '', { planType }) : value}</span>
  </div>
);
