export enum CapTableActivityVariant {
  LATEST_CAP_TABLE = 'latest-cap-table',
  CUSTOM_DATE = 'custom-date',
}

export enum AmountOfFetchedItems {
  FIVE = '5',
  TEN = '10',
  TWENTY = '20',
  FIFTY = '50',
  HUNDRED = '100',
}

export enum GraphVariant {
  VOTING_RIGHTS = 'voting-rights',
  OWNERSHIP = 'ownership',
}

export const numberOfFetchedItemsSelectVariant = Object.values(AmountOfFetchedItems);

export const capTableActivityVariantTitle: Record<CapTableActivityVariant, string> = {
  [CapTableActivityVariant.LATEST_CAP_TABLE]: 'Latest Cap Table',
  [CapTableActivityVariant.CUSTOM_DATE]: 'Custom Date',
};

export const capTableActivityVariantTitleMap = Object.entries(capTableActivityVariantTitle);

export const latestSnapshotId = 'latest-snapshot';
