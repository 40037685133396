import { ReactNode } from 'react';

export enum ApproveHurdleColumn {
  SELECT = 'select',
  STAKEHOLDER = 'stakeholder',
  GRANT = 'grant',
  APPROVAL_DATE = 'approval-date',
  SHARE_CLASS = 'share-class',
  HURDLE = 'hurdle',
  CURRENT_VALUATION = 'current-valuation',
  ISSUED_SHARES = 'issued-shares',
}
export const approveHurdleColumnMap = Object.values(ApproveHurdleColumn);

export const approveHurdleColumnTitle: Record<ApproveHurdleColumn, string | ReactNode> = {
  [ApproveHurdleColumn.SELECT]: '',
  [ApproveHurdleColumn.STAKEHOLDER]: 'Stakeholder',
  [ApproveHurdleColumn.GRANT]: 'Grant',
  [ApproveHurdleColumn.APPROVAL_DATE]: 'Approval Date',
  [ApproveHurdleColumn.SHARE_CLASS]: 'Share Class',
  [ApproveHurdleColumn.HURDLE]: 'Hurdle',
  [ApproveHurdleColumn.CURRENT_VALUATION]: 'Current Valuation',
  [ApproveHurdleColumn.ISSUED_SHARES]: 'Issued Shares',
};
