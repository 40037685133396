import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppQuery } from '../useAppQuery';

export const useVestingStructure = ({
  companyId,
  planId,
  stakeholderId,
  enabled,
}: {
  companyId: string;
  planId: string;
  stakeholderId: string;
  enabled?: boolean;
}) => {
  const queryClient = useQueryClient();

  const { data: vestingStructure, isLoading } = useAppQuery<{
    totalGrantVested: number;
    exercised: number;
    exercisable: number;
    sharesVested: number;
    granted: number;
  }>({
    queryKey: [QueryKey.GET_VESTING_TASKS_STRUCTURE, companyId, planId, stakeholderId],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'stakeholders', stakeholderId, 'plan', planId, 'vesting-structure'],
      }),
    enabled: !!companyId && !!planId && !!stakeholderId && enabled,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_VESTING_TASKS_STRUCTURE] });
  }, [queryClient]);

  return {
    invalidateQuery,
    vestingStructure,
    isLoading,
  };
};
