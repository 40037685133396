import React, { FC, useState } from 'react';

import { ChevronDownIcon } from '../../../assets/icons';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '../../../components/Combobox';
import { useFormat } from '../../../hooks';
import { Snapshot } from '../../../types/snapshot.types';

type EventDateValue = {
  id: string;
  date: Date;
};

const EventDateItemsList: FC<{ snapshots: Snapshot[] }> = ({ snapshots }) => {
  const { format } = useFormat();
  return (
    <>
      {snapshots.map(({ valuation, id }) => (
        <ComboboxOption key={id} value={JSON.stringify({ id: valuation.id, date: valuation.date })}>
          <span className="text-sm text-gray-700">{format(valuation.date, 'dd/MM/yyyy')}</span>
        </ComboboxOption>
      ))}
    </>
  );
};

export type EventDateComboboxProps = {
  onSelect?: (state: EventDateValue) => void;
  defaultData?: EventDateValue;
  snapshots: Snapshot[];
};

export const EventDateCombobox: FC<EventDateComboboxProps> = ({
  defaultData,
  onSelect,
  snapshots,
}) => {
  const [value, setValue] = useState<EventDateValue | null>(defaultData || null);
  const { format } = useFormat();
  return (
    <Combobox
      as={'div'}
      className="relative"
      onChange={(value: string) => {
        const parsedValue = JSON.parse(value) as EventDateValue;
        onSelect?.(parsedValue);
        setValue(parsedValue);
      }}
    >
      <div className="relative">
        <ComboboxInput
          className={'bg-gray-900'}
          icon={<ChevronDownIcon className="mt-2" />}
          onChange={() => ''}
          placeholder="Event date"
          readOnly
          value={value ? format(value.date && value.date, 'dd/MM/yyyy') : ''}
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <EventDateItemsList snapshots={snapshots} />
      </ComboboxOptions>
    </Combobox>
  );
};
