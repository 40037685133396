import { FC, useEffect, useState } from 'react';

import { ActivityOverviewIcon } from '../../assets/icons';
import Loader from '../../components/Loader';
import { Company, SnapshotHooks, useScreenSize } from '../../hooks';
import { Snapshot } from '../../types/snapshot.types';
import { CapTableSelect } from './CapTableSelect';
import { DesktopDetails } from './DesktopDetails';
import { CapTableGraphWrapper } from './Graphs';
import { MobileDetails } from './MobileDetails';
import { OverAllStatus } from './OverAllStatus';
import { GraphVariant } from './variables';

const CapTable: FC = () => {
  const { selectedCompanyId } = Company.useSelected();
  const [customSnapshotDate, setCustomSnapshotDate] = useState<Date | null>(null);
  const [valuationId, setValuationId] = useState('');
  const [selectedGraphVariant, setGraphVariant] = useState(GraphVariant.OWNERSHIP);
  const { width } = useScreenSize();
  const [snapshot, setSnapshot] = useState<Snapshot | undefined>();

  const { snapshots, isLoading } = SnapshotHooks.useSnapshots({ companyId: selectedCompanyId });
  const { snapshot: currentSnapshot } = SnapshotHooks.useCurrent({ companyId: selectedCompanyId });
  const { snapshot: snapshotByDate } = SnapshotHooks.useSnapshotByDate({
    companyId: selectedCompanyId,
    date: customSnapshotDate,
  });

  useEffect(() => {
    if (!valuationId) return setSnapshot(currentSnapshot);

    if (customSnapshotDate) return setSnapshot(snapshotByDate);
    setSnapshot(snapshots.find(({ valuation: { id } }) => id === valuationId));
  }, [currentSnapshot, customSnapshotDate, snapshotByDate, snapshots, valuationId]);

  const toggleGraphVariant = () => {
    setGraphVariant((prevVariant) =>
      prevVariant === GraphVariant.OWNERSHIP ? GraphVariant.VOTING_RIGHTS : GraphVariant.OWNERSHIP,
    );
  };

  const isDesktop = width >= 1344;
  const isTablet = width >= 1024 && width < 1344;

  if (isLoading || !snapshot) return <Loader />;

  return (
    <div className="flex h-fit w-full flex-col gap-4 overflow-hidden p-1">
      <div className="flex items-center justify-between rounded-md p-4 shadow-sm">
        <div className="flex items-center gap-2">
          <ActivityOverviewIcon />
          <span className="text-base font-semibold text-gray-700">Overview</span>
        </div>

        <CapTableSelect
          companyId={selectedCompanyId}
          onSelectCapTable={setValuationId}
          onSelectCustomCapTableDate={setCustomSnapshotDate}
        />
      </div>
      <div className="flex h-max w-full items-stretch gap-4 max-lg:flex-col">
        <OverAllStatus snapshot={snapshot} wrapperClassName={isTablet ? 'w-[40%]' : 'w-[100%]'} />
        <CapTableGraphWrapper
          isDesktop={isDesktop}
          isTablet={isTablet}
          selectedGraphVariant={selectedGraphVariant}
          snapshot={snapshot}
          toggleGraphVariant={toggleGraphVariant}
          wrapperClassName={isTablet ? 'w-[60%]' : 'w-[100%]'}
        />
      </div>
      <DesktopDetails className="max-lg:hidden" companyId={selectedCompanyId} snapshot={snapshot} />
      <MobileDetails className="lg:hidden" snapshot={snapshot} />
    </div>
  );
};

CapTable.displayName = 'CapTable';

export default CapTable;
