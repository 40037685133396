import { useLocation, useNavigate } from 'react-router-dom';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { pageModule, PageRoute, replaceIdWithPath } from '../../types/pageTypes';
import { useAppQuery } from '../useAppQuery';
import { useSelected } from './useSelected';

export const useCheckModuleAccess = (enabled?: boolean) => {
  const navigate = useNavigate();
  const { selectedCompanyId: id } = useSelected();
  const location = useLocation();
  const module = pageModule[replaceIdWithPath(location.pathname)];

  useAppQuery({
    queryKey: [QueryKey.CHECK_MODULE_ACCESS, module],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [id, 'check-permissions', module as string],
      }),
    enabled: !!module && enabled,
    onError: () => navigate(PageRoute.HOME),
  });
};
