import { FC, useMemo, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { twMerge } from 'tailwind-merge';

import { SnapshotHooks, useScreenSize, ValuationHooks } from '../../../hooks';
import { activeColors, fillColors, strokeColors } from '../../CapTable/Graphs';
import { EmptyPositionGraph } from './EmptyPositionGraph';

export type PositionGraphProps = {
  companyId: string;
  stakeholderId: string;
};

export const PositionGraph: FC<PositionGraphProps> = ({ companyId, stakeholderId }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { valuation } = ValuationHooks.useCurrent({ companyId });
  const { snapshots } = SnapshotHooks.useSnapshots({ companyId });
  const { width } = useScreenSize();
  const isDesktop = width > 1120;
  const isMobile = width < 660;

  const valuationId = valuation?.id || '';

  const snapshot = useMemo(
    () => snapshots.find(({ valuation: { id } }) => id === valuationId),
    [snapshots, valuationId],
  );

  const data = useMemo(() => {
    if (!snapshot?.stats?.stakeholders) {
      return [];
    }

    const stakeholders = snapshot.stats.stakeholders;
    const totalIssuedPercentage = stakeholders.reduce(
      (total, { issuedPercentage }) => total + issuedPercentage,
      0,
    );

    const calculateIssuedPercentage = (percentage: number) =>
      parseFloat(((percentage * 100) / (totalIssuedPercentage || 1)).toFixed(2));

    const sortedStakeholders = [...stakeholders].sort(
      (a, b) => b.issuedPercentage - a.issuedPercentage,
    );

    const userIndex = sortedStakeholders.findIndex(({ id }) => id === stakeholderId);
    const userInTop4 = userIndex >= 0 && userIndex < 4;

    const formatStakeholders = (stakeholdersArray: typeof sortedStakeholders) =>
      stakeholdersArray.map(({ id, name, issuedPercentage }) => ({
        id,
        name,
        issuedPercentage: calculateIssuedPercentage(issuedPercentage),
      }));

    const getOthers = (remainingStakeholders: typeof sortedStakeholders) => {
      const othersTotalPercentage = remainingStakeholders.reduce(
        (total, { issuedPercentage }) => total + issuedPercentage,
        0,
      );
      return {
        id: '',
        name: 'Others',
        issuedPercentage: calculateIssuedPercentage(othersTotalPercentage),
      };
    };

    if (userInTop4) {
      const top4 = sortedStakeholders.slice(0, 4);
      const others = sortedStakeholders.slice(4);
      return [...formatStakeholders(top4), getOthers(others)];
    }

    const top3 = sortedStakeholders.slice(0, 3);
    const others = sortedStakeholders.filter((_, index) => index >= 3 && index !== userIndex);
    const userStakeholder = sortedStakeholders[userIndex];

    return [
      ...formatStakeholders(top3),
      {
        id: userStakeholder?.id,
        name: userStakeholder?.name,
        issuedPercentage: calculateIssuedPercentage(userStakeholder?.issuedPercentage),
      },
      getOthers(others),
    ];
  }, [snapshot?.stats?.stakeholders, stakeholderId]);

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <div
      className={twMerge(
        'flex w-[40%] min-w-[396px] flex-grow flex-col gap-3 rounded-lg bg-gray-50 p-1 pt-3',
        isMobile && 'min-w-[100px]',
      )}
    >
      <span className="pl-4 text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
        Your position (Issued) vs Cap Table
      </span>
      <div
        className={twMerge(
          'flex w-full items-center justify-center gap-8 rounded-md bg-white px-7 py-9 shadow-sm',
          isMobile && 'flex-col px-3 py-8',
        )}
      >
        <div className={twMerge('min-w-[180px]', isDesktop ? 'size-[240px]' : 'size-[280px]')}>
          {data.length > 2 ? (
            <ResponsiveContainer height="100%" width="100%">
              <PieChart>
                <Pie
                  cx="50%"
                  cy="50%"
                  data={data}
                  dataKey="issuedPercentage"
                  innerRadius="50%"
                  isAnimationActive={false}
                  outerRadius="100%"
                >
                  {data.map((entry, index) => (
                    <Cell
                      fill={
                        activeIndex === index
                          ? activeColors[index % activeColors.length]
                          : fillColors[index % fillColors.length]
                      }
                      fillOpacity={1}
                      key={entry.name}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      stroke={strokeColors[index % strokeColors.length]}
                      style={{
                        transition: 'all 0.5s ease, stroke 0.5s ease',
                      }}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <EmptyPositionGraph />
          )}
        </div>
        <div
          className={twMerge(
            'flex max-w-[216px] flex-wrap gap-x-4 gap-y-2 pt-2',
            isMobile && 'w-full justify-center',
          )}
        >
          {data.map((item, index) => (
            <div
              className="flex w-[100px] flex-col gap-x-4 gap-y-2 pb-2"
              key={`${item.name}_${index}`}
            >
              <div className="flex gap-1">
                <span
                  className="truncate text-label-md font-[500] text-gray-600"
                  style={{
                    color: activeIndex !== null && activeIndex !== index ? '#98A2B3' : '#344054',
                    transition: 'color 0.5s ease',
                  }}
                >
                  {item.name}
                </span>
                {item.id === stakeholderId && (
                  <span
                    className="text-label-md font-[500] text-gray-600"
                    style={{
                      color: activeIndex !== null && activeIndex !== index ? '#98A2B3' : '#344054',
                      transition: 'color 0.5s ease',
                    }}
                  >
                    (You)
                  </span>
                )}
              </div>

              <div className="flex items-center gap-2">
                <div
                  className="h-[5px] w-3 rounded"
                  style={{
                    backgroundColor:
                      activeIndex === index
                        ? activeColors[index % activeColors.length]
                        : fillColors[index % fillColors.length],
                    border:
                      activeIndex === index
                        ? `1px solid ${activeColors[index % activeColors.length]}`
                        : `1px solid ${fillColors[index % fillColors.length]}`,
                    transition: 'background-color 0.5s ease, border 0.5s ease',
                  }}
                />
                <span
                  className="whitespace-nowrap text-sm font-[450]"
                  style={{
                    color:
                      activeIndex !== null
                        ? activeIndex === index
                          ? activeColors[index % activeColors.length]
                          : '#98A2B3'
                        : '#344054',
                    transition: 'color 0.5s ease',
                  }}
                >
                  {item.issuedPercentage.toFixed(2)} %
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
