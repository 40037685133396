import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useActivateCoupon = (id: string) => {
  const {
    mutate: activate,
    isPending,
    data,
  } = useAppMutation([QueryKey.ACTIVATE_COUPON], {
    mutationFn: ({ coupon }: { coupon: string }) =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [id, 'activate-coupon', coupon],
      }),
  });
  return { activate, isPending, data };
};
