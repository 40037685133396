import { useEffect } from 'react';

import { ActionCategory, PossibleAction } from '../../types/google-analytics-types';
import { pageTitle, replaceIdWithPath } from '../../types/pageTypes';
import { createGAEvent } from '../../utils/createGoogleAnalyticEvent';

export const useScrollDepth = () => {
  useEffect(() => {
    const scrollElement = document.getElementById('scrollbar-target');
    if (!scrollElement) return;

    const handleScroll = () => {
      const scrollDepth = (window.scrollY / scrollElement.scrollHeight) * 100;
      if (scrollDepth > 50) {
        createGAEvent({
          category: ActionCategory.SCROLLING,
          action: PossibleAction.SCROLLED_50,
          label: pageTitle[replaceIdWithPath(location.pathname)],
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
};
