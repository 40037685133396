import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { GetStartedProgress } from '../../types/companyTypes';
import { useAppQuery } from '../useAppQuery';
import { useLocale } from '../useLocale';

type FetchCompany = {
  companyId: string;
  enabled?: boolean;
};

export const useFetStartedProgress = ({ companyId, enabled }: FetchCompany) => {
  const queryClient = useQueryClient();
  const { locale } = useLocale();
  const { data: companyProgress, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_COMPANY_GET_STARTED_PROGRESS, companyId],
    queryFn: () =>
      ApiService.get<GetStartedProgress>(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'get-started-progress'] },
        { locale },
      ),
    enabled: !!companyId && enabled,
  });
  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_COMPANY_GET_STARTED_PROGRESS] });
  }, [queryClient]);

  return {
    isLoading,
    invalidateQuery,
    companyProgress,
  };
};
