import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../assets/icons';
import { useFormat, useSelectedCompany } from '../../hooks';
import { PageRoute } from '../../types/pageTypes';
import { Snapshot } from '../../types/snapshot.types';
import { toNumber } from '../../utils/toNumber';
import { SummaryItem } from './SummaryItem';

export type OverAllStatusProps = {
  wrapperClassName?: string;
  snapshot?: Snapshot;
  shouldNavigate?: boolean;
  hasValuation?: boolean;
};

export const OverAllStatus: FC<OverAllStatusProps> = ({
  wrapperClassName,
  snapshot,
  shouldNavigate = false,
  hasValuation = true,
}) => {
  const { format } = useFormat();
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();
  const currency = selectedCompany?.currency;

  const stakeholdersCount = snapshot?.stats?.stakeholders.filter(
    (stakeholder) => stakeholder.diluted > 0,
  ).length;

  return (
    <div
      className={twMerge(
        'flex min-h-full flex-col items-center rounded-lg bg-gray-50 p-1',
        wrapperClassName,
      )}
    >
      <div className="flex w-full items-start justify-between gap-4 px-4 py-3">
        <span className="w-full text-left text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          overall status
        </span>
        {shouldNavigate && (
          <ChevronDownIcon
            className="-rotate-90 transform cursor-pointer"
            onClick={() => navigate(PageRoute.CAP_TABLE)}
          />
        )}
      </div>

      <div className="flex h-full w-full flex-wrap content-start gap-3 rounded-md bg-white p-3 shadow-sm max-lg:flex-col">
        <SummaryItem
          className={hasValuation ? '' : 'animate-pulse'}
          title={hasValuation ? 'Post-Money Valuation' : ''}
          value={
            hasValuation
              ? `${currency?.symbol || currency?.iso3?.toLocaleUpperCase()}${toNumber(snapshot?.postMoneyValuation).toLocaleString('en-US')}`
              : ''
          }
        />
        <SummaryItem
          className={hasValuation ? '' : 'animate-pulse'}
          title={hasValuation ? 'valuation date' : ''}
          value={hasValuation ? format(snapshot?.valuation?.date, 'dd/MM/yyyy') || '-' : ''}
        />

        <SummaryItem
          className={hasValuation ? '' : 'animate-pulse'}
          title={hasValuation ? 'Lifetime equity investment' : ''}
          value={
            hasValuation ? toNumber(snapshot?.lifetimeInvestmentEquity).toLocaleString('en-US') : ''
          }
        />
        <SummaryItem
          className={hasValuation ? '' : 'animate-pulse'}
          title={hasValuation ? 'Issued shares' : ''}
          value={hasValuation ? toNumber(snapshot?.issuedShares).toLocaleString('en-US') : ''}
        />

        <SummaryItem
          className={hasValuation ? '' : 'animate-pulse'}
          title={hasValuation ? 'Investors' : ''}
          value={hasValuation ? toNumber(snapshot?.investorsCount).toLocaleString('en-US') : ''}
        />

        <SummaryItem
          className={hasValuation ? '' : 'animate-pulse'}
          title={hasValuation ? 'stakeholders' : ''}
          value={hasValuation ? toNumber(stakeholdersCount).toLocaleString('en-US') : ''}
        />

        <SummaryItem
          className={hasValuation ? '' : 'animate-pulse'}
          title={hasValuation ? 'Diluted shares' : ''}
          value={hasValuation ? toNumber(snapshot?.dilutedShares).toLocaleString('en-US') : ''}
        />
      </div>
    </div>
  );
};
