import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { EmptySearchList } from '../../../components/EmptySearchList';
import { PaginationController } from '../../../components/PaginationController';
import { StakeholderItem } from '../StakeholderItem';
import { StakeholdersCardComponentProps } from '../Stakeholders';
import { StakeholderTerminatedTable } from '../StakeholderTerminatedTable';

export type TerminatedCardProps = StakeholdersCardComponentProps;

export const TerminatedCard: FC<TerminatedCardProps> = ({
  currentPage,
  handlePageChange,
  totalPages,
  ...props
}) => {
  const { searchValue: search, data } = props;

  return (
    <>
      <StakeholderTerminatedTable {...props} className="max-lg:hidden" />
      <div
        className={twMerge(
          'flex h-fit w-full flex-wrap justify-center gap-4 px-4 pt-4 lg:hidden',
          search && data?.length === 0 && 'items-center',
        )}
      >
        {search && data?.length === 0 && <EmptySearchList className="my-20" />}

        {data?.map((stakeholderData) => (
          <StakeholderItem
            {...props}
            {...stakeholderData}
            key={stakeholderData.id}
            terminatedCard
          />
        ))}
      </div>
      <PaginationController
        className="shrink-0 max-lg:px-4 lg:mx-6"
        currentPage={currentPage}
        onClick={handlePageChange}
        totalPages={totalPages || 0}
      />
    </>
  );
};
