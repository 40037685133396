import React, { FC } from 'react';
import { Control } from 'react-hook-form';

import { ChevronDownIcon } from '../../../../../assets/icons';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../../../components/Combobox';
import { TerminationCauseTitle } from '../../../../../types/stakeholderTypes';
import { FormSchema } from '../validation';

type TerminationItemsListProps = {
  control: Control<FormSchema>;
  index: number;
};

const TerminationItemsList: FC<TerminationItemsListProps> = ({ control, index }) => {
  return (
    <>
      {Object.entries(TerminationCauseTitle).map(([key, value]) => (
        <FormComboboxOption
          className="w-full"
          control={control}
          key={key}
          name={`terminateForm.stakeholderItems.${index}.terminationCause`}
          value={key}
        >
          <span className="text-sm text-gray-700">{value}</span>
        </FormComboboxOption>
      ))}
    </>
  );
};

export type TerminationTypeComboboxProps = {
  control: Control<FormSchema>;
  index: number;
};

export const TerminationTypeCombobox: FC<TerminationTypeComboboxProps> = ({ control, index }) => {
  return (
    <FormCombobox
      className="w-[152px]"
      control={control}
      name={`terminateForm.stakeholderItems.${index}.terminationCause`}
    >
      <div className="relative">
        <FormComboboxInput
          control={control}
          customValue={(value) => value && TerminationCauseTitle[value]}
          icon={<ChevronDownIcon />}
          name={`terminateForm.stakeholderItems.${index}.terminationCause`}
          readOnly
          wrapperClassName="h-10"
        />
        <ComboboxButton className="absolute left-0 top-0 z-20 h-full w-full" />
      </div>
      <ComboboxOptions className="z-20 max-h-[96px] w-fit overflow-x-hidden">
        <TerminationItemsList control={control} index={index} />
      </ComboboxOptions>
    </FormCombobox>
  );
};
