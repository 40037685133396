import { Field, Label } from '@headlessui/react';
import { FC } from 'react';

import { Checkbox } from '../../Checkbox';
import { DatePicker } from '../../DatePicker';
import { TableRow } from '../../Table';
import { ApproveHurdleColumn, approveHurdleColumnMap } from './types';

export type HurdleTableRowProps = {
  isSelected: boolean;
  onSelect: (stakeholderId: string) => void;
  onRemoveSelect: (stakeholderId: string) => void;
  id: string;
};

export const HurdleTableRow: FC<HurdleTableRowProps> = ({
  isSelected,
  onRemoveSelect,
  onSelect,
  id,
}) => {
  return (
    <TableRow
      columns={approveHurdleColumnMap}
      fields={{
        id,
        [ApproveHurdleColumn.SELECT]: (
          <Field className="flex h-full w-full items-center">
            <Checkbox
              checked={isSelected}
              id={id}
              onChange={(checked) => {
                checked ? onSelect(id) : onRemoveSelect(id);
              }}
            />
            <Label className="absolute left-0 top-0 h-full w-full cursor-pointer" htmlFor={id} />
          </Field>
        ),
        [ApproveHurdleColumn.STAKEHOLDER]: 'Stakeholder',
        [ApproveHurdleColumn.GRANT]: 'Grant',
        [ApproveHurdleColumn.APPROVAL_DATE]: (
          <DatePicker className="border-b border-gray-700 bg-gray-50 px-3 py-2" />
        ),
        [ApproveHurdleColumn.SHARE_CLASS]: 'Share Class',
        [ApproveHurdleColumn.HURDLE]: 'Hurdle',
        [ApproveHurdleColumn.CURRENT_VALUATION]: 'Current Valuation',
        [ApproveHurdleColumn.ISSUED_SHARES]: 'Issued Shares',
      }}
      tdClassName="pl-4"
      trClassName={isSelected && 'bg-gray-50'}
    />
  );
};
