import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Snapshot } from '../../types/snapshot.types';

export type FetchCompanies = {
  take?: number;
  currentPage?: number;
  companyId: string;
};

type GetCompaniesResponseType = {
  data: Snapshot[];
  totalPages: number;
};

export const useSnapshots = ({ currentPage = 1, take = 100, companyId }: FetchCompanies) => {
  const queryClient = useQueryClient();
  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * take;
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteQuery<GetCompaniesResponseType>({
      queryKey: [
        QueryKey.GET_SNAPSHOTS,
        {
          companyId,
          currentPage,
          take,
          skip,
        },
      ],
      queryFn: ({ pageParam = 0 }) =>
        ApiService.get(
          { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'snapshots'] },
          {
            queryParams: {
              take,
              skip: currentPage ? skip : pageParam,
            },
          },
        ),
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.totalPages > allPages.length ? allPages.length * take : undefined,

      enabled: !!companyId,
    });

  const snapshotList = useMemo(
    () =>
      data?.pages?.reduce<Snapshot[]>((acc, page) => {
        return [...acc, ...page.data];
      }, []),
    [data?.pages],
  );

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SNAPSHOTS] });
  };

  return {
    invalidateQuery,
    snapshots: snapshotList || [],
    totalPages: data?.pages[0]?.totalPages || 0,
    isEmpty: snapshotList?.length === 0,
    fetchNextPage,
    hasNextPage,
    isLoading,
  };
};
