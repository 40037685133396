import React, { useState } from 'react';

import { CapQuestLogo, EyeIcon } from '../assets/icons';
import Button from '../components/Button';
import { Input } from '../components/Input';
import { useTemporaryAuthAccess } from '../hooks';
import { Tag, TagVariant } from './Events/Tag';

interface LoginDto {
  password: string;
}

export const TemporaryAuthAccess = () => {
  const [loginDto, setLoginDto] = useState<LoginDto>({
    password: '',
  });
  const [isPasswordShown, setPasswordShown] = useState(false);

  const { grantAccess, isPending } = useTemporaryAuthAccess();

  return (
    <div className="flex h-full items-center justify-center overflow-y-auto bg-gray-50 font-inter">
      <div className="max-h-full max-lg:h-full max-lg:w-full">
        <div
          className="flex h-fit min-h-full w-full flex-col gap-8 rounded-lg bg-white px-4 pb-8 pt-16 lg:h-fit lg:w-[458px] lg:items-center lg:px-8 lg:pt-8 lg:text-center"
          style={{
            filter:
              'drop-shadow(0px 1px 2px #1018280F) drop-shadow(0px 2px 3px #1018281A) drop-shadow(0px -1px 3px #1018280A)',
          }}
        >
          <CapQuestLogo size={48} />
          <div className="flex flex-col items-center gap-4">
            <Tag title="Cap Quest access check page" variant={TagVariant.BLUE} />
            <div className="text-sm font-[450] text-gray-500">
              Enter secret password to check access
            </div>
          </div>
          <div className="mt-2 flex w-full flex-col gap-6">
            <form className="flex flex-col gap-4">
              <Input
                icon={<EyeIcon variant={isPasswordShown ? 'ACTIVE' : 'CLOSE'} />}
                onChange={(value) => setLoginDto({ ...loginDto, password: value })}
                onIconClick={() => setPasswordShown((prev) => !prev)}
                placeholder="Password"
                type={isPasswordShown ? 'text' : 'password'}
                value={loginDto.password}
              />
            </form>
          </div>
          <div className="mt-2 w-full">
            <Button
              disabled={!loginDto.password.trim()}
              isLoading={isPending}
              onClick={() => grantAccess(loginDto)}
            >
              Check Access
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
