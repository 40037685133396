import * as React from 'react';
import { twMerge } from 'tailwind-merge';

import { Calendar } from '../../../components/Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { useFormat } from '../../../hooks';

type DatePickerProps = {
  onSelect?: (state: Date | undefined) => void;
  className?: string;
  fromDate?: Date;
  toDate?: Date;
  placeholder?: string;
  icon?: React.ReactNode;
  value?: Date;
  defaultMonth?: Date;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  onSelect,
  fromDate,
  toDate,
  className,
  placeholder,
  icon,
  value,
  defaultMonth,
}) => {
  const [date, setDate] = React.useState<Date | undefined>(value);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const { format } = useFormat();
  const handleDatePicked = (state: Date | undefined) => {
    onSelect?.(state);
    setDate(state);
    setIsPopoverOpen(false);
  };

  return (
    <Popover onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
      <PopoverTrigger
        className={twMerge(
          'flex h-10 w-full rounded-t-md border-b border-gray-700 bg-gray-50 p-3 pt-2',
          className,
        )}
      >
        <div className="flex flex-col items-start gap-1">
          {date && <span className="text-xs font-[450] text-gray-500">{placeholder}</span>}
          <span className="text-sm font-[450] text-gray-700">
            {date ? format(date, 'dd/MM/yyyy') : placeholder || ''}
          </span>
        </div>
        {icon}
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          defaultMonth={
            new Date(defaultMonth || 0)?.getTime() === new Date(0).getTime()
              ? undefined
              : defaultMonth
          }
          fromDate={fromDate}
          initialFocus
          mode="single"
          onSelect={handleDatePicked}
          selected={date}
          toDate={toDate}
        />
      </PopoverContent>
    </Popover>
  );
};
