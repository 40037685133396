import { Column, DetailsSortVariant, Snapshot, ViewBy } from '../../../../types/snapshot.types';

export const getShareClasses = <T extends { id: string }>(
  defaultShareClassId: string,
  arr: T[],
) => ({
  defaultShareClass: arr.find(({ id }) => id === defaultShareClassId),
  otherShareClasses: arr.filter(({ id }) => id !== defaultShareClassId),
});

export type SnapshotViewByProps = {
  snapshot: Snapshot;
  viewBy: Record<ViewBy, boolean>;
  visibleColumns: Column[];
  type: DetailsSortVariant;
  search: string;
  setEmptySearchResult: (state: boolean) => void;
};
