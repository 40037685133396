import { BackendRoute } from '../config';
import { QueryKey } from '../constants';
import { ApiService } from '../services';
import { PageRoute } from '../types/pageTypes';
import { useAppMutation } from './useAppMutation';
import { useInvalidateQueries } from './useInvalidateQueries';
import { useNavigate } from './useNavigate';

export const useTemporaryAuthAccess = () => {
  const { invalidateQuery } = useInvalidateQueries(QueryKey.CHECK_AUTH_TEMPORARY_ACCESS);
  const navigate = useNavigate(PageRoute.MY_COMPANIES);
  const {
    mutate: grantAccess,
    isError,
    isPending,
  } = useAppMutation([QueryKey.GRANT_AUTH_TEMPORARY_ACCESS], {
    mutationFn: ({ password }: { password: string }) =>
      ApiService.post({ endpoint: BackendRoute.AUTH_TEMPORARY_ACCESS }, { body: { password } }),
    onSuccess: () => {
      navigate();
      invalidateQuery();
    },
  });

  return {
    grantAccess,
    isError,
    isPending,
  };
};
