import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export type SummaryItemProps = { title: string; value: string | number; className?: string };

export const SummaryItem: FC<SummaryItemProps> = ({ title, value, className }) => (
  <div
    className={twMerge(
      className,
      'flex h-20 w-full flex-grow basis-[40%] flex-col gap-2 text-nowrap rounded-lg bg-gray-50 px-2 py-3',
    )}
  >
    <span className="text-[10px] font-semibold uppercase leading-4 tracking-[1px] text-gray-500">
      {title}
    </span>
    <span className="text-base font-semibold text-gray-600">{value}</span>
  </div>
);
