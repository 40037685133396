import React, { FC, memo } from 'react';

import { useSelectedCompany } from '../../hooks';

export const SelectedCompanyCurrency: FC = memo(() => {
  const { selectedCompany } = useSelectedCompany();
  const currencyInfo = selectedCompany?.currency;

  if (!currencyInfo) return;
  return <span>{currencyInfo.symbol || currencyInfo.iso3.toUpperCase()}</span>;
});
