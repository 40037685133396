import React, { FC, useRef, useState } from 'react';
import { Control, useController, UseFormSetValue } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, SearchIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import { Flag, FlagCode } from '../../../components/Flag';
import { BackendRoute } from '../../../config';
import { QueryKey } from '../../../constants';
import { Company, IpInfoHooks, useAppQuery, useLocale } from '../../../hooks';
import { StringKey } from '../../../lang';
import { ApiService } from '../../../services';
import { Currency } from '../../../types/currencyTypes';
import { FormSchema } from './Validation';

export type ReportingCurrencyItemsListProps = {
  data: Currency[];
  control: Control<FormSchema>;
  isFetching: boolean;
  isLoading: boolean;
};

const ReportingCurrencyItemsList: FC<ReportingCurrencyItemsListProps> = ({
  data,
  control,
  isLoading,
  isFetching,
}) => {
  const { countryCode } = IpInfoHooks.useCountryCode();
  const { selectedCompany: { country } = {} } = Company.useSelected();

  const recommendedCurrency = data?.filter(
    ({ countries, iso3 }) =>
      countries.some(({ iso2 }) => iso2 === (country?.iso2 || countryCode)) || iso3 === 'usd',
  );

  if (!data || isLoading || isFetching) return <span>Loading ...</span>;

  const sortedData = data
    .filter(
      ({ countries, iso3 }) =>
        countries.every(({ iso2 }) => iso2 !== (country?.iso2 || countryCode)) && iso3 !== 'usd',
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const sortedRecommendedCurrency = recommendedCurrency.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return (
    <div className="flex w-full flex-col gap-2">
      {sortedRecommendedCurrency.map(({ name, iso3 }, i) => (
        <FormComboboxOption
          control={control}
          key={`${iso3}_${i}_${country?.iso2}`}
          name="currency"
          value={{ name, iso3Code: iso3 }}
        >
          <Flag countryCode={iso3 as FlagCode} />
          <span className="text-sm text-gray-700">{name}</span>
        </FormComboboxOption>
      ))}

      {sortedData.length > 0 && <div className="h-[1px] w-full bg-gray-100" />}
      {sortedData?.map(({ name, iso3 }) => (
        <FormComboboxOption
          control={control}
          key={`${iso3}`}
          name="currency"
          value={{ name, iso3Code: iso3 }}
        >
          <Flag countryCode={iso3 as FlagCode} />
          <span className="text-sm text-gray-700">{name}</span>
        </FormComboboxOption>
      ))}
    </div>
  );
};

export type ReportingCurrencyComboboxProps = {
  control: Control<FormSchema>;
  selectedCurrency: Partial<FormSchema['currency']> | undefined;
  setValue: UseFormSetValue<FormSchema>;
};

const ReportingCurrencyCombobox: FC<ReportingCurrencyComboboxProps> = ({
  control,
  selectedCurrency,
  setValue,
}) => {
  const { locale } = useLocale();
  const [search, setSearch] = useState('');
  const { formState } = useController({ control, name: 'currency' });
  const {
    data: currencyData,
    isLoading,
    isFetching,
  } = useAppQuery<Currency[]>({
    queryKey: [QueryKey.GET_CURRENCY, { inputValue: search }],
    queryFn: async () =>
      ApiService.get({ endpoint: BackendRoute.CURRENCIES }, { queryParams: { search }, locale }),
  });

  const ref = useRef<HTMLInputElement>(null);

  return (
    <FormCombobox
      className="relative"
      control={control}
      name={'currency'}
      onClose={() => setSearch('')}
      onSelect={(currency) => {
        setValue('currency', currency);
        setSearch('');
      }}
    >
      <div className="relative">
        <FormComboboxInput
          className={'bg-gray-900'}
          control={control}
          customValue={(value) => value?.name}
          icon={<ChevronDownIcon className={twMerge('mt-3', formState.disabled && 'hidden')} />}
          iconBeforeInput={
            <Flag className="mt-5" countryCode={selectedCurrency?.iso3Code as FlagCode} />
          }
          name={'currency'}
          placeholder={<AppFormattedMessage id={StringKey.REPORTING_CURRENCY} />}
          readOnly
          wrapperClassName={formState.disabled && 'border-none'}
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <div className="relative flex items-center" onClick={() => ref.current?.focus()}>
          <SearchIcon className="absolute ml-[6px]" />
          <input
            className="w-full rounded border-[2px] border-transparent bg-gray-50 p-[6px] pl-8 text-gray-700 outline-none placeholder:text-gray-400 focus:border-brand-700 focus:bg-white"
            onChange={({ target: { value } }) => setSearch(value)}
            ref={ref}
            value={search}
          />
        </div>
        <ReportingCurrencyItemsList
          control={control}
          data={currencyData || []}
          isFetching={isFetching}
          isLoading={isLoading}
        />
      </ComboboxOptions>
    </FormCombobox>
  );
};

export default ReportingCurrencyCombobox;
