import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useDeleteCoupon = (id: string) => {
  const { mutate: deactivate, isPending } = useAppMutation([QueryKey.DEACTIVATE_COUPON], {
    mutationFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [id, 'delete-coupon'],
      }),
  });
  return { deactivate, isPending };
};
