import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

import {
  ActionCategory,
  actionCategoryTitle,
  PossibleAction,
  possibleActionTitle,
} from '../types/google-analytics-types';

export const createGAEvent = ({
  action,
  category,
  ...options
}: UaEventOptions & { category: ActionCategory; action: PossibleAction }) =>
  ReactGA.event({
    ...options,
    action: possibleActionTitle[action],
    category: actionCategoryTitle[category],
  });
