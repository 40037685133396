import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { CapQuestLogo, CrossIcon } from '../../assets/icons';
import Button from '../../components/Button';

export enum TermsTab {
  TERMS = 'terms',
  POLICY = 'policy',
}

const termsSections = [
  { title: 'Term section no 1', id: 'section-1' },
  { title: 'Term section no 2', id: 'section-2' },
  { title: 'Term section no 3', id: 'section-3' },
  { title: 'Term section no 4', id: 'section-4' },
  { title: 'Term section no 5', id: 'section-5' },
  { title: 'Term section no 6', id: 'section-6' },
  { title: 'Term section no 7', id: 'section-7' },
  { title: 'Term section no 8', id: 'section-8' },
  { title: 'Term section no 9', id: 'section-9' },
  { title: 'Term section no 10', id: 'section-10' },
  { title: 'Term section no 11', id: 'section-11' },
];

const policySections = [
  { title: 'Policy section no 1', id: 'section-1' },
  { title: 'Policy section no 2', id: 'section-2' },
  { title: 'Policy section no 3', id: 'section-3' },
  { title: 'Policy section no 4', id: 'section-4' },
  { title: 'Policy section no 5', id: 'section-5' },
  { title: 'Policy section no 6', id: 'section-6' },
  { title: 'Policy section no 7', id: 'section-7' },
  { title: 'Policy section no 8', id: 'section-8' },
  { title: 'Policy section no 9', id: 'section-9' },
  { title: 'Policy section no 10', id: 'section-10' },
  { title: 'Policy section no 11', id: 'section-11' },
];

export const Terms: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialTab = location.state || TermsTab.TERMS;
  const [selectedTab, setTab] = useState<TermsTab>(initialTab);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="relative flex h-screen min-h-full w-full flex-col gap-2 overflow-hidden bg-brand-25">
      <div className="flex w-full justify-between rounded-b-3xl bg-white px-8 py-3">
        <div className="flex items-center gap-2" onClick={() => navigate('/')}>
          <CapQuestLogo size={32} />
          <span className="text-xl font-semibold text-gray-700">CapQuest</span>
        </div>
        <Button
          className="shadow-xxs size-9 rounded-full border-[1px] border-[#f2f2f2] bg-white"
          onClick={() => navigate('/')}
        >
          <CrossIcon className="size-4" />
        </Button>
      </div>
      <div className="flex h-full min-h-full w-full flex-col gap-8 rounded-t-3xl bg-white px-8 py-4">
        <div className="flex h-[38px] w-full gap-4 border-b-[1px] border-gray-200">
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent pb-4 text-sm font-[450] text-gray-400',
              selectedTab === TermsTab.TERMS && 'border-brand-700 font-[550] text-brand-700',
            )}
            onClick={() => {
              setTab(TermsTab.TERMS);
            }}
            styleType="NONE"
          >
            Terms of service
          </Button>
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent pb-4 text-sm font-[450] text-gray-400',
              selectedTab === TermsTab.POLICY && 'border-brand-700 font-[550] text-brand-700',
            )}
            onClick={() => {
              setTab(TermsTab.POLICY);
            }}
            styleType="NONE"
          >
            Privacy Policy
          </Button>
        </div>
        <div className="flex w-full items-start gap-6">
          <div className="flex flex-col gap-2 rounded-md py-4 shadow-sm">
            <span
              className="text-nowrap px-6 py-2 text-label-sm font-bold uppercase text-gray-400"
              style={{ letterSpacing: '1px' }}
            >
              On this page
            </span>
            {selectedTab === TermsTab.TERMS ? (
              <div className="flex w-full flex-col gap-[2px]">
                <div
                  className="w-full cursor-pointer text-nowrap p-2 px-6 text-xs font-[450] text-gray-600 transition-all duration-300 hover:bg-brand-25 hover:font-semibold hover:text-brand-700"
                  onClick={() => scrollToSection('heading')}
                >
                  Section Heading
                </div>
                {termsSections.map((section) => (
                  <div
                    className="w-full cursor-pointer text-nowrap p-2 pl-6 pr-12 text-xs font-[450] text-gray-600 transition-all duration-300 hover:bg-brand-25 hover:font-semibold hover:text-brand-700"
                    key={section.id}
                    onClick={() => scrollToSection(section.id)}
                  >
                    {section.title}
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex w-full flex-col gap-[2px]">
                <div
                  className="w-full cursor-pointer text-nowrap p-2 px-6 text-xs font-[450] text-gray-600 transition-all duration-300 hover:bg-brand-25 hover:font-semibold hover:text-brand-700"
                  onClick={() => scrollToSection('heading')}
                >
                  Policy Section Heading
                </div>
                {policySections.map((section) => (
                  <div
                    className="w-full cursor-pointer text-nowrap p-2 pl-6 pr-12 text-xs font-[450] text-gray-600 transition-all duration-300 hover:bg-brand-25 hover:font-semibold hover:text-brand-700"
                    key={section.id}
                    onClick={() => scrollToSection(section.id)}
                  >
                    {section.title}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="flex h-full max-h-[80vh] w-full flex-col gap-2 overflow-y-auto rounded-md p-4 shadow-sm">
            {selectedTab === TermsTab.TERMS ? (
              <>
                <div className="flex flex-col gap-2" id="heading">
                  <span className="text-xl font-semibold text-gray-700">Section Heading</span>
                  <span className="text-xs font-[450] text-gray-700">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quod cupiditate
                    reprehenderit nisi? Architecto pariatur dolor, tempore placeat maiores libero
                    veritatis earum? Corporis deleniti veniam minus, distinctio quaerat cum dolorem.
                    Repellendus et exercitationem recusandae pariatur officia architecto rem
                    veritatis quaerat, praesentium explicabo repudiandae optio! Exercitationem
                    distinctio nemo velit deleniti labore aut laborum quisquam placeat, facere,
                    quaerat, dolorem ea atque nam! Non qui accusantium repellendus. Tempora aperiam
                    modi neque eius provident ipsa dolores alias. Enim magnam expedita doloremque,
                    optio id ut quos quia nobis eius illum corporis animi? Corporis, reiciendis
                    officiis. Sunt, voluptate tempore aut assumenda consectetur odio minima ex porro
                    atque, sapiente enim sed qui repudiandae totam ducimus! Deserunt numquam
                    laboriosam, odit beatae eius quasi doloremque soluta recusandae accusantium
                    quam? Magnam pariatur tenetur atque praesentium. Magni harum veritatis accusamus
                    numquam obcaecati velit, minima laborum, nam corporis, aliquid expedita?
                    Quisquam non dolorem ab corporis reprehenderit modi, laborum vel similique et
                    unde. Accusantium doloribus vero sunt
                  </span>
                </div>
                {termsSections.map((section) => (
                  <div className="flex flex-col gap-2" id={section.id} key={section.id}>
                    <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
                      {section.title}
                    </h2>
                    <span className="text-xs font-[450] text-gray-700">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quod cupiditate
                      reprehenderit nisi? Architecto pariatur dolor, tempore placeat maiores libero
                      veritatis earum? Corporis deleniti veniam minus, distinctio quaerat cum
                      dolorem. Repellendus et exercitationem recusandae pariatur officia architecto
                      rem veritatis quaerat, praesentium explicabo repudiandae optio! Exercitationem
                      distinctio nemo velit deleniti labore aut laborum quisquam placeat, facere,
                      quaerat, dolorem ea atque nam! Non qui accusantium repellendus. Tempora
                      aperiam modi neque eius provident ipsa dolores alias. Enim magnam expedita
                      doloremque, optio id ut quos quia nobis eius illum corporis animi? Corporis,
                      reiciendis officiis. Sunt, voluptate tempore aut assumenda consectetur odio
                      minima ex porro atque, sapiente enim sed qui repudiandae totam ducimus!
                      Deserunt numquam laboriosam, odit beatae eius quasi doloremque soluta
                      recusandae accusantium quam? Magnam pariatur tenetur atque praesentium. Magni
                      harum veritatis accusamus numquam obcaecati velit, minima laborum, nam
                      corporis, aliquid expedita? Quisquam non dolorem ab corporis reprehenderit
                      modi, laborum vel similique et unde. Accusantium doloribus vero sunt
                    </span>
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="flex flex-col gap-2" id="heading">
                  <span className="text-xl font-semibold text-gray-700">
                    Policy Section Heading
                  </span>
                  <span className="text-xs font-[450] text-gray-700">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quod cupiditate
                    reprehenderit nisi? Architecto pariatur dolor, tempore placeat maiores libero
                    veritatis earum? Corporis deleniti veniam minus, distinctio quaerat cum dolorem.
                    Repellendus et exercitationem recusandae pariatur officia architecto rem
                    veritatis quaerat, praesentium explicabo repudiandae optio! Exercitationem
                    distinctio nemo velit deleniti labore aut laborum quisquam placeat, facere,
                    quaerat, dolorem ea atque nam! Non qui accusantium repellendus. Tempora aperiam
                    modi neque eius provident ipsa dolores alias. Enim magnam expedita doloremque,
                    optio id ut quos quia nobis eius illum corporis animi? Corporis, reiciendis
                    officiis. Sunt, voluptate tempore aut assumenda consectetur odio minima ex porro
                    atque, sapiente enim sed qui repudiandae totam ducimus! Deserunt numquam
                    laboriosam, odit beatae eius quasi doloremque soluta recusandae accusantium
                    quam? Magnam pariatur tenetur atque praesentium. Magni harum veritatis accusamus
                    numquam obcaecati velit, minima laborum, nam corporis, aliquid expedita?
                    Quisquam non dolorem ab corporis reprehenderit modi, laborum vel similique et
                    unde. Accusantium doloribus vero sunt
                  </span>
                </div>
                {policySections.map((section) => (
                  <div className="flex flex-col gap-2" id={section.id} key={section.id}>
                    <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
                      {section.title}
                    </h2>
                    <span className="text-xs font-[450] text-gray-700">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quod cupiditate
                      reprehenderit nisi? Architecto pariatur dolor, tempore placeat maiores libero
                      veritatis earum? Corporis deleniti veniam minus, distinctio quaerat cum
                      dolorem. Repellendus et exercitationem recusandae pariatur officia architecto
                      rem veritatis quaerat, praesentium explicabo repudiandae optio! Exercitationem
                      distinctio nemo velit deleniti labore aut laborum quisquam placeat, facere,
                      quaerat, dolorem ea atque nam! Non qui accusantium repellendus. Tempora
                      aperiam modi neque eius provident ipsa dolores alias. Enim magnam expedita
                      doloremque, optio id ut quos quia nobis eius illum corporis animi? Corporis,
                      reiciendis officiis. Sunt, voluptate tempore aut assumenda consectetur odio
                      minima ex porro atque, sapiente enim sed qui repudiandae totam ducimus!
                      Deserunt numquam laboriosam, odit beatae eius quasi doloremque soluta
                      recusandae accusantium quam? Magnam pariatur tenetur atque praesentium. Magni
                      harum veritatis accusamus numquam obcaecati velit, minima laborum, nam
                      corporis, aliquid expedita? Quisquam non dolorem ab corporis reprehenderit
                      modi, laborum vel similique et unde. Accusantium doloribus vero sunt
                    </span>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
