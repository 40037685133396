import { StringKey } from '../string-key.enum';

export const enMessages: Record<StringKey, string> = {
  /// For this keys Translation is missing
  [StringKey.EMPLOYEE]: 'Employee',
  [StringKey.FOUNDER]: 'Founder',
  [StringKey.BOARD_MEMBERS]: 'Board Member',
  [StringKey.VCS]: 'Venture Capital',
  [StringKey.INVESTMENT_BANKS]: 'Investment Bank',
  [StringKey.PES]: 'Private Equity',
  [StringKey.OTHER_INST]: 'Other Institutional Investor',
  [StringKey.FAMILY_OFFICE]: 'Family Office',
  [StringKey.STAKEHOLDER]: 'Stakeholder',
  [StringKey.PLAN_SOON_EXPIRE]:
    'We are sorry to see you go. You may continue to use CapQuest until your current plan expires on {date}.',
  [StringKey.NO_GO_BACK]: 'No, go back',
  [StringKey.NO_KEEP_1]: 'No, Keep',
  [StringKey.NO_KEEP_2]: 'No, Keep',
  [StringKey.NO_KEEP_3]: 'No, Keep',
  [StringKey.NO_KEEP_4]: 'No, Keep',
  [StringKey.NO_KEEP_5]: 'No, Keep',
  [StringKey.CAN_REACTIVATE]: 'You can reactivate the subscription within next 6 months',
  [StringKey.SAVE_APPROX]: '(save approx. 17%)',
  [StringKey.BETWEEN_SYMBOL]: '-',
  [StringKey.COUNT_STAKEHOLDERS]: '{count} stakeholders',
  [StringKey.ACTIVE]: 'Active',
  [StringKey.ACTIVE_UNTIL]: 'Active until {date}',
  [StringKey.ACTIVITY]: 'Activity',
  [StringKey.ADD_NEW_COMPANY]: 'Add a new Company',
  [StringKey.ADD_STAKEHOLDER]: 'Add stakeholder',
  [StringKey.ADDRESS]: 'Address',
  [StringKey.ADDRESS_OPTIONAL]: 'Address (optional)',
  [StringKey.INACTIVE]: 'Inactive',
  [StringKey.ROWS_SELECTED]: '{count, plural, =1 {# row} other {# rows}} selected',
  [StringKey.ACCESS_AND_PERMISSIONS]: 'Access & Permissions',
  [StringKey.ACCESS_REVOKED]: 'Access revoked',
  [StringKey.ACCOUNT]: 'Account',
  [StringKey.ADMIN]: 'Admin',
  [StringKey.ADVISOR]: 'Advisor',
  [StringKey.ALL]: 'All',
  [StringKey.ALL_ASSOCIATED_STAKEHOLDER_DATA_WILL_BE_LOST]:
    'All of the data associated to the stakeholder will be lost.',
  [StringKey.ALL_STAKEHOLDERS]: 'All Stakeholders',
  [StringKey.ALREADY_HAVE_ACCOUNT]: 'Already have an account?',
  [StringKey.AMOUNT_TO_PAY_TODAY]: 'Amount to pay today',
  [StringKey.ANGEL]: 'Angel',
  [StringKey.ANGELS]: 'Angels',
  [StringKey.ANNUAL]: 'Annual',
  [StringKey.ANNUAL_REVENUE]: 'Annual revenue ($)',
  [StringKey.ANOTHER_BENEFIT]: 'Another benefit No {num}',
  [StringKey.ANY_ADDITIONAL_FEEDBACK]: 'Any additional feedback?',
  [StringKey.ARE_YOU_SURE_YOU_WANT_CANCEL]: 'Are you sure you want to cancel the subscription?',
  [StringKey.ARE_YOU_SURE_YOU_WANT_DEACTIVATE]: 'Are you sure you want to deactivate the company?',
  [StringKey.LOSE_ACCESS_CAUTION]:
    'You and all stakeholders won’t have access to it. You have {time} to reactivate the company after which it will be permanently deleted',
  [StringKey.ARE_YOU_SURE_YOU_WANT_DELETE_ACCOUNT]: 'Are you sure you want to delete your account?',
  [StringKey.ARE_YOU_SURE_YOU_WANT_GRANT_ACCESS]: 'Are you sure you want to grant access?',
  [StringKey.ARE_YOU_SURE_YOU_WANT_MAKE_CONTRIBUTOR]:
    'Are you sure you want to make this user contributor?',
  [StringKey.AT_LEAST_ONE_LOWER_CASE_LETTER]: 'At least 1 lower case letter (a-z)',
  [StringKey.AT_LEAST_ONE_SPECIAL_CHAR]: 'At least 1 special characters (e.g !@#$%^&*)',
  [StringKey.AT_LEAST_ONE_UPPER_CASE_LETTER]: 'At least 1 upper case letter (A-Z)',
  [StringKey.AT_LEAST_EIGHT_CHARS]: 'At least 8 characters',
  [StringKey.AUTO_RENEW_ON]: 'Auto renew on',
  [StringKey.BACK]: 'Back',
  [StringKey.BACK_TO_SIGN_IN]: 'Back to Sign in',
  [StringKey.BEFORE_YOU_START_YOUR_JOURNEY_CONFIRMATION]:
    'Before you start your journey, we just wanted to show you how your information has been saved. If you have any comments, please contact your admin',
  [StringKey.BILLED_ANNUALY]: 'Billed annually',
  [StringKey.BILLED_MONTHLY]: 'Billed monthly',
  [StringKey.BIRTHDAY]: 'Birthday',
  [StringKey.BIRTHDAY_OPTIONAL]: 'Birthday (optional)',
  [StringKey.BULK_MODIFY]: 'Bulk modify',
  [StringKey.BULK_MODIFY_STAKEHOLDERS]: 'Bulk Modify Stakeholders',
  [StringKey.BY_PROCEEDING_YOU_AGREE_TO]: 'By proceeding, you agree to',
  [StringKey.TERMS_OF_SERVICE]: 'the Terms of Service',
  [StringKey.AND]: 'and',
  [StringKey.PRIVACY_POLICY]: 'Privacy Policy',
  [StringKey.CANCEL]: 'Cancel',
  [StringKey.CANCEL_INVITATION]: 'Cancel Invitation',
  [StringKey.CANCEL_SUBSCRIPTION]: 'Cancel Subscription',
  [StringKey.CANNOT_DOWNGRADE]: 'Cannot downgrade',
  [StringKey.CAP_TABLE_MANAGEMENT]: 'Cap table management',
  [StringKey.MISSING_FEATURES]: 'CapQuest has missing features',
  [StringKey.TOO_EXPENSIVE]: 'CapQuest is too expensive',
  [StringKey.CARD_NUMBER]: 'Card number ',
  [StringKey.CARDHOLDER_NAME]: 'Cardholder name',
  [StringKey.CHANGE_PASSWORD]: 'Change Password',
  [StringKey.CHANGE_PLAN]: 'Change Plan',
  [StringKey.CHANGE_YOUR_PASSWORD]: 'Change your password',
  [StringKey.CHECK_THE_URL]: 'Check the URL for any typos and try again.',
  [StringKey.CHECK_YOUR_EMAIL]: 'Check your email',
  [StringKey.CHECK_YOUR_INBOX]: 'Check your inbox',
  [StringKey.CITY]: 'City',
  [StringKey.CLICK_TO_UPLOAD]: 'Click to upload',
  [StringKey.OR_DRAG_AND_DROP]: 'or drag and drop',
  [StringKey.CLOSE]: 'Close',
  [StringKey.COLLAPSE]: 'Collapse',
  [StringKey.COMPANY_ADDRESS]: 'Company Address',
  [StringKey.COMPANY_ID_NUMBER_OPTIONAL]: 'Company ID number (Optional)',
  [StringKey.COMPANY_IDENTIFIER]: 'Company Identifier',
  [StringKey.COMPANY_IDENTIFIER_OPTIONAL]: 'Company Identifier (Optional)',
  [StringKey.COMPANY_LOGO_OPTIONAL]: 'Company logo (optional)',
  [StringKey.COMPANY_NAME]: 'Company name',
  [StringKey.COMPANY_REGISTRATION_DETAILS]: 'Company registration details',
  [StringKey.COMPANY_SETTINGS]: 'Company settings',
  [StringKey.COMPANY_SETUP]: 'Company setup',
  [StringKey.COMPANY_TYPE]: 'Company Type',
  [StringKey.COMPANY_URL]: 'Company URL',
  [StringKey.COMPANY_URL_OPTIONAL]: 'Company URL (optional)',
  [StringKey.CONFIRM]: 'Confirm',
  [StringKey.CONFIRM_PASSWORD]: 'Confirm Password',
  [StringKey.CONGRATS_ON_YOUR_GROWTH]:
    "Congratulations on your growth! You've reached your stakeholder limit. Please upgrade your plan to continue",
  [StringKey.CONTINUE]: 'Continue',
  [StringKey.CONTINUE_TO_PLANS]: 'Continue to plans',
  [StringKey.CONTINUE_TO_USE_CAPQUEST_UNTIL]:
    'Continue to use CapQuest until your current plan expires on {date}',
  [StringKey.CONTINUE_WITH_GOOGLE]: 'Continue with Google',
  [StringKey.CONTRIBUTOR]: 'Contributor',
  [StringKey.INVITE_USER]: 'Invite user',
  [StringKey.INVITED]: 'Invited',
  [StringKey.ACTIVE_STAKEHOLDERS]: 'Active Stakeholders',
  [StringKey.INACTIVE_STAKEHOLDERS]: 'Inactive Stakeholders',
  [StringKey.ALL_TERMINATED]: 'All Terminated',
  [StringKey.ACTIVE_TERMINATED]: 'Active Terminated',
  [StringKey.INACTIVE_TERMINATED]: 'Inactive Terminated',
  [StringKey.STAKEHOLDERS_TAB]: 'Stakeholders',
  [StringKey.TERMINATED_TAB]: 'Terminated',
  [StringKey.FILTER_ALL]: 'All',
  [StringKey.FILTER_ACTIVE]: 'Active',
  [StringKey.FILTER_INACTIVE]: 'Inactive',
  [StringKey.FILTER_IN_USE]: 'In Use',
  [StringKey.FILTER_NOT_USED]: 'Not Used',
  [StringKey.KILOBYTE]: 'kb',
  [StringKey.MINUTES_AGO]: '{date} ago',
  [StringKey.SINGULAR_MIN]: 'min',
  [StringKey.PLURAL_MIN]: 'minutes',
  [StringKey.HOURS_AGO]: '{date} ago',
  [StringKey.SINGULAR_HOUR]: 'hour',
  [StringKey.PLURAL_HOUR]: 'hours',
  [StringKey.DAYS_AGO]: '{date} ago',
  [StringKey.SINGULAR_DAY]: 'day',
  [StringKey.PLURAL_DAY]: 'days',
  [StringKey.MONTHS_AGO]: '{date} ago',
  [StringKey.SINGULAR_MONTH]: 'month',
  [StringKey.PLURAL_MONTH]: 'months',
  [StringKey.LESS_THAN_MIN_AGO]: 'less than a min ago',
  [StringKey.ZIP_CODE]: 'Zip Code',
  [StringKey.SWITCH_TO_ANNUAL_PLAN_MESSAGE]:
    'Your subscription will be renewed starting from {date}',
  [StringKey.COUNTRY_OF_COMPANY]: 'Country of company',
  [StringKey.CREATE_MY_ACCOUNT]: 'Create my account',
  [StringKey.CREATING_YOUR_ACCOUNT]: 'Creating your account ...',
  [StringKey.CURRENT_PLAN]: 'Current Plan',
  [StringKey.CURRENTLY_SELECTED]: 'Currently selected',
  [StringKey.DASHBOARD]: 'Dashboard',
  [StringKey.DATE_CREATED]: 'Date created',
  [StringKey.DATE_OF_INCORPORATION]: 'Date of Incorporation',
  [StringKey.DATE_OF_INCORPORATION_OPTIONAL]: 'Date of Incorporation (optional)',
  [StringKey.DAY]: 'Day',
  [StringKey.DEACTIVATE]: 'Deactivate',
  [StringKey.DEACTIVATE_COMPANY]: 'Deactivate Company',
  [StringKey.DELETE]: 'Delete',
  [StringKey.DELETE_ACCOUNT]: 'Delete Account',
  [StringKey.DELETE_STAKEHOLDER]: 'Delete {count, plural, =1 {stakeholder} other {stakeholders}}',
  [StringKey.DELETE_YOUR_CAPQUEST_ACCOUNT]: 'Delete your CapQuest account',
  [StringKey.DEMO]: 'Demo',
  [StringKey.DETAILS]: 'Details',
  [StringKey.DONT_HAVE_AN_ACCOUNT_YET]: 'Don’t have an account yet',
  [StringKey.DONT_WORRY_YOUR_DATA_IS_SAVED]:
    'Don’t worry, your data is saved with us. You can reactivate the subscription during next {timeDistance}',
  [StringKey.DOWNGRADE]: 'Downgrade',
  [StringKey.SWITCH_TO_ANNUAL_PLAN_CHARGE_MESSAGE]:
    'Your primary billing method will be charged {currency} {price}',
  [StringKey.SWITCH_TO_ANNUAL_PLAN_ACTIVE_MESSAGE]: 'Your plan will be activate immediately',
  [StringKey.COMPANY_SETUP_EXIT]: 'Your progress will be lost if you exit',
  [StringKey.PRIVATE_EQUITY]: 'Private Equity',
  [StringKey.VENTURE_CAPITAL]: 'Venture Capital',
  [StringKey.SPV]: 'SPV',
  [StringKey.COMPANY]: 'Company',
  [StringKey.OTHER]: 'Other financial institutions',
  [StringKey.STAKEHOLDER_COMPANY_GROUP]: 'Legal entities',
  [StringKey.STAKEHOLDER_HUMAN_GROUP]: 'Natural persons',
  [StringKey.MONTH]: 'Month',
  [StringKey.ANNUALY]: 'Annualy',
  [StringKey.MONTHLY]: 'Monthly',
  [StringKey.BILLED_MONTHLY_PLAN]: 'Billed monthly',
  [StringKey.PAY_MONTHLY]: 'Pay monthly',
  [StringKey.MAXIMUM_FILE_SIZE]: 'Maximum per file size {size} MB',
  [StringKey.MAXIMUM_PHOTO_SIZE]: 'Maximum size: {rules}',
  [StringKey.CARD_ENDING_NUMBERS]: '{brand} ending in {number}',
  [StringKey.LETS_TALK]: 'Let`s talk',
  [StringKey.LAST_UPDATE]: 'Last update: {date}',
  [StringKey.INDUSTRY]: 'Industry',
  [StringKey.STAKEHOLDERS_LIMIT]:
    'You cannot downgrade your plan because you currently have more than 25 stakeholders. Contact support',
  [StringKey.YOURE_SAVE_APPROX_WITH_ANNUAL]: 'You are saving approx. 17%',
  [StringKey.UPGRADE]: 'Upgrade',
  [StringKey.SWITCH_ANNUAL]: 'Switch to annual',
  [StringKey.SELECT]: 'Select',
  [StringKey.SPACIAL_PLAN_TEXT]: 'Speak to sales',
  [StringKey.CANNOT_DOWNGRADE_STAKEHOLDERS]:
    'You cannot downgrade your plan because you currently have yearly plan',
  [StringKey.CANCEL_SUBSCRIPTION_PROBLEM_1]:
    'You and all stakeholders won’t have access to this company afterward.',
  [StringKey.EXIT]: 'Yes, Exit',
  [StringKey.CONFIRM_DELETE]: 'Yes, Delete',
  [StringKey.CONFIRM_DEACTIVATE]: 'Yes, Deactivate',
  [StringKey.CONFIRM_CANCEL]: 'Yes, Cancel',
  [StringKey.YEAR]: 'Year',
  [StringKey.WHERERE_LOCATED]: 'Where are you located?',
  [StringKey.WELCOME]: 'Welcome',
  [StringKey.WELCOME_TO_CAPQUEST]: 'Welcome to CapQuest',
  [StringKey.WELCOME_BACK]: 'Welcome back',
  [StringKey.WELCOME_ABOARD]: 'Welcome Aboard',
  [StringKey.FEW_DETAILS]: 'We just need a few details to get you started',
  [StringKey.SENDED_RECEIPT_TO_EMAIL]:
    'We have sent you the receipt on your affiliated email address',
  [StringKey.SENDED_LOGIN_VERIFY_LINK]:
    'We have sent you a secure login link. Please click the link to authenticate your account',
  [StringKey.WANT_EXIT]: 'Want to exit?',
  [StringKey.VOTING_RIGHTS]: 'Voting rights %',
  [StringKey.VIEW_MY_COMPANIES]: 'View my companies',
  [StringKey.VIEW_MODIFICATION_GUID]: 'View modification guide',
  [StringKey.VIEW_IMPORT_GUID]: 'View import guide',
  [StringKey.VIEW_DETAILS]: 'View Details',
  [StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART1]: 'Download',
  [StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART2]: 'stakeholder',
  [StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART3]: 'info',
  [StringKey.DOWNLOAD_TEMPLATE]: 'Download template',
  [StringKey.EDIT_DETAILS]: 'Edit Details',
  [StringKey.EDIT_STAKEHOLDER]: 'Edit Stakeholder',
  [StringKey.EFFICIENTLY_HANDLE_MANAGEMENT]:
    'Efficiently handle stock option management with our cost-saving annual plans',
  [StringKey.EMAIL]: 'Email',
  [StringKey.EMAIL_ADDRESS]: 'Email address',
  [StringKey.ENTER_A_NEW_PASSWORD_BELOW]:
    'Enter a new password below to change your current password and continue your access to CapQuest',
  [StringKey.ENTER_YOUR_EMAIL_ADDRESS_AND_WE_WILL]:
    'Enter your email address, and we will send you an email with password reset instructions',
  [StringKey.ENTERPRISE_AND_FI]: 'Enterprise',
  [StringKey.EQUITY_AND_CAPITAL_CHANGE]: 'Equity & Capital Change',
  [StringKey.EVENTS]: 'Events',
  [StringKey.EVERYTHING_FROM_STARTER_PLAN]: 'Everything from Starter Plan +',
  [StringKey.EXPIRY]: 'Expiry',
  [StringKey.EXPORT]: 'Export',
  [StringKey.FILE_FORMAT_NOT_SUPPORTED]: 'File format is not supported',
  [StringKey.FILE_PROCESSED_SUCCESSFULLY]: 'File processed successfully',
  [StringKey.FOR_CUSTOM_PRICING]: 'for custom pricing',
  [StringKey.FORGOT_PASSWORD]: 'Forgot password',
  [StringKey.FULL_NAME]: 'Full name',
  [StringKey.GENERATING_INVITATION_AGAIN_WILL_VOID]:
    'Generating invitation again will void the previous invite.',
  [StringKey.GRANT_ACCESS]: 'Grant Access',
  [StringKey.HELP_ME_SETUP]: 'Help me setup',
  [StringKey.HELP_US_MAKE_CAPQUEST_BETTTER]:
    'Help us make CapQuest better - tell us why you cancelled this plan',
  [StringKey.HERE_ARE_A_FEW_THING_TO_TRY]: 'Here are a few things you can try',
  [StringKey.I_FOUND_BETTER_ALTERNATIVE]: 'I found a better alternative',
  [StringKey.I_HAVE_SEEN_MY_INFO]: 'I have seen my information',
  [StringKey.I_NO_LONGER_NEED_IT]: 'I no longer need it',
  [StringKey.IF_YOU_CANCEL]: 'If you cancel',
  [StringKey.IF_YOU_RECEIVED_LINK_IN_EMAIL]:
    'If you received the link in an email, try contacting the sender for a new one.',
  [StringKey.IMPORT]: 'Import',
  [StringKey.IMPORT_STAKEHOLDERS]: 'Import Stakeholders',
  [StringKey.IN_THE_MEANTIME_FEEL_FREE_TO]: 'In the meantime, feel free to browse our',
  [StringKey.INACTIVE_COMPANY]: 'Inactive company',
  [StringKey.INCOMPLETE_SETUP]: 'Incomplete setup',
  [StringKey.LAST_ACTIVE]: 'Last active',
  [StringKey.LET_US_HELP_TO_SETUP]: 'Let us help to setup your CapQuest',
  [StringKey.LET_US_KNOW_ABOUT_YOUR_COMPANY_DETAILS]: 'Let us know about your company details',
  [StringKey.LOCATION]: 'Location',
  [StringKey.LOG_IN]: 'Log in',
  [StringKey.LOG_OUT]: 'Log out',
  [StringKey.LOSE_SPECIAL_OFFER_PRICE]: 'Lose your special offer price, if any.',
  [StringKey.MAKE_CONTRIBUTOR]: 'Make Contributor',
  [StringKey.MY_BUSINESS_IS_NO_LONGER_ACTIVE]: 'My business is no longer active',
  [StringKey.MY_COMPANIES]: 'My Companies',
  [StringKey.MY_COMPANY]: 'My Company',
  [StringKey.MY_HOLDING_EQUITY]: 'My Holdings / Equity',
  [StringKey.MY_HOLDINGS]: 'My Holdings',
  [StringKey.MY_PROFILE]: 'My Profile',
  [StringKey.NAME]: 'Name',
  [StringKey.NATIONALITY]: 'Nationality',
  [StringKey.NATIONALITY_OPTIONAL]: 'Nationality (optional)',
  [StringKey.NEW_PASSWORD]: 'New password',
  [StringKey.NEXT]: 'Next',
  [StringKey.VAT_GST]: 'VAT / GST number',
  [StringKey.USER_ROLE]: 'User role',
  [StringKey.MISC_ADVISE_3]:
    "Use the website's search bar to find the information you were looking for.",
  [StringKey.UPLOAD_AGAIN]: 'Upload again',
  [StringKey.UPGRADE_PLAN_NOW]: 'Upgrade your plan now',
  [StringKey.UPDATE]: 'Update',
  [StringKey.UNABLE_DELETE]: 'Unable to delete',
  [StringKey.TYPE]: 'Type',
  [StringKey.TERMINATION]: 'Termination',
  [StringKey.TERMINATED]: 'Terminated',
  [StringKey.TERMINATE]: 'Terminate',
  [StringKey.SWITCH_TO_ANNUAL_BILLING]: 'Switch to annual billing',
  [StringKey.SWITCH_COMPANY]: 'Switch company',
  [StringKey.SUPPORTED_FORMATS]: 'Supported formate: {formats}',
  [StringKey.SUBSCRIPTION_CYCLE]: 'Subscription cycle',
  [StringKey.SUBSCRIPTION_CANCELED]: 'Subscription Cancelled',
  [StringKey.SUBMIT]: 'Submit',
  [StringKey.STATUS]: 'Status',
  [StringKey.STATE]: 'State',
  [StringKey.STAKEHOLDERS]: 'Stakeholders',
  [StringKey.STAKEHOLDER_UPDATE_TOAST]: 'Stakeholder updated successfully',
  [StringKey.STAKEHOLDER_TYPE]: 'Stakeholder Type',
  [StringKey.STAKEHOLDER_DELETED_TOAST]: 'Deleted successfully',
  [StringKey.STAKEHOLDER_CREATED_TOAST]: 'Stakeholder created successfully',
  [StringKey.SOMETHING_ELSE]: 'Something else',
  [StringKey.SKYROCKETING]: 'Skyrocketing growth ahead',
  [StringKey.SKIP]: 'Skip',
  [StringKey.SIGN_UP]: 'Sign up',
  [StringKey.SIGN_UP_TO_CAPQUEST]: 'Sign in to your CapQuest account',
  [StringKey.SIGN_IN_TO_CAPQEUST]: 'Sign in to your CapQuest account',
  [StringKey.SIGN_IN]: 'Sign in',
  [StringKey.SHARE_CLASSES]: 'Share Classes',
  [StringKey.POOL_PLANS_POOL]: 'Pool Details',
  [StringKey.POOL_PLANS_PLAN]: 'Plan Details',
  [StringKey.POOL_PLANS]: 'Pool & Plans',
  [StringKey.CAP_TABLE]: 'Cap Table',
  [StringKey.SETTINGS]: 'Settings',
  [StringKey.ACCEPT_INVITATION]: 'Accept Invitation',
  [StringKey.LOGIN]: 'Login',
  [StringKey.REGISTER]: 'Register',
  [StringKey.REGISTER_VERIFY]: 'Register Verify',
  [StringKey.PASSWORD_RECOVERY]: 'Password Recovery',
  [StringKey.BILLING]: 'Billing',
  [StringKey.ERROR]: 'Error',
  [StringKey.SEND_EMAIL]: 'Send email',
  [StringKey.SEARCH]: 'Search',
  [StringKey.SAVED_APPROX_V2]: 'Saved approx. 17%',
  [StringKey.SAVE_APPROX_V2]: 'Save approx. 17%',
  [StringKey.SAVE_APPROX_W_ANNUAL_BILLING]: 'Save approx. 17% with annual billing',
  [StringKey.SAVE]: 'Save',
  [StringKey.REVOKE_CONTRIBUTOR_ROLE]: 'Revoke Contributor Role',
  [StringKey.REVOKE_ACCESS]: 'Revoke Access',
  [StringKey.RESUME_COMPANY_SETUP]: 'Resume setting up your company',
  [StringKey.RESUME]: 'Resume',
  [StringKey.RESIGNATION]: 'Resignation',
  [StringKey.RESEND_VERIFICATION_EMAIL]: 'Resend verification email',
  [StringKey.RESEND_INVITATION]: 'Resend Invitation',
  [StringKey.RESEND_EMAIL]: 'Resend email',
  [StringKey.NO]: 'No',
  [StringKey.NO_OF_EMPLOYEES]: 'No of employees',
  [StringKey.NO_RESULTS_MATCHS]: 'No results match this search term',
  [StringKey.NO_REV]: 'No Revenue',
  [StringKey.NO_THANKS]: 'No, thanks',
  [StringKey.NOTE_THAT_YOUR_INFORMATION_STILL_SAVED]:
    'Note that your information will still be saved as a stakeholder and visible to Admins and Contributors.',
  [StringKey.OOPS]: 'Oops!',
  [StringKey.OR]: 'OR',
  [StringKey.PASSPORT_EXPIRY_DATE]: 'Passport Expiry date',
  [StringKey.PASSPORT_EXPIRY_DATE_OPTIONAL]: 'Passport Expiry date (optional)',
  [StringKey.PASSPORT_NO]: 'Passport No',
  [StringKey.PASSPORT_NO_OPTIONAL]: 'Passport No (optional)',
  [StringKey.PASSWORD]: 'Password',
  [StringKey.PASSWORD_CHANGED]: 'Password changed',
  [StringKey.PAY_NOW]: 'Pay now',
  [StringKey.PAY_YEARLY]: 'Pay yearly',
  [StringKey.PAYMENT_METHOD]: 'Payment method',
  [StringKey.PER_MONTH]: 'Per month',
  [StringKey.PERSONAL_INFO]: 'Personal info',
  [StringKey.PHONE_NUMBER]: 'Phone number',
  [StringKey.PHONE_NUMBER_OPTIONAL]: 'Phone number (optional)',
  [StringKey.PLAN_BILLING]: 'Plan & Billing',
  [StringKey.PLAN_FOR_ALL_SIZES]: 'Plan for all sizes',
  [StringKey.PLAN_SELECTED]: 'Plan selected',
  [StringKey.PLEASE_CHECK_THE_EMAIL]:
    'Please check the email address {email} for instructions to reset your password.',
  [StringKey.PLEASE_TRY_AGAIN]: 'Please try again',
  [StringKey.POOLS_PLANS]: 'Pool & Plans',
  [StringKey.PREVIOUS]: 'Previous',
  [StringKey.REACTIVATE]: 'Reactivate',
  [StringKey.REACTIVATE_SUBSCRIPTION]: 'Reactivate subscription',
  [StringKey.REENTER_NEW_PASSWORD]: 'Re-enter new password',
  [StringKey.REGISTRATION_NUMBER_OPTIONAL]: 'Registration number (optional)',
  [StringKey.REPORTING_CURRENCY]: 'Reporting Currency',
  [StringKey.YES]: 'Yes',
  [StringKey.YES_CANCEL]: 'Yes, Cancel',
  [StringKey.YES_DEACTIVATE]: 'Yes, Deactivate',
  [StringKey.YES_DELETE]: 'Yes, Delete',
  [StringKey.YES_EXIT]: 'Yes, Exit',
  [StringKey.YES_GRANT]: 'Yes, Grant',
  [StringKey.YES_RESEND]: 'Yes, Resend',
  [StringKey.YES_REVOKE]: 'Yes, Revoke',
  [StringKey.YES_UPDATE]: 'Yes, Update',
  [StringKey.YES_TERMINATE]: 'Yes, Terminate',
  [StringKey.UPDATE_STAKEHOLDER]: 'Update Stakeholder',
  [StringKey.UPDATE_TERMINATION]: 'Update Termination',
  [StringKey.SIGN_UP_MESSAGE]:
    'Thank you for being an early adopter. To start enjoying the benefits, let’s set up your account.',
  [StringKey.COMPANY_ID_EXPLANATION]:
    'The company ID number can be any unique identifier typically used for a company in contracts, grants, and invoicing, e.g., for US companies, this could be an Employer Identification Number (EIN), and for UAE companies, it may be a license number.',
  [StringKey.UPDATE_INFORMATION_MESSAGE]: 'The information you changed will be updated and saved.',
  [StringKey.INCORRECT_LINK_PT_1]: 'The link you clicked might be out of date or incorrect.',
  [StringKey.INCORRECT_LINK_PT_2]: "Don't worry, these things happen!",
  [StringKey.NOT_ABLE_TO_USE_STAKEHOLDER]:
    'The selected stakeholder will not be able to use the CapQuest.',
  [StringKey.STAKEHOLDER_CANCEL_INVITE_DESCRIPTION]:
    'The stakeholder may not able to join your team on CapQuest.',
  [StringKey.GREETING_COMPANY_SETUP]:
    'To get started, add your stakeholders and invite people from your company to join your team',
  [StringKey.PASSWORD_RECOMMENDATION]: 'Your password must contain:',
  [StringKey.PASSWORD_SUCCESSFULLY_CHANGED]: 'Your password has been changed successfully',
  [StringKey.TERMINATE_STAKEHOLDER]: 'Terminate stakeholder',
  [StringKey.STAKEHOLDER_SUCCESSFULLY_CREATED]: 'Stakeholder successfully created',
  [StringKey.FRENCH]: 'Français',
  [StringKey.ENGLISH]: 'English',
  [StringKey.NO_KEEP]: 'No, keep',
  [StringKey.CONTACT_SUPPORT]: 'Contact support',
  [StringKey.WEBSITE]: 'Website',
  [StringKey.ALL_DONE]: 'All done',
  [StringKey.HANG_TIGHT]: 'Hang tight',
  [StringKey.ONBOARDING]: 'Onboarding',
};
// <AppFormattedMessage id={StringKey.} />
