import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';
import { useNotifications } from '../../context/notifications.context';

export const useMarkReadAll = ({
  companyId,
  onSuccess,
}: {
  companyId: string;
  onSuccess?: () => void;
}) => {
  const { handleReadAllNotification } = useNotifications();
  const { mutate: markAllAsRead } = useAppMutation(
    [QueryKey.MARK_NOTIFICATION_AS_READ, companyId],
    {
      mutationFn: () =>
        ApiService.post({
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'notifications', 'mark-read-all'],
        }),
      onSuccess: () => {
        handleReadAllNotification();
        onSuccess?.();
      },
    },
  );

  return {
    markAllAsRead,
  };
};
