import React, { FC, useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { FormInput } from '../../../../components/Input';
import { BackendRoute } from '../../../../config';
import { QueryKey } from '../../../../constants';
import { useAppMutation, useDebounce, useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ApiService } from '../../../../services';
import { SharePlanType } from '../../../../types/pool-plans.types';
import { PoolCombobox } from '../PoolCombobox';
import { SharePlanTypeCombobox } from '../SharePlanTypeCombobox';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepOne as stepOneFormSchema } from '../Validation';

export type StepOneProps = ChildrenFormProps;

export const StepOne: FC<StepOneProps> = ({
  handleCloseModal,
  nextFormStep,
  control,
  setFormData,
  companyId,
  formData,
  lockMode,
  setError,
  clearErrors,
  filedState,
}) => {
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const { stepOne } = useWatch<FormSchema>({ control });
  const { success } = stepOneFormSchema.safeParse(stepOne);
  const { format } = useFormat();
  const { mutate } = useAppMutation(
    [QueryKey.SHARE_PLAN_NAME_CHECK, { name: stepOne?.name || '' }],
    {
      mutationFn: async (name: string) =>
        await ApiService.get<{ isInUse: boolean }>({
          endpoint: BackendRoute.SHARE_PLANS,
          routePath: ['check-is-name-in-use', name],
        }),
      onSuccess: ({ isInUse }) => {
        setError('stepOne.name', {
          message: 'Name should be unique',
          type: 'validate',
          types: { required: true },
        });
        if (!isInUse) return clearErrors('stepOne.name');
      },
      defaultErrorHandling: false,
    },
  );

  const isValid = success && !filedState('stepOne').invalid;

  const { debounceCallback } = useDebounce<string>({
    callback: (name) => name && mutate(name),
    debounce: 300,
  });

  const handleTypeChange = useCallback(
    (type: SharePlanType) => {
      const { financialDetails, type: prevType } = formData('stepOne');
      const value: FormSchema['stepOne']['financialDetails'] = {
        [prevType]: undefined,
        [type]: {
          enabled: true,
          ...financialDetails?.[type],
        },
      };
      setFormData('stepOne.financialDetails', value);
    },
    [formData, setFormData],
  );

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <FormInput
          control={control}
          name="stepOne.name"
          onChange={debounceCallback}
          placeholder="Plan name"
        />
        <PoolCombobox companyId={companyId} control={control} />
        <FormDatePicker
          defaultMonth={stepOne?.date || undefined}
          inputValue={stepOne?.date ? format(stepOne?.date, 'dd/MM/yyyy') : ''}
          inputWrapperClassName="w-full"
          isDefaultOpenCalendar={isOpenCalendar}
          onSelect={(date) => {
            setFormData('stepOne.date', date);
            setOpenCalendar(false);
          }}
          placeholder="Creation Date"
          value={stepOne?.date}
        />
        <SharePlanTypeCombobox control={control} onSelect={handleTypeChange} />
        {stepOne?.type === SharePlanType.GROWTH_HURDLE && (
          <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 py-1">
            <span className="pl-[14px] pt-2 text-sm font-[450] text-brand-700">Hurdle</span>
            <div className="flex flex-col items-center gap-3 rounded-md bg-white p-4 px-4 shadow-sm">
              <FormInput
                control={control}
                name="stepOne.financialDetails.growth-hurdle.value"
                numberOnly
                placeholder="Value"
                shouldFormatNumber
                wrapperClassName="w-full"
              />
              <DropDown
                answer="The Hurdle shares allow employees to participate in the value of a company over and above a valuation hurdle. This means that other shareholders’ economic interests are diluted only if and when the company's value exceeds the specified hurdle."
                className="rounded-lg bg-gray-50"
                question="What is Hurdle value?"
              />
            </div>
          </div>
        )}
        {stepOne?.type === SharePlanType.BSPCE && (
          <>
            <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 py-1">
              <span className="pl-[14px] pt-2 text-sm font-[450] text-brand-700">Strike Price</span>
              <div className="flex flex-col items-center gap-3 rounded-md bg-white p-4 px-4 shadow-sm">
                <FormInput
                  control={control}
                  maxDecimalPlaces={2}
                  name="stepOne.financialDetails.bspce.value"
                  placeholder="Value"
                  wrapperClassName="w-full"
                />
                <DropDown
                  answer="The strike price indicates the value at which the stakeholder(s) associated to the Plan will effectively purchase the shares. (Note that the share purchase will occur outside of the CapQuest ecosystem, and that on CapQuest users will manually “register” the transaction)."
                  className="rounded-lg bg-gray-50"
                  question="What is Strike price?"
                />
              </div>
            </div>
            <FormInput
              control={control}
              maxDecimalPlaces={2}
              name="stepOne.financialDetails.bspce.conversionRatio"
              placeholder="Conversion ratio (x) (optional)"
              wrapperClassName="w-full"
            />
            <DropDown
              answer="The conversion ratio of the Grant (established at Plan level) indicates the conversion of the grant into a share class. In other words, Alice gets 1000 Grants that can have a conversion ratio on a Stock Options Plan of 2x. In such a way that when she exercises, she will get 2000 shares from the selected share class. This means that a Grant does not always have a 1-1 value with the selected share class underlying the pool"
              className="rounded-lg bg-gray-50"
              question="What is conversion ratio?"
            />
          </>
        )}
        {stepOne?.type === SharePlanType.STOCK_OPTIONS && (
          <>
            <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 py-1">
              <span className="pl-[14px] pt-2 text-sm font-[450] text-brand-700">Strike Price</span>
              <div className="flex flex-col items-center gap-3 rounded-md bg-white p-4 px-4 shadow-sm">
                <FormInput
                  control={control}
                  maxDecimalPlaces={2}
                  name="stepOne.financialDetails.stock-options.value"
                  placeholder="Value"
                  wrapperClassName="w-full"
                />
                <DropDown
                  answer="The strike price indicates the value at which the stakeholder(s) associated to the Plan will effectively purchase the shares. (Note that the share purchase will occur outside of the CapQuest ecosystem, and that on CapQuest users will manually “register” the transaction)."
                  className="rounded-lg bg-gray-50"
                  question="What is Strike price?"
                />
              </div>
            </div>
            <FormInput
              control={control}
              maxDecimalPlaces={2}
              name="stepOne.financialDetails.stock-options.conversionRatio"
              placeholder="Conversion ratio (x) (optional)"
              wrapperClassName="w-full"
            />
            <DropDown
              answer="The conversion ratio of the Grant (established at Plan level) indicates the conversion of the grant into a share class. In other words, Alice gets 1000 Grants that can have a conversion ratio on a Stock Options Plan of 2x. In such a way that when she exercises, she will get 2000 shares from the selected share class. This means that a Grant does not always have a 1-1 value with the selected share class underlying the pool"
              className="rounded-lg bg-gray-50"
              question="What is conversion ratio?"
            />
          </>
        )}
        {stepOne?.type === SharePlanType.WARRANTS && (
          <>
            <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 py-1">
              <span className="pl-[14px] pt-2 text-sm font-[450] text-brand-700">
                Warrant Price
              </span>
              <div className="flex flex-col items-center gap-3 rounded-md bg-white p-4 px-4 shadow-sm">
                <FormInput
                  control={control}
                  maxDecimalPlaces={2}
                  name="stepOne.financialDetails.warrants.value"
                  placeholder="Value"
                  wrapperClassName="w-full"
                />
                <DropDown
                  answer="A stock warrant gives the holder the right to purchase a company's stock at a specified price and on a specified date."
                  className="rounded-lg bg-gray-50"
                  question="What is Warrant price?"
                />
              </div>
            </div>
            <FormInput
              control={control}
              maxDecimalPlaces={2}
              name="stepOne.financialDetails.warrants.conversionRatio"
              placeholder="Conversion ratio (x) (optional)"
              wrapperClassName="w-full"
            />
            <DropDown
              answer="The conversion ratio of the Grant (established at Plan level) indicates the conversion of the grant into a share class. In other words, Alice gets 1000 Grants that can have a conversion ratio on a Stock Options Plan of 2x. In such a way that when she exercises, she will get 2000 shares from the selected share class. This means that a Grant does not always have a 1-1 value with the selected share class underlying the pool"
              className="rounded-lg bg-gray-50"
              question="What is conversion ratio?"
            />
          </>
        )}

        {stepOne?.type === SharePlanType.SAR && (
          <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 py-1">
            <span className="pl-[14px] pt-2 text-sm font-[450] text-brand-700">SAR Base Price</span>
            <div className="flex flex-col items-center gap-3 rounded-md bg-white p-4 px-4 shadow-sm">
              <FormInput
                control={control}
                maxDecimalPlaces={2}
                name="stepOne.financialDetails.sar.value"
                placeholder="Value"
                wrapperClassName="w-full"
              />
              <DropDown
                answer="Stock appreciation rights (SARs) are a type of employee compensation linked to the company's stock price during a predetermined period. SARs are profitable for employees when the company's stock price rises, which makes them similar to employee stock options (ESOs). However, employees do not have to pay the exercise price with SARs. Instead, they receive the sum of the increase in stock or cash."
                className="rounded-lg bg-gray-50"
                question="What is SAR Base Price?"
              />
            </div>
          </div>
        )}

        {stepOne?.type === SharePlanType.RSU && (
          <>
            <FormInput
              control={control}
              maxDecimalPlaces={2}
              name="stepOne.financialDetails.rsu.conversionRatio"
              placeholder="Conversion ratio (x) (optional)"
              wrapperClassName="w-full"
            />
          </>
        )}

        {stepOne?.type === SharePlanType.PHANTOM && (
          <>
            <FormInput
              control={control}
              maxDecimalPlaces={2}
              name="stepOne.financialDetails.phantom.conversionRatio"
              placeholder="Conversion ratio (x) (optional)"
              wrapperClassName="w-full"
            />
          </>
        )}

        {(stepOne?.type === SharePlanType.PHANTOM || stepOne?.type === SharePlanType.RSU) && (
          <DropDown
            answer="The conversion ratio of the Grant (established at Plan level) indicates the conversion of the grant into a share class. In other words, Alice gets 1000 Grants that can have a conversion ratio on a Stock Options Plan of 2x. In such a way that when she exercises, she will get 2000 shares from the selected share class. This means that a Grant does not always have a 1-1 value with the selected share class underlying the pool"
            className="rounded-lg bg-gray-50"
            question="What is conversion ratio?"
          />
        )}

        {stepOne?.type === SharePlanType.RSA && (
          <>
            <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 py-1">
              <span className="pl-[14px] pt-2 text-sm font-[450] text-brand-700">
                RSA Purchase Price
              </span>
              <div className="flex flex-col items-center gap-3 rounded-md bg-white p-4 px-4 shadow-sm">
                <FormInput
                  control={control}
                  maxDecimalPlaces={2}
                  name="stepOne.financialDetails.rsa.value"
                  placeholder="Value"
                  wrapperClassName="w-full"
                />
                <DropDown
                  answer=""
                  className="rounded-lg bg-gray-50"
                  question="What is RSA Purchase Price?"
                />
              </div>
            </div>
            <FormInput
              control={control}
              maxDecimalPlaces={2}
              name="stepOne.financialDetails.rsa.conversionRatio"
              placeholder="Conversion ratio (x) (optional)"
              wrapperClassName="w-full"
            />
            <DropDown
              answer="The conversion ratio of the Grant (established at Plan level) indicates the conversion of the grant into a share class. In other words, Alice gets 1000 Grants that can have a conversion ratio on a Stock Options Plan of 2x. In such a way that when she exercises, she will get 2000 shares from the selected share class. This means that a Grant does not always have a 1-1 value with the selected share class underlying the pool"
              className="rounded-lg bg-gray-50"
              question="What is conversion ratio?"
            />
          </>
        )}
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : (
              <AppFormattedMessage id={StringKey.NEXT} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
