import { FC, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, CloseIcon } from '../../assets/icons';
import Button from '../../components/Button';
import { Calendar } from '../../components/Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../../components/Popover';
import { EventHooks, useFormat, useModalState } from '../../hooks';
import { capTableStore } from '../../storage/capTableStore';
import { EventTypeBackend, SortByType, ValuationEvent } from '../../types/events.types';
import { CapTableActivityVariant, capTableActivityVariantTitle } from './variables';
export type CapTableSelectProps = {
  onSelectCapTable: (valuationId: string) => void;
  companyId: string;
  onSelectCustomCapTableDate: (date: Date | null) => void;
};
type CapTableSelect = {
  id: string;
  name: string;
};
export const CapTableSelect: FC<CapTableSelectProps> = ({
  onSelectCapTable,
  companyId,
  onSelectCustomCapTableDate,
}) => {
  const customCapTableDate = capTableStore.get()?.customCapTableDate;

  const [selectedVariant, setSelected] = useState(
    customCapTableDate
      ? capTableActivityVariantTitle[CapTableActivityVariant.CUSTOM_DATE]
      : capTableActivityVariantTitle[CapTableActivityVariant.LATEST_CAP_TABLE],
  );
  const { format } = useFormat();
  const [customDate, setCustomDate] = useState<Date | null>(
    customCapTableDate ? new Date(customCapTableDate) : null,
  );
  const { events } = EventHooks.useEvents({
    companyId,
    typesFilter: [SortByType.VALUATION],
  });
  const { isOpen, toggler } = useModalState();
  const valuationEvents = events.filter(
    (event) => event.type === EventTypeBackend.VALUATION,
  ) as ValuationEvent[];
  const selectOptions: CapTableSelect[] = useMemo(
    () => [
      {
        name: capTableActivityVariantTitle[CapTableActivityVariant.LATEST_CAP_TABLE],
        id: '',
      },
      ...valuationEvents,
      {
        name: capTableActivityVariantTitle[CapTableActivityVariant.CUSTOM_DATE],
        id: '',
      },
    ],
    [valuationEvents],
  );

  const handleValuationSelect = ({ id, name }: CapTableSelect) => {
    setSelected(name);
    if (name === capTableActivityVariantTitle[CapTableActivityVariant.CUSTOM_DATE]) return;
    capTableStore.set({ customCapTableDate: null });
    onSelectCustomCapTableDate(null);
    onSelectCapTable(id);
    toggler(false);
  };
  const handleCustomCapTableDate = (date: Date) => {
    onSelectCustomCapTableDate(date);
    toggler(false);
  };

  return (
    <>
      <Popover onOpenChange={toggler} open={isOpen}>
        <PopoverTrigger className="flex items-center gap-2 rounded border border-gray-200 bg-gray-50 px-3 py-2">
          <span className="text-sm font-[450] text-gray-700">{selectedVariant}</span>
          <ChevronDownIcon className="h-4 w-4 text-gray-400" />
        </PopoverTrigger>
        {selectedVariant === capTableActivityVariantTitle[CapTableActivityVariant.CUSTOM_DATE] ? (
          <PopoverContent className="flex w-auto flex-col gap-4 text-nowrap rounded-lg bg-gray-100 p-0 shadow-xl">
            <div className="flex flex-col gap-4 rounded-b-lg bg-white p-4">
              <div className="flex w-full items-center justify-between pb-4">
                <span className="text-xl font-semibold text-gray-700">Custom Date</span>
                <Button className="h-fit w-fit" onClick={() => toggler(false)} styleType="NONE">
                  <CloseIcon className="size-3" />
                </Button>
              </div>
              <div className="flex flex-col gap-2 text-sm font-[450] text-gray-700">
                <span>Date</span>
                <div className="flex h-10 w-full items-center rounded-t border-b border-b-gray-700 bg-gray-50 px-[10px]">
                  {customDate && format(customDate, 'dd/MM/yyyy')}
                </div>
              </div>
              <Calendar
                className="p-0"
                initialFocus
                mode="single"
                onSelect={(date) => {
                  if (!date) return;
                  setCustomDate(date);
                  capTableStore.set({ customCapTableDate: date });
                }}
                selected={customDate || undefined}
                toDate={new Date()}
              />
            </div>
            <div className="flex w-full items-center justify-between px-6 pb-4 text-sm font-[450] text-gray-700">
              <Button
                className="h-fit w-fit"
                onClick={() => handleValuationSelect(selectOptions[0])}
                styleType="NONE"
              >
                Cancel
              </Button>
              <div className="flex h-9 gap-3">
                <Button
                  className="rounded border border-gray-300 bg-white px-4"
                  onClick={() => setCustomDate(null)}
                  styleType="NONE"
                >
                  Reset
                </Button>
                <Button
                  className="w-fit px-4 font-[550] text-white"
                  onClick={() => customDate && handleCustomCapTableDate(customDate)}
                >
                  Select
                </Button>
              </div>
            </div>
          </PopoverContent>
        ) : (
          <PopoverContent className="flex max-h-[400px] w-auto max-w-[350px] flex-col gap-2 overflow-y-auto text-nowrap rounded p-2 shadow-xl lg:max-w-[700px]">
            {selectOptions.map((data) => (
              <span
                className={twMerge(
                  'block w-full cursor-pointer rounded px-2 py-[6px] text-sm text-gray-700 hover:bg-brand-25',
                )}
                key={`${data.id}_${data.name}`}
                onClick={() => handleValuationSelect(data)}
              >
                <p className="truncate">{data.name}</p>
              </span>
            ))}
          </PopoverContent>
        )}
      </Popover>
    </>
  );
};
