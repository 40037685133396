import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Stats } from '../../types/snapshot.types';
import { useAppQuery } from '../useAppQuery';

export const useStats = ({ companyId }: { companyId: string }) => {
  const queryClient = useQueryClient();

  const { data: stats, isLoading } = useAppQuery<Stats>({
    queryKey: [QueryKey.GET_STATS, companyId],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'stats'],
      }),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_STATS] });
  }, [queryClient]);

  return {
    invalidateQuery,
    shareClasses: stats?.shareClasses || [],
    stakeholders: stats?.stakeholders || [],
    stakeholdersTypes: stats?.stakeholdersTypes || [],
    isLoading,
  };
};
