import { Field, Label } from '@headlessui/react';
import { FC, useState } from 'react';

import { CloseIcon } from '../../../assets/icons';
import MinusIcon from '../../../assets/icons/MinusIcon';
import { useModalState } from '../../../hooks';
import { AlertDialogWrapper } from '../../AlertDialog';
import Button from '../../Button';
import { Checkbox } from '../../Checkbox';
import { Table } from '../../Table';
import { HurdleTableRow } from './HurdleTableRow';
import { ApproveHurdleColumn, approveHurdleColumnMap, approveHurdleColumnTitle } from './types';

export type ApproveHurdlePlanProps = {
  companyId: string;
};

const data = ['1', '2', '3', '4'];

export const ApproveHurdlePlan: FC<ApproveHurdlePlanProps> = () => {
  const { isOpen, toggler, handleCloseModal } = useModalState();
  const [selectedStakeholderIds, setSelectedStakeholderIds] = useState<string[]>([]);

  const onDeselectAll = () => setSelectedStakeholderIds([]);

  const onSelectAll = (stakeholdersId: string[]) => {
    setSelectedStakeholderIds(stakeholdersId);
  };

  return (
    <AlertDialogWrapper control={{ onOpenChange: toggler, open: isOpen }}>
      <div className="flex flex-col gap-8 rounded-2xl bg-white p-8">
        <div className="flex w-full justify-between">
          <span className="text-xl font-semibold text-gray-700">Approve Hurdle Plan</span>
          <Button
            className="centered flex size-7 rounded-full border border-gray-100 shadow-sm"
            onClick={handleCloseModal}
            styleType="NONE"
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="max-h-[400px] overflow-y-auto bg-white">
          <Table
            columns={approveHurdleColumnMap}
            columnsTitle={{
              ...approveHurdleColumnTitle,
              [ApproveHurdleColumn.SELECT]: (
                <Field className="flex cursor-pointer items-center">
                  <Checkbox
                    checked={selectedStakeholderIds.length > 0}
                    id="select-all-hurdle-plans"
                    onChange={(checked) =>
                      checked ? onSelectAll(data?.map((id) => id) || []) : onDeselectAll()
                    }
                    selectIcon={<MinusIcon />}
                  />
                  <Label
                    className="absolute left-0 top-0 h-full w-full cursor-pointer"
                    htmlFor="select-all-hurdle-plans"
                  />
                </Field>
              ),
            }}
            tHeadClassNames={{ thClassName: 'pl-4' }}
          >
            {data.map((id) => (
              <HurdleTableRow
                id={id}
                isSelected={selectedStakeholderIds.includes(id)}
                key={id}
                onRemoveSelect={(selectedId) =>
                  setSelectedStakeholderIds((prev) => prev.filter((id) => id !== selectedId))
                }
                onSelect={(selectedId) =>
                  setSelectedStakeholderIds((prev) => [...prev, selectedId])
                }
              />
            ))}
          </Table>
        </div>
        <div className="flex h-11 w-full justify-between overflow-x-auto text-sm">
          <Button className="w-fit font-[450] text-gray-700" styleType="NONE">
            Cancel
          </Button>
          <Button className="w-[260px] font-[550] text-white">Approve Hurdles</Button>
        </div>
      </div>
    </AlertDialogWrapper>
  );
};
