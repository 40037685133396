import { FC } from 'react';

import { SelectedCompanyCurrency } from '../../../../components/SelectedCompanyCurrency';
import { useFormat, ValuationHooks } from '../../../../hooks';
import { eventFormType, EventTypeBackend } from '../../../../types/events.types';
import { SharePlanType } from '../../../../types/pool-plans.types';
import { toNumber } from '../../../../utils/toNumber';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const Exercised: FC<CardProps> = ({ event, companyId }) => {
  const { format } = useFormat();
  const { valuation } = ValuationHooks.useCurrent({ companyId });

  if (event.type !== EventTypeBackend.EXERCISE) return <></>;

  const {
    type,
    exercise: {
      exercised,
      vestingTask: {
        grantItem: {
          numbersOfGrants,
          stakeholder,
          plan: {
            type: planType,
            pool: { shareClass },
          },
        },
      },
    },
    createdAt,
  } = event;

  if (planType === SharePlanType.STOCK_OPTIONS)
    return (
      <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm">
        <TransactionTitleItem
          createdAt={format(createdAt, 'dd/MM/yyyy')}
          name={eventFormType[type]}
        />
        <TransactionOverviewItem title={'Transaction Type'} value={eventFormType[type]} />
        <TransactionOverviewItem title={'Stakeholder'} value={stakeholder.fullName} />
        <TransactionOverviewItem title={'Exercised Warrants'} value={exercised} />
        <TransactionOverviewItem title={'Share Class'} value={shareClass.name} />
        <TransactionOverviewItem
          title={'Issued Shares'}
          value={numbersOfGrants.toLocaleString('en-US')}
        />
      </div>
    );

  if (planType === SharePlanType.WARRANTS)
    return (
      <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm">
        <TransactionTitleItem
          createdAt={format(createdAt, 'dd/MM/yyyy')}
          name={eventFormType[type]}
        />
        <TransactionOverviewItem title={'Transaction Type'} value={eventFormType[type]} />
        <TransactionOverviewItem title={'Stakeholder'} value={stakeholder.fullName} />
        <TransactionOverviewItem title={'Exercised Options'} value={exercised} />
        <TransactionOverviewItem title={'Share Class'} value={shareClass.name} />
        <TransactionOverviewItem
          title={'Issued Shares'}
          value={numbersOfGrants.toLocaleString('en-US')}
        />
      </div>
    );

  if (planType === SharePlanType.SAR)
    return (
      <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm">
        <TransactionTitleItem
          createdAt={format(createdAt, 'dd/MM/yyyy')}
          name={eventFormType[type]}
        />
        <TransactionOverviewItem title={'Transaction Type'} value={eventFormType[type]} />
        <TransactionOverviewItem title={'Stakeholder'} value={stakeholder.fullName} />
        <TransactionOverviewItem title={'SAR Exercised'} value={exercised} />
        <TransactionOverviewItem title={'Share Class'} value={shareClass.name} />
        <TransactionOverviewItem
          title={'SAR Payout'}
          value={
            <>
              <SelectedCompanyCurrency />
              {(exercised * toNumber(valuation?.sharePrice)).toLocaleString('en-US')}
            </>
          }
        />
        <TransactionOverviewItem
          title={'Issued Shares'}
          value={numbersOfGrants.toLocaleString('en-US')}
        />
      </div>
    );

  return <></>;
};
