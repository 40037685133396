import { FC, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useFakePagination, useLocale } from '../../../../../hooks';
import { stakeholderTypeTranslation } from '../../../../../translations/stakeholderTranslation';
import {
  Column,
  DetailsSortVariant,
  ShareClassColumns,
  ShareClassStats,
  StakeholderStats,
  ViewBy,
} from '../../../../../types/snapshot.types';
import { filterByAllFields } from '../../../../../utils/filterByAllFields';
import { toRound } from '../../../../../utils/getRoundedNumber';
import { toNumber } from '../../../../../utils/toNumber';
import { AmountOfFetchedItems } from '../../../variables';
import { TableRow } from '../TableRow';
import { PaginationItem } from './PaginationItem';

type StakeholderTableItemProps = {
  visibleColumns: Column[];
  viewBy: Record<ViewBy, boolean>;
  fields: Record<ShareClassColumns, string | number | null>;
  stakeholders?: StakeholderStats[];
  className?: string;
  id?: string;
  search?: string;
};

export const ShareClassTableItem: FC<StakeholderTableItemProps> = ({
  viewBy,
  visibleColumns,
  fields,
  stakeholders = [],
  className,
  id,
  search = '',
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsToFetch, setItemsToFetch] = useState(AmountOfFetchedItems.FIVE);

  const searchResult = useMemo(
    () => filterByAllFields(stakeholders, search),
    [stakeholders, search],
  );

  const { data, totalPages } = useFakePagination({
    currentPage,
    data: searchResult,
    take: Number(itemsToFetch),
  });
  const { messagesLocale } = useLocale();
  const [isExpanded, setExpanded] = useState(false);

  const isStakeholdersEmpty = stakeholders.length === 0;

  return (
    <>
      <TableRow
        fields={fields}
        handleExpandRow={setExpanded}
        isExpanded={isExpanded}
        isExpandedRow={isStakeholdersEmpty}
        tdClassName={className}
        trClassName={twMerge(isExpanded && 'bg-brand-25')}
        type={DetailsSortVariant.BY_SHARE_CLASS}
        viewBy={viewBy}
        visibleColumns={visibleColumns}
      />
      {isExpanded &&
        data.map(({ shareClasses, name, type }, i) => {
          const stakeholderShareClass =
            shareClasses.find((shareClass) => shareClass.id === id) ||
            ({} as DeepPartial<ShareClassStats>);

          const {
            diluted,
            dilutedPercentage,
            issued,
            issuedPercentage,
            votingPercentage,
            votingRight,
            investment,
            avgSharePrice,
          } = stakeholderShareClass;

          return (
            <TableRow
              className="bg-[#F9FAFB] shadow-[inset_2px_0px_0px_0px_#2565C8]"
              fields={{
                [Column.NAME]: name,
                [Column.SHARE_CLASS_NAME]: null,
                [Column.STAKEHOLDER_NAME]: name,
                [Column.TYPE]: messagesLocale[stakeholderTypeTranslation[type]],
                [Column.ISSUED]: toRound(toNumber(issuedPercentage) * 100),
                [Column.DILUTED]: toRound(toNumber(dilutedPercentage) * 100),
                [Column.VOTING]: toRound(toNumber(votingPercentage) * 100),
                [Column.NO_ISSUED]: toNumber(issued).toLocaleString('en-US'),
                [Column.DILUTED_SHARES]: toNumber(diluted).toLocaleString('en-US'),
                [Column.NO_OF_STAKEHOLDERS]: 0,
                [Column.VOTING_RIGHTS]: toNumber(votingRight).toLocaleString('en-US'),
                [Column.INVESTMENT]: toNumber(investment).toLocaleString('en-US'),
                [Column.AVERAGE_SHARE_PRICE]: toRound(avgSharePrice),
              }}
              isExpandedRow={true}
              key={`${i}_${id}`}
              trClassName="bg-brand-25"
              type={DetailsSortVariant.BY_SHARE_CLASS}
              viewBy={viewBy}
              visibleColumns={visibleColumns}
            />
          );
        })}

      {isExpanded && stakeholders.length > 3 && (
        <tr className="shadow-[inset_0px_-1px_0px_0px_#F2F4F7]">
          <td colSpan={visibleColumns.length}>
            <PaginationItem
              currentPage={currentPage}
              handleChangeCurrentPage={setCurrentPage}
              handleFetchAmountChange={setItemsToFetch}
              itemsToFetch={itemsToFetch}
              totalPages={totalPages}
            />
          </td>
        </tr>
      )}
    </>
  );
};
