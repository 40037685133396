import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { SharePlan } from '../../types/pool-plans.types';
import { useAppQuery } from '../useAppQuery';

export const useApproveHurdleSharePlans = ({ companyId }: { companyId: string }) => {
  const queryClient = useQueryClient();
  const { data: approveHurdleSharePlans = [], isLoading } = useAppQuery<SharePlan[]>({
    queryKey: [QueryKey.GET_APPROVE_SHARE_PLANS, companyId],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'share-plans', 'hurdle-approve'],
      }),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_APPROVE_SHARE_PLANS] });
  }, [queryClient]);

  return {
    invalidateQuery,
    approveHurdleSharePlans,
    isLoading,
  };
};
