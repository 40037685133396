import React, { FC } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

export const EmptyPositionGraph: FC = () => {
  const data = [
    { name: 'A', value: 600 },
    { name: 'B', value: 400 },
  ];
  return (
    <ResponsiveContainer height="100%" width="100%">
      <PieChart>
        <Pie cx="50%" cy="50%" data={data} dataKey="value" innerRadius="50%" outerRadius="100%">
          {data.map((_, index) => (
            <Cell
              fill="#F9FAFB"
              fillOpacity={1}
              key={index}
              stroke="#EAECF0"
              strokeDasharray="5 5"
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
