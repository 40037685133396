import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Pool } from '../../types/pool-plans.types';

type PoolsByStakeholders = {
  companyId: string;
  stakeholderId: string;
  take?: number;
  currentPage?: number;
};

type PoolsByStakeholdersResponse = {
  pools: Pool[];
  totalPages: number;
};

export const usePoolsByStakeholder = ({
  companyId,
  stakeholderId,
  currentPage = 0,
  take = 100,
}: PoolsByStakeholders) => {
  const queryClient = useQueryClient();

  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * take;
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteQuery<PoolsByStakeholdersResponse>({
      queryKey: [
        QueryKey.GET_POOLS_BY_STAKEHOLDER,
        companyId,
        stakeholderId,
        {
          take,
          skip,
        },
      ],
      queryFn: ({ pageParam = 0 }) =>
        ApiService.get(
          {
            endpoint: BackendRoute.COMPANIES,
            routePath: [companyId, 'stakeholders', stakeholderId, 'pools'],
          },
          {
            queryParams: {
              take,
              skip: currentPage ? skip : pageParam,
            },
          },
        ),
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.totalPages > allPages.length ? allPages.length * take : undefined,
      enabled: !!companyId && !!stakeholderId,
    });

  const poolList = useMemo(
    () =>
      data?.pages?.reduce<Pool[]>((acc, page) => {
        return [...acc, ...page.pools];
      }, []),
    [data?.pages],
  );

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_POOLS_BY_STAKEHOLDER] });
  };

  return {
    invalidateQuery,
    fetchNextPage,
    hasNextPage,
    pools: poolList || [],
    totalPages: data?.pages[0]?.totalPages || 0,
    isLoading,
    isEmpty: poolList?.length === 0,
  };
};
