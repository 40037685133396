import React, { memo, ReactElement } from 'react';
import { Control, FieldPathByValue, FieldValues, PathValue, useController } from 'react-hook-form';

import Checkbox, { CheckboxProps } from './Checkbox';

export type FormCheckboxProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
> = {
  control: Control<TFieldValues>;
  defaultValue?: PathValue<TFieldValues, TPath>;
  name: TPath;
} & Omit<CheckboxProps, 'defaultValue' | 'onBlur' | 'onChange' | 'value'>;

const FormCheckbox = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
>({
  control,
  defaultValue,
  name,
  ...props
}: FormCheckboxProps<TFieldValues, TPath>): ReactElement | null => {
  const { field } = useController({
    control,
    defaultValue,
    name,
  });
  return (
    <Checkbox
      {...props}
      {...field}
      checked={field.value}
      defaultChecked={defaultValue}
      defaultValue={field.value}
    />
  );
};

export default memo(FormCheckbox) as typeof FormCheckbox;
