import { Company } from './companyTypes';
import { ShareClass } from './share-classes.types';

export enum DilutionCondition {
  INCLUDE_CAP_TABLE = 'include-cap-table',
  EXCLUDE_CAP_TABLE = 'exclude-cap-table',
}

export const DilutionConditionTitle: Record<DilutionCondition, string> = {
  [DilutionCondition.INCLUDE_CAP_TABLE]: 'Include in Cap Table',
  [DilutionCondition.EXCLUDE_CAP_TABLE]: 'Exclude from Cap Table',
};

export enum SharePlanType {
  GROWTH_HURDLE = 'growth-hurdle',
  STOCK_OPTIONS = 'stock-options',
  WARRANTS = 'warrants',
  SAR = 'sar',
  RSU = 'rsu',
  PHANTOM = 'phantom',
  RSA = 'rsa',
  COMMON_STOCK = 'common-stock',
  BSPCE = 'bspce',
}

export const sharePlanTypeTitle: Record<SharePlanType, string> = {
  [SharePlanType.GROWTH_HURDLE]: 'Growth / Hurdle',
  [SharePlanType.STOCK_OPTIONS]: 'Stock Options',
  [SharePlanType.WARRANTS]: 'Warrants',
  [SharePlanType.SAR]: 'SAR',
  [SharePlanType.RSU]: 'RSU',
  [SharePlanType.PHANTOM]: 'Phantom',
  [SharePlanType.RSA]: 'RSA',
  [SharePlanType.COMMON_STOCK]: 'Common Stock',
  [SharePlanType.BSPCE]: 'BSPCE',
};

export enum SharePlanVestingType {
  TIME_BASED_STANDARD = 'time-based-standard',
  TIME_BASED_DYNAMIC = 'time-based-dynamic',
  TARGET_BASED = 'target-based',
}

export const sharePlanVestingTypeTitle: Record<
  SharePlanVestingType,
  { name: string; description: string }
> = {
  [SharePlanVestingType.TARGET_BASED]: { description: 'Standard', name: 'Target Based' },
  [SharePlanVestingType.TIME_BASED_DYNAMIC]: { description: 'Dynamic', name: 'Time Based' },
  [SharePlanVestingType.TIME_BASED_STANDARD]: { description: 'Standard', name: 'Time Based' },
};

export enum VestingOnOption {
  GRANT_DATE = 'grant-date',
  FIRST_DATE = 'first-date',
  LAST_DAY = 'last-day',
}

export const VestingOnTitle: Record<VestingOnOption, string> = {
  [VestingOnOption.GRANT_DATE]: 'Grant Date',
  [VestingOnOption.FIRST_DATE]: 'First Day of the Month',
  [VestingOnOption.LAST_DAY]: 'Last Day of the Month',
};
export enum TerminationInterval {
  MONTH = 'month',
  YEAR = 'year',
}

export const terminationIntervalTitle: Record<TerminationInterval, string> = {
  [TerminationInterval.MONTH]: 'Months',
  [TerminationInterval.YEAR]: 'Years',
};

export enum PoolsPlansFilter {
  BY_POOLS = 'by pools',
  BY_STAKEHOLDER = 'by stakeholder',
}

export enum PoolsPlansSelect {
  NEW_POOL = 'new pool',
  NEW_PLAN = 'new plan',
}

export type SelectVariant = 'add' | 'export' | PoolsPlansSelect;

export enum NumberOfFetchedItems {
  FIVE = '5',
  TEN = '10',
  TWENTY = '20',
  FIFTY = '50',
  HUNDRED = '100',
}

export type SelectNumberOfFetchedItems = NumberOfFetchedItems;

export enum PoolsPlansTableColumn {
  DILUTED_SHARES = 'diluted shares',
  AVAILABLE_POOL = 'available pool',
  POOL_GRANTED = 'pool granted',
  GRANT_VESTED = 'total grant vested',
  SHARES_VESTED = 'shares vested',
  EXERCISABLE = 'exercisable',
  EXERCISED = 'exercised',
  CASH_SETTLED = 'cash settled',
  RETURNED_TO_POOL = 'returned to pool',
  EXPIRED = 'expired',
}

export enum StakeholderPoolsPlansTableColumn {
  POOL_GRANTED = 'pool granted',
  GRANT_VESTED = 'total grant vested',
  SHARES_VESTED = 'shares vested',
  EXERCISABLE = 'exercisable',
  EXERCISED = 'exercised',
  CASH_SETTLED = 'cash settled',
  RETURNED_TO_POOL = 'returned to pool',
  EXPIRED = 'expired',
}

export enum PoolPlansTab {
  ACTIVE = 'active',
  TERMINATED = 'terminated',
}

export enum ExerciseType {
  STOCK_OPTIONS = 'stock-options',
  RSA = 'rsa',
  WARRANTS = 'warrants',
  SAR = 'sar',
  HURDLE = 'hurdle',
  PHANTOM = 'phantom',
  BSPCE = 'bspce',
}

export const ExerciseTypeTitle: Record<ExerciseType, string> = {
  [ExerciseType.STOCK_OPTIONS]: 'Exercise Stock Options',
  [ExerciseType.RSA]: 'Exercise RSA',
  [ExerciseType.WARRANTS]: 'Exercise / Expire Warrants',
  [ExerciseType.SAR]: 'Exercise SARs',
  [ExerciseType.HURDLE]: 'Approve Hurdle Plan',
  [ExerciseType.PHANTOM]: 'Payout Phantom Shares',
  [ExerciseType.BSPCE]: 'Exercise BSPCE',
};

export enum ExerciseTab {
  EXERCISE = 'exercise',
  EXPIRE = 'expire',
}

export type CreatePoolDto = {
  name: string;
  dillutedSharesCount: number;
  shareClassId: string;
  date: string;
  capTableInclusion: boolean;
  filesLinks: string[];
  additionalNotes?: string;
};

export type PatchPoolDto = {
  name?: string;
  dillutedSharesCount?: number;
  shareClassId?: string;
  date?: string;
  capTableInclusion?: boolean;
  filesLinks?: string[];
  additionalNotes?: string;
};
export type TerminatePoolDto = {
  date: string;
};

export type Pool = {
  id: string;
  name: string;
  dillutedSharesCount: number;
  shareClass: ShareClass;
  date: Date;
  capTableInclusion: boolean;
  filesLinks: string[];
  additionalNotes: string;
  isTerminated: boolean;
  terminationDate?: Date;
  sharePlansCount: number;
  granted: number;
  totalGrantVested: number;
  sharesVested: number;
  exercisable: number;
  exercised: number;
  expired: number;
  returnedToPool: number;
};

export interface VestingSettingsCommon {
  type: SharePlanVestingType;
}

export interface TimeBasedStandardVestingSettings extends VestingSettingsCommon {
  type: SharePlanVestingType.TIME_BASED_STANDARD;
  vestingOn: VestingOnOption;
  durationMonths: number;
  frequencyMonths: number;
  cliffMonths: number;
  accelarationDate: Date;
}

export interface TimeBasedDynamicVestingPeriod {
  durationMonths: number;
  frequencyMonths: number;
  planAllocation: number;
}

export interface TimeBasedDynamicVestingSettings extends VestingSettingsCommon {
  type: SharePlanVestingType.TIME_BASED_DYNAMIC;
  vestingOn: VestingOnOption;
  periods: TimeBasedDynamicVestingPeriod[];
  accelarationDate: Date;
}

export interface TargetBasedVestingMilestone {
  name: string;
  weight: number;
  targetDate: Date;
}

export interface TargetBasedVestingSettings extends VestingSettingsCommon {
  type: SharePlanVestingType.TARGET_BASED;
  milestones: TargetBasedVestingMilestone[];
}

export type VestingSettingsUnion =
  | TimeBasedStandardVestingSettings
  | TimeBasedDynamicVestingSettings
  | TargetBasedVestingSettings;

export enum TerminationCause {
  RESIGNATION = 'resignation',
  TERMINATION = 'termination',
  TERMINATION_WITH_CAUSE = 'termination-with-cause',
  DEATH = 'death',
  RETIREMENT = 'retirement',
}

export interface TerminationSpecifier {
  value: number;
  interval: TerminationInterval;
}

export type TerminationLogic = Record<TerminationCause, TerminationSpecifier | null>;

export interface SharePlanCommon {
  id: string;
  name: string;
  pool: Pool;
  date: Date;
  type: SharePlanType;
  isTerminated: boolean;
  terminationDate?: Date;
  vesting?: VestingSettingsUnion;
  terminationLogic?: TerminationLogic;
  filesLinks: string[];
  additionalNotes?: string;
  granted: number;
  totalGrantVested: number;
  sharesVested: number;
  exercisable: number;
  exercised: number;
  expired: number;
}

export interface ConvertionableSharePlan {
  conversionRatio: number;
}

export interface GrowthHurdleSharePlan extends SharePlanCommon {
  type: SharePlanType.GROWTH_HURDLE;
  hardleValue: number;
}

export interface StockOptionsSharePlan extends SharePlanCommon, ConvertionableSharePlan {
  type: SharePlanType.STOCK_OPTIONS;
  strikePrice: number;
}

export interface WarrantsSharePlan extends SharePlanCommon, ConvertionableSharePlan {
  type: SharePlanType.WARRANTS;
  warrantPrice: number;
  expiryDate?: Date;
}

export interface SARSharePlan extends SharePlanCommon {
  type: SharePlanType.SAR;
  sarBasePrice: number;
}

export interface RSUSharePlan extends SharePlanCommon, ConvertionableSharePlan {
  type: SharePlanType.RSU;
}

export interface PhantomSharePlan extends SharePlanCommon, ConvertionableSharePlan {
  type: SharePlanType.PHANTOM;
}

export interface RSASharePlan extends SharePlanCommon, ConvertionableSharePlan {
  type: SharePlanType.RSA;
  rsaPurchasePrice?: number;
}

export interface CommonStockSharePlan extends SharePlanCommon {
  type: SharePlanType.COMMON_STOCK;
}

export interface BSPCESharePlan extends SharePlanCommon, ConvertionableSharePlan {
  type: SharePlanType.BSPCE;
  strikePrice: number;
}

export type SharePlanUnionType =
  | GrowthHurdleSharePlan
  | StockOptionsSharePlan
  | WarrantsSharePlan
  | SARSharePlan
  | RSUSharePlan
  | PhantomSharePlan
  | RSASharePlan
  | CommonStockSharePlan
  | BSPCESharePlan;

export type AutomaticVestingSharePlanUnionType =
  | GrowthHurdleSharePlan
  | SARSharePlan
  | RSUSharePlan
  | PhantomSharePlan
  | CommonStockSharePlan
  | BSPCESharePlan;

export const automaticVestingSharePlanTypeMap = [
  SharePlanType.GROWTH_HURDLE,
  SharePlanType.SAR,
  SharePlanType.RSU,
  SharePlanType.PHANTOM,
  SharePlanType.COMMON_STOCK,
  SharePlanType.BSPCE,
];

export const exercisableVestingSharePlanTypeMap = [
  SharePlanType.STOCK_OPTIONS,
  SharePlanType.WARRANTS,
  SharePlanType.RSA,
];

export type DefinedSharePlan<T extends SharePlanType> = Extract<SharePlanUnionType, { type: T }>;

export type TargetBasedMilestone = {
  name: string;
  weight: number;
  targetDate: Date;
  isComplete: boolean;
  sharePlanId: string;
};

export type SharePlan = {
  id: string;
  company: Company;
  name: string;
  pool: Pool;
  date: Date;
  type: SharePlanType;
  isTerminated: boolean;
  terminationDate?: Date;
  vesting?: VestingSettingsUnion;
  terminationLogic?: TerminationLogic;
  additionalNotes: string;
  hardleValue?: number;
  filesLinks: string[];
  conversionRatio?: number;
  strikePrice?: number;
  warrantPrice?: number;
  basePrice?: number;
  expiryDate?: Date;
  rsaPurchasePrice?: number;
  sarBasePrice?: number;
};
export type TerminationSpecifierDto = {
  value: number;
  interval: TerminationInterval;
};

export type TerminationLogicDto = {
  [TerminationCause.RESIGNATION]: TerminationSpecifierDto | null;
  [TerminationCause.TERMINATION]: TerminationSpecifierDto | null;
  [TerminationCause.TERMINATION_WITH_CAUSE]: TerminationSpecifierDto | null;
  [TerminationCause.DEATH]: TerminationSpecifierDto | null;
  [TerminationCause.RETIREMENT]: TerminationSpecifierDto | null;
};

export type TimeBasedDynamicVestingPeriodDto = {
  durationMonths: number;
  frequencyMonths: number;
  planAllocation: number;
};

export type TargetBasedVestingMilestoneDto = {
  name: string;
  weight: number;
  targetDate: string;
};

export type VestingSettingsDto = {
  type: SharePlanVestingType;
  vestingOn?: VestingOnOption;
  durationMonths?: number;
  frequencyMonths?: number;
  cliffMonths?: number;
  accelarationDate?: string;
  periods?: TimeBasedDynamicVestingPeriodDto[];
  milestones?: TargetBasedVestingMilestoneDto[];
};

export type CreateSharePlanDto = {
  name: string;
  poolId: string;
  date: string;
  type: SharePlanType;
  vesting: VestingSettingsDto | null;
  terminationLogic: TerminationLogicDto | null;
  filesLinks: string[];
  additionalNotes?: string;
  conversionRatio?: number;
  hardleValue?: number;
  strikePrice?: number;
  warrantPrice?: number;
  expiryDate?: string | null;
  sarBasePrice?: number;
  rsaPurchasePrice?: number;
};

export type PatchSharePlanDto = {
  name?: string;
  poolId?: string;
  date?: string;
  type?: SharePlanType;
  vesting?: VestingSettingsDto | null;
  terminationLogic?: TerminationLogicDto | null;
  filesLinks?: string[];
  additionalNotes?: string;
  conversionRatio?: number;
  hardleValue?: number;
  strikePrice?: number;
  warrantPrice?: number;
  expiryDate?: string | null;
  sarBasePrice?: number;
  rsaPurchasePrice?: number;
};
