import { ReactNode } from 'react';

import { ViewBy } from '../../../../types/snapshot.types';
import { SnapshotViewByAll } from './SnapshotAll';
import { SnapshotViewByStakeholder } from './SnapshotStakeholder';
import { SnapshotViewByType } from './SnapshotType';
import { SnapshotViewByProps } from './type';

export const snapshotViewBy: Record<ViewBy | 'all', (props: SnapshotViewByProps) => ReactNode> = {
  [ViewBy.STAKEHOLDERS]: (props) => <SnapshotViewByStakeholder {...props} />,
  [ViewBy.TYPE]: (props) => <SnapshotViewByType {...props} />,
  all: (props) => <SnapshotViewByAll {...props} />,
};
