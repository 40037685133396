import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { SummaryItem } from '../../../../components/SummaryItem';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  SharePlanType,
  sharePlanTypeTitle,
  SharePlanVestingType,
  terminationIntervalTitle,
  VestingOnTitle,
} from '../../../../types/pool-plans.types';
import { ChildrenFormProps } from '../type';

export type FinalStepProps = ChildrenFormProps;

export const formatSingular = (value: number | undefined, formatString: string) =>
  value ? (value > 1 ? `${value} ${formatString}s` : `${value} ${formatString}`) : '-';

export const FinalStep: FC<FinalStepProps> = ({
  handleCloseModal,
  nextFormStep,
  formData,
  prevFormStep,
}) => {
  const { format } = useFormat();
  const { stepOne, stepTwo, stepThree, stepFour } = formData();

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">General</span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-0">
            <SummaryItem className="pt-0" title="Plan name" value={stepOne.name} />
            <SummaryItem title="Pool" value={stepOne.pool.name} />
            <SummaryItem title="Date" value={format(stepOne.date, 'dd/MM/yyyy')} />
            <SummaryItem title="Equity plan type" value={sharePlanTypeTitle[stepOne.type]} />
            {stepOne.type === SharePlanType.GROWTH_HURDLE && (
              <SummaryItem
                title="Hurdle value"
                value={
                  stepOne?.financialDetails['growth-hurdle']?.value &&
                  Number(stepOne?.financialDetails['growth-hurdle']?.value).toLocaleString('en-US')
                }
              />
            )}
            {stepOne.type === SharePlanType.STOCK_OPTIONS && (
              <>
                <SummaryItem
                  title="Strike Price value"
                  value={stepOne?.financialDetails['stock-options']?.value}
                />
                <SummaryItem
                  title="Conversion ratio (x)"
                  value={stepOne?.financialDetails['stock-options']?.conversionRatio || '-'}
                />
              </>
            )}
            {stepOne.type === SharePlanType.BSPCE && (
              <>
                <SummaryItem
                  title="Strike Price value"
                  value={stepOne?.financialDetails['bspce']?.value}
                />
                <SummaryItem
                  title="Conversion ratio (x)"
                  value={stepOne?.financialDetails['bspce']?.conversionRatio || '-'}
                />
              </>
            )}
            {stepOne.type === SharePlanType.WARRANTS && (
              <>
                <SummaryItem
                  title="Warrant Price value"
                  value={stepOne?.financialDetails['warrants']?.value}
                />
                <SummaryItem
                  title="Conversion ratio (x)"
                  value={stepOne?.financialDetails['warrants']?.conversionRatio || '-'}
                />
              </>
            )}
            {stepOne.type === SharePlanType.SAR && (
              <SummaryItem
                title="SAR Base Price value"
                value={stepOne?.financialDetails['sar']?.value}
              />
            )}
            {stepOne.type === SharePlanType.RSU && (
              <SummaryItem
                title="Conversion ratio (x)"
                value={stepOne?.financialDetails['rsu']?.conversionRatio || '-'}
              />
            )}
            {stepOne.type === SharePlanType.PHANTOM && (
              <SummaryItem
                title="Conversion ratio (x)"
                value={stepOne?.financialDetails['phantom']?.conversionRatio || '-'}
              />
            )}
            {stepOne.type === SharePlanType.RSA && (
              <>
                <SummaryItem
                  title="RSA Purchase Price value"
                  value={stepOne?.financialDetails['rsa']?.value || '-'}
                />
                <SummaryItem
                  title="Conversion ratio (x)"
                  value={stepOne?.financialDetails['rsa']?.conversionRatio || '-'}
                />
              </>
            )}
          </div>
        </div>
        {stepTwo?.vesting?.enabled && (
          <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
            <span className="px-4 text-sm font-[550] text-gray-700">Vesting</span>
            <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
              {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_STANDARD && (
                <>
                  <div>
                    <SummaryItem
                      className="pt-0"
                      title={<AppFormattedMessage id={StringKey.TYPE} />}
                      value="Time Based (Standard)"
                    />
                    <SummaryItem
                      className="pt-0"
                      title="Vesting on"
                      value={
                        stepTwo.vesting?.timeBasedStandard?.vestingOn &&
                        VestingOnTitle[stepTwo.vesting?.timeBasedStandard?.vestingOn]
                      }
                    />
                  </div>
                  <div>
                    <SummaryItem
                      className="pb-0"
                      title="Duration"
                      value={formatSingular(
                        stepTwo?.vesting?.timeBasedStandard?.vestingDuration,
                        'month',
                      )}
                    />
                    <SummaryItem
                      className="pb-0"
                      title="Vesting freq"
                      value={formatSingular(
                        stepTwo?.vesting?.timeBasedStandard?.vestingFrequency,
                        'month',
                      )}
                    />
                    <SummaryItem
                      className="pb-0"
                      title="Cliff"
                      value={formatSingular(
                        stepTwo?.vesting?.timeBasedStandard?.cliffPeriod,
                        'month',
                      )}
                    />
                    <SummaryItem
                      className={!stepTwo?.expiryDate?.enabled && 'pb-0'}
                      title="Acceleration date"
                      value={
                        stepTwo?.vesting?.timeBasedStandard?.accelerationDate
                          ? format(
                              stepTwo?.vesting?.timeBasedStandard?.accelerationDate,
                              'dd/MM/yyyy',
                            )
                          : '-'
                      }
                    />
                  </div>
                </>
              )}
              {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_DYNAMIC && (
                <>
                  <div>
                    <SummaryItem
                      className="pt-0"
                      title={<AppFormattedMessage id={StringKey.TYPE} />}
                      value="Time Based (Dynamic)"
                    />
                    <SummaryItem
                      className="pt-0"
                      title="Vesting on"
                      value={
                        stepTwo.vesting?.timeBasedDynamic?.vestingOn &&
                        VestingOnTitle[stepTwo.vesting?.timeBasedDynamic?.vestingOn]
                      }
                    />
                  </div>

                  <div className="flex flex-col divide-y divide-gray-100">
                    {stepTwo?.vesting?.timeBasedDynamic?.dynamicVestings.map((period, index) => (
                      <div className="pt-3" key={index}>
                        <span className="text-xs font-[550] text-gray-700">Period {index + 1}</span>
                        <SummaryItem
                          className="pb-0"
                          title="Duration"
                          value={formatSingular(period.vestingDuration, 'month')}
                        />
                        <SummaryItem
                          className="pb-0"
                          title="Vesting freq"
                          value={formatSingular(period.vestingFrequency, 'month')}
                        />
                        <SummaryItem title="Plan allocation" value={`${period.planAllocation} %`} />
                      </div>
                    ))}
                  </div>
                  <SummaryItem
                    className="pb-0"
                    title="Acceleration date"
                    value={
                      stepTwo?.vesting?.timeBasedDynamic?.accelerationDate
                        ? format(stepTwo?.vesting?.timeBasedDynamic?.accelerationDate, 'dd/MM/yyyy')
                        : '-'
                    }
                  />
                </>
              )}
              {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TARGET_BASED && (
                <>
                  <SummaryItem
                    className="pt-0"
                    title={<AppFormattedMessage id={StringKey.TYPE} />}
                    value="Target Based"
                  />
                  {stepTwo?.vesting?.targetBasedStandard?.targetVestings.map((period, index) => (
                    <div className="pt-3" key={index}>
                      <span className="text-xs font-[550] text-gray-700">
                        Milestone {index + 1}
                      </span>
                      <SummaryItem className="pb-0" title="Milestone name" value={period?.name} />
                      <SummaryItem className="pb-0" title="Weight" value={`${period?.weight} %`} />
                      <SummaryItem
                        title="Target date"
                        value={period.targetDate ? format(period.targetDate, 'dd/MM/yyyy') : '-'}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        )}

        {stepTwo?.expiryDate?.enabled && (
          <div className="w-full rounded-lg border border-gray-100 bg-white px-4 py-2">
            <SummaryItem
              className="p-0"
              title="Expiry date"
              value={format(stepTwo.expiryDate.date, 'dd/MM/yyyy')}
            />
          </div>
        )}

        {stepThree?.terminationLogic?.enabled && (
          <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
            <span className="px-4 text-sm font-[550] text-gray-700">Termination Logic</span>
            <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-0">
              {stepThree?.terminationLogic?.resignation?.enabled ? (
                <div>
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.RESIGNATION} />}
                    value="Exercise Expiration Period"
                  />
                  <SummaryItem
                    className="pb-0"
                    title="Value"
                    value={stepThree?.terminationLogic.resignation.value}
                  />
                  <SummaryItem
                    title="Interval"
                    value={`${terminationIntervalTitle[stepThree.terminationLogic.resignation.interval]}`}
                  />
                </div>
              ) : (
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.RESIGNATION} />}
                  value="No Exercise Expiration Period"
                />
              )}
              {stepThree?.terminationLogic?.termination?.enabled ? (
                <div>
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.TERMINATION} />}
                    value="Exercise Expiration Period"
                  />
                  <SummaryItem
                    className="pb-0"
                    title="Value"
                    value={stepThree.terminationLogic.termination.value}
                  />
                  <SummaryItem
                    title="Interval"
                    value={`${terminationIntervalTitle[stepThree.terminationLogic.termination.interval]}`}
                  />
                </div>
              ) : (
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.TERMINATION} />}
                  value="No Exercise Expiration Period"
                />
              )}
              {stepThree?.terminationLogic?.terminationWithCause?.enabled ? (
                <div>
                  <SummaryItem
                    className="pb-0"
                    title="Termination with cause"
                    value="Exercise Expiration Period"
                  />
                  <SummaryItem
                    className="pb-0"
                    title="Value"
                    value={stepThree.terminationLogic.terminationWithCause.value}
                  />
                  <SummaryItem
                    title="Interval"
                    value={`${terminationIntervalTitle[stepThree.terminationLogic.terminationWithCause.interval]}`}
                  />
                </div>
              ) : (
                <SummaryItem title="Termination with cause" value="No Exercise Expiration Period" />
              )}
              {stepThree?.terminationLogic?.death?.enabled ? (
                <div>
                  <SummaryItem className="pb-0" title="Death" value="Exercise Expiration Period" />
                  <SummaryItem
                    className="pb-0"
                    title="Value"
                    value={stepThree.terminationLogic.death.value}
                  />
                  <SummaryItem
                    title="Interval"
                    value={`${terminationIntervalTitle[stepThree.terminationLogic.death.interval]}`}
                  />
                </div>
              ) : (
                <SummaryItem title="Death" value="No Exercise Expiration Period" />
              )}
              {stepThree?.terminationLogic?.retirement?.enabled ? (
                <div>
                  <SummaryItem
                    className="pb-0"
                    title="Retirement"
                    value="Exercise Expiration Period"
                  />
                  <SummaryItem
                    className="pb-0"
                    title="Value"
                    value={stepThree?.terminationLogic.retirement.value}
                  />
                  <SummaryItem
                    title="Interval"
                    value={`${terminationIntervalTitle[stepThree.terminationLogic.retirement.interval]}`}
                  />
                </div>
              ) : (
                <SummaryItem title="Retirement" value="No Exercise Expiration Period" />
              )}
            </div>
          </div>
        )}

        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className={'flex gap-3 text-nowrap px-4 py-2'}>
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              Documents:
            </span>
            <div className="flex max-w-[220px] flex-col gap-2">
              {stepFour?.files && stepFour?.files?.length > 0 ? (
                stepFour.files.map(({ id, doc }) => (
                  <span className="truncate text-xs font-[450] text-gray-700" key={id}>
                    {doc.name}
                  </span>
                ))
              ) : (
                <span className="truncate text-xs font-[450] text-gray-700">-</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <SummaryItem
            className="px-4 py-2"
            title="Additional notes"
            value={stepFour?.additionalNotes || '-'}
          />
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
            onClick={prevFormStep}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.BACK} />
          </Button>

          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            onClick={nextFormStep}
            type="button"
          >
            Add Plan
          </Button>
        </div>
      </div>
    </>
  );
};
