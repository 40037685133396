import React, { FC, useState } from 'react';

import { CloseIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { Flag, FlagCode } from '../../components/Flag';
import { Input } from '../../components/Input';
import Loader from '../../components/Loader';
import { DeprecatedModalWrapper } from '../../components/Modal';
import { Company, useFormat, useLocale } from '../../hooks';
import { StringKey } from '../../lang';
import { inviteStakeholderStore } from '../../storage/invitedStakeholder';
import { stakeholderTypeTranslation } from '../../translations/stakeholderTranslation';
import { b2bStakeholderTypes, b2cStakeholderTypes } from '../../types/stakeholderTypes';

const InviteModal: FC = () => {
  const { format } = useFormat();
  const invitedStakeholder = inviteStakeholderStore.get();
  const [isOpenModal, setOpen] = useState<boolean>(!!invitedStakeholder || false);
  const { messagesLocale } = useLocale();
  const { selectedCompany, isLoading } = Company.useSelected();

  const handleClose = () => {
    setOpen(false);
    inviteStakeholderStore.delete();
  };
  if (isOpenModal && (!selectedCompany || isLoading)) return <Loader />;

  const stakeholder = selectedCompany?.stakeholder;

  const birthDayDate = stakeholder?.birthday ? new Date(stakeholder.birthday) : null;
  const passportExpDate = stakeholder?.passportExpDate
    ? new Date(stakeholder.passportExpDate)
    : null;
  const incDate = stakeholder?.incDate ? new Date(stakeholder.incDate) : null;

  const formattedBirthDate = birthDayDate
    ? format(
        new Date(birthDayDate?.getFullYear(), birthDayDate?.getMonth() - 1, birthDayDate?.getDay()),
        'dd MMMM yyyy',
      )
    : '-';
  const formattedPassportExpDate = passportExpDate
    ? format(
        new Date(
          passportExpDate?.getFullYear(),
          passportExpDate?.getMonth() - 1,
          passportExpDate?.getDay(),
        ),
        'dd MMMM yyyy',
      )
    : '-';
  const formattedIncDate = incDate
    ? format(
        new Date(incDate?.getFullYear(), incDate?.getMonth() - 1, incDate?.getDay()),
        'dd MMMM yyyy',
      )
    : '-';

  return (
    <DeprecatedModalWrapper isOpen={isOpenModal} mode="BLUR">
      <div className="relative flex w-full max-w-[600px] flex-col gap-8 rounded-xl border border-gray-200 bg-white p-6 shadow-sm">
        <Button
          className="absolute right-4 top-4 flex size-8 items-center justify-center rounded-full shadow-sm"
          onClick={handleClose}
          styleType="NONE"
        >
          <CloseIcon />
        </Button>
        <div className="flex flex-col gap-4 overflow-hidden text-center">
          <span
            className="truncate !bg-clip-text pr-4 text-4xl-mobile font-bold text-transparent lg:text-4xl"
            style={{
              background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
            }}
          >
            <AppFormattedMessage id={StringKey.WELCOME} />
            {stakeholder?.type && !b2cStakeholderTypes.includes(stakeholder?.type)
              ? stakeholder?.fullName + ','
              : ','}
          </span>
          <span className="text-xs font-medium text-gray-600">
            <AppFormattedMessage id={StringKey.BEFORE_YOU_START_YOUR_JOURNEY_CONFIRMATION} />
          </span>
        </div>
        <div className="flex w-full flex-col gap-4">
          <div className="flex gap-4">
            {stakeholder?.type && b2bStakeholderTypes.includes(stakeholder?.type) && (
              <>
                <div className="flex w-full flex-col gap-4">
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.FULL_NAME} />}
                    value={stakeholder?.fullName || '-'}
                    wrapperClassName="w-full"
                  />
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.STAKEHOLDER_TYPE} />}
                    value={
                      stakeholder?.type
                        ? messagesLocale[stakeholderTypeTranslation[stakeholder?.type]]
                        : '-'
                    }
                    wrapperClassName="w-full"
                  />
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.PASSPORT_EXPIRY_DATE} />}
                    value={stakeholder.passportNo || '-'}
                    wrapperClassName="w-full"
                  />
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.BIRTHDAY} />}
                    value={formattedBirthDate}
                    wrapperClassName="w-full"
                  />
                </div>
                <div className="flex w-full flex-col gap-4">
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.EMAIL} />}
                    value={stakeholder?.email || '-'}
                    wrapperClassName="w-full"
                  />
                  <Input
                    disabled
                    iconBeforeInput={
                      <Flag
                        className="mt-5"
                        countryCode={stakeholder.nationalityCountry?.iso2.toUpperCase() as FlagCode}
                      />
                    }
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.NATIONALITY} />}
                    value={stakeholder?.nationalityCountry?.name || '-'}
                    wrapperClassName="w-full"
                  />
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.PASSPORT_EXPIRY_DATE} />}
                    value={formattedPassportExpDate}
                    wrapperClassName="w-full"
                  />
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.PHONE_NUMBER} />}
                    value={stakeholder?.phone || '-'}
                    wrapperClassName="w-full"
                  />
                </div>
              </>
            )}

            {stakeholder?.type && b2cStakeholderTypes.includes(stakeholder.type) && (
              <>
                <div className="flex w-full flex-col gap-4">
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.FULL_NAME} />}
                    value={stakeholder?.fullName || '-'}
                    wrapperClassName="w-full"
                  />
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.STAKEHOLDER_TYPE} />}
                    value={
                      stakeholder?.type
                        ? messagesLocale[stakeholderTypeTranslation[stakeholder?.type]]
                        : '-'
                    }
                    wrapperClassName="w-full"
                  />
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.COMPANY_IDENTIFIER} />}
                    value={stakeholder?.stakeholderCompanyId || '-'}
                    wrapperClassName="w-full"
                  />
                </div>
                <div className="flex w-full flex-col gap-4">
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.EMAIL} />}
                    value={stakeholder?.email || '-'}
                    wrapperClassName="w-full"
                  />
                  <Input
                    disabled
                    iconBeforeInput={
                      <Flag
                        className="mt-5"
                        countryCode={stakeholder.countryOfInc?.iso2.toUpperCase() as FlagCode}
                      />
                    }
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.COUNTRY_OF_COMPANY} />}
                    value={stakeholder?.countryOfInc?.name || '-'}
                    wrapperClassName="w-full"
                  />
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.DATE_OF_INCORPORATION} />}
                    value={formattedIncDate}
                    wrapperClassName="w-full"
                  />
                </div>
              </>
            )}
          </div>
          {stakeholder?.type && b2cStakeholderTypes.includes(stakeholder.type) && (
            <Input
              disabled
              onChange={() => ''}
              placeholder={<AppFormattedMessage id={StringKey.PHONE_NUMBER} />}
              value={stakeholder?.phone || '-'}
              wrapperClassName="w-full"
            />
          )}
          <Input
            disabled
            onChange={() => ''}
            placeholder={<AppFormattedMessage id={StringKey.ADDRESS} />}
            value={stakeholder?.address || '-'}
            wrapperClassName="w-full"
          />
        </div>
        <Button className="w-full" onClick={handleClose}>
          <AppFormattedMessage id={StringKey.I_HAVE_SEEN_MY_INFO} />
        </Button>
      </div>
    </DeprecatedModalWrapper>
  );
};

export default InviteModal;
