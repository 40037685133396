import { Company } from '..';
import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { ShareClass } from '../../types/share-classes.types';
import { useAppQuery } from '../useAppQuery';
import { useInvalidateQueries } from '../useInvalidateQueries';

export const useDefaultShareClass = (enabled?: boolean) => {
  const { invalidateQuery } = useInvalidateQueries(QueryKey.GET_DEFAULT_SHARE_CLASS);

  const { selectedCompanyId: companyId } = Company.useSelected();
  const { data: shareClass, isLoading } = useAppQuery<ShareClass>({
    queryKey: [QueryKey.GET_DEFAULT_SHARE_CLASS, companyId],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'share-classes', 'default'],
      }),
    enabled: !!companyId && enabled,
  });

  return {
    invalidateQuery,
    shareClass,
    isLoading,
  };
};
