import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import {
  CheckCircleIcon,
  CheckIcon,
  ChevronLeftIcon,
  EventsIcon,
  PoolPlansIcon,
  ShareClassesIcon,
  StakeholdersIcon,
} from '../../assets/icons';
import { Company } from '../../hooks';
import { showOnboardingPageState } from '../../storage/showOnboardingPageStorage';
import { PageRoute } from '../../types/pageTypes';
import { toRound } from '../../utils/getRoundedNumber';
import { percentage } from '../../utils/percentage';
import Button from '../Button';
import Loader from '../Loader';

type GetStartedItemProps = {
  title: string;
  description: string;
  icon: ReactNode;
  complete: boolean;
  onComplete: () => void;
  isNext?: boolean;
};

const GetStartedItem: FC<GetStartedItemProps> = ({
  complete,
  description,
  icon,
  onComplete,
  title,
  isNext = false,
}) => (
  <div className="flex w-full items-center justify-between py-2">
    <div className="flex gap-2">
      <div
        className={twMerge(
          'centered flex size-10 rounded-lg',
          complete ? 'border border-[#2FB487]' : 'shadow-sm',
        )}
      >
        {icon}
      </div>
      <div className="flex flex-col">
        <span className={twMerge('text-sm font-[550] text-[#172335]', complete && 'line-through')}>
          {title}
        </span>
        <span className="text-[10px] leading-4 text-gray-500">{description}</span>
      </div>
    </div>

    {complete ? (
      <CheckCircleIcon iconColor="#2FB487" />
    ) : (
      <Button className="w-fit" onClick={onComplete} styleType="NONE">
        <ChevronLeftIcon className="rotate-180" iconColor={isNext ? '#2565C8' : '#344054'} />
      </Button>
    )}
  </div>
);
type GetStartedProps = {
  companyId: string;
  isOnboardingPage?: boolean;
};

export const GetStarted: FC<GetStartedProps> = ({ companyId, isOnboardingPage }) => {
  const navigate = useNavigate();

  const { companyProgress, isLoading } = Company.useFetStartedProgress({ companyId });

  if (isLoading || !companyProgress) return <Loader />;

  const completedStepsMap = Object.values(companyProgress);

  const progress = percentage(
    completedStepsMap.length + 1,
    completedStepsMap.filter((state) => state).length + 1,
  );

  return (
    <div
      className={twMerge(
        'flex h-full w-full flex-col rounded-xl bg-gray-50 p-4 pb-2',
        isOnboardingPage && 'bg-transparent',
      )}
    >
      <div className="flex flex-col gap-1">
        {!isOnboardingPage && (
          <span className="text-xl font-semibold text-gray-700">Get Started</span>
        )}
        <div className="flex w-full items-center gap-4">
          <div className="relative flex h-1 w-full overflow-hidden rounded bg-gray-100">
            <div
              className="absolute h-full bg-gradient-to-r from-forest-500 to-forest-300 transition-all duration-700"
              style={{ width: `${progress}%` }}
            />
          </div>
          <span className="text-sm font-[550] text-forest-500">{toRound(progress, 0)}%</span>
        </div>
        <div className="mt-5 flex flex-col gap-1 divide-y divide-gray-200">
          <GetStartedItem
            complete={true}
            description="You have successfully set up your company"
            icon={<CheckIcon iconColor="#2FB487" />}
            onComplete={() => ''}
            title="Company setup"
          />
          <GetStartedItem
            complete={companyProgress.isCompleteStakeholders}
            description="Add details about your company stakeholders"
            icon={
              <StakeholdersIcon
                iconColor={companyProgress.isCompleteStakeholders ? '#2FB487' : '#344054'}
              />
            }
            onComplete={() => {
              isOnboardingPage && showOnboardingPageState.set(false);
              navigate(PageRoute.STAKEHOLDERS);
            }}
            title="Add Stakeholder"
          />
          <GetStartedItem
            complete={companyProgress.isCompleteShareClass}
            description="Add details about your company share classes"
            icon={
              <ShareClassesIcon
                iconColor={companyProgress.isCompleteShareClass ? '#2FB487' : '#344054'}
              />
            }
            onComplete={() => {
              isOnboardingPage && showOnboardingPageState.set(false);
              navigate(PageRoute.SHARE_CLASSES);
            }}
            title="Add Share Class"
          />
          <GetStartedItem
            complete={companyProgress.isCompletePool}
            description="Setup your first pool"
            icon={
              <PoolPlansIcon iconColor={companyProgress.isCompletePool ? '#2FB487' : '#344054'} />
            }
            onComplete={() => {
              isOnboardingPage && showOnboardingPageState.set(false);
              navigate(PageRoute.POOL_PLANS);
            }}
            title="Add Pool"
          />
          <GetStartedItem
            complete={companyProgress.isCompletePlan}
            description="Setup your first company plan"
            icon={
              <PoolPlansIcon iconColor={companyProgress.isCompletePlan ? '#2FB487' : '#344054'} />
            }
            onComplete={() => {
              isOnboardingPage && showOnboardingPageState.set(false);
              navigate(PageRoute.POOL_PLANS);
            }}
            title="Add Plan"
          />
          <GetStartedItem
            complete={companyProgress.isCompleteEvent}
            description="Add new or existing events "
            icon={
              <EventsIcon iconColor={companyProgress.isCompleteEvent ? '#2FB487' : '#344054'} />
            }
            onComplete={() => {
              isOnboardingPage && showOnboardingPageState.set(false);
              navigate(PageRoute.EVENTS);
            }}
            title="Add Event"
          />
        </div>
      </div>
    </div>
  );
};
