import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { PlanStakeholder } from '../../types/stakeholderTypes';

type StakeholdersByPlan = {
  companyId: string;
  planId: string;
  take?: number;
  currentPage?: number;
};

type StakeholderByType = PlanStakeholder;

type StakeholderByPlanResponse = {
  stakeholders: StakeholderByType[];
  totalPages: number;
};

export const useStakeholders = ({
  companyId,
  planId,
  currentPage = 0,
  take = 100,
}: StakeholdersByPlan) => {
  const queryClient = useQueryClient();

  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * take;
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteQuery<StakeholderByPlanResponse>({
      queryKey: [
        QueryKey.SHARE_PLAN_STAKEHOLDERS,
        companyId,
        planId,
        {
          take,
          skip,
        },
      ],
      queryFn: ({ pageParam = 0 }) =>
        ApiService.get(
          {
            endpoint: BackendRoute.COMPANIES,
            routePath: [companyId, 'share-plans', planId, 'stakeholders'],
          },
          {
            queryParams: {
              take,
              skip: currentPage ? skip : pageParam,
            },
          },
        ),
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.totalPages > allPages.length ? allPages.length * take : undefined,
      enabled: !!companyId && !!planId,
    });

  const stakeholderList = useMemo(
    () =>
      data?.pages?.reduce<StakeholderByType[]>((acc, page) => {
        return [...acc, ...page.stakeholders];
      }, []),
    [data?.pages],
  );

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.SHARE_PLAN_STAKEHOLDERS] });
  };

  return {
    invalidateQuery,
    fetchNextPage,
    hasNextPage,
    stakeholders: stakeholderList || [],
    totalPages: data?.pages[0]?.totalPages || 0,
    isLoading,
  };
};
