import { ReactNode } from 'react';

export enum ApproveHurdleColumn {
  SELECT = 'select',
  STAKEHOLDER = 'stakeholder',
  GRANT = 'grant',
  APPROVAL_DATE = 'approval-date',
  SHARE_CLASS = 'share-class',
  HURDLE = 'hurdle',
  CURRENT_VALUATION = 'current-valuation',
  ISSUED_SHARES = 'issued-shares',
}
export const approveHurdleColumnMap = Object.values(ApproveHurdleColumn);

export const approveHurdleColumnTitle: Record<ApproveHurdleColumn, string | ReactNode> = {
  [ApproveHurdleColumn.SELECT]: '',
  [ApproveHurdleColumn.STAKEHOLDER]: 'Stakeholder',
  [ApproveHurdleColumn.GRANT]: 'Grant',
  [ApproveHurdleColumn.APPROVAL_DATE]: 'Approval Date',
  [ApproveHurdleColumn.SHARE_CLASS]: 'Share Class',
  [ApproveHurdleColumn.HURDLE]: 'Hurdle',
  [ApproveHurdleColumn.CURRENT_VALUATION]: 'Current Valuation',
  [ApproveHurdleColumn.ISSUED_SHARES]: 'Issued Shares',
};

export enum ApproveTargetBasedColumn {
  SELECT = 'select',
  STAKEHOLDER = 'stakeholder',
  GRANT = 'grant',
  APPROVAL_DATE = 'approval-date',
  SHARE_CLASS = 'share-class',
  PLAN_TYPE = 'plan-type',
  TARGET_NAME = 'target-name',
  TARGET_DATE = 'target-date',
  ISSUED_SHARES = 'issued-shares',
}
export const approveTargetBasedColumnMap = Object.values(ApproveTargetBasedColumn);

export const approveTargetBasedColumnTitle: Record<ApproveTargetBasedColumn, string | ReactNode> = {
  [ApproveTargetBasedColumn.SELECT]: '',
  [ApproveTargetBasedColumn.STAKEHOLDER]: 'Stakeholder',
  [ApproveTargetBasedColumn.GRANT]: 'Grant',
  [ApproveTargetBasedColumn.APPROVAL_DATE]: 'Approval Date',
  [ApproveTargetBasedColumn.SHARE_CLASS]: 'Share Class',
  [ApproveTargetBasedColumn.PLAN_TYPE]: 'Plan Type',
  [ApproveTargetBasedColumn.TARGET_NAME]: 'Target Name',
  [ApproveTargetBasedColumn.TARGET_DATE]: 'Target Date',
  [ApproveTargetBasedColumn.ISSUED_SHARES]: 'Issued Shares',
};
