import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import clipboard from '../../assets/images/clipboard.png';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { StringKey } from '../../lang';

export type EmptySearchListProps = {
  className?: string;
  customTitleText?: string;
  customDescriptionText?: string;
  customIconSrc?: string;
} & Optional<{
  variant?: '2';
  customTitleText?: string;
  customDescriptionText?: string;
  customIconSrc?: string;
}>;

export const EmptySearchList: FC<EmptySearchListProps> = ({
  className,
  variant = '1',
  customDescriptionText,
  customTitleText,
  customIconSrc,
}) => {
  switch (variant) {
    case '2':
      return (
        <div
          className={twMerge(
            'centered relative m-auto my-20 flex h-fit flex-col gap-6 rounded-2xl px-6 py-8 lg:max-w-[393px]',
            className,
          )}
        >
          <div
            className="rounded-full bg-white p-3"
            style={{
              boxShadow: '0px 0px 8px 0px #DC680321 inset',
            }}
          >
            <div
              className="centered flex size-28 items-center gap-3 rounded-full"
              style={{
                background:
                  ' linear-gradient(180deg, rgba(254, 200, 75, 0.13) 0%, rgba(220, 104, 3, 0.13) 100%)',
              }}
            >
              <div className="size-[56px] object-cover">
                <img alt="" src={customIconSrc} />
              </div>
            </div>
          </div>

          <span
            className="!bg-clip-text text-xl font-semibold text-transparent"
            style={{
              background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
            }}
          >
            {customTitleText}
          </span>
          <span className="line-clamp-2 max-w-[240px] text-center text-xs font-[450] text-gray-500">
            {customDescriptionText}
          </span>
        </div>
      );
    default:
      return (
        <div className={twMerge('flex w-fit flex-col items-center gap-6', className)}>
          <div
            className="flex size-32 shrink-0 items-center justify-center rounded-full bg-fireside-50"
            style={{
              border: '1.07px solid',
              borderImageSource:
                'linear-gradient(180deg, rgba(254, 228, 226, 0.2) 0%, rgba(254, 205, 202, 0.3) 100%)',
              boxShadow: '0px 4.27px 6.4px -2.13px #D92D2008, 0px 12.8px 17.07px -4.27px #F044381F',
            }}
          >
            <div
              className="flex size-32 shrink-0 items-center justify-center rounded-full bg-fireside-50"
              style={{
                border: '1.07px solid',
                borderImageSource:
                  'linear-gradient(180deg, rgba(254, 228, 226, 0.2) 0%, rgba(254, 205, 202, 0.3) 100%)',
                boxShadow: ' 0px 0px 17.07px 0px #7A271A14 inset',
              }}
            >
              <div className="size-[68px] object-cover">
                <img alt="" src={customIconSrc || clipboard} />
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col gap-[6px] text-center">
            <span className="text-nowrap text-sm font-[450] text-gray-700">
              {customTitleText || <AppFormattedMessage id={StringKey.NO_RESULTS_MATCHS} />}
            </span>
            <span className="text-nowrap text-xs font-[450] text-gray-600">
              {customDescriptionText ?? <AppFormattedMessage id={StringKey.PLEASE_TRY_AGAIN} />}
            </span>
          </div>
        </div>
      );
  }
};
