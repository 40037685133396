import React, { FC } from 'react';

import { CloseIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { StringKey } from '../../../lang';

export type SubscriptionModalProps = {
  onClose: () => void;
  onSuccess: () => void;
};

const SubscriptionModal: FC<SubscriptionModalProps> = ({ onClose, onSuccess }) => (
  <div className="flex w-full max-w-[450px] flex-col rounded bg-white p-4 text-xs font-normal text-gray-500 shadow-xl">
    <span className="text-xl font-semibold text-gray-700">
      <AppFormattedMessage id={StringKey.CANCEL_SUBSCRIPTION} />?
    </span>
    <span>
      <AppFormattedMessage id={StringKey.ARE_YOU_SURE_YOU_WANT_CANCEL} />
    </span>
    <div className="my-6 flex flex-col gap-3 rounded bg-gray-50 p-3">
      <span className="text-base font-semibold text-gray-700">
        <AppFormattedMessage id={StringKey.IF_YOU_CANCEL} />:
      </span>
      <div className="flex w-full items-center gap-2">
        <CloseIcon height="10" iconColor="#D92D20" width="10" />
        <span className="text-xs font-[450] text-gray-800">
          <AppFormattedMessage id={StringKey.CANCEL_SUBSCRIPTION_PROBLEM_1} />
        </span>
      </div>
      <div className="flex w-full items-center gap-2">
        <CloseIcon height="10" iconColor="#D92D20" width="10" />

        <span className="text-xs font-[450] text-gray-800">
          <AppFormattedMessage id={StringKey.LOSE_SPECIAL_OFFER_PRICE} />
        </span>
      </div>
    </div>
    <div className="mt-8 flex justify-end gap-2">
      <Button
        className="rounded border-[1px] border-gray-300 text-sm font-[450] text-gray-700"
        onClick={onClose}
        styleType="DEFAULT_ROUNDED"
      >
        <AppFormattedMessage id={StringKey.NO_KEEP} />
      </Button>
      <Button
        className="rounded border-gray-300 bg-fireside-600 text-sm font-[550] text-gray-25"
        onClick={onSuccess}
        styleType="DEFAULT_ROUNDED"
      >
        <AppFormattedMessage id={StringKey.CONFIRM_CANCEL} />
      </Button>
    </div>
  </div>
);

SubscriptionModal.displayName = 'SubscriptionModal';

export default SubscriptionModal;
