import { useQueryClient } from '@tanstack/react-query';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BackendRoute } from '../config';
import { QueryKey } from '../constants';
import { HttpException } from '../exceptions';
import { Company, useAppQuery } from '../hooks';
import { TemporaryAuthAccess } from '../pages/TemporaryAuthAccess';
import { ApiService } from '../services';
import { inviteStakeholderStore } from '../storage/invitedStakeholder';
import { showOnboardingPageState } from '../storage/showOnboardingPageStorage';

// const INTERVAL_DELAY_TO_RECHECK = 60000; // 60s

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { data: isAccessGrantedData } = useAppQuery<string>({
    queryKey: [QueryKey.CHECK_AUTH_TEMPORARY_ACCESS],
    queryFn: () => ApiService.get({ endpoint: BackendRoute.CHECK_AUTH_TEMPORARY_ACCESS }),
  });

  const isAccessGranted = isAccessGrantedData ? JSON.parse<boolean>(isAccessGrantedData) : false;

  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isInvitationHandled, setIsInvitationHandled] = useState(false);

  const queryClient = useQueryClient();

  Company.useCheckModuleAccess(isAuthenticated);

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GET_SELECTED_COMPANY],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GET_COMPANIES],
    });
  }, [queryClient]);

  const handleInvitation = useCallback(async () => {
    const invitation = inviteStakeholderStore.get();
    if (!invitation?.id || invitation.isAccepted) {
      setIsInvitationHandled(true);
      return;
    }

    try {
      await ApiService.get({
        endpoint: BackendRoute.INVITATIONS,
        routePath: ['accept', invitation.id],
      });
      inviteStakeholderStore.set({
        ...invitation,
        isAccepted: true,
      });

      invalidateQuery();
    } catch (e) {
      if (e instanceof HttpException) {
        toast.error(e.body?.message);
      }
      inviteStakeholderStore.delete();

      navigate('/error-page');
    } finally {
      setIsInvitationHandled(true);
    }
  }, [invalidateQuery, navigate]);

  const validateAccess = useCallback(async () => {
    try {
      await ApiService.get({ endpoint: BackendRoute.AUTH_VALIDATE });
      setIsAuthenticated(true);
    } catch (e) {
      if (e instanceof HttpException) {
        navigate('/register');
      } else throw e;
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    validateAccess();
  }, [navigate, validateAccess]);

  useEffect(() => {
    const showOnboardingPage = showOnboardingPageState.get();
    if (showOnboardingPage) {
      navigate('/onboarding');
    }
  }, [navigate]);

  useEffect(() => {
    if (!isAuthenticated) return;

    handleInvitation();
  }, [handleInvitation, isAuthenticated]);

  if (!isAccessGranted) return <TemporaryAuthAccess />;

  if (isLoading || !isAuthenticated || !isInvitationHandled) return <></>;

  return <>{children}</>;
};

export default AuthGuard;
