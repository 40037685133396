import { BackendRoute } from '../config';
import { HttpException } from '../exceptions';
import { Locale } from '../lang';
import { accessTokenStorage } from '../storage/accessTokenStorage';
import { HttpService } from './HttpService';
import { QueryParams, QueryParamsBuilder } from './QueryParamBuilder';

export class ApiService extends HttpService {
  private queryParamBuilder = new QueryParamsBuilder();
  constructor(public readonly backendUrl = process.env.REACT_APP_BACKEND_URL) {
    super();
  }

  public buildRoutePath<T extends string | number>(routePath: T[] = []) {
    return routePath.length > 0 ? '/' + routePath.join('/') : '';
  }

  public async delete<T, B>(
    { endpoint, routePath = [] }: { endpoint: BackendRoute; routePath?: (string | number)[] },
    { body, ...params }: { body?: B; queryParams?: QueryParams; locale?: Locale } = {},
  ) {
    return this.requestWithAuth<T>(endpoint + this.buildRoutePath(routePath), {
      init: {
        method: 'DELETE',
        body: JSON.stringify(body),
      },
      ...params,
    });
  }

  public async downloadFile<T>(
    {
      endpoint,
      routePath = [],
    }: {
      endpoint: BackendRoute;
      routePath?: (string | number)[];
    },

    {
      locale = Locale.EN,
      queryParams,
      body,
    }: { queryParams?: QueryParams; locale?: Locale; body?: T } = {},
  ) {
    try {
      const res = await fetch(
        super.buildQueryParam(
          this.getJoinedPath(endpoint + this.buildRoutePath(routePath)),
          queryParams,
        ),
        {
          method: body ? 'POST' : 'GET',
          body: JSON.stringify(body),
          headers: {
            credentials: 'include',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessTokenStorage.get() || ''}`,
            locale: locale,
          },
        },
      );

      const filename = decodeURIComponent(
        res?.headers?.get('Content-Disposition')?.split('filename=')[1] || '',
      );
      const blob = await res.blob();

      const file = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = file;
      a.download = filename;

      document.body.appendChild(a);

      a.click();
      a.remove();

      URL.revokeObjectURL(file);
    } catch (e) {
      try {
        await this.refreshTokens();
      } catch (_ingore) {
        throw e;
      }
    }
  }

  public uploadFile<TResponse = Response>() {
    const xhr = new XMLHttpRequest();

    const getCallback = async (
      { endpoint, routePath = [] }: { endpoint: BackendRoute; routePath?: (string | number)[] },
      [key, file]: [string, File],
      params?: {
        queryParams?: QueryParams;
        locale?: Locale;
        requestMethod?: 'POST' | 'PUT' | 'PATCH';
        onUploadProgress?: (progress: number) => void;
        onAboard?: () => void;
      },
    ) => {
      await this.refreshTokens();
      const accessToken = accessTokenStorage.get();

      const reqMethod = params?.requestMethod || 'POST';
      const onUploadProgress = params?.onUploadProgress;
      const onAboard = params?.onAboard;
      const queryParams = params?.queryParams;

      const fileData = new FormData();
      fileData.append(key, file);
      const promiseCallback = new Promise<TResponse>((resolve, reject) => {
        xhr.upload.addEventListener('progress', (event) => {
          if (event.lengthComputable && onUploadProgress) {
            onUploadProgress((event.loaded / event.total) * 100);
          }
        });
        xhr.onabort = () => onAboard?.();

        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.response);
          } else {
            reject(
              new HttpException(xhr.response, JSON.parse(xhr.response) as { message?: string }),
            );
          }
        };

        const queries = queryParams ? this.queryParamBuilder.buildQuery(queryParams) : '';

        xhr.open(
          reqMethod,
          this.getJoinedPath(endpoint + this.buildRoutePath(routePath) + queries),
          true,
        );

        xhr.withCredentials = true;
        xhr.setRequestHeader('locale', params?.locale || '');
        xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
        xhr.send(fileData);
      });

      return promiseCallback;
    };

    return {
      abort: () => xhr.abort(),
      callback: getCallback,
    };
  }

  public async get<T>(
    {
      endpoint,
      routePath = [],
    }: {
      endpoint: BackendRoute;
      routePath?: (string | number)[];
    },
    params: { queryParams?: QueryParams; locale?: Locale } = {},
  ) {
    return this.requestWithAuth<T>(endpoint + this.buildRoutePath(routePath), {
      ...params,
    });
  }

  public getJoinedPath(endpoint: string) {
    return `${this.backendUrl}${endpoint}`;
  }

  public async patch<T, B>(
    { endpoint, routePath = [] }: { endpoint: BackendRoute; routePath?: (string | number)[] },
    { body, ...params }: { queryParams?: QueryParams; locale?: Locale; body?: B } = {},
  ) {
    return this.requestWithAuth<T>(endpoint + this.buildRoutePath(routePath), {
      init: {
        method: 'PATCH',
        body: JSON.stringify(body),
      },
      ...params,
    });
  }

  public async post<T, B = unknown>(
    { endpoint, routePath = [] }: { endpoint: BackendRoute; routePath?: (string | number)[] },
    { body, ...params }: { queryParams?: QueryParams; locale?: Locale; body?: B } = {},
  ) {
    return this.requestWithAuth<T>(endpoint + this.buildRoutePath(routePath), {
      init: {
        method: 'POST',
        body: JSON.stringify(body),
      },
      ...params,
    });
  }

  public async refreshTokens() {
    const { accessToken } = await super.requestWithAuth<{
      accessToken: string;
    }>(this.getJoinedPath(BackendRoute.AUTH_REFRESH));

    accessTokenStorage.set(accessToken);
  }

  public async requestWithAuth<T>(
    route: string,
    {
      init,
      locale = Locale.EN,
      ...params
    }: {
      init?: RequestInit | undefined;
      queryParams?: QueryParams;
      locale?: Locale;
    } = {},
  ): Promise<T> {
    const accessToken = accessTokenStorage.get() || '';

    const url = this.getJoinedPath(route);

    let res: T;

    try {
      res = await super.requestWithAuth<T>(url, {
        init: {
          headers: { 'Content-Type': 'application/json', ...init?.headers, locale },
          ...init,
        },
        ...params,
        accessToken,
      });
      return res;
    } catch (e) {
      if (!(e instanceof HttpException) || e.response.status !== 401) throw e;

      try {
        await this.refreshTokens();
      } catch (_ingore) {
        throw e;
      }
      res = await super.requestWithAuth<T>(url, {
        init,
        accessToken: accessTokenStorage.get() || '',
      });
      return res;
    }
  }
}

const apiService = new ApiService();

export default apiService;
