import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { SharePlanType } from '../../types/pool-plans.types';
import { VestingTask } from '../../types/vestingTasks.types';

export type VestingTasksResponse = {
  data: VestingTask[];
  totalPages: number;
};
export const useVestingTasks = ({
  companyId,
  currentPage = 0,
  take = 100,
  exercisable = false,
  stakeholderId,
  sharePlanType,
}: {
  companyId: string;
  stakeholderId: string;
  take?: number;
  currentPage?: number;
  exercisable?: boolean;
  sharePlanType?: SharePlanType;
}) => {
  const queryClient = useQueryClient();
  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * take;
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<VestingTasksResponse>({
    queryKey: [
      QueryKey.GET_VESTING_TASKS,
      {
        companyId,
        currentPage,
        exercisable,
        stakeholderId,
        take,
        skip,
        sharePlanType,
      },
    ],
    queryFn: ({ pageParam = 0 }) =>
      ApiService.get(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'stakeholders', stakeholderId, 'vesting-tasks'],
        },
        {
          queryParams: {
            take,
            skip: currentPage ? skip : pageParam,
            exercisable,
            sharePlanType,
          },
        },
      ),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.totalPages > allPages.length ? allPages.length * take : undefined,
    enabled: !!companyId && !!stakeholderId,
    refetchOnWindowFocus: false,
  });
  const vestingTaskList = useMemo(
    () =>
      data?.pages?.reduce<VestingTask[]>((acc, page) => {
        return [...acc, ...page.data];
      }, []),
    [data?.pages],
  );
  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_VESTING_TASKS] });
  };
  return {
    invalidateQuery,
    vestingTasks: vestingTaskList || [],
    fetchNextPage,
    totalPages: data?.pages[0]?.totalPages || 0,
    hasNextPage,
    isLoading,
  };
};
