import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Company, FilterVariant } from '../../types/companyTypes';

export type FetchCompanies = {
  numberOfFetchedItems?: number;
  filter: FilterVariant;
};

type GetCompaniesResponseType = {
  data: Company[];
  totalPages: number;
};

export const useCompanies = ({ filter, numberOfFetchedItems = 500 }: FetchCompanies) => {
  const queryClient = useQueryClient();
  const { data, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteQuery<GetCompaniesResponseType>({
      queryKey: [
        QueryKey.GET_COMPANIES,
        {
          filter,
        },
      ],
      queryFn: ({ pageParam = 0 }) =>
        ApiService.get(
          { endpoint: BackendRoute.COMPANIES },
          {
            queryParams: {
              take: numberOfFetchedItems,
              skip: pageParam,
              filter: filter === 'all' ? '' : filter,
            },
          },
        ),
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.totalPages > allPages.length ? allPages.length * numberOfFetchedItems : undefined,
    });

  const companiesList = useMemo(
    () =>
      data?.pages?.reduce<Company[]>((acc, page) => {
        return [...acc, ...page.data];
      }, []),
    [data?.pages],
  );

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_COMPANIES] });
  };

  return {
    invalidateQuery,
    companies: companiesList || [],
    fetchNextPage,
    hasNextPage,
    isLoading,
  };
};
