import { FC, useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { twMerge } from 'tailwind-merge';

import { CalendarIcon, ChevronDownIcon, CrossIcon, RefreshIcon } from '../../../../assets/icons';
import { CalendarIconVariant } from '../../../../assets/icons/CalendarIcon';
import { RefreshIconVariant } from '../../../../assets/icons/RefreshIcon';
import Button from '../../../../components/Button';
import { Calendar } from '../../../../components/Calendar';
import { Checkbox } from '../../../../components/Checkbox';
import { PaginationController } from '../../../../components/PaginationController';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components/Popover';
import { Select, SelectButton, SelectOption, SelectOptions } from '../../../../components/Select';
import { EventHooks, useFormat, useModalState } from '../../../../hooks';
import {
  EventUnion,
  SortByDate,
  sortByDateMap,
  sortByDateTitle,
  SortByType,
  sortByTypeMap,
  sortByTypeTitle,
} from '../../../../types/events.types';
import { toUpperCaseWords } from '../../../../utils/toUppercaseUtil';
import { EmptyEventHistory } from '../../EmptyEventHistory';
import { EventItem } from './EventItem';

export enum AmountOfFetchedItems {
  FIVE = '5',
  TEN = '10',
  TWENTY = '20',
  FIFTY = '50',
  HUNDRED = '100',
}

export const numberOfFetchedItemsSelectVariant = Object.values(AmountOfFetchedItems);

type HistoryProps = {
  companyId: string;
  onEdit: (id: string) => void;
  onView: (id: string) => void;
  onDelete: (id: string) => void;
  onTransactionView: (id: string, transactionId: string) => void;
  onTransactionDelete: (event: EventUnion, transactionId: string) => void;
  onTransactionEdit: (id: string, transactionId: string) => void;
  onAddEvent: () => void;
};

const sortByTypeValues = Object.values(SortByType);

export const History: FC<HistoryProps> = ({
  companyId,
  onAddEvent,
  onEdit,
  onView,
  onDelete,
  onTransactionDelete,
  onTransactionView,
  onTransactionEdit,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsToFetch, setItemsToFetch] = useState(AmountOfFetchedItems.FIVE);
  const [selectedTypeFilters, setTypeFilters] = useState<SortByType[]>(sortByTypeValues);
  const { format } = useFormat();
  const [selectedDateFilter, setDateFilter] = useState(SortByDate.ALL_TIME);
  const [selectedCustomDate, setCustomDate] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });
  const [selectedCustomDateFilter, setCustomDateFilter] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });

  const handleSelect = () => {
    setCustomDateFilter(selectedCustomDate);
    handleCloseModal();
  };

  const { events, isEmpty, isSearchResultEmpty, totalPages } = EventHooks.useEvents({
    companyId,
    dateFilter: selectedDateFilter,
    typesFilter: selectedTypeFilters,
    customDateFilter: selectedCustomDateFilter,
    take: Number(itemsToFetch),
    currentPage,
  });

  const { isOpen, toggler, handleCloseModal } = useModalState();

  const handleReset = () => {
    setCustomDateFilter({ from: undefined, to: undefined });
    setCustomDate({ from: undefined, to: undefined });
  };

  const handleCancel = () => {
    setDateFilter(SortByDate.LAST_30_DAYS);
  };

  const isSelectedAllTypeFilters = selectedTypeFilters.length === sortByTypeValues.length;

  const isSelectedSomeFilter = selectedCustomDate.from !== undefined || !isSelectedAllTypeFilters;

  useEffect(() => {
    if (totalPages > 0 && currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [itemsToFetch, totalPages, currentPage]);

  return (
    <div className="flex w-full flex-col items-center gap-4 rounded-md p-4 shadow-sm">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-2">
          <RefreshIcon variant={RefreshIconVariant.EVENTS} />
          <span className="text-base font-semibold text-gray-700">Event History</span>
        </div>
        {(!isEmpty || isSelectedSomeFilter) && (
          <div className="flex h-9 items-center gap-2">
            <Popover>
              <PopoverTrigger className="h-full w-fit rounded border bg-white data-[state=open]:bg-gray-50">
                <div className="flex items-center justify-between gap-2 rounded border-gray-200 px-3">
                  <span className="text-sm font-[450] text-gray-700">
                    {isSelectedAllTypeFilters || !sortByTypeTitle[selectedTypeFilters[0]]
                      ? 'All Types'
                      : `${sortByTypeTitle[selectedTypeFilters[0]]} ${selectedTypeFilters.length - 1 < 1 ? '' : `+ ${selectedTypeFilters.length - 1}  more`}`}
                  </span>
                  <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                </div>
              </PopoverTrigger>
              <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
                <div className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700">
                  <Checkbox
                    checked={isSelectedAllTypeFilters}
                    onChange={() => {
                      setTypeFilters(isSelectedAllTypeFilters ? [] : sortByTypeValues);
                    }}
                  />
                  <span className="text-sm font-normal text-gray-700">All Types</span>
                </div>

                {sortByTypeMap.map(([sortKey, sortTitle]) => (
                  <div
                    className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700"
                    key={sortKey}
                  >
                    <Checkbox
                      checked={selectedTypeFilters.includes(sortKey)}
                      onChange={(state) =>
                        setTypeFilters((prev) =>
                          state
                            ? [...prev, sortKey]
                            : prev.filter((sortVariant) => sortVariant !== sortKey),
                        )
                      }
                    />
                    <span className="text-sm font-normal text-gray-700">{sortTitle}</span>
                  </div>
                ))}
              </PopoverContent>
            </Popover>

            <Popover onOpenChange={toggler} open={isOpen}>
              <PopoverTrigger className="h-full w-fit rounded border bg-white data-[state=open]:bg-gray-50">
                <div className="flex items-center justify-between gap-2 rounded border-gray-200 px-3">
                  <CalendarIcon variant={CalendarIconVariant.WITH_NUMBER} />
                  <span className="text-sm font-[450] text-gray-700">
                    {toUpperCaseWords(sortByDateTitle[selectedDateFilter])}
                  </span>
                  <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                </div>
              </PopoverTrigger>
              {selectedDateFilter === SortByDate.CUSTOM_DATE ? (
                <PopoverContent className="w-fit flex-col gap-4 overflow-hidden rounded-lg bg-gray-100 p-0">
                  <div className="flex w-full flex-col rounded-b-lg bg-white shadow-sm">
                    <div className="flex w-full items-center justify-between px-4 py-3">
                      <span className="text-xl font-semibold text-gray-700">Custom Date</span>
                      <Button
                        className="h-fit w-fit rounded p-1 transition-colors hover:bg-gray-200"
                        onClick={handleCancel}
                        styleType="NONE"
                      >
                        <CrossIcon className="size-5" />
                      </Button>
                    </div>
                    <div className="flex w-full gap-6 p-4">
                      <div className="flex w-full flex-col gap-2">
                        <span className="text-sm font-[450] text-gray-700">Start date</span>
                        <span className="h-10 rounded-t border-b border-gray-700 bg-gray-50 p-3 text-sm font-[450] text-gray-700">
                          {selectedCustomDate.from
                            ? format(selectedCustomDate.from, 'dd/MM/yyyy')
                            : ''}
                        </span>
                      </div>
                      <div className="flex w-full flex-col gap-2">
                        <span className="text-sm font-[450] text-gray-700">End date</span>
                        <span className="h-10 rounded-t border-b border-gray-700 bg-gray-50 p-3 text-sm font-[450] text-gray-700">
                          {selectedCustomDate.to ? format(selectedCustomDate.to, 'dd/MM/yyyy') : ''}
                        </span>
                      </div>
                    </div>
                    <div className="centered flex w-full">
                      <Calendar
                        className="px-2"
                        initialFocus
                        mode="range"
                        onSelect={(date) => date && setCustomDate(date)}
                        selected={selectedCustomDate}
                      />
                    </div>
                  </div>
                  <div className="-mt-4 flex w-full justify-between gap-20 px-6 py-4">
                    <Button
                      className="text-sm font-[450] text-gray-700"
                      onClick={handleCancel}
                      styleType="NONE"
                    >
                      Cancel
                    </Button>
                    <div className="flex gap-3">
                      <Button
                        className="h-9 w-fit rounded border border-gray-300 bg-white px-4 text-sm font-[450]"
                        onClick={handleReset}
                        styleType="NONE"
                      >
                        Reset
                      </Button>
                      <Button
                        className="h-9 w-fit rounded border px-4 text-sm font-[550] text-white"
                        disabled={!selectedCustomDate.from || !selectedCustomDate.to}
                        onClick={handleSelect}
                      >
                        Select
                      </Button>
                    </div>
                  </div>
                </PopoverContent>
              ) : (
                <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
                  {sortByDateMap.map(([sortKey, sortTitle]) => (
                    <div
                      className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700"
                      key={sortKey}
                    >
                      <Checkbox
                        checked={sortKey === selectedDateFilter}
                        className="rounded-full"
                        onChange={() => {
                          setDateFilter(sortKey);
                        }}
                      />
                      <span className="text-sm font-normal text-gray-700">
                        {toUpperCaseWords(sortTitle)}
                      </span>
                    </div>
                  ))}
                </PopoverContent>
              )}
            </Popover>
          </div>
        )}
      </div>
      <div
        className={twMerge(
          'flex w-full flex-col items-center justify-center gap-4',
          isEmpty && 'flex',
        )}
      >
        {!isEmpty ? (
          <>
            {events.map((event) => (
              <EventItem
                companyId={companyId}
                event={event}
                key={event.eventId}
                onDelete={() => onDelete(event.eventId)}
                onEdit={() => onEdit(event.eventId)}
                onTransactionDelete={onTransactionDelete}
                onTransactionEdit={onTransactionEdit}
                onTransactionView={onTransactionView}
                onView={() => onView(event.eventId)}
                selectedCustomDateFilter={selectedCustomDateFilter}
                selectedDateFilter={selectedDateFilter}
              />
            ))}
          </>
        ) : (
          <EmptyEventHistory
            isDisplayedAddEvent={!isSearchResultEmpty}
            onAddEventClick={onAddEvent}
            text={
              isSearchResultEmpty ? 'No results match this search term Please try again' : undefined
            }
            title={isSearchResultEmpty ? 'No events found' : undefined}
          />
        )}
        <div className="flex w-full items-center justify-between">
          <Select
            onChange={(fetchItems: AmountOfFetchedItems) => setItemsToFetch(fetchItems)}
            value={itemsToFetch}
          >
            {({ open }) => (
              <div className="relative rounded border-gray-200">
                <SelectButton className="flex items-center gap-4 bg-gray-25 py-[10px] pl-[12px] pr-[6px]">
                  <span className="text-m font-[450] text-gray-700">{itemsToFetch}</span>
                  <ChevronDownIcon
                    className={twMerge(
                      'h-[13px] w-[13px] transition-transform duration-200',
                      open && 'rotate-180',
                    )}
                  />
                </SelectButton>
                <SelectOptions
                  className={twMerge(
                    'absolute left-0 right-0 top-[-6px] mt-1 max-h-60 w-[58px] -translate-y-full transform overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                  )}
                >
                  {numberOfFetchedItemsSelectVariant.map((select) => (
                    <SelectOption key={select} value={select}>
                      <span className="block text-m font-normal text-gray-700">{select}</span>
                    </SelectOption>
                  ))}
                </SelectOptions>
              </div>
            )}
          </Select>
          <PaginationController
            className="shrink-0"
            currentPage={currentPage}
            onClick={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};
