import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { StakeholderTypeGroup } from '../../types/stakeholderTypes';
import { useAppMutation } from '../useAppMutation';
import { useLocale } from '../useLocale';

export const useStakeholderInfo = () => {
  const { locale } = useLocale();
  const { mutate: downloadStakeholderInfo, isPending } = useAppMutation(
    [QueryKey.STAKEHOLDERS_DOWNLOAD_INFO],
    {
      mutationFn: ({
        companyId,
        typeGroup,
      }: {
        companyId: string;
        typeGroup: StakeholderTypeGroup;
      }) =>
        ApiService.downloadFile(
          {
            endpoint: BackendRoute.COMPANIES,
            routePath: [companyId, 'stakeholders', typeGroup, 'xlsx'],
          },
          { locale },
        ),
    },
  );
  return { downloadStakeholderInfo, isPending };
};
