import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { StakeholderOwnershipSummary } from '../../types/stakeholderTypes';
import { useAppQuery } from '../useAppQuery';

export const useOwnershipSummary = ({
  companyId,
  stakeholderId,
}: {
  companyId: string;
  stakeholderId: string;
}) => {
  const queryClient = useQueryClient();

  const { data: ownershipSummary, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_STAKEHOLDER_OWNERSHIP_SUMMARY, companyId, stakeholderId],
    queryFn: () =>
      ApiService.get<StakeholderOwnershipSummary>({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'stakeholders', stakeholderId, 'ownership-summary'],
      }),
    enabled: !!companyId && !!stakeholderId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_STAKEHOLDER_OWNERSHIP_SUMMARY] });
  }, [queryClient]);

  return {
    invalidateQuery,
    ownershipSummary,
    isLoading,
  };
};
