import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Company } from '../../types/companyTypes';
import { useAppQuery } from '../useAppQuery';
import { useLocale } from '../useLocale';
import { accessTokenStorage } from '../../storage/accessTokenStorage';

export const useSelected = ({
  redirectOnSelectedCompanyError,
}: { redirectOnSelectedCompanyError?: boolean } = {}) => {
  const accessToken = accessTokenStorage.get() || '';

  const navigate = useNavigate();
  const { locale } = useLocale();
  const queryClient = useQueryClient();

  const { data: selectedCompany, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_SELECTED_COMPANY],
    queryFn: () =>
      ApiService.get<Company>(
        { endpoint: BackendRoute.COMPANIES, routePath: ['selected'] },
        { locale },
      ),
    onError: () => {
      if (!redirectOnSelectedCompanyError) return;
      navigate('/error-page');
    },
    enabled: !!accessToken,
  });

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SELECTED_COMPANY] });
  };

  return {
    selectedCompanyId: selectedCompany?.id || '',
    selectedCompany,
    isLoading,
    invalidateQuery,
  };
};
