import { Field, Label } from '@headlessui/react';
import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  AddContributorIcon,
  CrossIcon,
  EditIcon,
  EyeIconSmall,
  GrantAccessIcon,
  RevokeAccessIcon,
  ThreeDotsIcon,
  TrashBinIcon,
} from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Checkbox } from '../../../components/Checkbox';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { useFormat } from '../../../hooks';
import { StringKey } from '../../../lang';
import {
  stakeholderExTypeTitle,
  StakeholderRole,
  TerminationCauseTitle,
} from '../../../types/stakeholderTypes';
import { toUpperCaseWords } from '../../../utils/toUppercaseUtil';
import { getStakeholderCommonActions } from '../StakeholderPermissions';
import StakeholderStatus, { StakeholderActionStatus } from '../StakeholderStatus';
import { StakeholderTableItemProps } from '../StakeholderTable/StakeholderTableItem';

export const StakeholderTerminatedTableItem: FC<StakeholderTableItemProps> = ({
  role,
  type,
  isActive,
  isAccessRevoked,
  id,
  fullName,
  email,
  isTerminated,
  terminationDate,
  lastVestingDate,
  terminationCause,
  handleDelete,
  handleEdit,
  handleView,
  handleGrantAccess,
  handleMakeContributor,
  handleRemoveContributorRole,
  handleRevokeAccess,
  handleEditTermination,
  isSelected,
  onRemoveSelect,
  onSelect,
  roleInCompany,
  accountId,
  isDemo,
}) => {
  const {
    canDelete,
    grantAccess,
    makeContributor,
    revokeAccess,
    revokeContributor,
    canEditTerminate,
  } = getStakeholderCommonActions({
    role,
    isActive,
    isAccessRevoked,
    isTerminated,
    isDemo,
  });

  const { format } = useFormat();

  return (
    <tr className={twMerge('w-full bg-white', isSelected(id) && 'bg-gray-50')} key={id}>
      <td className="relative">
        <div className="flex h-full w-full cursor-pointer items-center justify-center">
          <Field className="flex items-center">
            <Checkbox
              checked={isSelected(id)}
              id={id}
              onChange={(checked) => {
                checked ? onSelect(id) : onRemoveSelect(id);
              }}
            />
            <Label className="absolute left-0 top-0 h-full w-full cursor-pointer" htmlFor={id} />
          </Field>
        </div>
      </td>
      <td className="flex w-fit items-center gap-4 overflow-hidden text-nowrap p-4">
        <div className="relative flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
          {fullName[0]}
          <div className="absolute bottom-0 right-0 flex size-[14px] shrink-0 items-center justify-center rounded-full border-[1px] border-white bg-fireside-600">
            <CrossIcon className="size-[10px]" iconColor="#ffffff" />
          </div>
        </div>
        {accountId === id ? (
          <div className="flex items-center gap-1">
            <span className="max-w-[100px] truncate text-sm font-[450] text-gray-700">
              {fullName}
            </span>
            <span className="text-sm font-[450] text-gray-700">(You)</span>
          </div>
        ) : (
          <span className="max-w-[100px] truncate text-sm font-[450] text-gray-700">
            {fullName}
          </span>
        )}
      </td>
      <td className="p-4 text-sm text-gray-700">
        {toUpperCaseWords(stakeholderExTypeTitle[type])}
      </td>
      <td className="overflow-hidden p-4">
        <span className="max-w-[100px] truncate text-sm text-gray-700">
          {terminationCause && TerminationCauseTitle[terminationCause]}
        </span>
      </td>
      <td className="p-4 text-sm text-gray-700">{format(terminationDate, 'dd/MM/yyyy')}</td>
      <td className="p-4 text-sm text-gray-700">{format(lastVestingDate, 'dd/MM/yyyy')}</td>
      <td className="p-4 text-sm text-gray-700">{email}</td>
      <td className="p-4 text-sm text-gray-700">
        <div className="flex h-full w-full shrink-0 items-center justify-between gap-4 pr-4">
          <div className="flex gap-4">
            <StakeholderStatus
              type={isActive ? StakeholderActionStatus.ACTIVE : StakeholderActionStatus.INACTIVE}
            />
            {isActive && <StakeholderStatus type={role} />}

            {!isActive && (
              <>
                {isAccessRevoked && (
                  <StakeholderStatus type={StakeholderActionStatus.ACCESS_REVOKED} />
                )}
              </>
            )}
          </div>

          <Popover>
            <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
              <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
                <ThreeDotsIcon />
              </div>
            </PopoverTrigger>
            <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
              <PopoverClose>
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleView(id)}
                >
                  <EyeIconSmall />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.VIEW_DETAILS} />
                  </span>
                </div>
                {(role !== StakeholderRole.ADMIN || roleInCompany === StakeholderRole.ADMIN) && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleEdit(id)}
                  >
                    <EditIcon />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage id={StringKey.EDIT_DETAILS} />
                    </span>
                  </div>
                )}
                {grantAccess && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleGrantAccess(id)}
                  >
                    <GrantAccessIcon />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage id={StringKey.GRANT_ACCESS} />
                    </span>
                  </div>
                )}

                {makeContributor && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleMakeContributor(id)}
                  >
                    <AddContributorIcon />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage id={StringKey.MAKE_CONTRIBUTOR} />
                    </span>
                  </div>
                )}

                {revokeAccess && accountId !== id && !isAccessRevoked && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleRevokeAccess(id)}
                  >
                    <RevokeAccessIcon />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage id={StringKey.REVOKE_ACCESS} />
                    </span>
                  </div>
                )}

                {revokeContributor && accountId !== id && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleRemoveContributorRole(id)}
                  >
                    <RevokeAccessIcon />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage id={StringKey.REVOKE_CONTRIBUTOR_ROLE} />
                    </span>
                  </div>
                )}
                {canEditTerminate && isTerminated && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleEditTermination(id)}
                  >
                    <EditIcon />
                    <span className="text-sm font-normal">Edit termination</span>
                  </div>
                )}
                {canDelete && accountId !== id && (
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-fireside-500 hover:bg-gray-50"
                    onClick={() => handleDelete(id)}
                  >
                    <TrashBinIcon iconColor="#F04438" />
                    <span className="text-sm font-normal text-fireside-500">
                      <AppFormattedMessage id={StringKey.DELETE} />
                    </span>
                  </div>
                )}
              </PopoverClose>
            </PopoverContent>
          </Popover>
        </div>
      </td>
    </tr>
  );
};
