import { FC } from 'react';

import { AutomaticVestingTable } from './AutomaticVestingTable';
import { VestingPlanType, vestingSharePlanType } from './tableVariant';

export type AutomaticVestingProps = {
  companyId: string;
  stakeholderId: string;
};

export const AutomaticVesting: FC<AutomaticVestingProps> = ({ companyId, stakeholderId }) => {
  const vestingTypeMap = Object.values(VestingPlanType);

  return vestingTypeMap.map((vestingType) =>
    vestingSharePlanType[vestingType].map((sharePlanType, i) => (
      <AutomaticVestingTable
        companyId={companyId}
        key={`${stakeholderId}_${i}`}
        sharePlanType={sharePlanType}
        stakeholderId={stakeholderId}
        vestingType={vestingType}
      />
    )),
  );
};
