import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { CheckIcon } from '../../assets/icons';
import { StringKey } from '../../lang';
import { subscriptionDurationTranslation } from '../../translations/planTranslation';
import { Plan, SubscriptionDuration, SubscriptionType } from '../../types/planTypes';
import { toRound } from '../../utils/getRoundedNumber';
import { AppFormattedMessage } from '../AppFormattedMessage';
import Button from '../Button';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../HoverCard';

enum CardStyleVariant {
  DEFAULT = 'bg-white hover:bg-gradient-to-b from-white to-[#F3F8FF] hover:ring-brand-700',
  DISABLED = 'bg-white hover:border-transparent',
  SPECIAL = 'bg-gray-100 hover:ring-gray-700',
}

enum SelectButtonStyleVariant {
  DEFAULT = 'bg-brand-700',
  SPECIAL = 'bg-gray-700',
  LIGHT = 'disabled:border-gray-200 disabled:bg-brand-50 border-gray-200 bg-brand-50 text-gray-700 border-[1px] disabled:text-gray-400',
}

export enum SelectButtonTextVariant {
  SELECTED = 'selected',
  BLOCKED_STAKEHOLDERS = 'blocked-stakeholders',
  BLOCKED_MONTH = 'blocked-month',
  CAN_CHANGE = 'can-change',
  CAN_SWITCH = 'can-switch',
  DEFAULT = 'default',
  SPECIAL = 'special',
  CAN_DOWNGRADE = 'can-downgrade',
}

const time = new Date().getTime();

const selectButtonTextTitleMap: Record<SelectButtonTextVariant, StringKey> = {
  [SelectButtonTextVariant.SELECTED]: StringKey.YOURE_SAVE_APPROX_WITH_ANNUAL,
  [SelectButtonTextVariant.BLOCKED_STAKEHOLDERS]: StringKey.DOWNGRADE,
  [SelectButtonTextVariant.BLOCKED_MONTH]: StringKey.DOWNGRADE,
  [SelectButtonTextVariant.CAN_CHANGE]: StringKey.UPGRADE,
  [SelectButtonTextVariant.CAN_SWITCH]: StringKey.SWITCH_ANNUAL,
  [SelectButtonTextVariant.DEFAULT]: StringKey.SELECT,
  [SelectButtonTextVariant.SPECIAL]: StringKey.SPACIAL_PLAN_TEXT,
  [SelectButtonTextVariant.CAN_DOWNGRADE]: StringKey.DOWNGRADE,
};

export type BillingPlanProps = {
  name: string;
  price: number;
  priceInterval: string;
  description: string;
  currencyMark: string;
};

export type BillingPlanCardProps = {
  cardMode?: keyof typeof SelectButtonTextVariant;
  cardStyleType?: keyof typeof CardStyleVariant;
  selectButtonStyleType?: keyof typeof SelectButtonStyleVariant;
  selectButtonText?: (() => string) | string;
  onSelectClick?: (props: Plan) => void;
  isSelected?: boolean;
  isDisabledSelect?: boolean;
  isShownTooltip?: boolean;
  selectSpecialText?: string;
  listOfBenefits?: ReactNode[];
  includes?: string;
  disabled?: boolean;
  isEnterprise?: boolean;
} & (
  | ({
      onSpecialSelectClick?: (type: SubscriptionType) => void;
      specialMessage: string;
      description: ReactNode;
      priceInCents?: never;
      duration?: never;
      stakeholdersCount?: never;
      id?: never;
      stripePriceId?: never;
      onSelectClick?: never;
      localCurrency?: never;
      localPrice?: never;
      title: ReactNode;
    } & Omit<
      Plan,
      | 'duration'
      | 'id'
      | 'localCurrency'
      | 'localPrice'
      | 'priceInCents'
      | 'stakeholdersCount'
      | 'stripePriceId'
      | 'title'
    >)
  | ({
      specialMessage?: never;
      onSpecialSelectClick?: never;
      description?: never;
    } & Plan)
);

export const BillingPlanCard: FC<BillingPlanCardProps> = (plan) => {
  const {
    title,
    type,
    listOfBenefits,
    includes,
    specialMessage,
    cardStyleType = 'DEFAULT',
    onSelectClick,
    selectSpecialText,
    duration,
    stakeholdersCount,
    onSpecialSelectClick,
    description,
    cardMode = 'DEFAULT',
    localCurrency,
    localPrice,
    isEnterprise,
  } = plan;

  const isBlocked = cardMode === 'BLOCKED_MONTH' || cardMode === 'BLOCKED_STAKEHOLDERS';

  return (
    <div
      className={twMerge(
        'relative flex w-full min-w-[300px] max-w-[330px] flex-1 flex-col items-center gap-2 overflow-hidden rounded-2xl border-transparent bg-white px-6 py-4 ring-1 ring-inset ring-gray-200 transition-all hover:ring-2',
        (cardMode === 'SELECTED' || cardMode === 'CAN_SWITCH') &&
          'bg-gradient-to-b from-white to-[#F3F8FF] ring-2 ring-brand-700',

        !isBlocked ? CardStyleVariant[cardStyleType] : CardStyleVariant.DISABLED,
      )}
    >
      {(cardMode === 'SELECTED' ||
        cardMode === 'CAN_SWITCH' ||
        (cardMode === 'SPECIAL' && isEnterprise)) && (
        <span className="absolute right-[2px] top-[2px] z-0 shrink-0 rounded-bl-[4px] rounded-tr-[14px] bg-brand-50 px-2 py-1 text-label-md font-medium text-brand-700">
          <AppFormattedMessage id={StringKey.CURRENT_PLAN} />
        </span>
      )}
      <span className="text-xl font-[550] text-gray-700">{title}</span>
      <div className="flex items-center gap-2">
        {specialMessage ? (
          <span className="text-4xl-mobile font-bold text-gray-700 lg:text-4xl">
            {specialMessage}
          </span>
        ) : (
          <>
            <span className="whitespace-nowrap text-4xl-mobile font-bold uppercase text-gray-700 lg:text-4xl">
              {toRound(
                duration === SubscriptionDuration.MONTHLY
                  ? (localPrice || 0) / 100
                  : (localPrice || 0) / 1200,
              )}{' '}
              {localCurrency}
            </span>
            <span className="text-sm font-[550] lowercase text-gray-400">
              /{' '}
              {duration && (
                <AppFormattedMessage id={subscriptionDurationTranslation[duration].duration} />
              )}
            </span>
          </>
        )}
      </div>
      <span className="-mt-2 text-label-md font-medium text-gray-700">
        {duration && (
          <AppFormattedMessage id={subscriptionDurationTranslation[duration].description} />
        )}
        {description}
      </span>
      <div className="mt-2 flex w-full justify-center rounded-md border-[1px] border-gray-200 p-2">
        <span className="text-sm font-[550] text-gray-700">
          <AppFormattedMessage
            id={StringKey.COUNT_STAKEHOLDERS}
            values={{
              count: stakeholdersCount ? 'Up to ' + stakeholdersCount : '50+',
            }}
          />
        </span>
      </div>
      <div className="mt-2 flex w-full justify-center">
        <span className="text-sm font-[450] text-brand-700">Includes: {includes}</span>
      </div>

      <div className="mt-4 flex h-full w-full flex-col justify-start gap-4">
        {listOfBenefits?.map((benefit, i) => (
          <div className="flex items-center gap-2" key={`${time}_${i}`}>
            <CheckIcon />
            <span className="text-nowrap text-sm font-[450] text-gray-700">{benefit}</span>
          </div>
        ))}
      </div>
      <div className="mt-[18px] flex w-full flex-col gap-[2px] text-center">
        {selectSpecialText && (
          <span className="w-full rounded-t-2xl bg-forest-50 px-2 py-[1px] text-label-sm font-[450] text-forest-600">
            {selectSpecialText}
          </span>
        )}
        <HoverCard>
          <HoverCardTrigger>
            <Button
              className={twMerge(
                'px-4 py-[10px]',
                cardMode === 'CAN_SWITCH' || cardMode === 'SELECTED'
                  ? SelectButtonStyleVariant.LIGHT
                  : SelectButtonStyleVariant.DEFAULT,
                cardMode === 'SPECIAL' && SelectButtonStyleVariant.SPECIAL,
                selectSpecialText && 'rounded-b rounded-t-none',
              )}
              disabled={isBlocked || cardMode === 'SELECTED'}
              onClick={() =>
                onSpecialSelectClick ? onSpecialSelectClick(type) : onSelectClick?.(plan)
              }
            >
              {isEnterprise ? (
                'Contact us'
              ) : (
                <AppFormattedMessage
                  id={selectButtonTextTitleMap[SelectButtonTextVariant[cardMode]]}
                />
              )}
            </Button>
          </HoverCardTrigger>
          {isBlocked && (
            <HoverCardContent
              className={twMerge(
                'flex max-w-[300px] flex-col rounded-lg bg-[#101828] p-3 text-start',
              )}
            >
              <span className="text-sm font-semibold text-white">
                <AppFormattedMessage id={StringKey.CANNOT_DOWNGRADE} />
              </span>
              <span className="text-xs text-white">
                {cardMode === 'BLOCKED_STAKEHOLDERS' ? (
                  <AppFormattedMessage id={StringKey.STAKEHOLDERS_LIMIT} />
                ) : (
                  <AppFormattedMessage id={StringKey.CANNOT_DOWNGRADE_STAKEHOLDERS} />
                )}
              </span>
              <a
                className="mt-4 cursor-pointer text-xs font-medium text-white underline"
                href="mailto:contact@capquest.io"
              >
                <AppFormattedMessage id={StringKey.CONTACT_SUPPORT} />
              </a>
            </HoverCardContent>
          )}
        </HoverCard>
      </div>
    </div>
  );
};
