import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { pageTitle, replaceIdWithPath } from '../../types/pageTypes';

export const usePageView = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: 'page_view',
      page: location.pathname,
      title: pageTitle[replaceIdWithPath(location.pathname)],
    });
  }, [location]);
};
