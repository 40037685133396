import React from 'react';

import { MessageIcon } from '../../assets/icons';

const ChatButton = () => {
  const handleOpenIntercom = () => {
    (window.Intercom as any)('show');
  };

  return (
    <button
      className="fixed bottom-5 right-5 z-1 flex h-12 w-12 items-center justify-center rounded-full bg-brand-500 shadow-lg transition-all hover:bg-brand-600"
      onClick={handleOpenIntercom}
    >
      <MessageIcon className="size-7" iconColor="#fff" />
    </button>
  );
};

export default ChatButton;
