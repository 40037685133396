import { useQueryClient } from '@tanstack/react-query';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';
import { useInvalidateQueries } from '../useInvalidateQueries';

export const useSetSelected = () => {
  const { invalidateQuery } = useInvalidateQueries(
    QueryKey.GET_NOTIFICATIONS,
    QueryKey.GET_SELECTED_COMPANY,
    QueryKey.GET_UNREAD_NOTIFICATION_COUNT,
  );

  const { mutate: setSelected, isPending } = useAppMutation([QueryKey.PATCH_SELECTED_COMPANY], {
    mutationFn: (id: string) =>
      ApiService.post({ endpoint: BackendRoute.COMPANIES, routePath: [id, 'select'] }),
    defaultErrorHandling: false,
  });
  return {
    setSelected,
    isPending,
    invalidateQuery,
  };
};
