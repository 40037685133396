import React, { FC, useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { StringKey } from '../../../../lang';
import { EventFormType } from '../../../../types/events.types';
import {
  BulkGrantStepOne,
  BuyBackStepOne,
  ClassConversionStepOne,
  FundraisingRoundStepOne,
  GrantStepOne,
  SecondariesStepOne,
  ShareIssuanceStepOne,
  ValuationStepOne,
} from '../StepComponents';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepOne as stepOneFormSchema } from '../validation';

export type StepOneProps = ChildrenFormProps;

export const StepOne: FC<StepOneProps> = ({
  handleCloseModal,
  nextFormStep,
  control,
  setFormData,
  companyId,
  issuedShares,
  lockMode,
  filedState,
}) => {
  const { stepOne, initialStep } = useWatch<FormSchema>({ control });
  const { success } = stepOneFormSchema.safeParse(stepOne);
  const isValid = success;

  useEffect(() => {
    if (initialStep?.type) {
      const eventTypeKey = `stepOne.eventDetails.${initialStep?.type}.enabled` as const;
      setFormData(eventTypeKey, true);
    }
  }, [initialStep?.type, setFormData]);

  stepOne;

  return (
    <>
      <div className="flex h-full flex-col gap-6 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        {stepOne?.eventDetails?.grant?.enabled && (
          <GrantStepOne companyId={companyId} control={control} setFormData={setFormData} />
        )}
        {stepOne?.eventDetails?.['bulk-import-grant']?.enabled && (
          <BulkGrantStepOne
            companyId={companyId}
            control={control}
            filedState={filedState}
            setFormData={setFormData}
          />
        )}
        {stepOne?.eventDetails?.['buyback']?.enabled && (
          <BuyBackStepOne companyId={companyId} control={control} setFormData={setFormData} />
        )}
        {stepOne?.eventDetails?.['secondaries']?.enabled && (
          <SecondariesStepOne companyId={companyId} control={control} setFormData={setFormData} />
        )}
        {stepOne?.eventDetails?.['conversion']?.enabled && (
          <ClassConversionStepOne
            companyId={companyId}
            control={control}
            setFormData={setFormData}
          />
        )}
        {stepOne?.eventDetails?.['valuation']?.enabled && (
          <ValuationStepOne
            control={control}
            issuedShares={issuedShares}
            lockMode={lockMode}
            setFormData={setFormData}
          />
        )}
        {stepOne?.eventDetails?.['fundraising-round']?.enabled && (
          <FundraisingRoundStepOne
            companyId={companyId}
            control={control}
            handleCloseModal={handleCloseModal}
            issuedShares={issuedShares}
            lockMode={lockMode ?? false}
            setFormData={setFormData}
          />
        )}
        {stepOne?.eventDetails?.['share-issuance']?.enabled && (
          <ShareIssuanceStepOne
            companyId={companyId}
            control={control}
            issuedShares={issuedShares}
            lockMode={lockMode ?? false}
            setFormData={setFormData}
          />
        )}
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            {initialStep?.type === EventFormType.BULK_IMPORT_GRANT ? (
              <AppFormattedMessage id={StringKey.SAVE} />
            ) : lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : (
              <AppFormattedMessage id={StringKey.NEXT} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
