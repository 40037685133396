import React, { FC } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { twMerge } from 'tailwind-merge';

type EventGraphProps = {
  isDesktop: boolean;
  isTablet: boolean;
  className?: string;
};

export const EmptyCapTableGraph: FC<EventGraphProps> = ({ isDesktop, isTablet, className }) => {
  const isMobile = !isDesktop && !isTablet;
  const data = [
    { name: 'A', value: 600 },
    { name: 'B', value: 400 },
  ];
  return (
    <div
      className={twMerge(
        'flex h-full w-full rounded-md bg-white shadow-sm',
        isDesktop ? 'px-6 py-[30px]' : isTablet ? 'px-4 py-5' : 'p-3 pt-8',
        className,
      )}
    >
      <div
        className={twMerge('flex w-full items-center justify-center gap-8', isMobile && 'flex-col')}
      >
        <div
          className={twMerge(
            isDesktop ? 'size-[294px]' : isTablet ? 'size-[188px]' : 'size-[280px]',
          )}
        >
          <ResponsiveContainer height="100%" width="100%">
            <PieChart>
              <Pie
                cx="50%"
                cy="50%"
                data={data}
                dataKey="value"
                innerRadius="50%"
                outerRadius="100%"
              >
                {data.map((_, index) => (
                  <Cell
                    fill="#F9FAFB"
                    fillOpacity={1}
                    key={index}
                    stroke="#EAECF0"
                    strokeDasharray="5 5"
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
