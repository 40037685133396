import { FC } from 'react';

import { useFormat } from '../../../../hooks';
import { eventFormType, EventTypeBackend } from '../../../../types/events.types';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const Expire: FC<CardProps> = ({ event }) => {
  const { format } = useFormat();

  if (event.type !== EventTypeBackend.EXPIRE) return <></>;

  const {
    type,
    exercise: {
      exercised,
      vestingTask: {
        grantItem: {
          stakeholder,
          plan: {
            pool: { shareClass },
          },
        },
      },
    },
    createdAt,
  } = event;

  return (
    <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm">
      <TransactionTitleItem
        createdAt={format(createdAt, 'dd/MM/yyyy')}
        name={eventFormType[type]}
      />
      <TransactionOverviewItem title={'Transaction Type'} value={eventFormType[type]} />
      <TransactionOverviewItem title={'Stakeholder'} value={stakeholder.fullName} />
      <TransactionOverviewItem title={'Expired Warrants'} value={exercised} />
      <TransactionOverviewItem title={'Share Class'} value={shareClass.name} />
    </div>
  );
};
