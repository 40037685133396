import { useQueryClient } from '@tanstack/react-query';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { InvestmentSummary } from '../../types/stakeholderTypes';
import { useAppQuery } from '../useAppQuery';

export const useInvestmentSummary = ({
  companyId,
  stakeholderId,
}: {
  companyId: string;
  stakeholderId: string;
}) => {
  const queryClient = useQueryClient();
  const { data: investmentSummary, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_INVESTMENT_SUMMARY, companyId, stakeholderId],
    queryFn: () =>
      ApiService.get<InvestmentSummary>({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'stakeholders', stakeholderId, 'investment-summary'],
      }),
    enabled: !!companyId && !!stakeholderId,
  });

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_INVESTMENT_SUMMARY] });
  };

  return {
    investmentSummary,
    isLoading,
    invalidateQuery,
  };
};
