import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { ShareClass, ShareClassOrder, ShareClassSortBy } from '../../types/share-classes.types';
import { useInvalidateQueries } from '../useInvalidateQueries';

export enum ShareClassesFilters {
  IN_USE = 'in-use',
  NOT_USED = 'not-used',
}

export type ShareClassResponse = {
  data: ShareClass[];
  totalPages: number;
};

export type FilterVariant = 'all' | ShareClassesFilters;

export const useShareClasses = ({
  companyId,
  filter = 'all',
  search,
  orderBy,
  sortBy,
  currentPage = 1,
  take = 100,
}: {
  companyId: string;
  filter?: FilterVariant;
  search?: string;
  orderBy?: ShareClassOrder | null;
  sortBy?: ShareClassSortBy | null;
  currentPage?: number;
  take?: number;
}) => {
  const { invalidateQuery } = useInvalidateQueries(QueryKey.GET_SHARE_CLASSES);
  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * take;

  const { data, fetchNextPage, hasNextPage, isLoading, refetch } =
    useInfiniteQuery<ShareClassResponse>({
      queryKey: [
        QueryKey.GET_SHARE_CLASSES,
        {
          filter,
          sortBy,
          orderBy,
          take,
          currentPage,
        },
      ],
      queryFn: ({ pageParam = 0 }) =>
        ApiService.get(
          { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'share-classes'] },
          {
            queryParams: {
              take,
              skip: currentPage ? skip : pageParam,
              search: search || '',
              filter: filter === 'all' ? '' : filter,
              ...(orderBy && sortBy
                ? {
                    sortBy,
                    sortOrder: orderBy,
                  }
                : {}),
            },
          },
        ),

      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.totalPages > allPages.length ? allPages.length * take : undefined,
      enabled: !!companyId,
    });

  const shareClassList = useMemo(
    () =>
      data?.pages?.reduce<ShareClass[]>((acc, page) => {
        return [...acc, ...page.data];
      }, []),
    [data?.pages],
  );

  return {
    invalidateQuery,
    shareClasses: shareClassList || [],
    isLoading,
    fetchNextPage,
    refetch,
    hasNextPage,
    totalPages: data?.pages[0]?.totalPages || 0,
  };
};
