import { DateRange } from 'react-day-picker';

import {
  EventExercisedTypeBackend,
  EventTypeBackend,
  EventUnion,
  SortByDate,
} from '../../../../../types/events.types';

export enum AmountOfFetchedItems {
  THREE = '3',
  SIX = '6',
  TEN = '10',
}
export const numberOfFetchedItemsSelectVariant = Object.values(AmountOfFetchedItems);

export type TableBodyProps = EventUnion & {
  onView: (id: string, transactionId: string) => void;
  onDelete: (event: EventUnion, transactionId: string) => void;
  onEdit: (id: string, transactionId: string) => void;
  companyId: string;
  selectedDateFilter: SortByDate;
  selectedCustomDateFilter: DateRange;
};

export const tableHeadVariant: Record<EventTypeBackend | EventExercisedTypeBackend, string[]> = {
  [EventExercisedTypeBackend.WARRANTS]: [
    'Transaction Date',
    'Transaction Type',
    'Stakeholder',
    'Exercised Options',
    'Share Class',
    'Issued Shares',
  ],

  [EventExercisedTypeBackend.SAR]: [
    'Transaction Date',
    'Transaction Type',
    'Stakeholder',
    'SAR Exercised',
    'Share Class',
    'SAR Payout',
    'Issued Shares',
  ],

  [EventExercisedTypeBackend.STOCK_OPTIONS]: [
    'Transaction Date',
    'Transaction Type',
    'Stakeholder',
    'Exercised Warrants',
    'Share Class',
    'Issued Shares',
  ],

  [EventTypeBackend.EXERCISE]: [
    'Transaction Date',
    'Transaction Type',
    'Stakeholder',
    'Exercised Options',
    'Share Class',
    'Issued Shares',
  ],

  [EventTypeBackend.APPROVAL]: [
    'Transaction Date',
    'Transaction Type',
    'Stakeholder',
    'Share Class',
    'Hurdle Value',
    'Issued Shares',
  ],

  [EventTypeBackend.PAYOUT]: [
    'Transaction Date',
    'Transaction Type',
    'Stakeholder',
    'Shares  Paid Out',
    'Total Payout',
  ],

  [EventTypeBackend.EXPIRE]: [
    'Transaction Date',
    'Transaction Type',
    'Stakeholder',
    'Expired Warrants',
    'Share Class',
  ],

  [EventTypeBackend.CONVERSION]: [
    'Transaction Date',
    'Transaction Type',
    'From Share Class',
    'To Share Class',
    'Stakeholder',
    'No of Shares',
    '',
  ],
  [EventTypeBackend.GRANT]: [
    'Transaction Date',
    'Transaction Type',
    'Shareholder',
    'Grant ID',
    'No of Shares',
    'Equity Plan',
    '',
  ],
  [EventTypeBackend.BUYBACK]: [
    'Transaction Date',
    'Transaction Type',
    'Shareholder',
    'Share Class',
    'No of Shares',
    '',
  ],
  [EventTypeBackend.FUNDRAISING_ROUND]: [
    'Transaction Date',
    'Transaction Type',
    'Shareholder',
    'Investments',
    'No of Shares',
    'Share Class',
    '',
  ],
  [EventTypeBackend.NEW_PLAN]: [
    'Transaction Date',
    'Transaction Type',
    'Name',
    'Equity Pool',
    'Equity Plan Type',
    '',
  ],
  [EventTypeBackend.NEW_POOL]: [
    'Transaction Date',
    'Transaction Type',
    'Name',
    '# of fully Diluted Shares',
    'Share Class',
    '',
  ],
  [EventTypeBackend.SHARE_ISSUANCE]: [
    'Transaction Date',
    'Transaction Type',
    'Shareholder',
    'Valuation Event',
    'Share Class',
    'Share Price',
    'No of Shares',
    '',
  ],
  [EventTypeBackend.SECONDARIES]: [
    'Transaction Date',
    'Transaction Type',
    'From Stakeholder',
    'To Stakeholder',
    'Share Class',
    'No of Shares',
    'Share Value',
    'Transfer Amount',
    '',
  ],
  [EventTypeBackend.VALUATION]: [
    'Transaction Date',
    'Transaction Type',
    'Share Price',
    'Pre-Money Valuation',
    '',
  ],
};
