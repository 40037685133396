import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Snapshot } from '../../types/snapshot.types';
import { useAppQuery } from '../useAppQuery';
import { useFormat } from '../useFormat';

export const useSnapshotByDate = ({
  companyId,
  date,
}: {
  companyId: string;
  date: Date | null | undefined;
}) => {
  const queryClient = useQueryClient();
  const { format } = useFormat();
  const formattedDate = date ? format(date, 'yyyy-MM-dd') : null;

  const { data: snapshot, isLoading } = useAppQuery<Snapshot>({
    queryKey: [QueryKey.GET_SNAPSHOT_BY_DATE, QueryKey.GET_SNAPSHOT, { companyId, formattedDate }],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'snapshots', 'custom-date', date?.toISOString() || ''],
      }),
    enabled: !!companyId && !!formattedDate && !!date,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SNAPSHOT_BY_DATE] });
  }, [queryClient]);

  return {
    invalidateQuery,
    snapshot,
    isLoading,
  };
};
