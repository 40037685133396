import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useRequestExercise = () => {
  const { mutate: request, isPending } = useAppMutation([QueryKey.EXERCISE], {
    mutationFn: ({
      companyId,
      data,
    }: {
      companyId: string;
      data: {
        vestingTaskId: string;
        value: number;
        payout?: number;
      };
    }) =>
      ApiService.post(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'request-exercise'] },
        { body: data },
      ),
  });

  return { request, isPending };
};
