import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CapQuestLogo } from '../../assets/icons';
import TickIcon from '../../assets/icons/TickIcon';
import signUpImage1 from '../../assets/images/image-carousel-1.png';
import signUpImage2 from '../../assets/images/image-carousel-2.png';
import signUpImage3 from '../../assets/images/image-carousel-3.png';
import signUpImage4 from '../../assets/images/image-carousel-4.png';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { GoogleAuthButton } from '../../components/GoogleAuthButton';
import ImageCarousel from '../../components/ImageCarousel';
import { ImageCarouselItem } from '../../components/ImageCarousel/ImageCarousel';
import { Input } from '../../components/Input';
import LineDelimiter from '../../components/LineDelimiter';
import OrDelimiter from '../../components/OrDelimiter';
import { BackendRoute } from '../../config';
import { HttpException } from '../../exceptions';
import { HttpExceptionBody } from '../../exceptions/HttpException';
import { useAppMutation, useLocale } from '../../hooks';
import { StringKey } from '../../lang';
import { ApiService } from '../../services';
import { inviteStakeholderStore } from '../../storage/invitedStakeholder';
import { AuthProvider } from '../../types/authTypes';
import { PageRoute } from '../../types/pageTypes';
import { TermsTab } from '../Terms';

const mockCarouselItems: ImageCarouselItem[] = [
  {
    image: signUpImage1,
    title:
      'Build ownership structures to cultivate and attract talent. Manage all aspects of equity in one single place.',
    from: 'Grow ',
  },
  {
    image: signUpImage2,
    title:
      'Deliver precise equity tracking and compliance assurance, fostering transparency and investor confidence',
    from: 'Govern ',
  },
  {
    image: signUpImage3,
    title:
      'Empower and align shareholders and employees through complete transparency of ownership with personalized dashboards and metrics. ',
    from: 'Empower',
  },
  {
    image: signUpImage4,
    title:
      'Simulate the impact financing rounds and exit scenarios will have on ownership and dilution.  ',
    from: 'Strategize',
  },
];

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const { locale } = useLocale();
  const [isVerificationLinkSent, setIsVerificationLinkSent] = useState(false);
  const continueWithGoogle = async () => {
    window.location.replace(`${process.env.REACT_APP_BACKEND_URL}/auth/google/callback`);
  };

  const handleSendVerificationLink = async () => {
    await ApiService.post({ endpoint: BackendRoute.VERIFICATION }, { body: { email }, locale });
    setIsVerificationLinkSent(true);
  };

  const handleResendVerificationLink = async () => {
    await ApiService.post(
      { endpoint: BackendRoute.VERIFICATION_REFRESH },
      { body: { email }, locale },
    );
  };

  const verificationLinkMutation = useAppMutation<void, HttpException>([], {
    mutationFn: handleSendVerificationLink,
    defaultErrorHandling: false,
  });

  const verificationRefreshLinkMutation = useAppMutation<void, HttpException>([], {
    mutationFn: handleResendVerificationLink,
    defaultErrorHandling: false,
  });

  if (verificationLinkMutation.error instanceof HttpException) {
    console.error(verificationLinkMutation.error);
  }

  useEffect(() => {
    const invitation = inviteStakeholderStore.get();
    if (!invitation) return;

    if (invitation.isRegistered) {
      inviteStakeholderStore.set({ ...invitation, isRegistered: false });
      navigate('/login');
      return;
    }

    setEmail(invitation.email);
  }, [navigate]);

  const error = verificationLinkMutation.error?.body as
    | (HttpExceptionBody & {
        error: AuthProvider;
      })
    | undefined;

  useEffect(() => {
    if (error) {
      navigate(`/login?email=${encodeURIComponent(email)}`);
    }
  }, [email, error, navigate]);

  return (
    <div className="flex h-full justify-between lg:p-6">
      <div className="hidden w-full lg:block">
        <ImageCarousel items={mockCarouselItems} />
      </div>
      <div className="h-full w-full overflow-y-auto">
        <div className="flex h-full flex-col items-center justify-between px-[clamp(16px,3%,24px)] pt-16">
          <div className="flex h-full max-w-[394px] flex-col justify-between gap-2">
            <div className="flex h-fit w-full flex-col gap-8">
              <div>
                <CapQuestLogo className="size-12 lg:size-16" />
              </div>

              <div className="flex flex-col gap-2">
                <div
                  className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
                  style={{
                    background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
                  }}
                >
                  <AppFormattedMessage
                    id={
                      isVerificationLinkSent
                        ? StringKey.CHECK_YOUR_INBOX
                        : StringKey.WELCOME_TO_CAPQUEST
                    }
                  />
                </div>
                <div className="text-sm text-gray-500">
                  {isVerificationLinkSent ? (
                    <AppFormattedMessage id={StringKey.SENDED_LOGIN_VERIFY_LINK} />
                  ) : (
                    <AppFormattedMessage id={StringKey.SIGN_UP_MESSAGE} />
                  )}
                </div>
              </div>

              <div className="flex flex-col gap-6">
                {!isVerificationLinkSent && (
                  <>
                    <GoogleAuthButton onClick={continueWithGoogle} />
                    <OrDelimiter />
                  </>
                )}

                <Input
                  disabled={isVerificationLinkSent}
                  errorMessage={verificationLinkMutation.error?.body?.message}
                  icon={isVerificationLinkSent ? <TickIcon /> : undefined}
                  isSuccess={isVerificationLinkSent}
                  onChange={setEmail}
                  placeholder={<AppFormattedMessage id={StringKey.EMAIL_ADDRESS} />}
                  type="email"
                  value={email}
                />

                {!isVerificationLinkSent && (
                  <Button
                    disabled={!email}
                    isLoading={verificationLinkMutation.isPending}
                    onClick={() => verificationLinkMutation.mutate()}
                  >
                    <AppFormattedMessage id={StringKey.CONTINUE} />
                  </Button>
                )}
              </div>

              {isVerificationLinkSent && (
                <div className="flex flex-col">
                  <button
                    className="w-fit font-inter text-sm font-[450] text-[#2565C8] underline"
                    onClick={() => verificationRefreshLinkMutation.mutate()}
                  >
                    <AppFormattedMessage id={StringKey.RESEND_VERIFICATION_EMAIL} />
                  </button>
                  {verificationRefreshLinkMutation.error && (
                    <div className="text-sm text-fireside-600">
                      {verificationRefreshLinkMutation?.error?.body?.message}
                    </div>
                  )}
                  {verificationRefreshLinkMutation.isSuccess && (
                    <div className="text-sm text-forest-500">
                      Successfully resent verification link
                    </div>
                  )}
                </div>
              )}

              <div className="flex h-full w-full flex-col items-start justify-center gap-8">
                <LineDelimiter />
                <div className="flex items-center gap-2 text-sm">
                  <div className="font-[450] text-gray-700">
                    <AppFormattedMessage id={StringKey.ALREADY_HAVE_ACCOUNT} />
                  </div>
                  <button
                    className="font-[550] text-[#2565C8] underline"
                    onClick={() => navigate('/login')}
                  >
                    <AppFormattedMessage id={StringKey.LOG_IN} />
                  </button>
                </div>
              </div>
            </div>

            <div className="font-inter text-label-md font-semibold text-gray-500">
              <AppFormattedMessage id={StringKey.BY_PROCEEDING_YOU_AGREE_TO} />{' '}
              <button
                className="text-[#2565C8]"
                onClick={() => navigate(PageRoute.TERMS, { state: TermsTab.TERMS })}
              >
                <AppFormattedMessage id={StringKey.TERMS_OF_SERVICE} />
              </button>{' '}
              <AppFormattedMessage id={StringKey.AND} />{' '}
              <button
                className="text-[#2565C8]"
                onClick={() => navigate(PageRoute.TERMS, { state: TermsTab.POLICY })}
              >
                <AppFormattedMessage id={StringKey.PRIVACY_POLICY} />
              </button>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
