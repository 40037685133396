import { FC } from 'react';

export type InfoCellProps = { title: string | undefined; description?: string };

export const InfoCell: FC<InfoCellProps> = ({ description, title }) => (
  <div className="flex flex-col">
    <span className="text-xs font-[450] text-gray-700">{title}</span>
    <span className="text-label-sm font-medium text-gray-500">{description}</span>
  </div>
);
