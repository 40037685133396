import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';
import { useNotifications } from '../../context/notifications.context';
import { NotificationType } from '../../types/notification.types';

export const useMarkRead = ({
  companyId,
  onSuccess,
}: {
  companyId: string;
  onSuccess?: () => void;
}) => {
  const { handleReadNotification } = useNotifications();
  const { mutate: markAsRead } = useAppMutation([QueryKey.MARK_NOTIFICATION_AS_READ, companyId], {
    mutationFn: ({ id, type }: { id: string; type: NotificationType }) =>
      ApiService.post({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'notifications', id, 'mark-read'],
      }),
    onSuccess: (_, { type }) => {
      onSuccess?.();
      handleReadNotification(type);
    },
  });

  return {
    markAsRead,
  };
};
